<template>
  <div class="confirm-dialog">
    <div class="dialog-content">
      <img class="handleCancel" src="../../../assets/confirmDialogImg/off.png" alt="" @click="handleCancel" />
      <p class="handleText">{{ message }}</p>
      <div class="buttons">
        <img class="handleConfirm" src="../../../assets/confirmDialogImg/button.png" alt=""  @click="handleConfirm" />
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel');
    },
    handleConfirm() {
      this.$emit('confirm');
    }
  }
};
</script>
  
<style scoped>
.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .5);
  z-index: 9999;
}

.dialog-content {
  width: 14rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;;
  align-items: center;
  border-radius: 4px;
  z-index: 999;
  background-image: url('../../../assets/confirmDialogImg/bg.png');
  background-size: 100% 100%;
  position: relative;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

button {
  margin-left: 10px;
}

.handleText {
  font-size: 18px;
}

.handleConfirm {
  position: absolute;
  bottom: 1rem;
  left: 30%;
  width: 6rem;
  height: 2rem;
  border: 0;
  font-size: 18px;
}
.handleCancel{
  position: absolute;
  right: .5rem;
  top: .5rem;
  width: 1.5rem;
  height: 1.5rem;
}
</style>