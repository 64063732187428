<template>
    <!-- 用户点击的按钮，基本公共样式 -->
    <div class="cursor-pointer btn " >
        <div :style="size" class="text-center">
            <slot>
                <!-- 默认返回这个，如果外面有插槽显示插槽内的内容 -->
                <div class="text" :style="location">
                    {{title}}
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "bottomBg",
        props:{
            // 按钮样式
            size:{type:Object,default(){return{}}},
            // 按钮文字
            title:{type: String,default:'文字title'},
            // 按钮位置
            location:{type: Object,default(){return {}}}
        },
        data() {
            return {}
        },
    }
</script>

<style scoped lang="less">

    .text{
        text-align: center;
        color: #FFFFFF;
        line-height: 35px;
    }
    .btn{
        width: 100%;
        font-size: 24px;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        background: #00c74d;
        box-shadow: 0 5px #00913c;
        text-shadow: 0 5px 8px rgba(0,0,0,.5);
        font-size: 20px;
      padding: 0!important;
        @media (max-width: 1000px) {
            font-size: 16px;
        }
}
</style>
