<template>
  <div class="content">
    <div class="h3 text-white font-weight-bold text-center my-5">
      {{ id == 0?'我的历史记录':'最近掉落'}}
    </div>
    <div @click="codeBack" class="p-2 bg-yellow cursor-pointer d-flex col-md-1 col-4 justify-content-center ml-auto">
      返回
    </div>

    <div class="mt-3  content-list">
      <table cellspacing="0" cellpadding="0" border="0" class="content-list-box" style="">
        <colgroup>
          <col width="100">
          <col v-if="id != 0" width="200">
          <col width="200">
          <col width="120">
          <col width="120">
          <col width="100">
          <col width="100">

          <col width="150">
        </colgroup>
        <thead class="text-danger">

        <tr class="grade">
          <th colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">合成结果 </div>
          </th>
          <th v-if="id != 0" colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">用户</div>
          </th>

          <th v-if="id != 0" colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">拉货比率</div>
          </th>
          
          <th colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">饰品图片</div>
          </th>

          <th colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">获得饰品外观</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">获得饰品价值</div>
          </th>


          <th colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">目标饰品名称</div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">目标饰品外观 </div>
          </th>
          <th colspan="1" rowspan="1">
            <div class="cell" style="color:white;font-weight: 900;">时间 </div>
          </th>


        </tr>
        </thead>
        <tr class="grade lucky-H-bg border-bottom" :class="item.award_id == item.get_award_id?'color_yellow':''" v-for="(item,index) in historyList" :key="index">


          <td>
            <div v-if="item.award_id != item.get_award_id" class="cell flex-column align-items-center justify-content-center" >
              <div><img style="width:50px" src="@/assets/img/lucky/upgradeHistory__lose.png" alt=""></div>
              <div>失败</div>
            </div>
            <div v-else class="cell flex-column align-items-center justify-content-center" >
              <div><img style="width:50px" src="@/assets/img/lucky/upgradeHistory__succeed.png" alt=""></div>
              <div>成功</div>
            </div>
          </td>

          <td v-if="id != 0" >
            <div class="px-4 pb-1 text-center d-flex align-items-center justify-content-center" >
              <img style="height: 50px" class="rounded-pill"  :class="item.is_anchor==1?'imgbac':'imgbacs'" :src="item.img" alt="">
              <div class="text-overflow-1 ml-1">{{item.user_name}}</div>
            </div>
          </td>

         
          <td>
            <div class="px-4  text-center d-flex flex-column align-items-center">
              <div class="text-overflow-1 ml-1">{{item.percent}}%</div>
            </div>
          </td>
          <td>
            <div class="px-4  text-center d-flex flex-column align-items-center cell">
              <img style="height: 50px" class="" :src="item.get_award_img" alt="">
              <!-- <div class="" style="font-size: 12px">{{item.get_award_name }}</div> -->
            </div>
          </td>
          <td >
            <div class="cell"><img style="height: 50px" :src="item.get_award_img " alt=""></div>
          </td>

          <td>
            <div class="cell">{{item.get_bean }}</div>
          </td>




          <td >
            <div class="cell">
              {{item.award_name}}
            </div>
          </td>

          <td >
            <div class="cell"><img style="height:50px" :src="item.award_img" alt=""></div>
          </td>
          <td >
            <div class="cell">{{item.create_time }}</div>
          </td>




        </tr>
      </table>
      <div class="mt-3">
        <!-- <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          @current-change="currentChange"
        >
        </el-pagination> -->
      </div>
    </div>


  </div>
</template>
<script>

import $api from '@/api/lucky/index'
export default {
  name: "history",
  props:{
    id:{
      type:[String,Number],
    }
  },
  components:{
  },
  data(){
    return {
      activetab: "history",
      historyList:[],
      pageSize:0,
      total:0,
    }
  },
  created() {
    console.log(this.id);
    // this.id = this.$route.params.id

  },
  async mounted() {
    if(this.id == 0){
      await this.getHistoryList()
    }else {
      this.getHistory()
    }
  },
  computed:{

  },
  methods: {
    // 翻页
    currentChange(val) {
      if(this.id == 0){
        this.getHistoryList(val)
      }else {
        this.getHistory()
      }
      window.scrollTo(0,0)
    },
    // 获取数据列表
    getHistoryList(page = 1){
      $api.meHistory(page).then(res => {
        // console.log(res)
        this.historyList = res.data.data
        this.total = res.data.total
        this.pageSize = res.data.per_page
        console.log(this.historyList);
      })

    },
    getHistory(){
      let time = parseInt(new Date().getTime() / 1000);
      let sign = this.$md5("cs#.go" + time);
      $api.getHistory(this.id,sign,time).then(res => {
        // console.log(res)
        this.historyList = res.data
        console.log(this.historyList);
        this.total = 0
        this.pageSize = 0
      })

    },
    codeBack(){
      this.$emit('codeBack',false)
    }
  }

}

</script>

<style scoped lang="less">
 .imgbac{
    background: url('../../assets/newimg/header.png') no-repeat center !important;
    background-size: 100% 100% !important;
    padding: 3px;
   }
   .imgbacs{
    background: url('../../assets/newimg/header2.png') no-repeat center !important;
    background-size: 100% 100% !important;
    padding:2px;
   }
.content-list{
  overflow-x: scroll;
  width: 100%;
  &-box{
    width: 100%;
    @media (max-width: 1366px) {
      width: 1200px;
    }
  }
}
.lucky-H-bg{
  background-image: url("../../assets/img/lucky/upgradeHistory__item__bg.png") !important;
  background-size: 100% 100%;
  margin-bottom: 10px;
}
td{
  height: 100px;
  vertical-align: middle;
}
.grade {
  background-image: linear-gradient(0deg,#13161b,#20232b),linear-gradient(#13161b,#13161b);
  @media (max-width: 1200px) {
    font-size: 12px;
  }
}

.input-code {
  @media (max-width: 1200px) {
    width: 150px !important;
  }
}

.tong-height {
  height: 38px;
  line-height: 38px;
}

.side-box {
  width: 204px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5)
}

.content {
  width: 90%;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 96%;
    font-size: 12px !important;
  }
}

.cell {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 14px;
  @media (max-width: 1200px) {
    padding: 5px;
  }
}
.highlighted-message {
  padding: 19px 5px 19px 16px;
  border: 1px solid #fada62;
  border-radius: 8px;
  background-color: rgba(250,218,98,0.12);
}

</style>
