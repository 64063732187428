<template>
  <div class="spread">
    <div class="spread-warp">
      <div class="roomleft-num">
        <div class="nav_item">消息中心</div>
        <span @click="deleInform">删除已读</span>
      </div>

      <div class="hist-list">
        <!-- 取回通知 -->
        <!-- <div>
            <div class="bot">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="日期" width="180">
                </el-table-column>
                <el-table-column prop="name" label="内容" width="180">
                </el-table-column>
                <el-table-column prop="address" label="状态"> </el-table-column>
              </el-table>
            </div>
          </div> -->
        <!-- 系统通知 -->
        <div>
          <div class="bot">
            <el-table
              :cell-style="cellstyle"
              :data="tableData"
              style="width: 100%"
            >
              <el-table-column prop="create_time" label="日期" width="120">
              </el-table-column>
              <el-table-column prop="message" label="内容"> </el-table-column>
              <el-table-column label="状态" width="120">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.status == 0 ? "未读" : "已读" }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="roll-page">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totalSize"
              :page-size="pageSize"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      totalSize: 0,
      pageSize: 10,
      tableData: [
        // {
        //   date: "2022-06-01  09：42：25",
        //   name: "通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知通知...",
        //   address: "ROLL房通知",
        // },
      ],
      page: 1,
    };
  },
  created() {
    this.currentChange();
  },
  mounted() {
    //8444242
    // this.reviseInform();
  },
  watch: {
    // tableData: {
    //   handler(newVal, oldVal) {
    //     console.log(newVal);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  methods: {
    //修改消息已读
    reviseInform() {
      // console.log("this.tableData11111");
      const ids = [];
      this.tableData.forEach((item) => {
        console.log("this.tableData222222", this.tableData);
        if (item.status == 0) {
          // item.id = item.id.toString();
          ids.push(item.id);
          // ids.toString();
        }
        console.log("ids333333", ids);
      });
      this.$post("/api//user/r_user_message", {
        ids: ids.toString(),
      }).then((res) => {
        console.log("reviseInform444444", res);
        this.tableData.forEach((ele) => {
          ele.status = 1;
        });
        // this.currentChange(this.page);
        // this.currentChange();
      });
    },
    // 指定未读颜色
    cellstyle({ row, column }) {
      if (row.status == 0) {
        return "color: #FF0000";
      }
    },
    //删除已读消息
    deleInform() {
      this.$post("/api//user/del_user_message")
        .then((res) => {
          console.log("del_user_message", res);
          this.currentChange();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    currentChange(page) {
      // let _this = this;
      let param = {
        // prage: this.prage,
        page: page,
        pageSize: this.pageSize,
      };
      this.$post("/api//user/user_message", param)
        .then((res) => {
          console.log("user_message", res);
          // this.active = res.data.active;
          if (res.code == 1) {
            this.tableData = res.data.list;
            this.totalSize = res.data.total;
          }
          this.reviseInform();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.spread {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  z-index: 1;
  .spread-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.3);
  }
  .roomleft-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid #ffc400;
    .nav_item {
      width: 156px;
      height: 61px;
      text-align: center;
      line-height: 61px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
    }
    span {
      font-size: 16px;
      color: #ff5cfd;
      cursor: pointer;
    }
  }
  //页数
  .roll-page {
    margin-top: 80px;
  }
  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #ff5cfd;
    color: #1a1c24;
  }
  .roll-page /deep/ .el-pagination {
    text-align: center;
  }
  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #333542;
    color: #848492;
  }
  .hist-list /deep/ .el-table th {
    text-align: center;
    color: #fff;
  }
  .hist-list /deep/ .el-table td {
    text-align: center;
    color: #ccc;
  }
  //表格
  .hist-list /deep/ .el-table th,
  .hist-list /deep/ .el-table tr {
    background: none;
  }
  .hist-list /deep/ .el-table td,
  .hist-list /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }
  .hist-list /deep/ .el-table::before {
    height: 0;
  }
  .hist-list /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: none;
  }
  .hist-list /deep/ .el-table,
  .el-table__expanded-cell {
    background: none;
  }
  /*.list /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }*/
  .hist-list /deep/ .el-table__empty-block {
    background-color: #2b2c37;
  }

  //tabs
  //tabs
  .hist-list {
    margin-top: 20px;
  }
  .hist-list /deep/ .el-tabs--border-card {
    //overflow: hidden;
    background-color: #2b2c37;
    border: none;
    // border-radius: 5px;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: #1a1c24;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    background-color: #24252f;
    border: none;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #e9b10e;
  }
}
</style>
