<template>
  <div class="win">
    <!-- <div class="win-top">
      <img src="../assets/newimg/img_50.png" />
    </div> -->
    <!--  -->
    <div
      :class="{
        'win-con': list1.length == 1,
        'win-con1': list1.length == 2,
        'win-con2': list1.length == 3,
        'win-con3': list1.length == 4,
        'win-con4': list1.length == 5,
      }"
    >
      <ul>
        <li
          @click="isCheckShow(index)"
          :class="item.check == false ? 'active' : ''"
          v-for="(item, index) in list1"
          :key="index"
          :style="'background-image: url('+item.background + ')'"
        >
          <div class="win-warp">
            <div class="win-img" style="margin-top:-20px">
              <img :src="item.img" />
            </div>
            <div style="color: #fff" class="win-text mt-3">{{ item.price }}</div>
            <div class="win-text" :title="item.name">{{ item.name }}</div>
            <!-- {{ item.name }} -->
          </div>
        </li>
      </ul>
    </div>
    <div class="win-price">
      <img src="../assets/img/money.png" /><span>{{ total_price }} </span>
      <img
        style="display: inline-block-block; margin-left: 15px"
        src="../assets/img/moneyRed.png"
      />
      <!-- <span>{{ list1.scoreAll }} </span> -->
      <span> {{ scoreAll }}</span>
    </div>
    <div class="win-bot">
      <div class="win-span2" @click="winget">放入背包</div>
      <div class="win-span1" @click="winexchange">兑换</div>
    </div>
    <div class="win-x" @click="winX">
      <img src="../assets/newimg/img_02.png" alt="" />
    </div>
    <!-- <div class="win-back">
      <img src="../assets/img/win3.png" />
    </div> -->
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import { log } from "util";
export default {
  props: ["winList", "winState", "player_skins_ids", "total_prices"],
  data() {
    return {
      //winState: true,
      list4: [],
      total_price: 0,
      scoreAll: 0,
      list1: [],
      boxIds: [],
      // scoreAll: "",
    };
  },
  methods: {
    isCheckShow(index) {
      this.$emit("Showindex", index);
      // console.log("11111", this.winList.skins_info[index].check);
    },
    //兑换
    winexchange() {
      this.$emit("winexchange", false);
      this.list1.forEach((item) => {
        if (item.check == true) {
          this.boxIds.push(item.player_skins_id);
        }
      });
      // console.log("666666", this.boxIds);
      let param = {
        player_id: this.$store.state.id,
        player_skins_ids: this.boxIds,
      };
      this.$post("/api/Users/exchangeToMoney", param)
        .then((res) => {
          if (res.status == 1) {
            this.$store.commit("getMoney", res.data.total_amount);
            Utils.$emit("money", res.data.total_amount);
            this.$message({
              message: res.msg,
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //放入背包
    winget() {
      this.$emit("winget", false);
    },
    //x掉
    winX() {
      this.$emit("winX", false);
    },
  },
  watch: {
    "winList.skins_info": {
      handler(a, b) {
        this.list1 = a;
        this.total_price = 0;
        this.scoreAll = 0;
        this.list1
          .filter((item) => item.check == true)
          .forEach((ele) => {
            this.total_price += Number(ele.price);
            this.scoreAll += Number(ele.score);
            // console.log("222222", ele.player_skins_id);
          });
        this.total_price = this.total_price.toFixed(2);
        this.scoreAll = this.scoreAll.toFixed(2);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.scoreAll = this.$store.state.scro;
    // console.log("this.$store.state.score", this.$store.state.scro);
    // console.log(this.winList);
    // for (let i = 0; i < this.list1.length; i++) {
    //   this.total_price += Number(this.list1[i].price);
    // }
    // this.total_price = this.total_price.toFixed(2);
  },
};
</script>

<style lang="less" scoped>
.win {
  width: 668px;
  //padding: 20px;
  //background-color: rgba(0, 0, 0, 0.2);
  position: relative;

  .win-back {
    width: 100%;
    height: auto;
    position: absolute;
    top: 15%;
    left: 0;
    z-index: -10;
    animation: move 5s linear infinite;

    img {
      width: 100%;
      height: auto;
    }
  }
  @keyframes move {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .win-top {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    img {
      width: 185px;
      height: 55px;
    }
  }
  .win-x {
    position: absolute;
    top: 7%;
    right: 0px;
    width: 52px;
    height: 45px;
    img {
      width: 52px;
      height: 45px;
    }
  }
  .win-x:hover {
    cursor: pointer;
  }
  .win-price {
    font-size: 20px;
    color: #ffc400;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    img {
      margin-right: 14px;
      width: auto;
      height: 26px;
    }
  }
  .win-bot {
    margin-top: 35px;
    display: flex;
    justify-content: center;

    .win-span1 {
      width: 149px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(../assets/newimg/fangru.png) no-repeat;
      background-size: 100% 100%;
      margin-left: 65px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0bfeb4;
    }

    .win-span1:hover {
      cursor: pointer;
    }
    .win-span2 {
      width: 149px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(../assets/newimg/img_52.png) no-repeat;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0bbcfe;
    }
    .win-span2:hover {
      cursor: pointer;
    }
  }

  .win-con {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 536px;
    height: 310px;
    background: url(../assets/newimg/img_51.png) no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    // padding-top: 40px;
    box-sizing: border-box;
    ul {
      width: 60%;
      // height: 85%;
      height: 70%;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      li {
        width: 70%;
        border-radius: 5px;
        overflow: hidden;

        background: url(../assets/newimg/img_69.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        .win-warp {
          width: 100%;
          position: relative;
          .win-img {
            padding: 10px;
            height: 135px;
            img {
              width: 100%;
              height: auto;
            }
          }
          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }
          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }
          span {
            position: absolute;
            right: 1px;
            top: 1px;
            background-color: rgba(0, 0, 0, 0.2);
            color: #fff;
            font-size: 12px;
            padding: 2px 4px;
            border-radius: 20px;
            min-width: 20px;
            text-align: center;
          }
          .win-text {
            width: 100%;
            padding: 10px 0;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
      .active {
        background: none;
      }
    }
  }

  .win-con1 {
    display: flex;
    justify-content: center;
    width: 536px;
    height: 310px;
    background: url(../assets/newimg/img_51.png) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    margin: 0 auto;
    ul {
      width: 60%;

      margin-top: 30px;
      display: flex;
      li {
        margin: 5px;
        width: 50%;
        border-radius: 5px;

        background: url(../assets/newimg/img_69.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        // background: url(../assets/newimg/kailistbg.png) no-repeat;
        // background-size: 100% 100%;
        .win-warp {
          width: 100%;
          position: relative;
          .win-img {
            padding: 10px;
            height: 105px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: auto;
            }
          }
          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }
          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }
          span {
            position: absolute;
            right: 1px;
            top: 1px;
            background-color: rgba(0, 0, 0, 0.2);
            color: #fff;
            font-size: 12px;
            padding: 2px 4px;
            border-radius: 20px;
            min-width: 20px;
            text-align: center;
          }
          .win-text {
            width: 100%;
            padding: 10px 0;
            padding-left: 8px;
            // padding: 10px;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
      .active {
        background: none;
      }
    }
  }

  .win-con2 {
    display: flex;
    justify-content: center;
    width: 536px;
    height: 310px;
    background: url(../assets/newimg/img_51.png) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    margin: 0 auto;
    ul {
      margin: 0 -5px;
      margin-top: 30px;
      width: 86%;
      display: flex;
      flex-wrap: wrap;
      li {
        margin-top: 5px;
        overflow: hidden;
        // min-width: 33.33%;
        max-width: 32%;
        // border-radius: 5px;
        // background-color: #e2c873;
        background: url(../assets/newimg/img_69.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        margin-right: 5px;
        .win-warp {
          width: 100%;
          position: relative;
          .win-img {
            // padding: 10px;
            img {
              border-top-right-radius: 5px;
              width: 100%;
              height: auto;
            }
          }
          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }
          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }
          span {
            position: absolute;
            right: 1px;
            top: 1px;
            background-color: rgba(0, 0, 0, 0.2);
            color: #fff;
            font-size: 12px;
            padding: 2px 4px;
            border-radius: 20px;
            min-width: 20px;
            text-align: center;
          }
          .win-text {
            width: 100%;
            padding: 5px 0;
            // padding: 10px;
            padding-left: 8px;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
      li:nth-child(3n) {
        margin-right: 0;
      }
      .active {
        background: none;
      }
    }
  }

  .win-con3 {
    display: flex;
    justify-content: center;
    width: 536px;
    height: 385px;
    background: url(../assets/newimg/img_51.png) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    margin: 0 auto;
    ul {
      // margin: -5px;
      margin-top: 70px;
      width: 65%;
      display: flex;
      flex-flow: row wrap;
      li {
        // margin: 5px;
        // min-width: 50%;
        max-width: 49%;
        background: url(../assets/newimg/img_69.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        margin-bottom: 5px;
        margin-right: 5px;
        .win-warp {
          // width: 100%;
          position: relative;
          .win-img {
            padding: 10px 10px 0;
            box-sizing: border-box;
            height: 105px;
            img {
              width: 100%;
              height: auto;
            }
          }
          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }
          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }
          span {
            position: absolute;
            right: 1px;
            top: 1px;
            background-color: rgba(0, 0, 0, 0.2);
            color: #fff;
            font-size: 12px;
            padding: 2px 4px;
            border-radius: 20px;
            min-width: 20px;
            text-align: center;
          }
          .win-text {
            width: 100%;
            //padding: 6px 0;
            // padding: 10px;
            padding-left: 8px;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
      }
      li:nth-child(2n) {
        margin-right: 0;
      }
      .active {
        background: none;
      }
    }
  }

  .win-con4 {
    display: flex;
    justify-content: center;
    width: 536px;
    height: 370px;
    background: url(../assets/newimg/img_51.png) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    margin: 0 auto;
    padding-top: 50px;
    ul {
      margin-top: 30px;
      width: 80%;
      display: flex;
      flex-flow: row wrap;
      li {
        // margin: 5px;
        // min-width: 33.33%;
        max-width: 33.33%;
        // padding: 2px;
        background: url(../assets/newimg/img_69.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        margin-bottom: 5px;
        //  margin-right: 5px;
        .win-warp {
          overflow: hidden;
          // width: 100%;
          position: relative;
          .win-img {
            padding: 10px;
            height: 100px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: auto;
            }
          }
          .win-img1 {
            background-image: url("../assets/img/box-skins-violet.jpg");
          }
          .win-img2 {
            background-image: url("../assets/img/box-skins-golden.jpg");
          }
          span {
            position: absolute;
            right: 1px;
            top: 1px;
            background-color: rgba(0, 0, 0, 0.2);
            color: #fff;
            font-size: 12px;
            padding: 2px 4px;
            border-radius: 20px;
            min-width: 20px;
            text-align: center;
          }
          .win-text {
            width: 95%;
            padding-left: 8px;
            //padding: 6px 0;
            // padding: 10px;
            color: #fff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
          }
        }
        // &.active {
        //   background: none;
        // }
      }
      .active {
        background: none;
      }
      li:nth-child(1) {
        min-width: 40%;
        max-width: 40%;
        margin-left: 10%;
      }
      li:nth-child(2) {
        min-width: 40%;
        max-width: 40%;
      }
    }
  }
}
</style>
