  export function battle() {
    // const wsuri = "ws://47.98.63.155:7654/websocket/";
    const wsuri = process.env.NODE_ENV === 'production'?"wss://v8steam.com/websocket/":"wss://ceshi.v8steam.com/websocket/";
    // const wsuri = "wss://csgo.huolieniaowangluo.com/websocket/";
    // const wsuri = "wss://cz-chunxinhuanbao.com/ws/";
    // const wsuri = "ws://192.168.101.12:7654/";
    // const wsuri = "ws://192.168.149.1:7654/";
    return wsuri;
  }
  export function openBox() {
    // const wsuri = "ws://47.98.63.155:7654/websocket/"
    const wsuri = process.env.NODE_ENV === 'production'?"wss://v8steam.com/websocket/":"wss://ceshi.v8steam.com/websocket/";
    // const wsuri = "wss://csgo.huolieniaowangluo.com/websocket/";
    // const wsuri = "wss://cz-chunxinhuanbao.com/wss/";
    // const wsuri = "ws://192.168.101.12:7655/";
    // const wsuri = "ws://192.168.149.1:7655/";
    return wsuri;
  }