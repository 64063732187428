<template>
  <div class="me">
    <div class="me-warp">
      <div class="me-title1">
        <div class="nav_item">个人中心</div>
      </div>

      <div class="me-tou">
        <div class="tou-left">
          <img :src="Data.img" />
          <span>
            <el-upload
              class="upload-demo"
              action="/"
              multiple
              :limit="1"
              :http-request="upLoad"
              ref="uploadImage"
            >
              <!-- :on-success="getImg" -->
              <el-button size="small" type="primary" class="btn"
                >编辑</el-button
              >
            </el-upload>
          </span>
        </div>
        <div class="tou-right">
          <div class="tou-right-top">
            <div class="tou1">
              <!-- <img src="../assets/img/13mdpi.png" /> -->
              <span class="tou1-span1">{{ Data.name }}</span>
              <!-- <span class="tou1-span2">Lv 1</span> -->
              <!-- <i class="el-icon-edit" @click="getName"></i> -->
            </div>
            <div class="tou2">
              <div class="tou2-name">
                用户ID：{{ Data.id }}
                <span class="fz" @click="copyText(Data.id)">复制</span>
              </div>
            </div>
          </div>
          <div class="tou-right-bot">
            <span>上次登录：{{ site }}</span>
            <span>{{ time }}</span>
          </div>
        </div>
      </div>

      <!-- <div class="me-title">Steam交易链接</div> -->

      <!-- <div class="url">
        <div class="url1">输入您的交易链接</div>
        <div class="url2">
          <div class="url2-input">
            <input type="text" v-model="url" :disabled="urlState" />
          </div>
          <div class="url2-btn">
            <span @click="edit" v-if="urlState">编辑</span>
            <span @click="off" v-if="!urlState" class="url2-btn">取消</span>
            <span @click="confirm" v-if="!urlState">保存</span>
          </div>
        </div>
        <div class="url3">
          <span
            >(1) 获取你的Steam交易链接
            <strong @click="goUrl(url1)">点击这里</strong></span
          >
          <span
            >(2) 打不开 Steam 怎么办?
            <strong @click="goUrl(url2)">点击看教程</strong></span
          >
        </div>
      </div> -->

      <div class="me-title">邀请</div>
      <div class="invite">
        <div class="input-con">
          <div style="display: flex">
            <div class="input-top">邀请码</div>
            <div class="input-bot">
              <input
                disabled
                type="text"
                v-model="p_invite_code2"
                placeholder="绑定邀请码"
              />
            </div>
          </div>
          <div class="fuzhi">
            <span>绑定</span>
          </div>
        </div>
        <div class="input-con">
          <div style="display: flex">
            <div class="input-top">绑定邀请码</div>
            <div class="input-bot">
              <input :disabled="codeShow" type="text" v-model="p_invite_code" />
            </div>
          </div>
          <div class="fuzhi">
            <span @click="copyText2()">绑定</span>
          </div>
        </div>

        <!-- <div class="invite-list" v-if="inviteImg">
          <img v-if="inviteImg" :src="inviteImg" />
          <span>{{ inviteName }}</span>
        </div>
        <div class="invite-list1" v-if="!inviteImg">新用户3天内可以绑定上级<span @click="openTop">绑定</span></div> -->
      </div>

      <div class="me-title">账号管理</div>

      <div class="number">
        <div class="input-con">
          <div style="display: flex">
            <div class="input-top">手机号</div>
            <div class="input-bot">
              <span>{{ Data.mobile }}</span>
            </div>
          </div>
          <div class="fuzhi">
            <span>修改</span>
          </div>
        </div>
        <div class="input-con">
          <div style="display: flex">
            <div class="input-top">邮箱绑定</div>
            <div class="input-bot">
              <input
                type="text"
                v-model="Data.email"
                placeholder="请输入绑定的邮箱号"
              />
            </div>
          </div>
          <div class="fuzhi" @click="openEmail">
            <span>绑定</span>
          </div>
        </div>
        <div class="input-con">
          <div style="display: flex">
            <div class="input-top">修改昵称</div>
            <div class="input-bot">
              <input type="text" v-model="Data.name" placeholder="请输入昵称" />
            </div>
          </div>
          <div class="fuzhi" @click="getName">
            <span>修改</span>
          </div>
        </div>
        <div class="input-con">
          <div style="display: flex">
            <div class="input-top">修改密码</div>
            <div class="input-bot">
              <input type="password" v-model="password" @focus="onfocus" />
            </div>
          </div>
          <div class="fuzhi" @click="openPass">
            <span>修改</span>
          </div>
        </div>
        <div class="input-con">
          <div style="display: flex">
            <div class="input-top">交易链接</div>
            <div class="input-bot">
              <input
                type="text"
                v-model="url"
                :disabled="urlState"
                style="width: 660px"
                placeholder="绑定steam交易链接"
              />
            </div>
          </div>
          <div class="fuzhi" style="justify-content: space-around">
            <span @click="edit" v-if="urlState">编辑</span>
            <span @click="off" v-if="!urlState" class="url2-btn">取消</span>
            <span @click="confirm" v-if="!urlState">保存</span>
          </div>
        </div>
      </div>

      <div class="me-title">偏好设置</div>

      <div class="hobby">
        <div class="hobby1">
          <span>开启声音 / 关闭声音</span>
          <el-checkbox v-model="checked1"></el-checkbox>
        </div>
        <div class="hobby1">
          <span>活动结果提醒</span>
          <el-checkbox v-model="checked2"></el-checkbox>
        </div>
      </div>
    </div>

    <span :plain="true">{{ urlHint }}</span>

    <!-- 邮箱弹框 -->
    <div class="hide-box">
      <div class=""></div>
    </div>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
export default {
  inject: ["reload"],
  data() {
    return {
      codeShow: false,
      inviteImg: "",
      inviteName: "",
      Data: {},
      site: "",
      time: "",
      url: "",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://www.bilibili.com/video/BV15D4y1X79w/",
      urlState: true,
      checked1: false,
      checked2: false,
      urlHint: "",
      p_invite_code: "",
      p_invite_code2: "",
      password: "******",
    };
  },
  mounted() {
    //console.log(this.$store.state.id)
    this.getList();
  },
  methods: {
    upLoad(file) {
      let _this = this;
      let time = parseInt(new Date().getTime() / 1000);
      let sign = this.$md5("cs#.go" + time);
      let formData = new FormData();
      formData.append("sign", sign);
      formData.append("time", time);
      formData.append("file", file.file);
      _this
        .$post("/api/common/upload", formData)
        .then((res) => {
          if (res.code == 1) {
            _this.getImg(res.data.fullurl, res.data.url);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //跟换头像
    getImg(url, urls) {
      let _this = this;
      _this
        .$post("/api/user/profile", { avatar: urls })
        .then((res) => {
          if (res.code == 1) {
            _this.Data.img = url;
            Utils.$emit("img", _this.Data.img);
            _this.reload();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //更换昵称
    getName() {
      let _this = this;
      if (_this.Data.name == "") {
        this.$message({
          message: "请输入昵称",
          type: "info",
        });
        return;
      }
      _this
        .$post("/api/user/profile", { name: _this.Data.name })
        .then((res) => {
          if (res.code == 1) {
            Utils.$emit("name", _this.Data.name);
            _this.reload();
            _this.$message({
              message: "修改成功",
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // this.$prompt("请输入昵称", "昵称修改", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      // })
      //   .then(({ value }) => {
      //     let param = {
      //       player_id: this.$store.state.id,
      //       name: value,
      //     };
      //     this.$axios
      //       .post("/index/User/editNickname", this.$qs.stringify(param))
      //       .then((res) => {
      //         var data = res.data;
      //        // console.log(data);
      //         if (data.status == "1") {
      //           this.$message({
      //             message: "修改成功",
      //             type: "success",
      //           });
      //           this.reload();
      //         } else {
      //           this.$message({
      //             message: "修改失败",
      //             type: "info",
      //           });
      //         }
      //       });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "取消输入",
      //     });
      //   });
    },
    //邮箱绑定
    openEmail() {
      var _this = this;
      var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (!reg.test(_this.Data.email)) {
        this.$message({
          message: "请输入正确的邮箱",
          type: "info",
        });
        return;
      }
      _this
        .$post("/api/user/profile", { email: _this.Data.email })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "绑定成功",
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // this.$prompt("请输入邮箱", "邮箱绑定", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
      //   inputErrorMessage: "邮箱格式不正确",
      // })
      //   .then(({ value }) => {
      //     // console.log(value);
      //     let param = {
      //       email: value,
      //     };
      //     this.$axios
      //       .post("/index/User/bindEmail", this.$qs.stringify(param))
      //       .then((res) => {
      //         var data = res.data;
      //        // console.log(data);
      //         if (data.status == "1") {
      //           this.$message({
      //             message: "绑定成功",
      //             type: "success",
      //           });
      //         } else {
      //           this.$message({
      //             message: "绑定失败",
      //             type: "info",
      //           });
      //         }
      //       });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "取消输入",
      //     });
      //   });
    },

    //修改密码
    onfocus() {
      this.password = "";
    },
    openPass() {
      var _this = this;
      if (_this.password == "******") {
        return;
      }
      if (_this.password == "") {
        this.$message({
          message: "请输入密码",
          type: "info",
        });
        return;
      }
      _this
        .$post("/api/user/profile", { password: _this.password })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "修改成功",
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // this.$prompt("请输入密码", "密码修改", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      // })
      //   .then(({ value }) => {
      //     let param = {
      //       player_id: this.$store.state.id,
      //       password: value,
      //     };
      //     this.$axios
      //       .post("/index/User/editPass", this.$qs.stringify(param))
      //       .then((res) => {
      //         var data = res.data;
      //        // console.log(data);
      //         if (data.status == "1") {
      //           this.$message({
      //             message: "修改成功",
      //             type: "success",
      //           });
      //         } else {
      //           this.$message({
      //             message: "修改失败",
      //             type: "info",
      //           });
      //         }
      //       });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "取消输入",
      //     });
      //   });
    },
    //绑定上级
    openTop() {
      this.$prompt("请输入邀请码", "绑定上级", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let param = {
            player_id: this.$store.state.id,
            invite_code: value,
          };
          this.$axios
            .post("index/User/bindInviter", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: "绑定失败，" + data.msg,
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //个人中心信息
    getList() {
      let _this = this;
      _this
        .$post("/api/user/me")
        .then((res) => {
          var data = res;
          if (data.code == 1) {
            this.Data = data.data;
            this.url = this.Data.tradeUrl;
            this.site = this.Data.last_login_info.position;
            this.time = this.Data.last_login_info.time;
            this.p_invite_code = data.data.p_invite_code;
            if (this.p_invite_code != "") {
              this.codeShow = true;
            }
            this.p_invite_code2 = data.data.invite_code;
            if (data.data.myInviter) {
              this.inviteImg = data.data.myInviter.img;
              this.inviteName = data.data.myInviter.name;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //复制用户ID 和 steamid
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    copyText2() {
      let _this = this;
      _this
        .$post("/api/user/bindInviter", {
          invite_code: _this.p_invite_code,
        })
        .then((res) => {
          console.log("1111111222", res);
          if (res.status == 1) {
            _this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
            });
          } else {
            _this.$message({
              showClose: true,
              message: res.msg,
              type: "none",
            });
          }
        });
    },
    //steam教程
    goUrl(url) {
      window.open(url, "_blank");
    },
    edit() {
      if (this.url && this.url != "") {
        this.$message({
          showClose: true,
          message: "不可修改",
          type: "none",
        });
        return;
      }
      this.urlState = false;
    },
    off() {
      this.url = "";
      this.urlState = true;
    },
    confirm() {
      //console.log(this.url);
      let param = {
        player_id: this.$store.state.id,
        tradeUrl: this.url,
      };
      this.$axios
        .post("/index/User/bindSteam", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            const userInfo = JSON.parse(localStorage.getItem("userInfo"));
            userInfo.tradeUrl = this.url;
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.urlState = true;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
            });
            this.urlState = true;
            // this.url = '';
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.me {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  .me-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.3);
  }
  .input-con {
    width: 100%;
    height: 57px;
    background: url(../assets/newimg/img_61.png) no-repeat 50%;
    background-size: 100% 100%;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .fuzhi {
      width: 151px;
      height: 57px;
      background: url(../assets/newimg/img_52.png) no-repeat 50%;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
    .input-top {
      width: 134px;
      font-size: 16px;
      text-align: center;
      color: #fff;
      border-right: 2px solid;
      border-image: linear-gradient(
          180deg,
          rgba(221, 255, 0, 0) 0%,
          #ddff00 51%,
          rgba(221, 255, 0, 0) 100%
        )
        2 2 2 2;
      //background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #00bbf2 20%, rgba(255, 255, 255, 0) 99%);
    }
    .input-bot {
      input {
        padding: 0 30px;
        border-radius: 5px;
        width: 500px;
        color: #ccc;
        font-size: 16px;
        outline: none;
        box-sizing: border-box;
        border: none;
        background: none;
      }
      span {
        color: #ccc;
        font-size: 16px;
        padding: 0 30px;
      }
    }
  }
  .me-title1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid #ffc400;
    .nav_item {
      width: 156px;
      height: 61px;
      text-align: center;
      line-height: 61px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
    }
  }
  .me-title {
    color: #fff;
    font-size: 20px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .me-tou {
    padding: 16px;
    display: flex;
    width: 100%;
    height: 141px;
    background: url(../assets/newimg/img_63.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    margin-top: 50px;
    .tou-left {
      margin-right: 20px;
      width: 110px;
      height: 110px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 600;
        color: #848492;
        background-color: rgba(0, 0, 0, 0.7);

        .upload-demo {
          width: 100%;
          /deep/ .el-upload {
            width: 100%;
            .btn {
              width: 100%;
              color: #848492;
              background-color: rgba(0, 0, 0, 0.7);
              border-color: rgba(0, 0, 0, 0.7);
            }
          }
        }
      }
      span:hover {
        cursor: pointer;
      }
    }
    .tou-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .tou-right-top {
        .tou1 {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }
          .tou1-span1 {
            // margin-left: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #fff;
          }
          .tou1-span2 {
            margin-left: 30px;
            background-color: #858493;
            border-radius: 3px;
            font-size: 12px;
            padding: 0 8px;
          }
          i {
            margin-left: 15px;
            font-size: 20px;
            color: #858493;
          }
          i:hover {
            cursor: pointer;
            color: #e9b10e;
          }
        }
        .tou2 {
          margin-top: 12px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #c0c0c0;

          .tou2-name {
            .fz {
              width: 40px;
              height: 20px;
              background: #4f4522;
              border-radius: 5px;
              font-size: 12px;
              display: inline-block;
              text-align: center;
              line-height: 20px;
              margin-left: 20px;
              cursor: pointer;
            }
            i:hover {
              cursor: pointer;
              color: #e9b10e;
            }
          }
        }
      }
      .tou-right-bot {
        padding-bottom: 10px;
        // display: flex;
        float: left;
        span {
          color: #c0c0c0;
          margin-right: 20px;
          font-size: 14px;
        }
        span:first-child {
          color: #c0c0c0;
        }
      }
    }
  }

  .url {
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;
    .url1 {
      font-size: 14px;
      color: #848492;
    }
    .url2 {
      margin-top: 20px;
      display: flex;
      .url2-input {
        width: 380px;
        min-width: 100px;
        input {
          height: 40px;
          line-height: 40px;
          padding: 0 10px;
          border-radius: 5px;
          width: 100%;
          color: #c3c3e2;
          font-size: 12px;
          background-color: #24252f;
          outline: none;
          box-sizing: border-box;
          border: none;
        }
      }

      .url2-btn {
        span {
          display: inline-table;
          height: 40px;
          margin-left: 20px;
          line-height: 40px;
          padding: 0 20px;
          background-color: #e9b10e;
          border-radius: 5px;
          font-weight: 600;
          color: #1a1c24;
          font-size: 15px;
          white-space: nowrap;
        }
        span:hover {
          cursor: pointer;
          background-color: #f5c432;
        }
        .url2-btn {
          background-color: #333542;
          color: #848492;
        }
        .url2-btn:hover {
          background-color: #3a3f50;
        }
      }
    }
    .url3 {
      margin-top: 20px;
      span {
        margin-right: 20px;
        font-size: 14px;
        color: #c3c3e2;
        strong {
          color: #e9b10e;
        }
        strong:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .invite {
    .invite-list {
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      span {
        margin-left: 20px;
        font-size: 16px;
        color: #848492;
      }
    }
    .invite-list1 {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #c3c3e2;

      span {
        font-weight: 600;
        color: #e9b10e;
        margin-left: 10px;
      }
      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .number {
    .number1 {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      color: #848492;
      font-size: 16px;
      i {
        font-size: 20px;
      }
      i:hover {
        cursor: pointer;
        color: #e9b10e;
      }
      .number-span:hover {
        cursor: pointer;
      }
    }
  }
  .hobby {
    display: flex;
    span {
      color: #fff;
    }
    .hobby1 {
      width: 224px;
      height: 57px;
      background: url(../assets/newimg/img_64.png) no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 70px;
    }
    .el-checkbox {
      color: #fff;
    }
  }
}
</style>
