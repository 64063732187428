<template>
  <div class="areroom">
    <div class="room-warp">
      <div class="roomleft-num">
        <div class="nav_item" style="cursor: pointer" @click="goLucky">
          <i class="back el-icon-arrow-left"></i>
          返回
        </div>
      </div>

      <!-- 中奖名单 -->
      <div class="room-win">
        <div class="win-con">
          <ul>
            <li v-for="(item, index) in list" :key="index">
              <div class="win-warp">
                <div class="win-img">
                  <img :src="item.skin_img" />
                </div>
                <div class="win-price">
                  <img src="../assets/img/money.png" />
                  <div style="color: #fff">{{ item.skin_price }}</div>
                  <!-- <span>{{ item.skin_price }}</span> -->
                </div>
                <div
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                  "
                >
                  {{ item.skin_name }}
                </div>
                <div class="win-name">
                  <!-- <img :src="item.player.img" alt="" /> -->
                  <!-- player.name -->
                  <span>{{ item.player.name }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: this.$route.query.time,
      id: this.$route.query.id,
      list: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    goLucky() {
      this.$router.push({
        path: `/GameRoom`,
        query: {
          id: this.id,
        },
      });
    },
    getList() {
      let _this = this;
      _this
        .$post("/api/wheel/weigh_one_log", {
          wheel_id: _this.id,
          day: _this.time,
        })
        .then((res) => {
          if (res.code == 1) {
            _this.list = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.areroom {
  width: 100;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .room-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    min-height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }
  .roomleft-num {
    font-size: 16px;
    // border-bottom: 3px solid #ffc400;
    .nav_item {
      width: 154px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
      cursor: pointer;
      position: relative;
      .back {
        position: absolute;
        left: 15px;
        font-size: 24px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
  }
  .room-win {
    margin-top: 30px;
    .win-con {
      margin-top: 20px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li:nth-child(5n) {
          margin-right: 0;
        }

        li {
          width: 18.4%;
          height: 264px;
          margin-right: 2%;
          margin-bottom: 2%;
          .win-warp {
            padding: 20px 10px;
            background-color: #fff;
            position: relative;
            border-radius: 5px;
            background: url(../assets/newimg/roombox.png) no-repeat;
            background-size: 100% 100%;
            //border: 1px solid #FFC400;
            box-sizing: border-box;
            .win-img {
              text-align: center;
              img {
                width: auto;
                height: 120px;
              }
            }
            .win-name {
              font-size: 13px;
              color: #ffffff;
              text-align: center;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            .win-price {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 15px 0;
              img {
                width: 16px;
                height: 16px;
              }
              span {
                font-size: 13px;
                color: #ffc400;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
