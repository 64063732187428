<template>
  <div class="oran">
    <!-- <myinform></myinform>
    <myslide></myslide> -->
    <div class="oran-warp">
      <div class="oran-top">
        <div class="nav_item">商品分类</div>
        <!-- <div class="orantop-left">幸运饰品</div>
        <div class="orantop-right" @click="goHistory">获得饰品历史</div> -->
      </div>
      <img
        class="duizhangang"
        src="../assets/newimg/duizhangang.png"
        alt=""
        style="width: 100%"
      />
      <div class="oran-sel">
        <div class="sel-top">
          <ul>
            <li
              :class="item.state ? 'seltop-warp1' : ''"
              v-for="(item, index) in selList"
              :key="index"
              @click="selOran(item.status, item.id, item.name)"
            >
              <div class="seltop-warp">
                <img class="warp-img" :src="item.img" />
                <span>{{ item.name }}</span>
              </div>
            </li>
          </ul>
          <div class="sel-tab">
            <!-- <div class="tab" :class="!item ? 'active' : ''" @click="goAll">
              全部
            </div> -->
            <div
              class="tab"
              :class="item.state ? 'active' : ''"
              v-for="(item, index) in classList"
              :key="index"
              @click="selClass(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="clear"></div>

        <div class="class-box">
          <div class="sel-bot">
            <div style="display: flex">
              <!-- <div class="sel-left">
                <el-select v-model="value1" placeholder="请选择" popper-class="dota-select">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div> -->
              <div class="sel-left">
                <el-select
                  v-model="value2"
                  placeholder="请选择"
                  popper-class="dota-select"
                  @change="changeSelect"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="sel-right">
              <div class="sx-box" @click="showSelect = !showSelect">筛选</div>
              <div class="selbot-right" v-if="showSelect">
                <div class="right-box">
                  <div class="search-name">
                    <!-- <el-input
                      placeholder="请输入积分"
                      v-model="typeName"
                      class="input1"
                      type="number"
                    >
                    </el-input> -->
                    <span @click="myBalance">填入我的余额</span>
                  </div>
                  <div style="width: 86%; margin: 0 auto">
                    <span style="color: #fff"></span>
                    <el-input
                      placeholder="最小积分"
                      v-model="pirce1"
                      class="input"
                    >
                    </el-input>
                    <span class="span">~</span>
                    <el-input
                      placeholder="最大积分"
                      v-model="pirce2"
                      class="input"
                    >
                    </el-input>
                  </div>

                  <div class="pirec-btn d-flex">
                    <div class="btn" @click="confirm"></div>
                    <div class="btn2" @click="cancel">取消</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="oran-list">
        <div class="roll-list">
          <ul>
            <!-- @click="goOrnamentOpen(item)" -->
            <li v-for="(item, index) in list" :key="index">
              <div class="list-warp">
                <!-- <div :class="item.state ? 'list-bor' : ''"></div>
                <span class="ico">{{ item.exteriorName }}</span> -->
                <div class="list-img">
                  <img :src="item.imageUrl" />
                </div>
                <div class="bot-1">
                  <div class="list-name" :title="item.itemName">
                    {{ item.itemName }}
                  </div>
                  <div class="list-pirce">
                    <div class="pirce-left">
                      <img src="../assets/img/money.png" />
                      <span>{{ item.price }}</span>
                    </div>
                    <div class="pirce-right"></div>
                  </div>
                  <div class="list-dh" @click="submit(item)"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="clear"></div>
        <div class="more-btn" v-if="list.length > 14">
          <span @click="moveList()">查看更多</span>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div class="myhomebot-mag"></div>

    <!-- 兑换 -->
    <div class="dh_dialog" v-if="dialogVisible">
      <!-- 兑换成功 -->
      <div class="dh_success" v-if="dialogSuccess">
        <img src="../assets/newimg/img_54.png" alt="" />
      </div>
      <!-- 余额不足 -->
      <div class="dh_success" v-if="dialogFail">
        <img src="../assets/newimg/img_77.png" alt="" />
      </div>
      <!-- 兑换 -->
      <div class="dh_main" v-if="dialogBox">
        <p class="title">确定兑换该商品？</p>
        <div class="goods_img">
          <img :src="dh_img" alt="" />
        </div>
        <div style="color:#fff;text-align:center;margin:10px">
          {{ dh_name }}
        </div>
        <div class="goods_price">
          <img src="../assets/img/money.png" />
          <span>{{ dh_price }}</span>
        </div>
        <div class="goods_btn">
          <div class="btn" @click="dhCancel">取消</div>
          <div class="btn" @click="dhSubmit">确定</div>
        </div>
      </div>
    </div>
    <div style="height: 300px"></div>
  </div>
</template>

<script>
import myslide from "@/components/seamless.vue";
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import Utils from "./../assets/js/util.js";
import { setTimeout } from "timers";
export default {
  components: { myslide, myhomebot, myinform },
  data() {
    return {
      pirce1: "",
      pirce2: "",
      typeName: "",
      page: 1,
      pageSize: 12,
      list: [],
      classList: [],
      selList: [],
      listSlide: [],
      classObj: [{ name: "全部", flag: 0, skin_type_id: "", state: true }],
      classId: "1",
      child_types:'',
      options1: [
        {
          value: "崭新出厂",
          label: "崭新出厂",
        },
        {
          value: "略有磨损",
          label: "略有磨损",
        },
        {
          value: "久经沙场",
          label: "久经沙场",
        },
        {
          value: "破败不堪",
          label: "破败不堪",
        },
        {
          value: "战痕累累",
          label: "战痕累累",
        },
        {
          value: "无涂装",
          label: "无涂装",
        },
      ],
      value1: "",
      options2: [
        {
          value: "asc",
          label: "价格从低到高排序",
        },
        {
          value: "desc", //desc
          label: "价格从高到低排序",
        },
      ],
      value2: "",
      dialogVisible: false,
      dialogSuccess: false,
      dialogBox: false,
      dialogFail: false,
      showTab: false,
      showSelect: false,
      dh_id: "",
      dh_img: "",
      dh_price: 0,
      dh_name:''
    };
  },
  mounted() {
    this.getList();
    this.getListClass();
  },
  methods: {
    //查看更多
    moveList() {
      // console.log(this.pageSize);
      const newPages = this.page + 1;
      this.page = newPages;
      // let _this = this;
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        score_order: this.value2,
        minPrice: this.pirce1,
        maxPrice: this.pirce2,
        typeName: this.typeName,
        child_type:this.child_types
        
      };
      console.log(param);
      this.$post("/api/store/skinList", param)
        .then((res) => {
          let data = res;
          if (data.code == 1) {
            data.data.data.forEach((item) => {
              this.list.push(item);
            });

            //分类中小分类
            //分类中小分类
            let params = {
              type_id: 1,
            };
            // this.$post("/api/store/luck_skin_child_type", params).then(
            //   (res) => {
            //     let data = res;
            //     // console.log("luck_skin_child_type", data);
            //     if (data.code == 1) {
            //       if (data.data.length != 0) {
            //         for (let i = 0; i < data.data.length; i++) {
            //           this.classList = data.data;
            //         }
            //       } else {
            //         this.classList = [];
            //       }
            //     }
            //   }
            // );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //兑换
    submit(item) {
      console.log("item666666", item);
      this.dialogVisible = true;
      this.dialogBox = true;
      this.dh_id = item.id;
      this.dh_img = item.imageUrl;
      this.dh_name=item.itemName
      this.dh_price = item.price;
    },
    dhSubmit() {
      var that = this;
      that
        .$post("/api/store/buy", { all_skin_id: that.dh_id })
        .then((res) => {
          let data = res;
          if (data.status == 1) {
            Utils.$emit("money", data.data.price);
            that.dialogBox = false;
            this.$message({
              showClose: true,
              message: '兑换成功',
              type: "success",
            });
            // that.dialogSuccess = true;
            setTimeout(() => {
              this.dialogVisible = false;
              // that.dialogSuccess = false;
            }, 2000);
          } else {
            that.dialogBox = false;
            that.dialogVisible = false;
            that.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    myBalance() {
      var info = localStorage.getItem("userInfo");
      console.log("999999", JSON.parse(info));
      this.pirce1 = 0;
      this.pirce2 = JSON.parse(info).score;
    },
    dhCancel() {
      this.dialogVisible = false;
      this.dialogBox = false;
    },
    confirm() {
      // if(this.pirce1==''){
      //   this.$message({
      //     message:"情商高移入",
      //     type: "warning",
      //   })
      //   return
      // }
      this.showSelect = false;
      this.getList();
    },
    cancel() {
      this.showSelect = false;
      this.pirce1 = "";
      this.pirce2 = "";
      this.getList();
    },
    changeSelect(e) {
      this.value2 = e;
      this.getList();
    },
    //分类
    getListClass() {
      this.$post("/api/store/luck_skin_type")
        .then((res) => {
          let data = res;
          if (data.code == 1) {
            this.selList = data.data;
            for (let i = 0; i < this.selList.length; i++) {
              this.selList[i].state = false;
            }
            this.selList[0].state = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //默认推荐列表
    getList() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        score_order: this.value2,
        minPrice: this.pirce1,
        maxPrice: this.pirce2,
        typeName: this.typeName,
        child_type:this.child_types
      };
      this.$post("/api/store/skinList", param)
        .then((res) => {
          let data = res;
          if (data.code == 1) {
            this.list = data.data.data;
            //分类中小分类
            //分类中小分类
            let params = {
              type_id: 1,
            };
            // this.$post("/api/store/luck_skin_child_type", params).then(
            //   (res) => {
            //     let data = res;
            //     // console.log("luck_skin_child_type", data);
            //     if (data.code == 1) {
            //       if (data.data.length != 0) {
            //         for (let i = 0; i < data.data.length; i++) {
            //           this.classList = data.data;
            //         }
            //       } else {
            //         this.classList = [];
            //       }
            //       // console.log("this.classList", this.classList);
            //     }
            //   }
            // );
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击全部
    goAll() {
      this.$post("/api/store/skinList", {
        page: this.page,
        child_type:this.child_types,
        type: this.classId,
        score_order: this.value2,
        minPrice: this.pirce1,
        maxPrice: this.pirce2,
      })
        .then((res) => {
          let data = res;
          if (data.code == 1) {
            this.list = data.data.data;
          } else {
            this.list = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击分类获取列表
    selOran(status, id, name) {
      console.log("status, id666", status, id, name);
      this.classId = id;
      for (let i = 0; i < this.selList.length; i++) {
        if (id == this.selList[i].id) {
          this.selList[i].state = true;
        } else {
          this.selList[i].state = false;
        }
      }
      //分类中小分类
      let params = {
        type_id: id,
      };
      this.$post("/api/store/luck_skin_child_type", params).then((res) => {
        let data = res;
        // console.log("luck_skin_child_type", data);
        if (data.code == 1) {
          if (data.data.length != 0) {
            for (let i = 0; i < data.data.length; i++) {
              this.classList = data.data;
            }
          } else {
            this.classList = [];
          }
          // console.log("this.classList", this.classList);
        }
      });

      var param = {
        page: this.page,
        child_type: 0,
        type: this.classId,
        score_order: this.value2,
        minPrice: this.pirce1,
        maxPrice: this.pirce2,
      };
      //列表数据
      this.$post("/api/store/skinList", param)
        .then((res) => {
          let data = res;
          if (data.code == 1) {
            this.list = data.data.data;
          } else {
            this.list = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      //分类中小分类
      // this.$post('/api/store/subclassList',param1).then(res => {
      //   let data = res;
      //   if (data.status == 1) {
      //     let datalist = data.data.list;
      //     this.classObj.splice(1, this.classObj.length - 1);
      //     this.classObj[0].skin_type_id = "";
      //     this.classObj[0].state = true;
      //     for (let i = 0; i < datalist.length; i++) {
      //       datalist[i].state = false;
      //       this.classObj.push(datalist[i]);
      //       this.classObj[0].skin_type_id = datalist[i].skin_type_id;
      //     }
      //     this.classList = this.classObj;
      //   } else {
      //     this.classList = [];
      //   }
      // }).catch(function (error) {
      //   console.log(error);
      // });
      this.$forceUpdate();
    },
    //点击小分类
    selClass(item) {
      console.log("点击小分类", item);
      for (let i = 0; i < this.classList.length; i++) {
        if (item.id == this.classList[i].id) {
          this.classList[i].state = true;
        } else {
          this.classList[i].state = false;
        }
      }
      this.child_types=item.id
      this.page=1
      var param = {
        page: this.page,
        // pageSize: this.pageSize,
        // recommend: 1,
        child_type: item.id,
        type: this.classId,
        score_order: this.value2,
        minPrice: this.pirce1,
        maxPrice: this.pirce2,
      };
      //console.log(param);
      //列表数据
      this.$post("/api/store/skinList", param)
        .then((res) => {
          let data = res;
          if (data.code == 1) {
            this.list = data.data.data;
          } else {
            this.list = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    goOrnamentOpen(item) {
      this.$router.push({
        path: `/OrnamentOpen`,
        query: {
          item: JSON.stringify(item),
        },
      });
    },
    //获得历史
    goHistory() {
      this.$router.push({
        path: `/OrnamentHistory`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
// .oran-list {
//   z-index: 1;
// }
.more-btn {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  span {
    background: url(../assets/newimg/img_15.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 15px;
    padding: 9px 25px;
    border-radius: 5px;
    font-weight: 600;
    // background-color: #333542;
  }
  span:hover {
    background-color: #3a3f50;
    cursor: pointer;
  }
}
.dh_dialog {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //overflow: auto;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  .dh_success {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    img {
      width: 368px;
      height: 89px;
    }
  }
  .dh_main {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 570px;
    height: 500px;
    background: url(../assets/newimg/selebg.png) no-repeat;
    background-size: 100% 100%;
    padding: 50px;
    box-sizing: border-box;
    .title {
      color: #fff;
      text-align: center;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .goods_img {
      text-align: center;
      img {
        width: auto;
        height: 235px;
      }
    }
    .goods_price {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .goods_btn {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;
      .btn {
        width: 157px;
        height: 44px;
        background: url(../assets/newimg/img_15.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        color: #fff;
        line-height: 44px;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
.oran {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  /deep/.el-select-dropdown {
    border: none;
    // color: #fff;
  }

  .myhomebot-mag {
    margin-top: 120px;
  }
  .oran-warp {
    max-width: 1200px;
    // width: 90%;
    //height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }
  .oran-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 3px solid #ffc400;
    .nav_item {
      width: 154px;
      height: 61px;
      text-align: center;
      line-height: 61px;

      background-size: contain;

      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      background-size: 100% 100%;
    }
    .orantop-left {
      color: #c3c3e2;
      font-size: 20px;
    }
    .orantop-right {
      padding: 12px 22px;
      background-color: #333542;
      border-radius: 5px;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
    }
    .orantop-right:hover {
      cursor: pointer;
      background-color: #3a3f50;
    }
  }
  .oran-sel {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    .sel-tab {
      width: 90%;
      margin: 0 auto;
      margin-top: 16px;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      // flex-wrap: wrap;
      overflow-x: scroll;
      overflow-y: hidden;
      display: -webkit-box;
      .tab {
        width: auto;
        // height: 61px;
        // line-height: 61px;
        padding: 10px 25px;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        margin-right: 20px;
        margin-top: 10px;
        cursor: pointer;
      }
      .active {
        // height: 61px;
        // line-height: 61px;
        // padding: 10px 20px;
        background: url(../assets/newimg/jifenkuang.png) no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .sel-tab::-webkit-scrollbar {
      height: 8px;
    }
    .sel-tab::-webkit-scrollbar-thumb {
      // height: 2px;
      border-radius: 10px;
      background: rgba(210, 206, 206, 0.2);
    }
    .sel-tab::-webkit-scrollbar-track {
      border-radius: 0;
      background: rgba(58, 59, 61, 0.2);
    }
    .sel-top {
      width: 100%;
      height: 334px;
      background: url(../assets/newimg/oranbg.png) no-repeat;
      background-size: 100% 100%;
      margin-top: -37px;
      overflow: hidden;
      ul {
        width: 90%;
        // height: 40%;
        margin: 0 auto;
        margin-top: 65px;
        display: flex;
        flex-flow: wrap;
        // border-bottom: 2px solid #ffc400;

        li {
          width: 11.11%;
          padding: 15px 0;
          // background: rgba(0, 0, 0, 0.4);
          .seltop-warp {
            height: 100px;
            display: flex;
            flex-shrink: 0;
            flex-direction: column;
            align-items: center;
            border-right: 1px solid #09e2f6;
            img {
              width: 60%;
              height: 78px;
              max-height: 78px;
              // background: url(../assets/newimg/jifenbg.png) no-repeat;
              // background-size: 100% 100%;
            }
            span {
              width: auto;
              height: 26px;
              font-size: 14px;
              color: #fff;
              margin-top: 20px;
              // background: url(../assets/newimg/jifenbg2.png) no-repeat;
              // background-size: 100% 100%;
              display: block;
              padding: 0 30px;
            }
          }
        }
        li:last-child .seltop-warp {
          border: none;
        }
        .seltop-warp1 {
          // border: 2px solid #ffc400;
          border-radius: 10px 10px 0 0;
          border-bottom: none;
          .seltop-warp {
            // border: none;
            img {
              width: 60%;
              height: 78px;
              max-height: 78px;
              background: url(../assets/newimg/jifenbg.png) no-repeat;
              background-size: 100% 100%;
            }
            span {
              width: auto;
              height: 26px;
              font-size: 14px;
              color: #ff5cfd;
              margin-top: 20px;
              background: url(../assets/newimg/jifenbg2.png) no-repeat;
              background-size: 100% 100%;
              display: block;
              padding: 0 30px;
            }
          }
        }
        li:hover {
          cursor: pointer;
        }
      }
    }
    .class-box {
      margin-top: 40px;
      .class-list {
        ul {
          display: flex;
          li {
            margin-right: 10px;
            span {
              background-color: #ffc400;
              border-radius: 5px;
              padding: 8px 16px;
              font-size: 14px;
            }
            .span2 {
              border: 1px solid #707070;
              background: #0f0d0a;
              color: #fff;
              font-size: 14px;
            }
            span:hover {
              cursor: pointer;
              background-color: #ffc400;
              color: #000;
            }
          }
        }
      }
    }
    .sel-bot {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sel-right {
        background: url(../assets/newimg/shaixuan.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        // z-index: 100;
        .sx-box {
          width: 90px;
          height: 40px;

          // background: rgba(0, 0, 0, 0.3);
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;
          box-sizing: border-box;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
        }
      }
      .sel-left {
        width: 131px;
        height: 52px;
        line-height: 52px;
        color: #e9b10e;
        font-size: 12px;
        font-weight: 600;
        margin-right: 30px;
        background: url(../assets/newimg/zhanxin.png) no-repeat;
        background-size: 100% 100%;
        /deep/ .el-input__inner {
          // border-color: #ffc400;
          border: none;
          background: rgba(0, 0, 0, 0.3);
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #00e4ff;
        }
        /deep/ .el-select .el-input .el-select__caret {
          display: none;
          // color: #fff;
        }
        /deep/ .el-select .el-input .el-input__suffix {
          width: 19px;
          height: 12px;
          margin-top: 15%;
          margin-right: 5px;
          background: url(../assets/newimg/xiajiantou.png) no-repeat;
          background-size: 100% 100%;
        }
      }
      .sel-left:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .selbot-left {
        display: flex;
        align-items: center;
        .span1 {
          font-size: 14px;
          color: #e9b10e;
          font-weight: 600;
        }
        .span1:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .selbot-right {
        width: 399px;
        height: 250px;
        background: #18140e;
        // border: 2px solid #ffc400;
        position: absolute;
        top: 55px;
        right: 0;
        z-index: 1000000;
        padding: 7px;
        box-sizing: border-box;
        background: url(../assets/newimg/selectbg.png) no-repeat;
        background-size: 100% 100%;
        .search-name {
          width: 100%;
          display: flex;
          align-items: center;
          color: #ffc400;
          font-size: 16px;
          margin-bottom: 20px;
          text-align: center;
          span {
            display: block;
            width: 100%;
            // text-align: right;
            margin-left: 20px;
            text-align: center;
            cursor: pointer;
          }
        }
        .span {
          color: #fff;
          margin: 0 10px;
        }
        .pirec-btn {
          // display: flex;
          // justify-content: space-around;
          position: fixed;
          bottom: 20px;
          width: 100%;
          left: 15%;
          top: calc(57%  ) !important;
          left: calc(50% - 110px );
          .btn {
            width: 109px;
            height: 36px;
            background: url(../assets/newimg/queding.png) no-repeat;
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
          .btn2 {
            width: 109px;
            height: 36px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .input {
          width: 120px;
          border: 1px solid #00e4ff;
          border-radius: 4px;
          img {
            width: auto;
            height: 18px;
          }
        }
        .input /deep/ .el-input__prefix,
        .input /deep/ .el-input__suffix {
          top: 11px;
        }
        .input /deep/ .el-input__inner {
          border: none;
        }
        .input1 {
          width: 200px;
        }
        .input1-i:hover {
          cursor: pointer;
        }
      }

      .right-box {
        height: 100%;
        // border: 1px solid #ffc400;
        padding: 50px 30px;
        box-sizing: border-box;
        .search-name /deep/ .el-input__inner {
          background-color: #18140e;
          // border: 1px solid #ffc400;
          color: #fff;
        }
        /deep/ .el-input__inner {
          background-color: #18140e;
          // border: 1px solid #fff;
          color: #fff;
        }
      }
    }
  }

  .roll-list {
    width: 100%;
    margin-top: 20px;
    z-index: 0;
    ul {
      width: 100%;

      display: flex;
      flex-wrap: wrap;
      li:nth-child(5n) {
        margin-right: 0;
      }
      li {
        flex-shrink: 0;
        // flex: 1;
        width: 18.4%;
        height: 264px;
        margin-right: 2%;
        background: url(../assets/newimg/roombox.png) no-repeat;
        background-size: 100% 100%;
        margin-bottom: 2%;
        padding-bottom: 22px;
        .list-warp {
          position: relative;
          height: 100%;
          overflow: hidden;
          .list-bor {
            position: absolute;
            top: 0;
            z-index: 666;
            width: 100%;
            height: 100%;
          }

          .ico {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 12px;
            color: #c3c3e2;
            padding: 2px 4px;
          }
          .buy-state {
            position: absolute;
            top: 0;
            right: 0;
            img {
              width: 15px;
              height: 15px;
            }
          }
          .list-img {
            padding: 20px 20px 0;
            height: 50%;
            margin-top: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .bot-1 {
            //background-color: #2b2c37;
            .list-name {
              padding: 0 20px;
              font-size: 16px;
              color: #fff;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
            }
            .list-pirce {
              padding: 10px 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              .pirce-left {
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: auto;
                  height: 15px;
                }
                span {
                  color: #fff;
                  font-size: 16px;
                  margin-left: 8px;
                }
              }
              .pirce-right {
                color: #848492;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .list-dh {
              width: 123px;
              height: 30px;
              background: url(../assets/newimg/duihuan2.png) no-repeat;
              background-size: 100% 100%;

              // border-radius: 10px 10px 10px 10px;
              // border: 2px solid #ffc400;
              // font-size: 16px;
              // color: #ffffff;
              // box-sizing: border-box;
              // text-align: center;
              // line-height: 38px;
              margin: 0 auto;
            }
            .list-dh:hover {
              width: 123px;
              height: 30px;
              background: url(../assets/newimg/duihuan.png) no-repeat;
              background-size: 100% 100%;
            }
          }
        }
      }
      li:hover {
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .dh_main {
    width: 100% !important;
    .goods_btn {
      width: 90% !important;
      margin: 0 auto !important;
      margin-top: 20px !important;
      .btn {
        width: 45% !important;
      }
    }
  }
  .duizhangang {
    width: 100% !important;
  }
  .oran-sel {
    width: 100% !important;
    .sel-top {
      // background: none !important;
      background-size: 108% 90% !important;
      background-position: top center !important;
      ul {
        overflow-x: scroll !important;
        overflow-y: hidden !important;
        flex-flow: row !important ;
        margin-top: 46px !important;
        display: -webkit-box;
        li {
          width: 50vw !important;
          // float: left !important;
          // padding-top: 0 !important;
          img{
            // height: 72px !important;
          }
          span{
            margin-top: 0 !important;
          }
        }
      }
    }
  }
  .sel-right {
    width: 100% !important;
    .selbot-right {
      width: 320px !important;
      height: 220px !important;
      // background: #18140e;
      border: 2px solid #ffc400;
      position: fixed !important;
      top: calc(50% - 110px ) !important;
      left: calc(50% - 160px );
      // left: 0 !important;
      right: 0 !important;
      .el-input {
        width: 80px !important;
        .el-input {
          // padding: 0 5px !important;
          font-size: 12px !important;
        }
      }
    }
  }
}
</style>
