<template>
  <div class="hist">
    <div class="hist-warp">
      <div class="roomleft-num">
        <div class="nav_item">我的账单</div>
        <div class="sel-left">
          <el-select v-model="value" placeholder="筛选" popper-class="dota-select"
          @change="changeSelect">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="hist-list">
        
          <div v-if="value=='one'">
            <div class="bot">
              <el-table :data="tableData" style="width: 100%" :cell-style="columnStyle">
                <el-table-column prop="create_time" label="日期">
                </el-table-column>
                <el-table-column prop="modeName" label="渠道">
                </el-table-column>
                <el-table-column prop="money" label="金额">
                  <template slot-scope="scope">
                    <span>{{ scope.row.money }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="statusName" label="结果">
                </el-table-column>
              </el-table>
            </div>
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize"
                :page-size="pageSize"
                @current-change="currentChange"
              >
              </el-pagination>
            </div>
          </div>

          <div v-if="value=='two'">
            <div class="bot">
              <el-table :data="tableData1" style="width: 100%" :cell-style="columnStyle1">
                <el-table-column prop="create_time" label="日期" width="180">
                </el-table-column>
                <el-table-column prop="price" label="支付" width="180">
                </el-table-column>
                <el-table-column prop="img" label="购买物品">
                  <template slot-scope="scope">
                    <img
                      :src="scope.row.img"
                      alt=""
                      style="width: 50px; height: 50px"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize1"
                :page-size="pageSize"
                @current-change="currentChange1"
              >
              </el-pagination>
            </div>
          </div>

          <div v-if="value=='three'">
            <div class="bot">
              <el-table :data="tableData2" style="width: 100%" :cell-style="columnStyle2">
                <el-table-column prop="create_time" label="日期">
                </el-table-column>
                <el-table-column prop="pay" label="类型"> </el-table-column>
                <el-table-column prop="type" label="描述">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type == 1">皮肤兑换Z币</span>
                    <span v-if="scope.row.type == 3">充值</span>
                    <span v-if="scope.row.type == 4">对战失败</span>
                    <span v-if="scope.row.type == 5">对战存在多个平局赢家平分输家的钱</span>
                    <span v-if="scope.row.type == 6">后台充值</span>
                    <span v-if="scope.row.type == 7">幸运失败</span>
                    <span v-if="scope.row.type == -1">购买盲盒</span>
                    <span v-if="scope.row.type == -2">加入对战房间</span>
                    <span v-if="scope.row.type == -3">购买幸运饰品</span>
                    <span v-if="scope.row.type == -4">商城购买饰品</span>
                  </template>
                </el-table-column>
                <el-table-column prop="amount" label="数量"> </el-table-column>
                <el-table-column prop="total_amount" label="余额">
                </el-table-column>
              </el-table>
            </div>
            <div class="roll-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="totalSize2"
                :page-size="pageSize"
                @current-change="currentChange2"
              >
              </el-pagination>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "one",
      totalSize: 0,
      page: 1,
      pageSize: 10,
      totalSize1: 0,
      totalSize2: 0,

      tableData: [],
      tableData1: [],
      tableData2: [],
      options: [{
        value: 'one',
        label: '充值金额'
      }, {
        value: 'two',
        label: '积分商城兑换的金额'
      }, {
        value: 'three',
        label: '物品回收的金额'
      }],
      value: 'one',
    };
  },
  mounted() {
    this.getTopupList(this.page);
  },
  methods: {
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
    },
    columnStyle1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
    },
     columnStyle2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 4) {
        //第三第四列的背景色就改变了2和3都是列数的下标
        return "color: #e9b10e ;";
      }
      if(columnIndex == 3){
        return "color: #c3c3e2 ;";
      }
      
      if(columnIndex == 1){
        if(row.pay == "支出"){
          return "color:  #c3c3e2 ;";
        }else{
          return "color:#02bf4d;";
        }
      }
      
    },
    changeSelect(e){
      if (e == "one") {
        this.getTopupList(this.page);
      } else if (e == "two") {
        this.getShoppList(this.page);
      } else {
        this.getBalanceList(this.page);
      }
    },
    getTopupList(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
        player_id: this.$store.state.id,
      };
      this.$post('/api/users/recharge',param).then(res => {
        var data = res;
        if (data.status == 1 && data.data != null) {
          this.totalSize = data.data.total;
          this.tableData = data.data.list;
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].mode == "zhifubao") {
              this.tableData[i].modeName = "支付宝";
            } else {
              this.tableData[i].modeName = "微信";
            }

            if (this.tableData[i].status == 1) {
              this.tableData[i].statusName = "未支付";
            } else if (this.tableData[i].status == 2) {
              this.tableData[i].statusName = "待支付";
            } else if (this.tableData[i].status == 3) {
              this.tableData[i].statusName = "支付成功";
            } else {
              this.tableData[i].statusName = "支付失败";
            }
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
      
    },

    getShoppList(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
        player_id: this.$store.state.id,
      };
      this.$post('/api/users/skinBought',param).then(res => {
        var data = res;
        if (data.status == "1" && data.data != null) {
          this.totalSize1 = data.data.total;
          this.tableData1 = data.data.list;
        }
      }).catch(function (error) {
        console.log(error);
      });
      
    },

    getBalanceList(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
        player_id: this.$store.state.id,
      };
      this.$post('/api/users/balanceDetail',param).then(res => {
        var data = res;
        if (data.status == "1" && data.data != null) {
          this.totalSize2 = data.data.total;
          this.tableData2 = data.data.list;

          for (let i = 0; i < this.tableData2.length; i++) {
            if (Number(this.tableData2[i].amount) >= 0) {
              this.tableData2[i].pay = "收入";
            } else {
              this.tableData2[i].pay = "支出";
            }

            // if (this.tableData2[i].type == 1) {
            //   this.tableData2[i].state = "皮肤兑换Z币";
            // } else if (this.tableData2[i].type == 3) {
            //   this.tableData2[i].state = "充值";
            // } else if (this.tableData2[i].type == 4) {
            //   this.tableData2[i].state = "对战失败";
            // } else if (this.tableData2[i].type == 5) {
            //   this.tableData2[i].state = "对战存在多个平局赢家平分输家的钱";
            // } else if (this.tableData2[i].type == -1) {
            //   this.tableData2[i].state = "购买盲盒";
            // } else if (this.tableData2[i].type == -2) {
            //   this.tableData2[i].state = "加入对战房间";
            // } else if (this.tableData2[i].type == -3) {
            //   this.tableData2[i].state = "购买幸运饰品";
            // } else if (this.tableData2[i].type == -4) {
            //   this.tableData2[i].state = "商城购买饰品";
            // }
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    },

    //充值流水 页数
    currentChange(val) {
      this.getTopupList(val);
    },

    //饰品商城 分页
    currentChange1(val) {
      this.getShoppList(val);
    },

    //余额流水 分页
    currentChange2(val) {
      this.getBalanceList(val);
    },

    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hist {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  .hist-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.3);
  }

  //tabs
  .hist-list {
    margin-top: 20px;
  }
  .hist-list /deep/ .el-tabs--border-card {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: #1a1c24;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    background-color: #24252f;
    border: none;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #FF5CFD;
  }

  //页数
  .roll-page {
    margin: 10px 0 0 -10px;
  }
  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #FF5CFD;
    color: #1a1c24;
  }
  .roll-page /deep/ .el-pagination{
    text-align: center;
  }
  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #333542;
    color: #848492;
  }
  /deep/ .el-table, .el-table__expanded-cell{
    background: none;
  }
  .bot /deep/ .el-table th{
    color: #fff;
  }
  .bot /deep/ .el-table td{
    color: #ccc;
  }
  //表格
  .bot /deep/ .el-table th,
  .bot /deep/ .el-table tr {
    background: none;
  }
  .bot /deep/ .el-table td,
  .bot /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }
  .bot /deep/ .el-table::before {
    height: 0;
  }
  .bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: none;
  }
  /*.bot /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }*/

  .roomleft-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid #FFC400;
    .nav_item {
      margin-right: 10px;
      width:156px;
      height:61px;
      text-align: center;
      line-height:61px;
      font-size:16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
    }
    .sel-left {
      width: 131px;
        height: 52px;
        line-height: 52px;
        color: #FF5CFD;
        font-size: 12px;
        font-weight: 600;
        margin-right: 30px;
        background: url(../assets/newimg/zhanxin.png) no-repeat;
        background-size: 100% 100%;
        /deep/ .el-input__inner {
          // border-color: #ffc400;
          border: none;
          background: rgba(0, 0, 0, 0.3);
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #00e4ff;
        }
        /deep/ .el-select .el-input .el-select__caret {
          display: none;
          // color: #fff;
        }
        /deep/ .el-select .el-input .el-input__suffix {
          width: 19px;
          height: 12px;
          margin-top: 15%;
          margin-right: 5px;
          background: url(../assets/newimg/xiajiantou.png) no-repeat;
          background-size: 100% 100%;
        }
    }
  }
}
</style>

