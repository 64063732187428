<template>
  <div class="rule">
    <div class="rule-warp">
      <div class="roomleft-num">
        <div class="nav_item" style="cursor: pointer" @click="goLucky">
          <i class="back el-icon-arrow-left"></i>
          返回
        </div>
      </div>
      <img class="duizhangang" src="../assets/newimg/duizhangang.png" alt="" />

      <div class="rule-text">
        <!-- <p style="color: #fff">活动规则</p> -->
        <div class="border"></div>
        <div class="text-title">关于活动</div>
        <p>
          1
          创建活动时，可以选择活动人数（1V1，3人对战和4人对战），一场乱斗最多可以选择
          6 个盲盒。房间一但创建，不可取消。
        </p>
        <p>
          2
          活动参与费用即本场活动开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还盲盒或参与费。
        </p>
        <p>
          3
          当一场活动有多名赢家均分奖励时，非本人所得饰品将被兑换为硬币发放到赢家的账户余额。
        </p>
        <p>
          4
          每场活动的结果受到（创建时间，用户ID，用户位置和开始时间等）多方面因素影响，为完全随机产生的结果。任意盲盒奖励均可以验证公平性。
        </p>
      </div>

      <!-- <div class="rule-input">
        <div class="input-title">请输入活动场次编号</div>
        <div class="input">
          <input type="text" />
          <span>验证活动</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .rule-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }
  .rule-text {
    width: 1236px;
    height: 777px;
    margin: 0 auto;
    margin-top: 20px;
    // border: 3px solid #FFC400;
    box-sizing: border-box;
    padding:30px 60px;
    background: url(../assets/newimg/rulebg.png) no-repeat;
    background-size: 100% 100%;
    .border {
      width: 100%;
      height: 16px;
      // background: #FFC400;
      margin-bottom: 20px;
    }
    .text-title {
      padding: 10px 0;
      color: #fff;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: #fff;
      padding: 8px 0;
    }
  }
  .rule-input {
    display: table;
    margin-top: 30px;
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;
    .input-title {
      font-size: 16px;
      color: #c3c3e2;
    }
    .input {
      padding: 16px 0;
      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #c3c3e2;
        font-size: 12px;
        background-color: #24252f;
        outline: none;
        box-sizing: border-box;
        border: none;
      }
      span {
        display: inline-table;
        height: 40px;
        margin-left: 20px;
        line-height: 40px;
        padding: 0 20px;
        background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        color: #1a1c24;
        font-size: 15px;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 16px;
    // border-bottom: 3px solid #FFC400;
    .nav_item {
      width: 156px;
      height: 41px;
      text-align: center;
      line-height: 41px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
      cursor: pointer;
      position: relative;
      .back {
        position: absolute;
        left: 15px;
        font-size: 24px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
  }
  .duizhangang {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .rule-text{
    width: 100% !important;
  }
}
</style>
