<template>
  <!-- import { log } from 'util'; -->
  <div class="homebot">
    <div class="bg_top">
      <img src="../assets/newimg/img_41.png" alt="" />
    </div>
    <div class="ft_out">
      <div class="ft_top">
        <div class="top_item">
          <p class="num">{{ wechartNumber }}</p>
          <p>在线人数</p>
        </div>
        <div class="top_item">
          <p class="num">{{ wechartNumber2 }}</p>
          <p>注册人数</p>
        </div>
        <div class="top_item">
          <p class="num">{{ wechartNumber3 }}</p>
          <p>开启盲盒数量</p>
        </div>
      </div>
    </div>
    <div class="top">
      <div class="top-left">
        <ul>
          <li v-for="(item, index) in routerList" :key="index">
            <span @click="fun(item.url, index)">{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="top-right">
        <div class="item">
          <img src="../assets/newimg/img_39.png" alt="" />
          <div class="li">
            <p class="name">微信支付</p>
            <p>WeChat Pay</p>
          </div>
        </div>
        <div class="item">
          <img src="../assets/newimg/img_40.png" alt="" />
          <div class="li">
            <p class="name">支付宝支付</p>
            <p>Alipay</p>
          </div>
        </div>
        <!-- <img src="../assets/img/weixin_back.png">
        <img src="../assets/img/zhifubao_back.png"> -->
      </div>
    </div>
    <div class="gateway">
      <!-- <a target="_blank" href="">
        <img src="" alt="" /> 鲁公网安备37160202000550号
      </a> -->
    </div>
    <div class="gateway" @click="goBeian">
      <div>备案号: ICP备2023004962号-1</div>
      <!-- 备案号: ICP备2023004962号-1 -->
      <!-- <a target="_blank" href="">https://beian.miit.gov.cn/#/Integrated/index</a> -->
    </div>
    <div class="gateway">版权所属：长沙奥拉夫网络科技有限公司</div>
    <!-- <div class="bot">
      <div class="bot-title">友情链接 ：</div>
      <ul>
        <li v-for="(item, index) in urlList" :key="index">
          <span @click="goUrl(item.url)">{{ item.name }}</span>
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      routerList: [
        { name: "关于我们", url: "Abouts" },
        { name: "用户协议", url: "Privacy" },
        { name: "隐私条款", url: "Privacy" },
        { name: "协议与条款", url: "Clause" },
        { name: "常见问题", url: "Doubt" },
      ],
      urlList: [],
      wechartNumber: undefined,
      wechartNumber2: undefined,
      wechartNumber3: undefined,
    };
  },
  created() {
    this.getPlayerInfo();
  },
  methods: {
    goBeian() {
      let url = "https://beian.miit.gov.cn/#/Integrated/index"; // 这里的地址前面就不用加协议了'http:// 或者 https://' 下面一句话就是处理这个的。
      // let path = window.location.protocol + "//" + url;
      window.open(url, "_blank");
      // window.location.href = url;
    },
    getPlayerInfo(pid) {
      let _this = this;
      _this
        .$post("/api//common/get_config")
        .then((res) => {
          // console.log("common", res);
          // this.active = res.data.active;
          if (res.code == 1) {
            _this.wechartNumber = res.data.online_num;
            _this.wechartNumber2 = res.data.register_num;
            _this.wechartNumber3 = res.data.open_box_num;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fun(url, index) {
      if (index == 1) {
        this.$router.push({
          path: `/Privacy`,
          query: {
            type: 1,
          },
        });
      } else if (index == 2) {
        this.$router.push({
          path: `/Privacy`,
          query: {
            type: 2,
          },
        });
      } else {
        this.$router.push({
          path: `/${url}`,
        });
      }
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
    goCase() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.homebot {
  width: 100%;
  background: url(../assets/newimg/img_38.png) no-repeat;
  background-size: 100% 100%;
  padding-bottom: 24px;
  padding-top: 30px;
  .bg_top {
    max-width: 1200px;
    margin: 0 auto 60px;
    img {
      width: 100%;
    }
  }
  .ft_out {
    max-width: 1400px;
    margin: 0 auto;
    .ft_top {
      display: flex;
      align-items: center;
      justify-content: center;
      .top_item {
        width: 206px;
        text-align: center;
        color: #ffffff;
        .num {
          font-size: 26px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #fff;
    max-width: 1400px;
    margin: 50px auto;
    flex-flow: wrap;
    .top-left {
      // width: 100%;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          margin-right: 3.75rem;
          border-right: 1px solid #fff;
          padding-right: 3.75rem;
          span {
            white-space: nowrap;
            display: inline-block;
          }
        }
        li:last-child {
          border: none;
        }
        li:hover {
          cursor: pointer;
        }
      }
    }
    .top-right {
      // width: 100%;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 145px;
        height: 58px;
        background: #000000;
        border-radius: 15px;
        margin-left: 20px;
        .li {
          font-size: 12px;
          .name {
            font-size: 14px;
          }
        }
      }
    }
  }
  .gateway {
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #878787;
    justify-content: center;
    cursor: pointer;
    a {
      font-size: 12px;
      color: #878787;
    }
  }
  .bot {
    margin-top: 24px;
    display: flex;
    font-size: 14px;
    color: #515260;
    overflow-y: hidden;
    .bot-title {
      margin-right: 12px;
    }
    ul {
      display: flex;
      li {
        margin-right: 24px;
      }
      li:hover {
        cursor: pointer;
        text-decoration: underline;
        color: #e9b10e;
      }
    }
  }
}
</style>
