<template>
  <div class="dota">
    <div class="dota-warp" v-if="!showDetails">
      <div class="data_nav">
        <div class="nav_item" @click="getListFun()">背包{{num}}</div>
        <span style="cursor: pointer" v-if="num==1" @click="history">取回记录</span>
      </div>
      <div class="data-top">
        <span>选择你要取回的饰品</span>
        <span  v-if="num==1"
          @click="goQuhui"
          style="font-size: 18px; color: #fff; cursor: pointer"
          >取回</span
        >
      </div>

      <div class="hint">
        <!-- <i class="el-icon-warning"></i> -->
        <span
          >点击取回后，饰品稍后会在取回助手中，请点击右侧取回助手点击接受报价。
          12小时内累计拒绝报价或不收货行为超过 5 次，你的取回功能将会被系统禁用
          12 小时。</span
        >
        <!-- <span style="cursor: pointer" @click="showDetails = !showDetails"
          >取回记录</span
        > -->
      </div>
      <div class="dota-sel">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <div class="sel-left">
            <el-select
              v-model="value"
              placeholder="请选择"
              popper-class="dota-select"
              @change="changeSelect"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="zengs">
            <el-input v-model="input" placeholder="请输入内容"></el-input><span @click="songs">赠送</span>
          </div> -->
          <div class="zengs" @click="zengflag='1'">
            赠送
          </div>
        </div>
        <div class="zengbac" v-if="zengflag=='1'">
          <div class="zeng">
            <h1 @click="zengflag='2'">×</h1>
            <div class="tops">
              <el-input v-model="input" placeholder="请输入内容"></el-input><span @click="searchs">搜索</span>
            </div>
            <div class="userlist">
              <div v-for="(item, index) in userlist" :key="index" class="useritem" @click="songs(item)">
                <p>{{ item.name }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="sel-right">
          <div class="right-one">
            <div class="span1" @click="selAll()" v-if="checkedPrice > 0">
              <span style="color: #e9b10e; padding: 0"
                >({{ checkedPrice }})</span
              >全选
            </div>
            <div class="span1" @click="selAll()" v-else>全选</div>
            <div class="span1" @click="offAll()">取消</div>
            <div class="span2" @click="coniAll()" :disabled="loading">
              <i v-if="loading" class="el-icon-loading"></i>兑换
            </div>
            <!--<span class="span3">取回</span> -->
          </div>
        </div>
      </div>

      <div class="dota-list">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="check(item.id)"
          >
            <div class="list-warp">
              <div class="warp3" v-if="item.state1"><span>取回中</span></div>
              <!-- <div class="warp1" v-if="item.state"></div> -->
              <!-- <img
                class="warp2"
                v-if="item.state"
                src="../assets/img/gou.png"
              /> -->
              <!-- <span class="ico">{{item.exteriorName}}</span> -->
              <span v-if="item.state" class="buy-state">
                <img src="../assets/newimg/img_60.png" />
              </span>
              <div class="list-img">
                <img :src="item.img" />
              </div>
              <div class="bot">
                <div class="list-name" :title="item.name">{{ item.name }}</div>
                <div class="list-pirce">
                  <!-- <div class="pirce-left" >
                    <img src="../assets/img/moneyRed.png" />
                    <span>{{ item.score }}</span>
                  </div> -->
                  <div class="pirce-left">
                    <img src="../assets/img/money.png" />
                    <span>{{ item.price ? item.price : 0 }}</span>
                  </div>

                  <!-- <div class="pirce-right">
                    <el-button
                      class="spanbtn1"
                      @click="getExchange($event, item.id)"
                      :disabled="item.loading1"
                      ><i v-if="item.loading1" class="el-icon-loading"></i
                      >兑换</el-button
                    >
                    <el-button
                      class="spanbtn2"
                      @click="getPull($event, item.id, item.itemId)"
                      :disabled="item.loading2"
                      ><i v-if="item.loading2" class="el-icon-loading"></i
                      >取回</el-button
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="clear"></div>
        <div class="roll-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="dota-warp" v-if="showDetails">
      <div class="data_nav">
        <div
          class="nav_item"
          style="cursor: pointer"
          @click="showDetails = !showDetails"
        >
          返回
        </div>
      </div>
      <div class="dota-list">
        <ul class="dota-ul">
          <li
            class="dota-li"
            v-for="(item, index) in skinList"
            :key="index"
            @click="check(item.id)"
          >
            <div class="list-warp">
              <div class="warp3" v-if="item.state1"><span>取回中</span></div>
              <!-- <div class="warp1" v-if="item.state"></div> -->
              <!-- <img
                class="warp2"
                v-if="item.state"
                src="../assets/img/gou.png"
              /> -->
              <!-- <span class="ico">{{item.exteriorName}}</span> -->
              <span v-if="item.state" class="buy-state">
                <!-- <img src="../assets/newimg/img_60.png" /> -->
                {{ item.state }}
              </span>
              <div class="list-img">
                <img :src="item.img" />
              </div>
              <div class="bot">
                <div class="list-name" :title="item.name">{{ item.name }}</div>
                <div class="list-pirce">
                  <!-- <div class="pirce-left">
                    <img src="../assets/img/moneyRed.png" />
                    <span>{{ item.score }}</span>
                  </div> -->
                  <div class="pirce-left">
                    <img src="../assets/img/money.png" />
                    <span>{{ item.price }}</span>
                  </div>
                  <!-- <div class="pirce-right">
                    <el-button
                      class="spanbtn1"
                      @click="getExchange($event, item.id)"
                      :disabled="item.loading1"
                      ><i v-if="item.loading1" class="el-icon-loading"></i
                      >兑换</el-button
                    >
                    <el-button
                      class="spanbtn2"
                      @click="getPull($event, item.id, item.itemId)"
                      :disabled="item.loading2"
                      ><i v-if="item.loading2" class="el-icon-loading"></i
                      >取回</el-button
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="clear"></div>
        <div class="roll-page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize2"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <span :plain="true">{{ exchangeHint }}</span>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import $api from "@/api/lucky/index";
export default {
  data() {
    return {
      skinList: [],
      loading: false,
      total: 0,
      page: 1,
      pageSize: 24,
      pageSize2: 12,
      num:1, //背包类型
      list: [],
      activelist: [],
      exchangeHint: "",
      checkedPrice: 0,
      random: "",
      userlist: [],
      options: [
        {
          value: 1,
          label: "价格从低到高排序",
        },
        {
          value: 2,
          label: "价格从高到低排序",
        },
        {
          value: 0,
          label: "按获取时间排序",
        },
      ],
      value: "",
      showDetails: false,
      total2: 0,
      input: '',
      zengflag:'2'
    };
  },
  methods: {
    async searchs(){
      console.log(this.skinList);
      
      let {data,code} = await $api.usersearch({mobile:this.input})
      console.log(data,code);
      this.userlist=data.data
    },
    async songs(row){
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if(this.activelist.length!=0){
        if(this.userlist.length!=0){
          if(userInfo.id){
            let {data,code,msg} = await $api.usergive({player_skins_ids:this.activelist[0].id,recept_id:row.id,id:userInfo.id})
            console.log(data,code,msg);
            if(code==1){
              this.$notify({
                  title: '赠送成功',
                  msg: msg,
                  type: 'success'
              });
              this.zengflag='2'
              this.getList(1)
              this.checkedPrice=0
            }else{
              this.$notify({
                title: msg,
                type: 'warning'
              });
            }
          }else{
            this.$notify({
            title: '请登录后重试',
            type: 'warning'
          });
          }
        }else{
          this.$notify({
            title: '赠送人错误',
            type: 'warning'
          });
        }
      }else{
        this.$notify({
            title: '选择饰品后重试',
            type: 'warning'
          });
      }
      
  

    },
    history() {
      this.showDetails = !this.showDetails;
      this.total = this.total2;
    },
    // @click="showDetails = !showDetails
    //取回记录
    quhuijilu() {
      const that = this;
      that
        .$post("/api/users/skinToSteamLog", {
          page: that.page,
        })
        .then((res) => {
          if (res.status == 1) {
            that.skinList = res.data.skinList;
            that.total2 = res.data.total;
            that.skinList.forEach((ele) => {
              if (ele.state == 1) {
                ele.state = "处理中";
              } else if (ele.state == 2) {
                ele.state = "取回成功";
              } else {
                ele.state = "驳回";
              }
            });
            // console.log("res66666", that.skinList);
          }
        });
    },
    changeSelect(e) {
      this.value = e;
      this.page = 1;
      this.total = 0;
      this.list = [];
      this.getList();
    },
    //取回
    goQuhui() {
      this.loading = true;
      var arr = [];
      // for (let i = 0; i < this.list.length; i++) {
      //   if (this.list[i].state1) {
      //     this.$message({
      //       message: "正在取回中，稍后重试",
      //       type: "warning",
      //     });
      //     this.loading = false;
      //     return;
      //   }
      // }
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state) {
          arr.push(this.list[i].id);
        }
      }
      let param = {
        // player_id: this.$store.state.id,
        player_skins_ids: arr.join(","),
      };
      this.$post("/api/users/skinToSteam", param)
        .then((res) => {
          var data = res;
          if (data.status == 1) {
            this.loading = false;
            this.winState = false;
            // this.$store.commit("getMoney", res.data.total_amount);
            // Utils.$emit("money", res.data.total_amount);
            this.getList(1);
            this.quhuijilu();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.checkedPrice = 0;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
            this.loading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //兑换多个
    coniAll() {
      this.loading = true;
      var arr = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          this.loading = false;
          return;
        }
      }
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state) {
          arr.push(this.list[i].id);
        }
      }
      let param = {
        player_id: this.$store.state.id,
        player_skins_ids: arr,
      };
      this.$post("/api/Users/exchangeToMoney", param)
        .then((res) => {
          var data = res;
          if (data.status == 1) {
            this.loading = false;
            this.winState = false;
            this.$store.commit("getMoney", res.data.total_amount);
            Utils.$emit("money", res.data.total_amount);
            this.getList(1);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.checkedPrice = 0;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
            this.loading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //单个选中
    check(id) {
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].id == id && this.list[i].state1 == false) {
          this.list[i].state = !this.list[i].state;
          if (this.list[i].state == true) {
            this.activelist.push(this.list[i])
            this.checkedPrice = (
              parseFloat(this.checkedPrice) + parseFloat(this.list[i].price)
            ).toFixed(2);
          } else {
            let indexs = this.activelist.indexOf(this.list[i]);
            if (indexs !== -1) {
              this.activelist.splice(indexs, 1);
            }
            // this.activelist.splice(i,1)
            // this.activelist
            this.checkedPrice = (
              parseFloat(this.checkedPrice) - parseFloat(this.list[i].price)
            ).toFixed(2);
          }
        }
      }
      console.log(this.activelist);
      this.$forceUpdate();
    },
    //选择当前页
    selAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.list.length; i++) {
        if (!this.list[i].state1) {
          this.list[i].state = true;
        }
        this.checkedPrice = (
          parseFloat(this.checkedPrice) + parseFloat(this.list[i].price)
        ).toFixed(2);
      }
      this.$forceUpdate();
    },
    //取消当前页
    offAll() {
      this.checkedPrice = 0;
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
        this.checkedPrice = (
          parseFloat(this.checkedPrice) - parseFloat(this.list[i].price)
        ).toFixed(2);
      }
      this.$forceUpdate();
    },
    //分页
    currentChange(val) {
      this.getList(val);
      this.checkedPrice = 0;
    },
    //背包数据
    getListFun(){
      this.num = this.num==1?2:1;
      this.getList(1)
    },
    getList(page) {
      this.page = page;
      let param = {
        page: page,
        pageSize: this.pageSize,
        order: this.value,
      };
      var url = this.num==1?'/api/users/packageList':'/api/users/packagelist1';
      this.$post(url, param)
        .then((res) => {
          var data = res;
          if (data.status == "1") {
            this.total = data.data.total;
            this.list = data.data.skinList;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
              this.list[i].state1 = false;
              this.list[i].loading1 = false;
              this.list[i].loading2 = false;
            }
            if (data.data.skinList.length == 0) {
              let param = {
                page: this.page - 1,
                pageSize: this.pageSize,
                order: this.value,
              };
              this.$post("/api/users/packageList", param).then((res) => {
                var data = res;
                if (data.status == "1") {
                  this.total = data.data.total;
                  this.list = data.data.skinList;
                  for (let i = 0; i < this.list.length; i++) {
                    this.list[i].state = false;
                    this.list[i].state1 = false;
                    this.list[i].loading1 = false;
                    this.list[i].loading2 = false;
                  }
                }
              });
            }
          } else {
            this.total = 0;
            this.list = [];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击兑换
    getExchange(event, id) {
      event.stopPropagation();
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].loading1 = true;
          this.list[i].loading2 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_id: this.$store.state.id,
        player_skins_ids: [id],
      };
      this.$post("/api/Users/exchangeToMoney", param)
        .then((res) => {
          var data = res;
          if (data.status == 1) {
            this.getList(this.page);
            this.$store.commit("getMoney", res.data.total_amount);
            Utils.$emit("money", res.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            for (let i = 0; i < this.list.length; i++) {
              if (id == this.list[i].id) {
                this.list[i].loading1 = false;
                this.list[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: data.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击取回
    getPull(event, id, steamId) {
      event.stopPropagation();
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].loading1 = true;
          this.list[i].loading2 = true;
          this.list[i].state1 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_id: this.$store.state.id,
        player_skins_id: id,
        itemId: steamId,
        random: this.random,
      };
      this.$axios
        .post("/index/User/skinToSteam", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.list.length; i++) {
              if (id == this.list[i].id) {
                this.list[i].loading1 = false;
                this.list[i].loading2 = false;
                this.list[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.randomString();
        });
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      this.random = n;
    },
  },
  mounted() {
    this.getList(this.page);
    this.randomString();
    this.quhuijilu();
  },
};
</script>

<style lang="less" scoped>
.userlist{
  overflow: scroll;
  height: 450px;
  .useritem{
    display: flex;
    height: 40px;
    // background: #00e4ff;
    margin-top: 10px;
    border-radius: 5px;
    background: url("../assets/image/pk/huo.webp") no-repeat center;
    background-size: 100% 100%;
    padding: 10px;
    p{
      line-height: 20px !important;
      color: white;
    }
  }
}
.zengbac{
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999;
  position: fixed;
  .zeng{
    width: 80%;
    height: 500px;
    background: white;
    margin: 80px auto;
    position: relative;
    background: url('../assets/image/S9/bac.png') no-repeat center center;
    background-size: 100% 100%;
    border-radius: 5px;
    h1{
      position: absolute;
      right: -20px;
      top: -50px;
      color: white;
      font-size: 30px;
    }
  }
  .tops{
    display: flex;
    flex-wrap: nowrap;
    border-radius: 5px !important;
    overflow: hidden;
    
    ::v-deep .el-input{
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
    ::v-deep .el-input__inner{
      background: #a5a5a5 !important;
      color: #fff !important;
     
    }
    span{
      width: 20%;
      background: rgb(204, 199, 206);
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
// .zengs{
//   color: white !important;
//   display: flex;
//   flex-wrap: nowrap;
//   width: 100%;
//   span{
//     width: 20%;
//     background: #3eccff;
//     text-align: center;
//     line-height: 25px;
//   }
// }
.zengs{
  color: #fff !important;
  width: 50px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  background: url(../assets/newimg/shaixuan.png) no-repeat !important;
  background-size: 100% 100% !important;
}
.dota {
  //width: 1400px;
  width: 90%;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .hint {
    margin-top: 10px;
    color: #a5a5a5;
    i {
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .dota-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
    .data_nav {
      width: 100%;
      height: 41px;
      //margin-top:20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      // border-bottom: 3px solid #ffc400;
      span {
        font-size: 14px;
        color: #fff;
      }
    }
    .nav_item {
      width: 156px;
      height: 61px;
      text-align: center;
      line-height: 61px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
    }
    .data-top {
      font-size: 18px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }

    .dota-sel {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sel-left {
        width: 131px;
        height: 52px;
        line-height: 52px;
        color: #e9b10e;
        font-size: 12px;
        font-weight: 600;
        margin-right: 30px;
        background: url(../assets/newimg/zhanxin.png) no-repeat;
        background-size: 100% 100%;
        /deep/ .el-input__inner {
          // border-color: #ffc400;
          border: none;
          background: rgba(0, 0, 0, 0.3);
          font-size: 14px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #00e4ff;
        }
        /deep/ .el-select .el-input .el-select__caret {
          display: none;
          // color: #fff;
        }
        /deep/ .el-select .el-input .el-input__suffix {
          width: 19px;
          height: 12px;
          margin-top: 15%;
          margin-right: 5px;
          background: url(../assets/newimg/xiajiantou.png) no-repeat;
          background-size: 100% 100%;
        }
      }


      .sel-left:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .sel-right {
        display: flex;
        align-items: center;
        .right-one {
          display: flex;
          align-items: center;
          justify-content: center;
          .span1 {
            min-width: 103px;
            margin-right: 8px;
            height: 36px;
            line-height: 36px;
            background: url(../assets/newimg/guize.png) no-repeat;
          background-size: 100% 100%;
            font-size: 14px;
            color: #848492;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .span1 {
            // background: rgba(255, 255, 255, 0.25);
            color: #fff;
          }
          .span1:hover {
            cursor: pointer;
            background-color: #3a3f50;
          }
          .span2 {
            width: 103px;
            text-align: center;
            background: url(../assets/newimg/shaixuan.png) no-repeat;
          background-size: 100% 100%;
            color: #fff;
            box-sizing: border-box;
            height: 36px;
            line-height: 36px;
            padding: 0;
          }
          .span2:hover {
            cursor: pointer;
            background-color: #f5c432;
          }
          .span3 {
            background-color: #17b4ed;
            color: #1a1c24;
          }
          .span3:hover {
            cursor: pointer;
            background-color: #3eccff;
          }
        }
        /* .right-two /deep/ .el-input__inner{
            background-color: #2b2c3f;
        }*/
      }
    }

    //分页
    .roll-page {
      margin: 10px 0 0px -10px;
      // margin-bottom: 50px;
      padding-bottom: 50px;
      /deep/ .el-pagination {
        text-align: center;
      }
    }
    .roll-page
      /deep/
      .el-pagination.is-background
      .el-pager
      li:not(.disabled).active {
      background-color: #FF5CFD;
      color: #1a1c24;
    }
    .roll-page /deep/ .el-pagination.is-background .btn-next,
    /deep/ .el-pagination.is-background .btn-prev,
    /deep/ .el-pagination.is-background .el-pager li {
      background-color: #333542;
      color: #848492;
    }

    .dota-list {
      margin-top: 20px;
      ul {
        // li:nth-child(5n) {
        //   margin-right: 0;
        // }
        li {
          width: 18%;
          height: 250px;
          float: left;
          background: url(../assets/newimg/roombox.png) no-repeat;
          background-size: 100% 100%;
          margin-right: 2%;
          margin-bottom: 2%;
          position: relative;
          .list-warp {
            margin: 8px;
            //background-color: #2b2c37;
            //border-radius: 5px;
            //position: relative;
            overflow: hidden;
            .warp1 {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(233, 177, 14, 0.1);
              z-index: 33;
            }
            .warp3 {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
              z-index: 66;
              display: flex;
              justify-content: center;
              span {
                margin-top: 40px;
                color: #fff;
                font-size: 20px;
              }
            }
            .warp2 {
              width: 20px;
              height: 20px;
              position: absolute;
              right: 0;
              top: 0;
            }
            .list-bor {
              position: absolute;
              top: 0;
              z-index: 666;
              width: 100%;
              height: 100%;
              background-color: rgba(233, 177, 14, 0.1);
            }

            .ico {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 12px;
              color: #c3c3e2;
              background-color: rgba(0, 0, 0, 0.5);
              padding: 2px 4px;
            }
            .buy-state {
              position: absolute;
              top: 30px;
              right: 10px;
              width: auto;
              height: 28px;
              color: #e9b10e
              // img {
              //   width: 41px;
              //   height: 28px;
              // }
            }
            .list-img {
              height: 150px;
              // background-image: url("../assets/img/box-skins-blue.jpg");
              // background-size: 100% 100%;
              padding: 0 20px;
              margin-top: 20px;
              img {
                width: 100%;
                height: 85%;
              }
            }
            .bot {
              //background-color: #2b2c37;
              .list-name {
                text-align: center;
                padding-left: 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 16px;
                color: #fff;
              }
              .list-pirce {
                padding: 10px 5px 10px 5px;
                display: flex;
                // flex-direction:row-reverse;
                // justify-content: space-between;
                justify-content: center;
                .pirce-left {
                  display: flex;
                  align-items: center;
                  margin-right: 10px;

                  img {
                    width: auto;
                    height: 15px;
                    margin-right: 5px;
                  }
                  span {
                    color: #fff;
                    font-size: 16px;
                  }
                }
                .pirce-right {
                  span {
                    margin-left: 10px;
                    padding: 4px 15px;
                    border-radius: 5px;
                    font-size: 12px;
                    white-space: nowrap;
                  }
                  span:hover {
                    cursor: pointer;
                  }
                  .spanbtn1 {
                    border: 1px solid #e9b10e;
                    color: #e9b10e;
                    padding: 4px 15px;
                  }
                  .spanbtn2 {
                    padding: 4px 15px;
                    background-color: #e9b10e;
                    border-color: #e9b10e;
                  }
                }
              }
            }
          }
          .list-warp:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  /* 在宽度小于等于 767px 的屏幕上应用以下样式 */
  /* 适配手机端 */
  .dota-warp{
	  padding: 30px 20px !important;
  }
}
</style>
