<template>
  <div class="hist">
    <div class="hist-warp">
      <div class="roomleft-num">
        <div class="nav_item" style="cursor: pointer" @click="goLucky">
          <i class="back el-icon-arrow-left"></i>
          返回
        </div>
        <div class="right-one">
          <div
            class="span1"
            :class="nav == 1 ? 'active' : ''"
            @click="getTab(1)"
          >
            我的记录
          </div>
          <div
            class="span1"
            :class="nav == 2 ? 'active' : ''"
            @click="getTab(2)"
          >
            所有记录
          </div>
        </div>
      </div>

      <div class="hist-list">
        <div v-if="nav == 2">
          <p style="color: #fff">所有记录</p>
          <div class="bot">
            <el-table
              @row-click="goLuckyRoom"
              :data="tableData"
              style="width: 100%"
            >
              <el-table-column prop="update_time" label="时间">
              </el-table-column>
              <!-- <el-table-column prop="room_num" label="编号"> </el-table-column> -->
              <el-table-column label="玩家">
                <template slot-scope="scope">
                  <div style="display: flex">
                    <div
                      v-for="item in scope.row.player_info"
                      :key="item.id"
                      style="
                        width: 36px;
                        height: 36px;
                        text-align: center;
                        position: relative;
                      "
                      class="pic"
                    >
                      <img
                        v-if="item.id == scope.row.winner"
                        style="
                          width: 120%;
                          height: 120%;
                          position: absolute;
                          left: -7px;
                          top: 9px;
                          z-index: 0;
                        "
                        src="../assets/img/yingjia.png"
                        alt=""
                      />
                      <img
                        style="
                          width: 30px;
                          height: 30px;
                          border-radius: 50%;
                          margin: 0 auto;
                          margin-right: 5px;
                          margin-top: 15px;
                          z-index: 1;
                        "
                        :src="item.img"
                        alt=""
                      />
                    </div>
                  </div>
                  <!-- <el-image
                    v-for="(item, index) in scope.row.player_info"
                    :key="index"
                    style="
                      width: 30px;
                      height: 30px;
                      border-radius: 50%;
                      margin-right: 5px;
                      margin-top: 15px;
                    "
                    :src="item.img"
                    :preview-src-list="[item.img]"
                  ></el-image> -->
                </template>
              </el-table-column>
              <el-table-column label="盲盒信息">
                <template slot-scope="scope">
                  <div style="display: flex;">
                    <div
                      style="
                        display: flex;
                        position: relative;
                        margin-right: 25px;
                      "
                      v-if="!isMobile||index==0"
                      v-for="(item, index) in scope.row.boxInfo"
                      :key="index"
                      :preview-src-list="[item.img_active]"
                    >
                      <img
                        :src="item.img_active"
                        style="width: 40px; height: 40px"
                        alt=""
                      />
                      <div
                        style="
                          font-size: 14px;
                          color: #fff;
                          width: 18px;
                          height: 18px;
                          position: absolute;
                          top: 0;
                          right: -15px;
                          background-color: #f00;
                          border-radius: 50%;
                          text-align: center;
                          line-height: 18px;
                        "
                      >
                        {{ item.imgNum }}
                      </div>
                    </div>
                    <span v-if="isMobile&&scope.row.boxInfo.length>1" style="color: white;">...</span>
                  </div>

                  <!-- <el-image
                    v-for="(item, index) in scope.row.boxInfo"
                    :key="index"
                    style="width: 40px; height: 40px"
                    :src="item.img_main"
                    :preview-src-list="[item.img_main]"
                    ><div style="font-size: 16px; color: #f00">
                      {{ item.imgNum }}
                    </div></el-image
                  > -->
                </template>
              </el-table-column>
              <el-table-column prop="statusName" label="状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.statusName == '等待中'" class="ending">
                    等待中
                  </div>
                  <div v-if="scope.row.statusName == '进行中'" class="handing">
                    进行中
                  </div>
                  <div v-if="scope.row.statusName == '已结束'">已结束</div>
                </template>
              </el-table-column>
              <el-table-column prop="total_price" label="金额">
              </el-table-column>
            </el-table>
          </div>
          <div class="roll-page">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totalSize"
              :page-size="pageSize"
              @current-change="currentChange"
            >
            </el-pagination>
          </div>
        </div>

        <div v-if="nav == 1">
          <p style="color: #fff">我的记录</p>
          <div class="bot">
            <el-table
              @row-click="goLuckyRoom"
              :data="tableData1"
              style="width: 100%"
            >
              <el-table-column prop="update_time" label="时间">
              </el-table-column>
              <!-- <el-table-column prop="room_num" label="编号"> </el-table-column> -->
              <el-table-column label="玩家">
                <template slot-scope="scope">
                  <div style="display: flex; padding: 3px">
                    <div
                      v-for="item in scope.row.player_info"
                      :key="item.id"
                      style="
                        width: 36px;
                        height: 36px;
                        text-align: center;
                        position: relative;
                      "
                      class="pic"
                    >
                      <img
                        v-if="item.id == scope.row.winner"
                        style="
                        width: 120%;
                          height: 120%;
                          position: absolute;
                          left: -6px;
                          top: 9px;
                          z-index: 0;
                        "
                        src="../assets/img/yingjia.png"
                        alt=""
                      />
                      <img
                        style="
                          width: 30px;
                          height: 30px;
                          border-radius: 50%;
                          margin: 0 auto;
                          margin-right: 5px;
                          margin-top: 15px;
                          z-index: 1;
                        "
                        :src="item.img"
                        alt=""
                      />
                    </div>
                  </div>
                  <!-- <el-image
                    v-for="(item, index) in scope.row.player_info"
                    :key="index"
                    style="
                      width: 30px;
                      height: 30px;
                      border-radius: 50%;
                      margin-right: 5px;
                      margin-top: 15px;
                    "
                    :src="item.img"
                    :preview-src-list="[item.img]"
                  ></el-image> -->
                </template>
              </el-table-column>
              <el-table-column label="盲盒信息">
                <template slot-scope="scope">
                  <div style="display: flex;">
                    <div
                      style="
                        display: flex;
                        position: relative;
                        margin-right: 25px;
                      "
                      v-for="(item, index) in scope.row.boxInfo"
                      :key="index"
                      v-if="!isMobile||index==0"
                      :preview-src-list="[item.img_active]"
                    >
                      <img
                        :src="item.img_active"
                        style="width: 40px; height: 40px"
                        alt=""
                      />
                      <div
                        style="
                          font-size: 14px;
                          color: #fff;
                          width: 18px;
                          height: 18px;
                          position: absolute;
                          top: 0;
                          right: -15px;
                          background-color: #f00;
                          border-radius: 50%;
                          text-align: center;
                          line-height: 18px;
                        "
                      >
                        {{ item.imgNum }}
                      </div>
                    </div>
                    <span v-if="isMobile&&scope.row.boxInfo.length>1" style="color: white;">...</span>
                  </div>
                  <!-- <el-image
                    v-for="(item, index) in scope.row.boxInfo"
                    :key="index"
                    style="width: 40px; height: 40px"
                    :src="item.img_main"
                    :preview-src-list="[item.img_main]"
                  ></el-image> -->
                </template>
              </el-table-column>
              <el-table-column prop="statusName" label="状态">
              </el-table-column>
              <el-table-column prop="total_price" label="金额">
              </el-table-column>
            </el-table>
          </div>
          <div class="roll-page">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="totalSize1"
              :page-size="pageSize1"
              @current-change="currentChange1"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from "qs";
export default {
  data() {
    return {
      nav: this.$route.query.type,
      totalSize: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      totalSize1: 0,
      page1: 1,
      pageSize1: 10,
      tableData1: [],
      isMobile:window.innerWidth < 768,
    };
  },
  mounted() {
    if (this.nav == 1) {
      this.getMyHist(this.page1);
    } else {
      this.getHist(this.page);
    }
    this.getMyHist(this.page1);
  },
  methods: {
    goLuckyRoom(row) {
      // console.log("scope6666", row);
      this.$router.push({
        path: "/LuckyRoom",
        query: {
          id: row.id,
        },
      });
    },
    getTab(tab) {
      this.nav = tab;
      if (tab == 2) {
        this.getHist(this.page);
      } else {
        this.getMyHist(this.page1);
      }
    },
    getHist(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$post("/api/battle/battleListLog", param)
        .then((res) => {
          var data = res;
          if (data.status == 1 && data.data != null) {
            data.data.battleList.forEach((ele) => {
              const newList = [];
              const imgarr = [];
              ele.boxInfo.forEach((ele1) => {
                // console.log("imgarr222" + ele1.box_id, imgarr);
                if (imgarr.indexOf(ele1.box_id) != -1) {
                  newList.forEach((v) => {
                    if (v.box_id == ele1.box_id) {
                      // console.log("v00000", v);
                      v.imgNum++;
                    }
                  });
                } else {
                  ele1.imgNum = 1;
                  newList.push(ele1);
                  imgarr.push(ele1.box_id);
                  // console.log("ele3333", newList);
                }
              });
              ele.boxInfo = newList;
            });

            if (data.data.total > 50) {
              this.totalSize = 50;
              this.tableData = data.data.battleList.splice(0, 50);
            } else {
              this.totalSize = data.data.total;
              this.tableData = data.data.battleList;
            }
            for (let i = 0; i < this.tableData.length; i++) {
              if (this.tableData[i].status == 1) {
                this.tableData[i].statusName = "等待中";
              } else if (this.tableData[i].status == 2) {
                this.tableData[i].statusName = "进行中";
              } else {
                this.tableData[i].statusName = "已结束";
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getMyHist(page) {
      let param = {
        player_id: this.$store.state.id,
        page: page,
        pageSize: this.pageSize1,
      };
      this.$post("/api/battle/battleHistory", param)
        .then((res) => {
          var data = res;
          if (data.status == 1 && data.data != null) {
            data.data.list.forEach((ele) => {
              // ele.boxInfo = JSON.parse(ele.boxInfo);
              const newList = [];
              const imgarr = [];
              ele.boxInfo.forEach((ele1) => {
                // console.log("imgarr222" + ele1.box_id, imgarr);
                if (imgarr.indexOf(ele1.box_id) != -1) {
                  newList.forEach((v) => {
                    if (v.box_id == ele1.box_id) {
                      // console.log("v00000", v);
                      v.imgNum++;
                    }
                  });
                } else {
                  ele1.imgNum = 1;
                  newList.push(ele1);
                  imgarr.push(ele1.box_id);
                  // console.log("ele3333", newList);
                }
              });
              ele.boxInfo = newList;
            });
            this.totalSize1 = data.data.total;
            this.tableData1 = data.data.list;
            for (let i = 0; i < this.tableData1.length; i++) {
              // console.log("this.tableData16666", this.tableData1);
              // this.tableData1[i].boxInfo = JSON.parse(
              //   this.tableData1[i].boxInfo
              // );
              console.log("this.tableData177777", this.tableData1);
              if (this.tableData1[i].status == 1) {
                this.tableData1[i].statusName = "等待中";
              } else if (this.tableData1[i].status == 2) {
                this.tableData1[i].statusName = "进行中";
              } else {
                this.tableData1[i].statusName = "已结束";
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页 所有记录
    currentChange(val) {
      this.getHist(val);
    },
    //分页 我的记录
    currentChange1(val) {
      this.getMyHist(val);
    },
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hist {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .hist-warp {
    // width: 1400px;
    max-width: 1200px;
    // width: 90%;
    height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }

  //tabs
  .hist-list {
    margin-top: 20px;
  }
  .hist-list /deep/ .el-tabs--border-card {
    //overflow: hidden;
    background-color: #2b2c37;
    border: none;
    // border-radius: 5px;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: #1a1c24;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    background-color: #24252f;
    border: none;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #e9b10e;
  }

  //页数
  .roll-page {
    margin: 10px 0 0 -10px;
  }
  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #ff5cfd;
    color: #1a1c24;
  }
  .el-table,
  .el-table__expanded-cell {
    background: none;
  }
  .el-pagination {
    text-align: center;
  }
  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background: #333542;
    color: #848492;
  }
  .bot /deep/ .el-table th {
    color: #fff;
  }
  .bot /deep/ .el-table td {
    color: #ccc;
  }
  //表格
  .bot /deep/ .el-table th,
  .bot /deep/ .el-table tr {
    background: none;
  }
  .bot /deep/ .el-table td,
  .bot /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }
  .bot /deep/ .el-table::before {
    height: 0;
  }
  .bot /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background: none !important;
  }
  .bot /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }
  .bot /deep/ .el-table__empty-block {
    background: none;
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    // border-bottom: 3px solid #ffc400;
    .right-one {
      display: flex;
      align-items: center;
      margin-top: -17px;
      .span1 {
        min-width: 88px;
        margin-left: 18px;
        height: 36px;
        line-height: 36px;
        background: url(../assets/newimg/guize.png) no-repeat 50%;
        background-size: 100% 100%;
        font-size: 14px;
        color: #fff;
        font-weight: 600;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .span1.active {
        width: 100px;
        height: 45px;
        line-height: 45px;
        background: url(../assets/newimg/chuangjian.png) no-repeat 50%;
        background-size: 100% 100%;
        color: #fff;
      }
    }
    .nav_item {
      width: 156px;
      height: 41px;
      text-align: center;
      line-height: 41px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
      cursor: pointer;
      position: relative;
      .back {
        position: absolute;
        left: 15px;
        font-size: 24px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
  }
}
.ending {
  color: #0a9cd3;
}
.handing {
  color: #17d30a;
}
@media screen and (max-width: 767px) {
  .hist-warp {
    .roomleft-num {
      width: 100% !important;
      .nav_item {
        width: 30% !important;
        font-size: 14px !important;
        i {
          font-size: 16px !important;
        }
      }
      .right-one {
        width: 65% !important;
        .span1 {
          min-width: 75px !important;
        }
      }
    }
  }
}
</style>
