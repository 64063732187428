var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"win"},[_c('div',{class:{
      'win-con': _vm.list1.length == 1,
      'win-con1': _vm.list1.length == 2,
      'win-con2': _vm.list1.length == 3,
      'win-con3': _vm.list1.length == 4,
      'win-con4': _vm.list1.length == 5,
    }},[_c('ul',_vm._l((_vm.list1),function(item,index){return _c('li',{key:index,class:item.check == false ? 'active' : '',style:('background-image: url('+item.background + ')'),on:{"click":function($event){return _vm.isCheckShow(index)}}},[_c('div',{staticClass:"win-warp"},[_c('div',{staticClass:"win-img",staticStyle:{"margin-top":"-20px"}},[_c('img',{attrs:{"src":item.img}})]),_c('div',{staticClass:"win-text mt-3",staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"win-text",attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])])])}),0)]),_c('div',{staticClass:"win-price"},[_c('img',{attrs:{"src":require("../assets/img/money.png")}}),_c('span',[_vm._v(_vm._s(_vm.total_price)+" ")]),_c('img',{staticStyle:{"display":"inline-block-block","margin-left":"15px"},attrs:{"src":require("../assets/img/moneyRed.png")}}),_c('span',[_vm._v(" "+_vm._s(_vm.scoreAll))])]),_c('div',{staticClass:"win-bot"},[_c('div',{staticClass:"win-span2",on:{"click":_vm.winget}},[_vm._v("放入背包")]),_c('div',{staticClass:"win-span1",on:{"click":_vm.winexchange}},[_vm._v("兑换")])]),_c('div',{staticClass:"win-x",on:{"click":_vm.winX}},[_c('img',{attrs:{"src":require("../assets/newimg/img_02.png"),"alt":""}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }