<template>
  <div class="">
    <div v-if="listData.length > 10" class="d-flex flex-nowrap seamless">
      <div
        v-for="(item, index) in listData"
        :key="index"
        :class="item.status ? 'status' : ''"
        class=""
        :style="'background-image: url(' + item.img + ')'"
        style="background-size: 100% 100%;"
        @click="selesshref(item)"
      >
        <div class="">
          <a
            class="kkCSGAO-wuqi"
         
          >
            <div
              class=""
            >
            </div>
            <div
              class="sessbox"
              style="width:130px"
            >
              <div
                class="imgbox"
                style=""
              >
                <img
                  height="50px"
                  class="swiper-list-content-box-item-img"
                  style="margin-top: 0; z-index: 99"
                  :src="item.imageUrl"
                  alt=""
                />
               
                <p class="users"><img :src="item.player_img" alt="" :class="item.is_anchor==1?'imgbac':'imgbacs'"> {{ item.player_name }}
                  <!-- <div class="anchor" v-if="item.is_anchor == 1">
                    <img src="../assets/newimg/header.png" />
                  </div>
                  <div class="anchor" v-else>
                    <img src="../assets/newimg/header2.png" />
                  </div> -->
                </p>

              </div>
              <div class="ml-2">
                <div
                  class="sizemoney"
                  style="width: 100%; font-size: 12px"
                >
                  
                  <p>{{ item.skin_name }}</p>
                  <p>打开 <span style="color:rgb(226, 166, 87)">{{ item.box_name }}</span>获得</p>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <van-notice-bar
      scrollable
      left-icon="volume-o"
      background="rgb(38,38,50)"
      color="white"
      style="font-size: 15px"
      :text="site_bulletin"
    />
  </div>
</template>


<script>
import { openBox } from "@/api/socket.js";
export default {
  components: {
    // NoticeBar
  },
  data() {
    return {
      // 数组长度长点 无限循环
      listData: [],
      site_bulletin: "",
      me:''
      // 无限循环
    };
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			if (userInfo) {
				this.me = userInfo;
			
			}
  },
  mounted() {
    this.init();
  },
  methods: {
    selesshref(item){
      console.log(123123);
      this.$router.push(`/Openbox?box_id=${item.box_id}`)
    },
    async init() {
      // 一开始的加载
      let time = parseInt(new Date().getTime() / 1000);
      let sign = this.$md5("cs#.go" + time);
      let param = {
        page: 1,
        // pageSize: this.pageSize,
        time: time,
        sign: sign,
      };
      let { data } = await this.$axios.post("/api/index/lately", this.$qs.stringify(param));
      console.log(data.data.list);
      if (data.data.list.length < 15) {
        for (let i = 0; i < 20; i++) {
          data.data.list.push(data.data.list[Math.floor(Math.random() * data.data.list.length)]);
        }
      }
      data.data.list.forEach((item) => {
        item.status = false;
      });
      this.listData = data.data.list;
      console.log(this.listData)

       this.getBoxHistory();
    },
    // 获取轮播内容
    async getBoxHistory() {
      try {
        let time = parseInt(new Date().getTime() / 1000);
      let sign = this.$md5("cs#.go" + time);
      let param = {
        page: 1,
        // pageSize: this.pageSize,
        time: time,
        sign: sign,
      };
        setInterval(() => {
          this.$axios.post("/api/index/lately", this.$qs.stringify(param)).then((res) => {
            let sum = Math.floor(Math.random() * 5);
            let arr = res.data.data.list.slice(sum, sum + 5);
            arr.forEach((item) => {
              item.status = true;
            });
            this.listData.unshift(...arr);
            this.listData.splice(20, this.listData.length);
            setTimeout(() => {
              this.listData.forEach((item) => {
                item.status = false;
              });
            }, 1000);
          });
        }, 5000);
      } catch (e) {
        // console.log(e)
      }
    },
  },
};
</script>


<style lang="less" scoped>

  .users{
    display: flex;
    align-items: center;
    font-size: 9px;
    position: relative;
   .imgbac{
    background: url('../assets/newimg/header.png') no-repeat center !important;
    background-size: 100% 100% !important;
    padding: 2px;
   }
   .imgbacs{
    background: url('../assets/newimg/header2.png') no-repeat center !important;
    background-size: 100% 100% !important;
    padding: 2px;
   }
    img{
      width: 20px;
      height: 20px;
      border-radius: 50%;
      padding: 2px;
    }
  }
.sessbox{
  display: flex;
  flex-wrap: wrap;
}
.imgbox{
  display: flex;
  justify-content: center;
  align-items: center;
}
.sizemoney {
  p{
    color: #fff;
  display: -webkit-box;//对象作为弹性伸缩盒子模型显示
  overflow: hidden;//溢出隐藏
  -webkit-box-orient: vertical;//设置伸缩盒子对象的子元素的排列方式
  -webkit-line-clamp: 1;//设置 块元素包含的文本行数
  font-size: 9px;
  }

  // color: #fff;
  // display: -webkit-box;//对象作为弹性伸缩盒子模型显示
  // overflow: hidden;//溢出隐藏
  // -webkit-box-orient: vertical;//设置伸缩盒子对象的子元素的排列方式
  // -webkit-line-clamp: 1;//设置 块元素包含的文本行数
  @media (max-width: 1100px) {
    font-size: 9px;
  }
}
.kkCSGAO {
  left: 0;
  // bottom: -200px;
  opacity: 0;
  transition: all 0.5s;
}
.kkCSGAO-wuqi {
  left: 0;
  top: 0;
  transition: all 0.5s;
  background: #fff !important;
  // bottom: -200px;
}
.seamless {
  width: 99.4vw;
  height: 130px;
  overflow: hidden;
  display: flex;

  @media (max-width: 1366px) {
    height: 90px;
  }
  @media (max-width: 1100px) {
    height: 90px;
  }
  & .status {
    transform-origin: 100% 50% 0;
    animation: scrolldar 0.65s ease;
  }
  &-item {
    //width: 162px;
    margin-right: 2px;
    transition: all 0.5s;
    &:hover .kkCSGAO {
      bottom: 0px;
      z-index: 9;
      opacity: 1;
    }
    &:hover .kkCSGAO-wuqi {
      opacity: 1;
      top: -200px;
    }
    &-img {
      width: 100%;
      height: 100%;
    }
  }
}
@keyframes scrolldar {
  0% {
    margin-left: -130px;
    opacity: 1;
    transform: perspective(800px) rotateY(-90deg) translateZ(0);
  }
  100% {
    margin-left: 1px;
    opacity: 1;
    transform: perspective(800px) rotateY(0deg) translateZ(0);
  }
}

.swiper-list-jieri {
  width: 100%;
  & img {
    position: absolute;
    right: 0;
    width: 26%;
  }
}

.swiper {
  width: 100%;
  overflow: hidden;
  &-list {
    position: relative;

    &-content {
      & .warp {
        height: 90px;
        @media (max-width: 1366px) {
          height: 90px;
        }
        @media (max-width: 1100px) {
          height: 80px;
        }
      }

      &-box {
        margin: 0 1px;
        top: 0;
        transition: all 0.5s;
        @media (max-width: 1100px) {
          height: 80px;
        }

        &:hover {
          top: -90px;
        }

        &-item {
          transition: all 0.5s;
          width: 160px;
          height: 130px;
          font-size: 12px;
          @media (max-width: 1000px) {
            height: 50px;
            width: 100px;
          }
          &-img {
            height: 80px;
            width: 120px;
            @media (max-width: 1366px) {
              height: 45px !important;
              width: 60px;
            }
          }
          @media (max-width: 1366px) {
            height: 90px !important;
            &-img {
              height: 40px;
            }
          }
          @media (max-width: 1100px) {
            width: 90px;
            height: 80px;
            &-type-num {
              font-size: 12px;
            }
            &-type {
              & img {
                width: 10px;
              }
            }
          }
          &-type {
            top: 6px;
          }
        }

        &-itemnone {
          opacity: 0;
          transition: all 0.5s;
          width: 170px;
          height: 100px;
          &-title {
            text-align: center;
            padding: 10px 0;
          }

          &-img {
            transition: all 1s;
          }
        }
      }
    }
  }
}
</style>
