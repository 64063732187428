<template>
  <div class="index">
    <!-- :style="{
      backgroundImage: 'url(' + img + ')',
    }" -->
    <myslide style="z-index: 0"></myslide>
    <myinform @isFuliShow="isFuliShow"></myinform>
    <mycard style="z-index: 10"></mycard>
    <!-- <a href="www.baidu.com">baidu</a> -->
    <div v-if="active" class="active">
      <div v-for="(item, index) in active" :key="index" class="active-img">
        <a :href="item.url" v-if="item.img" style="display: flex">
          <div v-for="(v, i) in item.img" :key="i">
            <img class="img-1" :src="v" alt="" />
            <img class="img-2" :src="v" alt="" />
          </div>
        </a>
      </div>
      <div style="clear: both"></div>
    </div>
    <ul class="moneyClass" style="margin-top: 40px;">
      <li v-for="(t, i) in list" :key="i" @click="money = i" :class=" money == i ? 'actives' :'' ">
        {{ t.name }}
      </li>
    </ul>
    <div class="center">
      <div v-for="(v, i) in typeBoxList" :key="i" >
        <div v-if="money == i">
          <div class="box">
            <div class="title">
              <img :src="list[i].img" alt="" />
              <!-- <span>{{ v.type_name }}</span>
              <img src="../assets/newimg/rightjiantou.png" alt="" /> -->
            </div>
            <ul>
              <li
                v-for="(item, index) in v.box_list"
                :key="index"
                class="box-hover mt-4"
                @mouseenter="handMouseenter"
                @mouseleave="mouseleave"
              >
                <!-- @mouseleave.once="mouseleave" -->
                <div @click="openbox(item.id)">
                  <div class="bei1">
                    <img :src="item.img_main" />
                  </div>
                  <div class="bei2">
                    <img :src="item.img_active" />
                  </div>
                  <div class="name">
                    <div class="name-warp">
                      <!--<img src="../assets/img/12mdpi.png" />-->
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                  <div class="money">
                    <div class="money-warp">
                      <img src="../assets/img/money.png" />
                      <span>{{ item.price }}</span>
                      <!-- <span class="money-buy">打开</span> -->
                      <span class="money-kong"></span>
                    </div>
                  </div>
                </div>
                <img class="dipan" src="../assets/newimg/dipan.png" alt="" />
              </li>
              <dir style="clear: both"></dir>
            </ul>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <!-- 新人红包home -->
    <!-- 口令规则 -->
    <!-- <div class="new-box" v-if="newhbShow">
      <div class="new">
        <div class="new-top">
          <img src="../assets/newimg/img_91.png" />
        </div>
        <div class="new-x">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
        <div class="new-con hb">
          <p class="hb-title">活动红包</p>
          <div class="hb-box">
            睡大觉回复工阿速达覅偶哈市个阿速达覅偶胡加速度哈付款和睡大觉回复
            睡大觉回复工阿速达覅偶哈市个阿速达覅偶胡加速度哈付款和工阿速达覅
            睡大觉回复工阿速达覅偶哈市个阿速达覅偶胡加速度哈付款和偶哈市个阿
          </div>
        </div>
      </div>
    </div> -->

    <!-- 公告 -->
    <div class="box-inform" v-if="informShow">
      <div class="informs" @click="signIn">
        <div class="new-x" @click.stop="closeBot">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
        <!-- <div class="box-img-inform" v-html="inform"></div> -->
      </div>
    </div>

    <!-- 新人红包 -->
    <!-- <div class="new-box" v-if="newxrShow">
      <div class="new">
        <div class="new-top">
          <img src="../assets/newimg/img_89.png" />
        </div>
        <div class="new-x">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
        <div class="new-con xr">
          <div class="xr-box">
            睡大觉回复工阿速达覅偶哈市个阿速达覅偶胡加速度哈付款和睡大觉回复
            睡大觉回复工阿速达覅偶哈市个阿速达覅偶胡加速度哈付款和工阿速达覅
          </div>
        </div>
      </div>
    </div> -->
    <audio v-trigger muted="“muted”" controls ref="notify" class="audio">
      <source src="../assets/audio/open_box2.mp3" />
    </audio>
    <el-button id="imports" ref="import" style="display: none">5555</el-button>
    <audio muted="“muted”" controls ref="notify1" class="audio" loop="loop">
      <source src="../assets/audio/open_box1.mp3" />
    </audio>
    <audio controls muted="“muted”" ref="notify2" class="audio" loop="loop">
      <source src="../assets/audio/y704.mp3" />
    </audio>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import myslide from "@/components/seamless.vue";
import myinform from "@/components/my_inform.vue";
import mycard from "@/components/my_card.vue";
import { log } from "util";
export default {
  components: { myslide, myinform, mycard },
  data() {
    return {
      //公告
      informShow: false,
      drawerBot: true,
      // 红包口令输入框
      inputMoney: "",
      typeBoxList: [],
      listSlide: [],
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      active: [],
      userState: "",
      nav: 0,
      newShow: false,
      newhbShow: false,
      newxrShow: false,
      activityRedPacket: [], //活动红包
      newRedPacket: [], //新人红包
      signInRedPacket: [], //签到红包
      inform: "", //公告
      money: 0,
      list: [
        {
          name: "二箱出金",
          img:require('@/assets/img/new/home/title1.png')
        },
        {
          name: "二箱出金s",
          img:require('@/assets/img/new/home/title2s.png')
        },
        {
          name: "三箱出金",
          img:require('@/assets/img/new/home/title3.png')
        },
        {
          name: "三箱出金s",
          img:require('@/assets/img/new/home/title3s.png')
        },
        {
          name: "五箱出金",
          img:require('@/assets/img/new/home/title5.png')
        },
      ],
    };
  },
  watch: {
    // $router: {
    //   handler(newVal, oldVal) {
    //     console.log(newVal, oldVal);
    //     // if (newVal) {
    //     //   this.newShow = false;
    //     // }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  computed: {},
  beforeMount() {
    // this.$el.addEventListener("mouseenter", this.handMouseenter, { once: true });
  },
  methods: {
    isFuliShow() {
      this.$parent.isFuliShow();
    },
    myClick() {},
    closeBot() {
      this.informShow = false;
    },
    // 跳转注册页面
    signIn(){
      this.closeBot();
      if(!localStorage.getItem("token")||localStorage.getItem("token")==''||localStorage.getItem("token")==null){
        this.$parent.showLogin(1);
      }
    },
    handMouseenter() {
      console.log("播放音乐");
      this.playHover();
      setTimeout(() => {
        this.playHover2();
      }, 1000);
    },
    mouseleave() {
      console.log("tingzhi音乐");
      this.playHover2();
    },
    playHover() {
      this.$refs.notify2.play();
    },
    playHover2() {
      this.$refs.notify2.pause();
    },
    //红包领取
    openMoney() {
      const _this = this;
      // console.log("6666666");
      if (_this.inputMoney == "") {
        _this.$message({
          message: "请复制口令",
          type: "info",
        });
        return;
      }
      if (_this.nav == 3) {
        _this.$nextTick(() => {
          _this
            .$post("/api/rebates/get_rebate", {
              token: localStorage.getItem("token"),
              password: _this.inputMoney,
            })
            .then((res) => {
              console.log("get_rebate", res);
            });
        });
      }
      if (_this.nav == 2) {
        const activi = _this.activityRedPacket.filter((item) => {
          return item.pass === _this.inputMoney;
        });
        console.log("activi", activi);
        _this
          .$post("/api/envelope/envelope", {
            token: localStorage.getItem("token"),
            envelope_id: activi[0].id,
            password: _this.inputMoney,
          })
          .then((res) => {
            console.log("envelope", res);
            if (res.code == 1) {
              _this.$message({
                message: res.msg,
                type: "succes",
              });
              _this.inputMoney = "";
            } else {
              // console.log("领取失败66666");
              _this.$message({
                message: res.msg,
                type: "info",
              });
            }
            _this.inputMoney = "";
            // console.log(" this.inputMoney", _this.inputMoney);
          });
      }
    },
    // 关闭新人红包

    // isNewShow2() {
    //   this.$nextTick(() => {
    //     this.$post("/api/envelope/existEnvelope", {
    //       token: localStorage.getItem("token"),
    //     }).then((res) => {
    //       console.log("existEnvelope", res);
    //       this.activityRedPacket = res.data.activityRedPacket;
    //       this.newRedPacket = res.data.newRedPacket;
    //       this.signInRedPacket = res.data.signInRedPacket;
    //     });
    //   });
    //   this.newShow = true;
    // },
    changeNav(index) {
      this.nav = index;
    },
    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    getboxList() {
      let _this = this;
      _this
        .$post("/api/box/boxList")
        .then((res) => {
          this.active = res.data.active;
          if (res.code == 1) {
            _this.typeBoxList = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openbox(box_id) {
      this.$router.push({
        path: `/Openbox`,
        query: {
          box_id: box_id,
        },
      });
    },
    getPlayerInfo(pid) {
      let _this = this;
      let param = {
        player_id: pid,
      };
      if (param.player_id) {
        _this.$axios
          .post("/index/User/getPlayerInfo", _this.$qs.stringify(param))
          .then((res) => {
            // console.log(res.data.data);
            if (res.data.data.status == 1) {
              // console.log(res.data.data.total_amount);
              // Utils.$emit("money", res.data.data.total_amount);
              // Utils.$emit("state", res.data.data.state);
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              Utils.$emit("login", true);
            }
          });
      }
    },
  },
  directives: {
    trigger: {
      inserted(el) {
        console.log("6666666666666666", el);
        el.click(); // 只点击第一个，id是在循环中手动添加的
      },
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.informShow = true;
    }
  },
  mounted() {
    document.getElementById("imports").click();
    this.$refs.import.$el.click();
    this.$nextTick(function () {
      this.$on("isNewShow2", function () {
        this.$nextTick(() => {
          this.$post("/api/envelope/existEnvelope", {
            token: localStorage.getItem("token"),
          }).then((res) => {
            console.log("existEnvelope", res);
            this.activityRedPacket = res.data.activityRedPacket;
            this.newRedPacket = res.data.newRedPacket;
            this.signInRedPacket = res.data.signInRedPacket;
          });
        });
        this.newShow = true;
      });
    });

    this.$bus.$on("informShow", (v) => {
      console.log("informShow", v);
      this.$nextTick(() => {
        if (v == true) {
          this.informShow = v;
          this.$post("/api/index/inform", {
            token: localStorage.getItem("token"),
          }).then((res) => {
            console.log("inform666666", res);
            this.inform = res.data.inform;
          });
        }
      });
    });
    // this.$post("/api/envelope/existEnvelope", {
    //   token: localStorage.getItem("token"),
    // }).then((res) => {
    //   console.log("existEnvelope", res);
    //   this.activityRedPacket = res.data.activityRedPacket;
    //   this.newRedPacket = res.data.newRedPacket;
    //   this.signInRedPacket = res.data.signInRedPacket;
    // });
    if (this.$route.query.pid > 0) {
      // Utils.$emit('pid',_this.$route.query.pid)
      console.log(this.$route.query.pid);
      this.getPlayerInfo(this.$route.query.pid);
    }
    this.getboxList();
    //this.getBack();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo) {
      this.userState = userInfo.state;
    }
    // this.userState = userInfo.state;
  },
};
</script>

<style lang="less">
@media screen and (max-width: 768px) {
  .moneyClass {
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    color: #797178;
    // color: #ff84f5;
    .actives{
      background: rgba(196, 158, 9, 1);
      color:#fff;
      font-weight: 700;
    }
    li{
      border: 1px solid rgba(181, 171, 171, 0.5);
      padding: 5px;
      background:rgba(108, 104, 104, 0.5);
    }
  }
  .bot-right {
    // height: 100% !important;
  }

  .index .center .box ul li .dipan {
    display: none;
  }
}
</style>

<style lang="less" scoped>
.audio {
  display: none;
}

.new-box {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;

  .new {
    width: 668px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;

    .open-money {
      opacity: 0;
      position: absolute;
      top: 30%;
      left: 42%;
      width: 100px;
      height: 100px;
      background: #c42727;
      z-index: 100;
    }

    .new-top {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 30px;

      img {
        width: 185px;
        height: 55px;
      }
    }

    .new-con.xr {
      background: url(../assets/newimg/img_93.png) no-repeat;
      background-size: 100% 100%;
      height: 360px;
      padding: 40px;

      .xr-box {
        font-size: 16px;
        color: #ffffff;
        line-height: 36px;
      }
    }

    .new-con.hb {
      background: url(../assets/newimg/img_92.png) no-repeat;
      background-size: 100% 100%;
      padding-left: 32px;
      padding-right: 32px;

      .hb-title {
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffc400;
      }

      .hb-box {
        font-size: 16px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        margin-top: 20px;
        overflow-y: scroll;
      }

      .hb-box::-webkit-scrollbar {
        width: 4px;
      }

      .hb-box::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }

      .hb-box::-webkit-scrollbar-track {
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .new-con {
      width: 580px;
      height: 700px;
      background: url(../assets/newimg/img_90.png) no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      padding: 45px 20px 20px 20px;
      box-sizing: border-box;
      position: relative;

      .con-top {
        .con-1 {
          width: 350px;
          height: 40px;
          background: rgba(225, 225, 225, 0.5);
          border-radius: 84px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          margin: 0 auto;

          span {
            color: #ffc400;
          }

          img {
            width: 14px;
            height: 14px;
            margin: 0 8px;
          }
        }

        .con-2 {
          margin-top: 25px;
          text-align: center;

          /deep/ .el-input__inner {
            width: 222px;
            height: 40px;
            background: #ffffff;
            border-radius: 84px 84px 84px 84px;
          }
        }
      }

      .con-bottom {
        position: absolute;
        top: 38%;
        color: #fff;
        width: 100%;
        left: 0;
        padding: 0 20px;
        box-sizing: border-box;

        .title {
          text-align: center;

          span {
            color: #ffc400;
          }
        }

        .con-box {
          width: 100%;
          height: 320px;
          border: 2px solid #ffc400;
          margin-top: 15px;

          .box-1 {
            padding: 25px;
            box-sizing: border-box;
            overflow-y: scroll;

            ul {
              li {
                display: flex;
                border-bottom: 1px solid #ffc400;
                padding-bottom: 15px;
                margin-bottom: 15px;

                .left {
                  width: 92px;
                  height: 92px;
                  border: 1px solid #ffc400;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .box {
                    width: 79px;
                    height: 78px;
                    border: 1px solid #ffc400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 13px;
                    text-align: center;
                  }
                }

                .right {
                  margin-left: 25px;
                  font-size: 13px;
                  width: calc(100% - 117px);

                  .titles {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  }

                  .dl {
                    margin-top: 15px;

                    .dd {
                      margin-bottom: 6px;
                    }
                  }
                }
              }
            }
          }

          .box-1::-webkit-scrollbar {
            width: 4px;
          }

          .box-1::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.2);
          }

          .box-1::-webkit-scrollbar-track {
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
          }

          .box-3 {
            .title {
              padding-top: 38px;
              text-align: center;
              font-size: 16px;
              color: #ffffff;

              span {
                color: #ffc400;
              }
            }

            .ewm {
              width: 194px;
              height: 194px;
              background: #ffffff;
              margin: 30px auto;
            }

            p {
              color: #ffc400;
              text-align: center;
            }
          }
        }

        .pay-nav {
          height: 37px;
          margin-top: 40px;
          color: #fff;
          display: flex;
          align-items: center;
          position: relative;
          border-bottom: 3px solid #ffc400;
        }

        .nav_item {
          width: 164px;
          height: 41px;
          text-align: center;
          line-height: 41px;
          font-size: 18px;
          background-size: contain;
          font-weight: 700;
          cursor: pointer;
        }

        .nav_item:first-child {
          background: url(../assets/newimg/img_04.png) no-repeat 50%;
          background-size: 100% 100%;
        }

        .nav_item:first-child.actives {
          background: url(../assets/newimg/img_03.png) no-repeat 50%;
          color: #000;
          background-size: 100% 100%;
        }

        .nav_item:nth-child(2).actives {
          background: url(../assets/newimg/img_05.png) no-repeat 50%;
          color: #000;
          background-size: 100% 100%;
        }

        .nav_item:nth-child(2) {
          background: url(../assets/newimg/img_16.png) no-repeat 50%;
          margin-left: -20px;
          background-size: 100% 100%;
        }

        .nav_item:nth-child(3).actives {
          background: url(../assets/newimg/img_05.png) no-repeat 50%;
          color: #000;
          background-size: 100% 100%;
        }

        .nav_item:nth-child(3) {
          background: url(../assets/newimg/img_16.png) no-repeat 50%;
          margin-left: -20px;
          background-size: 100% 100%;
        }

        .nav_item:nth-child(4).actives {
          background: url(../assets/newimg/img_05.png) no-repeat 50%;
          color: #000;
          background-size: 100% 100%;
        }

        .nav_item:nth-child(4) {
          background: url(../assets/newimg/img_16.png) no-repeat 50%;
          margin-left: -20px;
          background-size: 100% 100%;
        }
      }
    }

    .new-x {
      position: absolute;
      top: 3%;
      right: 0px;
      width: 52px;
      height: 45px;

      img {
        width: 52px;
        height: 45px;
      }
    }
  }
}

//公告
.box-inform {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  .informs {
    width: 844px;
    height: 658px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    background: url(../assets/newimg/gonggao2.png) no-repeat;
    background-size: 100% 105%;
    // padding: 40px;

    .new-x {
      position: absolute;
      top: 16%;
      right: 20px;
      width: 30px;
      height: 30px;

      img {
        width: 30px;
        height: 30px;
      }
    }

    .box-img-inform {
      width: 60%;
      margin: 0 auto;
      margin-top: 190px;
      margin-left: 200px;
      font-size: 16px;
      line-height: 40px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #09e2f6;
    }
  }
}

.index {
  position: relative;
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: url("../assets/newimg/img_104.png") no-repeat;
  background-size: 100% 100%;
  //--------
  position:absolute;
  top:75px;
  left:0;
  height:calc(100vh);
  width: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover; /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover; /* 兼容Opera */
  zoom: 1;
  scrollbar-color: transparent transparent;
  scrollbar-width: 0px;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  /* .top::-webkit-scrollbar {
        display: none;
  }*/

  .center {
    max-width: 1200px;
    min-height: 100%;
    padding-bottom: 40px;
    margin: 0 auto;
    // padding-top: 90px;
    .box {
      // padding-top: 45px;
      position: relative;
      margin-bottom: 50px;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 60px;
        color: #fff;
        width: 100%;
        height: 99px;

        img {
          // width: 300px;
          // height: 85px;
          width: 220%;
          // height: 80px;
        }

        // background: url(../assets/newimg/img_37.png) no-repeat;
        // background-size: 100% 100%;
        span {
          // display: table;
          // border-bottom: 3px solid #fff;
          // padding-bottom: 4px;
          // position: absolute;
          // top: 0;
          font-size: 60px;
          font-family: YouSheBiaoTiHei;
          font-weight: 400;
          color: #ffffff;
          flex-shrink: 0;
          text-shadow: 0 0 24px #cc00ff;
        }
      }

      ul {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
      }

      ul li {
        width: 18%;
        // float: left;
        // margin-right: 47px;
        position: relative;

        .dipan {
          width: 206px;
          height: 95px;
          position: absolute;
          left: 10px;
          bottom: 65px;
          z-index: 0;
        }

        // background: url(../assets/newimg/dipan.png) no-repeat;
        // background-size: 100% 100%;

        .bei1 {
          // display: flex;
          justify-content: center;
          z-index: 10;

          // height: 75.7%;
          img {
            width: 100%;
          }
        }

        .bei2 {
          width: 100%;
          display: flex;
          justify-content: center;
          position: absolute;
          top: 18%;
          z-index: 10;

          img {
            width: 60%;
            height: 60%;
          }
        }

        .name {
          display: flex;
          justify-content: center;

          .name-warp {
            display: flex;
            align-items: center;
            color: #fff;

            img {
              width: 25px;
              height: 25px;
              margin-right: 10px;
            }
          }
        }

        .money {
          margin-top: 15px;
          display: flex;
          justify-content: center;

          .money-warp {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            // border: 2px solid #ffc400;
            // background-color: #8dc0dd;
            // background:linear-gradient(to right, #FF571B 0%, #CE34C1 100%) border-box;
            overflow: hidden;
            border-radius: 10px;
            padding: 6px 35px;
            font-size: 14px;
            background: url("../assets/newimg/money.png") no-repeat;
            background-size: 100% 100%;

            //boder颜色渐变
            // border: 2px transparent solid;
            // background-clip: padding-box, border-box;
            // background-origin: padding-box, border-box;
            // background-image: linear-gradient(135deg,rgba(25,30,46,0.8),rgba(255, 255, 255, 0.5)), linear-gradient(135deg, #ff571b, #ff9b0b);
            // background-color:transparent;

            img {
              width: auto;
              height: 20px;
              margin-right: 5px;
            }
          }
        }
      }

      //动画
      .money-buy {
        display: none;
      }

      .money-kong {
        display: none;
      }

      // .box-hover {
      //   // height: 100px;
      // }
      .box-hover:last-child {
        margin-right: auto;
      }

      .box-hover:hover {
        background: url(../assets/newimg/bejingtu.png) no-repeat;
        background-size: 100% 70%;
        cursor: pointer;
      }

      .box-hover:hover .name-warp {
        color: #cc00ff;
      }

      .box-hover:hover .money-warp {
        // background-image: linear-gradient(#d28c1c, #bfab22);
        // border: 2px solid transparent;
        background: url(../assets/newimg/money-active.png) no-repeat;
        background-size: 100% 100%;
      }

      .box-hover:hover .money-buy {
        display: block;
        position: absolute;
        right: -1px;
        // background-color: #FF9B0B;
        background-image: linear-gradient(to right, #ff571b, #ff9b0b);
        border-radius: 20px;
        color: white;
        padding: 7px 16px;
      }

      // .box-hover:hover .money-kong {
      //   display: block;
      //   margin-left: 60px;
      // }
      .box-hover:hover .bei2 {
        position: absolute;
        top: 20%;
        animation: boxhover 1.5s linear 0s infinite alternate;
      }

      @keyframes boxhover {
        0% {
          top: 20%;
        }

        50% {
          top: 30%;
        }

        100% {
          top: 20%;
        }
      }
    }
  }
}

.active {
  // width: 40%;
  margin: 20px auto;
  // margin-top: 100px;
  position: relative;

  // display: flex;
  // float: left;
  .active-img {
    width: calc(33.33333% - 5px);
    position: relative;
    margin-left: 2.5px;
    margin-right: 2.5px;
    float: left;

    :hover {
      // transform: translate3d(0,-10px,0);
      // transition: transform 1s;
      animation: imghover 1s infinite;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  .img-1 {
    position: absolute;
    cursor: pointer;
  }

  .img-2 {
    visibility: hidden;
  }

  .img-1,
  .img-2 {
    // width: inherit;
    width: 100%;
    height: 90px;
    object-fit: cover;
  }

  @keyframes imghover {
    from {
      top: 0px;
    }

    to {
      top: -15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .active {
    width: 98%;
  }

  .index {
    background-size: 100% 100% !important;
    // background-attachment: fixed !important;
    background-repeat:no-repeat !important;
    background-position:left 70px !important;
  }
  .index::before {
            content: ' ';
            background-size: 100% 100% !important;
            background: url(../assets/newimg/openbac.png) no-repeat center !important;
            background-repeat:no-repeat !important;
            background-position:left 70px !important;
            width: 100% !important;
            height: 100% !important;
            position: fixed;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-size: cover;
        }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .active-img {
    width: calc(50% - 5px) !important;
  }
}

@media screen and (max-width: 767px) {
  .active-img {
    width: 100% !important;
  }

  .center {
    div {
      .box {
        .title {
          span {
            font-size: 30px !important;
            // position: absolute !important;
            // top: 1% !important;
            // left: 38% !important;
          }
        }
      }
    }
  }

  .new-box {
    padding-bottom: 5px !important;

    // height: 80% !important;
    .new {
      width: 668px !important;
      // height: 100px !important;
      position: absolute !important;
      top: 45% !important;
      left: 50% !important;

      .open-money {
        opacity: 0 !important;
        position: absolute !important;
        top: 30% !important;
        left: 40% !important;
        width: 80px !important;
        height: 100px !important;
        background: #c42727 !important;
        z-index: 100 !important;
      }

      .new-con {
        width: 100% !important;
        height: 620px !important;

        .con-bottom {
          .con-box {
            height: 90% !important;

            .box-1 {
              height: 100% !important;
            }
          }

          .box-3 {
            .title {
              padding-top: 25px !important;
            }

            // padding-bottom: 5px !important;
            p {
              margin-bottom: 10px !important;
              // padding-bottom: 5px !important;
            }
          }
        }
      }
    }
  }
}
</style>
