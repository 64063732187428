export default {
  datastime(type){
		let myDate = new Date();  //获取当前时间
		let yinian=myDate.getFullYear(); //年
		let yer=parseInt(myDate.getMonth())+1;//月份
		if(yer<=9){yer="0"+yer;}
		let riqi=myDate.getDate();//日
		if(riqi<=9){riqi="0"+riqi;}
		let time=myDate.getHours();//获取当前小时数(0-23)
		if(time<=9){time="0"+time;}
		let fen=myDate.getMinutes();     //获取当前分钟数(0-59)
		if(fen<=9){fen="0"+fen;}
		let miao=myDate.getSeconds();     //获取秒钟数
		if(miao<=9){miao="0"+miao;}
		if(type=='nyr'){return yinian+"年"+yer+"月"+riqi+"日"} // 2021年1月14日
		if(type=='yous'){
			//return yinian+"-"+yer+"-"+riqi+" "+time+":"+fen+":"+miao //2021-01-14 16:47:36
			return yinian+"-"+yer+"-"+riqi //2021-01-14
		}
		if(type=='year'){
			return yinian+"-"+yer+"-"+riqi+" "+time+":"+fen+":"+miao //2021-01-14 16:47:36
		}
		if(type=='int'){return parseInt(yinian+""+yer+""+riqi)}//202101141647
		return yinian+"年"+yer+"月"+riqi+"日"+" "+time+":"+fen+":"+miao //2021年1月14日 16:47:36
	}
}