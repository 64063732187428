<template>
  <div class="gameroom">
    <div class="room-warp">
      <div class="slide" v-if="boxList.length > 0">
        <div class="lately">
          <ul class="slide-ul" id="slide-ul">
            <div class="div-li" id="div-li">
              <li v-for="(item1, index1) in boxList.slice(0, 6)" :key="index1">
                <div class="slide-warp">
                  <div class="left">
                    <img :src="item1.all_skins.imageUrl" />
                  </div>
                  <div class="right">
                    <h4 class="r-2" style="color: #fff">
                      {{ item1.all_skins.itemName }}
                    </h4>
                    <!-- <h5>
                      打开 <span style="color:#FFC400;">{{ item1.all_skins.itemName }}</span> 获得
                    </h5> -->
                    <!-- <h6>
                      <img src="http://csgo.admin.17xiubei.com//head.png" />
                      <span style="color:#fff;">{{ item1.all_skins.itemName }}</span>
                    </h6> -->
                  </div>
                </div>
              </li>

              <div
                v-if="boxList.length > 6"
                class="all"
                @click="drawerBot = true"
              >
                奖品展示
              </div>
            </div>
          </ul>
        </div>

        <div class="clear"></div>
      </div>
      <div class="room-top">
        <div class="roomtop-left">
          <div class="room-img">
            <!-- <img src="../assets/newimg/img_79.png" /> -->
            <div class="guanfang">官方</div>
          </div>
          <p class="time">
            开奖时间：<span
              style="
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-style: italic;
                color: #de00ff;
              "
              >{{ info.end_time_text }}</span
            >
          </p>
        </div>
        <div class="roomtop-right">
          <div class="room-int">
            <div class="roomint-top" style="display: flex">
              <span style="width: 120px">玩法规则：</span>
              <span
                style="
                  width: 80%;
                  font-size: 18px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  font-style: italic;
                  color: #de00ff;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  display: block;
                "
                >{{ info.desc }}</span
              >
              <!-- {{ info.desc }} -->
            </div>
          </div>
        </div>
        <!-- v-if="myCard" -->
        <div class="btn-warp" v-if="myCard">
          <span>已选择（{{ myCard }}）</span>
        </div>
      </div>
      <div class="pay-nav">
        <div
          class="nav_item"
          :class="nav == 0 ? 'active' : ''"
          @click="changeNav(0)"
        >
          奖池
        </div>
        <!-- <div
          class="nav_item"
          :class="nav == 1 ? 'active' : ''"
          @click="changeNav(1)"
        >
          中奖名单
        </div> -->
        <div
          class="nav_item"
          :class="nav == 2 ? 'active' : ''"
          @click="changeNav(2)"
        >
          开奖记录
        </div>
      </div>
      <img
        class="duizhangang"
        style="width: 100%; margin-top: 4px"
        src="../assets/newimg/duizhangang.png"
        alt=""
      />
      <!-- 奖池 -->
      <div class="room-pool" v-if="nav == 0">
        <div class="item" v-for="(item, index) in skinsList" :key="index">
          <div class="item-num">{{ item.weigh }}</div>
          <div class="item-price">
            <img src="../assets/img/money.png" />
            <span>{{ totals }}</span>
          </div>
          <div class="item-select" @click="changeSelect(item, index)">
            <div class="icon" v-if="item.checkbox">
              <!-- v-if="item.checkbox" -->
              <i class="el-icon-check"></i>
            </div>
            <div class="xz" v-else>选择</div>
          </div>
          <div class="select-box" v-if="item.player != null">
            <div v-if="item.player">
              <img :src="item.player.player_img" />
              <p>{{ item.player.player_name }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 中奖名单 -->
      <div class="room-win" v-if="nav == 1">
        <div class="win-con">
          <ul>
            <li v-for="item in zjList" :key="item.id">
              <div class="win-warp">
                <div class="win-img">
                  <img :src="item.skin_img" />
                </div>
                <div class="win-price">
                  <img src="../assets/img/money.png" />
                  <div style="color: #fff">{{ item.skin_price }}</div>
                  <!-- <span>{{ item.skin_price }}</span> -->
                </div>
                <div
                  style="
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                  "
                >
                  {{ item.skin_name }}
                </div>
                <div class="win-name">
                  <img :src="item.player.img" alt="" />
                  <span>{{ item.player.name }}</span>
                  <!-- <span>{{ item.skin_name }}</span> -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 开奖记录 -->
      <div class="room-num" v-if="nav == 2">
        <ul>
          <li v-for="(item, index) in kjList" :key="index">
            <div class="list-warp">
              <div class="tou">
                <div class="tou2">
                  <!-- <img src="../assets/newimg/img_79.png" alt="" /> -->
                  <!-- <img :src="item.img" /> -->
                  <!-- {{ item.type == 1 ? "官方" : "主播" }} -->
                  <div>官方</div>
                </div>
                <img
                  class="join-img"
                  @click="toDetails(item.day)"
                  src="../assets/newimg/img_80.png"
                  alt=""
                />
              </div>
              <div class="time">
                开奖时间 : <span style="color: #d800ff">{{ item.day }}</span>
              </div>
              <div class="num">
                <div class="num-box">
                  <img src="../assets/newimg/img_81.png" alt="" />
                  <span>饰品数量</span>
                  <span class="num-span">{{ item.player_on_num }}</span>
                </div>
                <div class="num-box">
                  <img src="../assets/newimg/img_82.png" alt="" />
                  <span>参与人数</span>
                  <span class="num-span">{{ item.player_num }}</span>
                </div>
                <div class="num-box">
                  <img src="../assets/img/money.png" style="width: 15px" />
                  <span>饰品总价值</span>
                  <span class="num-span">{{ item.pool }}</span>
                </div>
              </div>
              <!-- <div class="img">
                <div
                  class="img-box"
                  v-for="(item1, index1) in item.log.slice(0, 3)"
                  :key="index1"
                >
                  <img :src="item1.skin_img" />
                </div>
              </div> -->
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 全部奖品 -->
    <el-drawer
      title="我是标题"
      :visible.sync="drawerBot"
      :with-header="false"
      direction="btt"
      class="box-bot all-reward"
      size="770px"
    >
      <!-- <div class="box-img-icon" @click="drawerBot = false">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div> -->
      <div class="box-img">
        <!-- <div class="box-img-title">
          <span class="span1">奖品展示</span>
        </div> -->
        <div class="box-img-icon" @click="drawerBot = false">
          <img src="../assets/newimg/cha2.png" alt="" />
        </div>
        <div class="box-img-list" v-if="boxList.length > 0">
          <ul>
            <li v-for="(item, index) in boxList" :key="index">
              <div v-if="item.all_skins">
                <div class="box-img1">
                  <img :src="item.all_skins.imageUrl" />
                </div>
                <div class="box-img-price">
                  <img src="../assets/img/money.png" />
                  <span>{{ item.all_skins.price }}</span>
                </div>
                <div class="box-img-name" :title="item.name">
                  {{ item.all_skins.itemName }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
export default {
  data() {
    return {
      id: this.$route.query.id,
      drawerBot: false,
      boxList: [],
      nav: 0,
      drawerList: [],
      info: "",
      skinsList: [],
      selectId: [],
      myCard: "",
      zjList: [],
      page: 1,
      kjList: [],
      totals: "",
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    // this.getData();
    this.getList();
    this.getLog();
  },
  methods: {
    toDetails(time) {
      this.$router.push({
        path: `/GameDetails`,
        query: {
          time: time,
          id: this.id,
        },
      });
    },
    changeNav(index) {
      this.nav = index;
    },
    changeSelect(item, index) {
      if (item.checkbox) {
        return;
      }
      var status = !item.checkbox;
      this.$post("/api/wheel/add_weigh", {
        wheel_id: this.id,
        weigh: item.weigh,
      })
        .then((res) => {
          if (res.code == 1) {
            this.$set(this.skinsList[index], "checkbox", status);
            this.getData();
            this.$store.commit("getMoney", Number(res.data).toFixed(2));
            Utils.$emit("money", res.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // if(!this.selectId.includes(item.weigh)){
      //   this.selectId.push(item.weigh)
      // }else{
      //   let i = this.selectId.indexOf(item.weigh)
      //   this.selectId.splice(i,1)
      // }
    },
    //selectSubmit(){
    // if(this.selectId.length==0){
    //   this.$notify({
    //     title: "提示",
    //     message: "请选择",
    //   });
    //   return
    // }
    // var ids=this.selectId.toString()
    // this.$post('/api/wheel/add_weigh',{wheel_id:this.id,weigh:ids}).then(res => {
    //   if (res.code==1) {

    //   }
    // }).catch(function (error) {
    //   console.log(error);
    // });
    //},
    getData() {
      let _this = this;
      _this
        .$post("/api/wheel/info", { wheel_id: _this.id })
        .then((res) => {
          if (res.code == 1) {
            _this.info = res.data;
            _this.totals = res.data.price;
            _this.skinsList = res.data.skins;
            _this.boxList = res.data.skins_list;
            console.log("_this.boxList777", _this.boxList);
            _this.myCard = res.data.my_card.toString();
            _this.$set(_this.skinsList, "checkbox", false);
            // _this.skinsList.map((item, index) => {
            //   res.data.my_card.map((val) => {
            //     if (item.weigh == val) {
            //       _this.$set(_this.skinsList[index], "checkbox", true);
            //     }
            //   });
            // });
            console.log("_this.skinsList===>", _this.skinsList);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getList() {
      let _this = this;
      let time = _this.$util.datastime("yous");
      _this
        .$post("/api/wheel/weigh_one_log", { wheel_id: _this.id, day: time })
        .then((res) => {
          if (res.code == 1) {
            _this.zjList = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getLog() {
      let _this = this;
      _this
        .$post("/api/wheel/weigh_log", { wheel_id: _this.id, page: this.page })
        .then((res) => {
          if (res.code == 1) {
            _this.kjList = res.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.gameroom {
  width: 100;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .room-warp {
    // width: 90%;
    max-width: 1200px;
    // width: 1400px;
    min-height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }

  .room-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left {
      font-size: 16px;
      color: #848492;
      span {
        color: #c3c3e2;
      }
    }
    .title-right {
      font-size: 16px;
      color: #848492;

      i {
        margin-left: 10px;
      }
      span {
        margin-left: 5px;
      }
    }
  }

  .room-top {
    padding: 55px 58px;
    box-sizing: border-box;
    width: 100%;
    height: 270px;
    background: url(../assets/newimg/img_83.png) no-repeat;
    background-size: 100% 100%;

    position: relative;
    margin-top: 30px;
    .roomtop-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .time {
        font-size: 18px;
        color: #ffffff;
      }
      .room-img {
        width: 112px;
        height: 61px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        background: url(../assets/newimg/img_79.png) no-repeat;
        background-size: 100% 100%;
        .guanfang {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          font-style: italic;
          color: #d800ff;
          margin-left: 70%;
        }
        // img {
        //   width: 99px;
        //   height: 42px;
        // }
        .span1 {
          font-size: 14px;
          background-color: #e9b10e;
          padding: 0 6px;
          border-radius: 10px;
        }
        .span2 {
          color: #fff;
          margin-left: 20px;
        }
      }
    }
    .roomtop-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 70px;
      box-sizing: border-box;
      .room-int {
        span {
          font-size: 18px;
          color: #fff;
        }
      }
    }
    .btn-warp {
      position: absolute;
      right: 59px;
      bottom: 51px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 184px;
      height: 60px;
      background: url(../assets/newimg/chuangjianbtn.png) no-repeat;
      background-size: 100% 100%;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .btn-warp:hover {
      cursor: pointer;
    }
  }
  .pay-nav {
    height: 51px;
    margin-top: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    // border-bottom: 3px solid #ffc400;
  }
  .nav_item {
    width: 164px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    background-size: contain;
    font-weight: 700;
    cursor: pointer;
  }
  .nav_item:first-child {
    width: 154px;
    height: 45px;
    line-height: 45px;
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    background-size: 100% 100%;
  }
  .nav_item:first-child.active {
    width: 154px;
    height: 61px;
    line-height: 66px;

    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    background-size: 100% 100%;
  }
  .nav_item:nth-child(2).active {
    width: 154px;
    height: 61px;
    line-height: 66px;
    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    background-size: 100% 100%;
  }
  .nav_item:nth-child(2) {
    width: 154px;
    height: 45px;
    line-height: 45px;
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    margin-left: 13px;
    background-size: 100% 100%;
  }
  .nav_item:nth-child(3).active {
    width: 154px;
    height: 61px;
    line-height: 66px;
    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    background-size: 100% 100%;
  }
  .nav_item:nth-child(3) {
    width: 154px;
    height: 45px;
    line-height: 45px;
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    margin-left: 13px;
    background-size: 100% 100%;
  }
  .room-pool {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .item:nth-child(5n) {
      margin-right: 0;
    }
    .item {
      position: relative;
      width: 215px;
      height: 153px;
      background: url(../assets/newimg/jiangchibg.png) no-repeat;
      background-size: 100% 100%;
      margin-bottom: 21px;
      margin-right: 11px;
      .select-box {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        img {
          width: 37px;
          height: 37px;
          border: 2px solid #ffffff;
          border-radius: 100px;
          margin-bottom: 15px;
        }
      }
      .item-select {
        position: absolute;
        left: 41%;
        top: -6px;
        .icon {
          width: 39px;
          height: 34px;
          // background: #ffc400;
          // box-shadow: 0px 0px 30px 1px rgba(255, 196, 0, 0.59);
          // border-radius: 10px 0px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          i {
            font-size: 28px;
          }
        }
        .xz {
          width: 39px;
          height: 34px;
          //box-shadow: 0px 0px 30px 1px rgba(255,196,0,0.59);

          opacity: 1;

          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #000000;
          line-height: 34px;
          text-align: center;
          cursor: pointer;
        }
        img {
          width: 39px;
          height: 34px;
        }
      }
      .item-price {
        position: absolute;
        right: 13px;
        top: 9px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #ffffff;
        z-index: 9;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .item-num {
        font-size: 20px;
        font-weight: bold;
        color: #183230;
        position: absolute;
        top: 64%;
        width: 100%;
        text-align: center;
      }
    }
  }
  .room-win {
    margin-top: 30px;
    .win-con {
      margin-top: 20px;
      ul {
        display: flex;
        flex-wrap: wrap;
        // li:nth-child(5n) {
        //   margin-right: 0;
        // }

        li {
          width: 18%;
          height: 250px;
          margin-right: 2%;
          margin-bottom: 2%;
          .win-warp {
            padding: 20px 10px;
            background-color: #fff;
            position: relative;
            border-radius: 5px;
            background: url(../assets/newimg/roombox.png) no-repeat;
            background-size: 100% 100%;
            box-sizing: border-box;
            .win-img {
              text-align: center;
              img {
                width: auto;
                height: 120px;
              }
            }
            .win-name {
              font-size: 13px;
              color: #ffffff;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 28px;
                height: 28px;
                border-radius: 100px;
                margin-right: 10px;
              }
              span {
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              }
            }
            .win-price {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 15px 0;
              img {
                width: 16px;
                height: 16px;
              }
              span {
                font-size: 13px;
                color: #ffc400;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .win-con1 {
    margin-top: 20px;
    background-color: #2b2c37;
    padding: 16px;
    border-radius: 5px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 7.69%;
        .win-warp {
          margin: 2px;

          background-color: #24252f;
          position: relative;
          border-radius: 5px;
          .win-warp1 {
            padding: 0 10px;
            background-image: url("../assets/img/box-skins-blue.jpg");
            background-size: 100% 100%;

            img {
              width: 100%;
              max-height: 70px;
            }
          }

          .win-name {
            margin-top: -25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }
            span {
              font-size: 14px;
              color: #c3c3e2;
              // margin-top: 5px;
              padding-bottom: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .span1 {
            position: absolute;
            top: 4px;
            left: 4px;
            background-color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            padding: 0 5px;
            border-radius: 10px;
            color: #fff;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .room-num {
    margin-top: 30px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 48%;
        position: relative;
        background: url(../assets/newimg/img_78.png) no-repeat;
        background-size: 100% 100%;
        padding: 60px 65px 50px;
        box-sizing: border-box;
        margin-right: 15px;
        margin-bottom: 15px;
        position: relative;
        .list-warp {
          overflow: hidden;
          position: relative;

          .tou-warp {
            width: 100%;
            height: 52px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .tou-warp-back {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 130px;
            }
          }

          .back1 {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
          }
          .tou {
            .tou2 {
              width: 112px;
              height: 61px;
              background: url(../assets/newimg/img_79.png) no-repeat;
              background-size: 100% 100%;
              // padding-left: 10%;

              div {
                height: 61px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-style: italic;
                color: #d800ff;
                line-height: 61px;
                margin-left: 56%;
              }
            }
            .join-img {
              width: 39px;
              height: 42px;
              cursor: pointer;
              position: absolute;
              right: 0px;
              top: 12px;
            }
            img {
              width: auto;
              height: 45px;
            }

            // span {
            //   color: #fff;
            //   font-size: 14px;
            //   margin-left: 18px;
            // }
          }
          .name {
            position: relative;
            z-index: 66;
            padding: 0 16px;
            margin-top: 5px;
            font-size: 16px;
            color: #c3c3e2;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .num {
            position: relative;
            z-index: 66;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .num-box {
              display: flex;
              align-items: center;
              img {
                margin-right: 10px;
              }
              span {
                color: #fff;
                font-size: 14px;
              }
              .num-span {
                color: #d800ff;
                margin-left: 15px;
              }
            }
          }
          .img {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            .img-box:nth-child(3n) {
              margin-right: 0;
            }
            .img-box {
              width: 31.33%;
              position: relative;
              text-align: center;
              border-radius: 10px 10px 10px 10px;
              background: linear-gradient(
                180deg,
                rgba(10, 156, 211, 0.3),
                rgba(24, 50, 48, 0.3)
              );
              border: 2px solid #0a8ed3;
              margin-right: 9px;
              margin-bottom: 9px;
              img {
                width: 100%;
                height: 82px;
              }
              span {
                position: absolute;
                top: 15px;
                left: 50%;
                margin-left: -25px;
                color: #fff;
                padding: 0 10px;
                border-radius: 20px;
                background-color: rgba(0, 0, 0, 0.3);
                font-size: 10px;
              }
            }
            .img-box:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            .img-box:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
          .time {
            font-size: 14px;
            color: #fff;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .pass-box {
    position: fixed;
    top: 30%;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    padding: 16px;
    border-radius: 5px;
    background-color: #333542;

    .pass-hint {
      position: absolute;
      right: 0;
      top: 0;
      i {
        font-size: 20px;
        color: #c3c3e2;
      }
      i:hover {
        cursor: pointer;
      }
    }
    .pass-title {
      display: flex;
      justify-content: center;
      color: #c3c3e2;
      font-size: 20px;
    }
    .input {
      margin-top: 15px;
      width: 100%;
      input {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        color: #848492;
      }
      input:focus {
        border: none;
      }
    }

    .pass-btn {
      margin-top: 15px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }
}
/deep/ .el-drawer__wrapper {
  top: 0;
}
/deep/ .el-drawer.btt {
  background: none;
  width: 100%;
  bottom: 150px;
  .box-img {
    color: #fff;
    // width: 1293px;
    width: 57%;
    height: 700px;
    background: url(../assets/newimg/img_88.png) no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    .box-img-icon {
      position: absolute;
      left: 45%;
      bottom: -70px;
      img {
        width: 52px;
        height: 45px;
      }
    }
  }
}
.box-bot {
  // width: 100%;
  .box-img {
    padding: 50px;
    box-sizing: border-box;

    .box-img-title {
      .span1 {
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffc400;
        border-bottom: 1px solid #ffc400;
        padding-bottom: 15px;
      }
    }

    .box-img-list {
      width: 100%;
      // margin-top: 40px;
      overflow-y: scroll;
      max-height: 600px;
      ul {
        display: flex;
        flex-wrap: wrap;
        // li:nth-child(6n) {
        //   margin-right: 0;
        // }
        li {
          width: 164px;
          height: 165px;
          margin-right: 22px;
          background-image: url("../assets/newimg/roombox.png");
          background-size: 100% 100%;
          padding-bottom: 20px;
          margin-bottom: 20px;
          .box-img1 {
            width: 99px;
            height: 47px;
            margin: 0 auto;
            margin-top: 52px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .box-img-price {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 18px 0;
            img {
              width: 12px;
              height: 12px;
            }
            span {
              color: #fff;
              font-size: 12px;
              margin-left: 7px;
            }
          }
          .box-img-name {
            width: 90%;
            margin: 0 auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
  .box-img-list::-webkit-scrollbar {
    width: 4px;
  }
  .box-img-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  .box-img-list::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
.slide /deep/ .el-carousel__indicators--outside {
  display: none;
}
.lately {
  position: relative;
  // height: 86px;
}
.card-img {
  width: 1400px;
  height: 218px;
  margin: 60px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.border {
  width: 100%;
  height: 5px;
  background: #ffc400;
}
@media screen and (min-width: 1280px) and (max-width: 1360px) {
  .slide {
    .slide-ul {
      li {
        width: calc(100vw / 5) !important;
      }
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .slide {
    .slide-ul {
      li {
        width: calc(100vw / 4) !important;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .slide {
    .slide-ul {
      li {
        width: calc(100vw / 3) !important;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .gameroom {
    .el-drawer__wrapper {
      /deep/ .el-drawer__container {
        /deep/ .el-drawer.btt {
          height: 120px !important;
          .el-drawer__body {
            height: 120px !important;
          }
        }
      }
    }
  }
.room-pool{
  .item{
    width: 46% !important;

    &:nth-child(5n){
      margin-right: 11px !important;
    }
  }
}
  .box-bot {
    width: 100% !important;

    ul {
      display: flex !important;
      // justify-content: space-around !important;
      flex-wrap: wrap !important;
      justify-content: flex-start !important;
      li {
        width: 46% !important;
        margin: 0 !important;
        margin-right: 15px !important;
        .box-img1 {
          width: 90% !important;
          height: 60% !important;
          margin-top: 10px !important;
          img {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      li:nth-child(2n) {
        margin-right: 0 !important;
      }
    }
  }
  .slide {
    .slide-ul {
      li {
        width: 46% !important;
      }
      .all {
        width: 81px !important;
        height: 31px !important;
        background: rgba(255, 196, 0, 0.37) !important;
        border-radius: 10px 10px 10px 10px !important;
        font-size: 16px !important;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN !important;
        font-weight: 400 !important;
        color: #ffc400 !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        text-align: center !important;
      }
    }
  }
}
.slide {
  overflow-y: hidden;
  overflow-x: hidden;
  .slide-ul {
    // height: 86px;
    display: flex;
    // position: absolute;
    // width:calc(100vw);
    width: 100%;
    // right: 0;
    // overflow-x: hidden;
    justify-content: center;
    // flex-flow: wrap;

    // height: 81px;
    //display: flex;
    // position: absolute;
    // width:calc(100vw);
    // width: 100%;
    // right: 0;
    // overflow-x: hidden;
    // justify-content: center;
    .div-li {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      // display: flex;
      //justify-content: center;
      // width: 100%;
      li {
        margin-bottom: 10px !important;
      }
    }
    .all {
      width: 31px;
      height: 81px;
      background: rgba(255, 196, 0, 0.37);
      border-radius: 10px 10px 10px 10px;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffc400;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    li:last-child {
      margin-right: 0;
    }
    li:nth-child(2) {
      background: url(../assets/newimg/img_35.png) no-repeat;
      background-size: 100% 100%;
    }
    li:nth-child(5) {
      background: url(../assets/newimg/img_35.png) no-repeat;
      background-size: 100% 100%;
    }
    li:nth-child(3n) {
      background: url(../assets/newimg/img_36.png) no-repeat;
      background-size: 100% 100%;
    }
    li {
      width: 15%;
      float: left;
      display: flex;
      justify-content: center;
      position: relative;
      background: url(../assets/newimg/img_34.png) no-repeat;
      background-size: 100% 100%;
      margin-right: 12px;
      z-index: 0;
      height: 81px;
      .line {
        position: absolute;
        bottom: 4px;
        width: 100%;
        height: 2px;
        background-color: #acc7ca;
      }
      .line1 {
        background-color: #b868b3;
      }
      .line2 {
        background-color: #f1a921;
      }
      // background-color: rgba(65, 105, 161, 0.4);
      // border-bottom: 2px solid #fff;

      .slide-warp {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        overflow: hidden;
        .left {
          margin-right: 10px;
          //flex: 1 1 auto;
          img {
            height: 55px;
            width: auto;
          }
        }
        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #adc8cb;
            font-weight: 200;
            font-size: 13px;
          }
          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;
            span {
              color: #adc8cb;
              font-size: 12px;
            }
          }
          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }
    }
    .li1 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(185, 105, 212, 0.5)
      );
    }
    .li2 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(241, 169, 32, 0.5)
      );
    }
  }
  .slide-ul:hover {
    cursor: pointer;
  }
}
.ul-line {
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: -1;
}
.ul-line-1 {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  bottom: 0px;
  z-index: -2;
}

/deep/ .el-carousel__item.is-animating {
  transition: transform 1.6s ease-in-out;
}

/deep/ .el-carousel__container {
  overflow-y: hidden;
}
</style>
