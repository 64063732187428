<template>
  <div class="game">
    <div class="game-warp">
      <div class="left-num">
        <div class="nav_item" style="cursor: pointer">福临门</div>
        <div class="right-one" @click.stop="informShow = true">
          <div class="span1">玩法介绍</div>
        </div>
      </div>
      <img class="duizhangang" src="../assets/newimg/duizhangang.png" alt="" />
      <div class="game-main">
        <div class="box" v-for="item in list" :key="item.id">
          <!-- <div class="name">{{ item.name }}</div> -->
          <img class="name" :src="item.bg_image" alt="" />
          <div class="center">
            <div class="text" v-if="item.status == 1">未开奖</div>
            <div class="text" v-if="item.status == 2">已开奖</div>
            <div class="time">开启时间：{{ item.start_time_text }}</div>
            <div class="time">结束时间：{{ item.end_time_text }}</div>
          </div>
          <div class="btn" @click="toLink(item.id)">第一期</div>
        </div>
      </div>

      <div class="bg-bottom"></div>
    </div>

    <!-- 玩法介绍 -->
    <div class="box-inform" v-if="informShow">
      <div class="informs">
        <div class="new-x" @click="closeBot">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
        <div class="box-img-inform" style="color: #fff">{{ content }}</div>
        <!-- v-html="playInfo" -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      informShow: false,
      content: "",
    };
  },
  created() {
    this.getPlayerInfo();
  },
  mounted() {
    this.getData();
  },
  methods: {
    getPlayerInfo() {
      let _this = this;
      _this
        .$post("/api//common/get_config")
        .then((res) => {
          if (res.code == 1) {
            this.content = res.data.oriental_pearl;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    closeBot() {
      this.informShow = false;
    },
    getData() {
      let _this = this;
      _this
        .$post("/api/wheel/list")
        .then((res) => {
          if (res.code == 1) {
            _this.list = res.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    toLink(id) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // console.log("gerenxinxi6666", userInfo);
      if (userInfo.tradeUrl == "") {
        this.$message({
          title: "提示",
          message: "请绑定steam链接",
        });
        return;
      }
      this.$router.push({
        path: `/GameRoom`,
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.game {
  width: 100;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .box-inform {
    position: fixed;
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    .informs {
      width: 668px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: relative;
      background: url(../assets/newimg/rulebg.png) no-repeat;
      background-size: 100% 100%;
      height: 360px;
      padding: 40px;
      .new-x {
        position: absolute;
        top: -12%;
        right: -20px;
        width: 52px;
        height: 45px;
        img {
          width: 52px;
          height: 45px;
        }
      }
    }
  }
  .game-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    min-height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
    position: relative;
    .bg-bottom {
      width: 100%;
      height: 267px;
      background: url(../assets/newimg/img_98.png) no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .game-main {
      width: 100%;
      padding: 100px 0;
      display: flex;
      justify-content: space-around;
      .box:nth-child(2) {
        background: url(../assets/newimg/gamekuang.png) no-repeat;
        background-size: 100% 100%;
        .name {
          position: absolute;
          left: 100px;
          top: 18%;
          width: 426px;
          height: 206px;
          // width: 100%;
        }
        .btn {
          background: url(../assets/newimg/diyiqi.png) no-repeat;
          background-size: 100% 100%;
          color: #00dcff;
          margin-top: 10px;
        }
      }
      .box {
        width: 658px;
        height: 602px;
        background: url(../assets/newimg/gamekuang.png) no-repeat;
        background-size: 100% 100%;
        position: relative;
        text-align: center;
        .name {
          position: absolute;
          left: 100px;
          top: 16%;
          width: 426px;
          height: 206px;
          // width: 100%;
        }
        .center {
          width: 100%;
          position: absolute;
          bottom: 38%;
          .text {
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-style: italic;
            color: #ffffff;
          }
          .time {
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-style: italic;
            color: #00eaff;
            margin-top: 10px;
          }
        }
        .btn {
          width: 202px;
          height: 80px;
          background: url(../assets/newimg/diyiqi.png) no-repeat;
          background-size: 100% 100%;
          opacity: 1;
          // border: 2px solid #ffc400;
          position: absolute;
          bottom: 20%;
          left: 50%;
          transform: translate(-50%, 0);
          font-size: 24px;
          color: #ffc400;
          line-height: 80px;
          cursor: pointer;
          z-index: 9;
        }
      }
    }
  }
  .left-num {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    // border-bottom: 3px solid #ffc400;
    .right-one {
      display: flex;
      align-items: center;
      margin-top: -17px;
      .span1 {
        min-width: 103px;
        margin-left: 18px;
        height: 36px;
        line-height: 36px;
        background: url(../assets/newimg/zise.png) no-repeat;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        cursor: pointer;
      }
      .span1.active {
        background: #ffc400;
        color: #000;
      }
    }
    .nav_item {
      width: 156px;
      height: 68px;
      text-align: center;
      line-height: 68px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;

      background-size: 100% 100%;
      position: relative;
    }
  }
  .duizhangang {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .game-warp {
    .game-main {
      width: 100% !important;
      padding: 10px 0 !important;
      display: flex !important;
      justify-content: space-around !important;
      flex-wrap: wrap !important;
      .name {
       position: static !important;
        display: block !important;
        margin: 0 auto !important;
        margin-top: 22% !important;
      }
    }
  }
}
</style>
