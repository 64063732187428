<template>
  <div class="content">

    <div v-show="!historyList" class="pt-5">
      <div class="d-flex align-items-center justify-content-between lucky-fontSize">
        <div class="d-md-flex d-block">
          <!--          <div class="mr-2 p-3 cursor-pointer d-block" @click="luckyHistory">我的记录</div>-->
          <div :style="SelectedPic ? 'opacity: 1' : 'opacity: 0'"  style="font-size: 16px;top: 40%; font-Weight:600" class="mr-2 p-3 cursor-pointer"
            @click="luckyHistoryQ" >最近掉落</div>
        </div>
        <div>
          <div class=" d-flex">

            <div class="mr-2">
              <el-switch class="animationOFF" v-model="animationOFF"
                         active-color="#13ce66" inactive-color="#ff4949">
              </el-switch>
              {{animationOFF?'关闭':'开启'}}
            </div>
          </div>
        </div>
      </div>
      <div class="content-top d-flex justify-content-around align-items-center">
        <div class="d-flex flex-column align-items-center justify-content-center content-top-left"
          @click="selectClose = true">
          <img :src="SelectedPic ? SelectedPic.imageUrl : require('@/assets/img/lucky/upgrade__icon__arrow_pic.png')" alt=""
            :class="SelectedPic ? 'content-top-active' : ''" style="width: 220px;height:165px" class="content-top-left-img">
          <div style="width: 220px ; !important;" class="text-overflow-1 text-center">{{ SelectedPic ? SelectedPic.itemName : '请选择你想要得到的物品'
          }}
          </div>
          <span class="mt-3 upDom">
            <span v-if="SelectedPic" class="d-flex align-items-center">
              {{ SelectedPic ? SelectedPic.bean : '0.00' }}
            </span>
            <img v-else style="width:50px;margin-bottom:-25px" class="content-top-left-img-ico" src="../../assets/img/lucky/upgrade__icon__arrow.png"
              alt="">
          </span>

        </div>
        <div class="d-flex justify-content-center align-items-center position-relative content-top-yuan">
          <img src="../../assets/img/lucky/upgrade__rotary.png" alt="">
          <!-- <img class="position-absolute anShanDian" src="../../assets/img/lucky/shengji_1.png" style="" alt=""
            v-if="oprnClickIsShow"> -->
          <img v-if="!oprnClickIsShow" class="position-absolute content-top-yuan-xian" name="1"
            :style="`transform: rotate(${360 * (successRate / 100)}deg);`"
            src="../../assets/img/lucky/upgrade__rotary__pointer.png" alt="">
          <img v-else class="position-absolute content-top-yuan-kai-jiang" name="2"
            :style="`transform: rotate(${successRateTow}deg);`" src="../../assets/img/lucky/upgrade__rotary__pointer.png"
            alt="">
          <img class="position-absolute" src="../../assets/img/lucky/upgrade__rotary__box.png" alt="">
          <div class="position-absolute">
            <linear-gradient :unconfirmedValue="successRate / 100"></linear-gradient>
          </div>
          <div class="position-absolute text-center">
            <div v-if="!loing">
              <div class="font-weight-bold h1" >{{ successRate }} <span class="h6">%</span> </div>
              <div >设置追梦概率</div>
            </div>

            <div v-else class="lucky-error-succeed animate__fadeInUp animate__animated ">
              <div v-if="all_skin_id != SelectedPic.id" class="lucky-error ">升级失败</div>
              <div v-else class="lucky-succeed">升级成功</div>
              <div class="font-weight-bold">{{ successRate }} <span class="">%</span> </div>
            </div>

          </div>
        </div>
        <div class="phone-therr d-flex flex-column align-items-center justify-content-center">
          <img src="../../assets/img/lucky/upgrade__icon__arrow_pic.png" alt="" style="width: 220px">
          <div style="width: 220px;" class="text-overflow-1 text-center">随机得到的饰品</div>
          <div style="opacity: 0">随机得到的饰品 </div>
          <div style="opacity: 0">随机得到的饰品 </div>
          <!--          <img  style="" class="mt-3 upDom" src="../../assets/img/lucky/upgrade__icon__arrow.png" alt="">-->
        </div>
      </div>

      <div class="content-bottom">
        <div class="d-flex justify-content-between col-12 mx-auto">
          <div>
            成功率<span style="color: #28eb6c"> ({{ successRate }}%) </span> / 失败率<span style="color: #eb2727">
              ({{ 100 - successRate }}%)</span>
          </div>
          <div class="d-flex">
            <!-- {{ money }} -->
          </div>
        </div>
        <!-- 进度条 -->
        <div class="content-bottom-slide">
          <el-slider v-if="!oprnClickIsShow" class="content-bottom-slide-slider" :min="0" :max="75" :marks="marksSlider"
            v-model="successRate"></el-slider>

        </div>
        <!-- 按钮 -->
        <div class="content-bottom-btn d-flex" :class="successRate > 4 ? 'content-bottom-btn2' : 'content-bottom-btn1'"
          @click="openAward(SelectedPic, successRate)">
          <div>立刻升级</div>
          <div class="d-flex align-items-center">
            {{ money }}
          </div>
        </div>
        <div class="text-center color-yellow mt-3">
          * 升级概率范围为5% - 75%
        </div>
      </div>

      <!-- 奖品弹窗 -->
      <div class="" v-if="false">
        <div style="width: 700px;left: calc(50% - 350px);top:50px"
          class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">
          <div>
            <div class="d-flex flex-column align-items-center justify-content-center py-5 flex-wrap">
              <div>
                <img :src="resData.cover" width="300px" alt="">
              </div>
              <div class="text-center">
                <div>{{ resData.name }}</div>
                <div class="mt-2">{{ resData.dura_alias }}</div>
                <div class="mt-2">
                  {{ resData.bean }}
                </div>

              </div>
            </div>
            <div class="d-flex px-5 justify-content-center mt-3">
              <div @click="svgaIsShowOff" style="width: 250px" class=" text-white text-center f_btn_green cursor-pointer">
                放入仓库
              </div>
            </div>
            <div @click="svgaIsShowOff" class="position-absolute" style="right: 20px;top: 30px">
              <div>
                <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
              </div>
            </div>

          </div>
        </div>
        <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
      </div>

      <!-- 武器弹窗 -->
      <div class="select" v-if="selectClose">
        <div class="select-top d-flex justify-content-between p-md-4 p-2">
          <div>选择饰品</div>
          <div @click="selectClose = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="select-lucky">
          <lucky-pup @pitchOn="pitchOn"></lucky-pup>
        </div>
        <div class="position-fixed bg-bg-2 op-4 z-index-0" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
      </div>


      <!-- 中将弹窗 -->
      <transition name="el-fade-in-linear">
        <div v-if="svgaIsShow">
          <div style="" class="f-border-top awardListPop position-fixed pop-up z-index-100 py-5 px-4 history_bg ">
            <div>
              <div class="h5 text-center">您获得了</div>
              <div class="awardListPop-box d-flex justify-content-center pt-5 flex-wrap ">
                <div class="text-center rounded award-box position-relative ggbg2 bacdraw p-3"
                  :class="all_skin_id == SelectedPic.id ? '' : ''">
                  <div class="my-1 text-overflow-1 title_qian" style="line-height:16px">{{ resData.name }}</div>
                  <img :src="resData.img" alt="" style="width: 50%;">
                  <div v-if="all_skin_id != SelectedPic.id" class="d-flex justify-content-between huishoubtn"
                    style="font-size: 14px">
                    <!-- <div class="align-items-center w-50 p-2 d-flex justify-content-center border-main-color">
                      <money />{{ resData.bean }}
                    </div> -->
                    <!-- <div
                      class="justify-content-center w-50 d-flex align-items-center p-2 cursor-pointer border-main-color"
                      @click="recycle(resData)">
                      回收
                    </div> -->
                  </div>
                  <!-- <div v-else class="d-flex  justify-content-between huishoubtn" style="font-size: 14px">
                    <div class="text-center w-100 p-2">
                      <money />{{ resData.bean }}
                    </div>
                  </div> -->

                </div>
              </div>
              <div class="d-flex mt-2 justify-content-between">
                <div @click="svgaIsShowOff" class="">
                  <bottom-bg2 class="">
                    <div style="color: #FFFFFF;font-size: 16px" class="">
                      {{ all_skin_id != SelectedPic.id ? '继续升级' : '继续升级' }}
                    </div>
                  </bottom-bg2>
                </div>
                <div @click="recycle(resData)" class="cursor-pointer ">
                  <bottom-bg class="">
                    <div style="color: #FFFFFF;font-size: 16px" class="d-flex p-2">
                      <span style="margin-right:10px;font-size:14px">溶解</span>
                      {{ resData.price }}
                    </div>
                  </bottom-bg>
                </div>
              </div>

              <div @click="svgaIsShowOff" class="position-absolute" style="right: 20px;top: 30px">
                <div>
                  <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
                </div>
              </div>

            </div>
          </div>
          <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
        </div>
      </transition>
    </div>



    <!-- 中将记录 -->
    <div v-if="historyList" style="">
      <history :id="SelectedPicID" @codeBack="codeBack"></history>
    </div>
    <!-- 玩法介绍 -->
    <!-- <div class="" v-if="playingMethod">
      <div style="width: 700px;left: calc(50% - 350px);top:15vh"
        class="position-fixed pop-up z-index-100 py-5 f-border-top history_bg">

        <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
          <div>
            <i class="iconfont text-white icon-guanbi-02-02  cursor-pointer" style="font-size:30px"></i>
          </div>
        </div>

      </div>
      <div class="position-fixed bg-bg-2 op-4 z-index-1" style="width: 100vw;height: 100vh;left: 0;top: 0;"></div>
    </div> -->
    <el-dialog custom-class="login_egister" :modal="false" :visible.sync="playingMethod" width="600px"  :show-close="false">
      <div class="text-white p-md-4 p-2" style="line-height: 2.0">
        <div class="text-center h3">
              追梦规则
          </div>
        <p>1.选择要追梦的饰品。</p>

        <p>2.追加追梦成功的机率。</p>

        3.追梦的随机率在5%到75%之间。
      </div>
      <div @click="playingMethod = false" class="position-absolute" style="right: 20px;top: 30px">
            <svg class="icon text-white" style="font-size: 30px" aria-hidden="true">
                <use xlink:href="#icon-guanbi-02-02"></use>
            </svg>
        </div>
    </el-dialog>
    <!-- 确定回收确定取消弹框组件 -->
    <confirm-dialog v-if="isDialogVisible" :message="dialogMessage" @cancel="handleCancel(resData)"
      @confirm="handleConfirm(resData)"></confirm-dialog>

  </div>
</template>

<script>
import $api from '@/api/lucky/index'
import $url from '@/api/CSGO/index'
import luckyPup from "./common/luckyPup";
import linearGradient from "./common/linearGradient";
import bottomBg2 from "@/components/bottomBg2";
import history from "./history.vue"
import sUserAgent from '@/assets/js/common.js'
import bottomBg from "@/components/bottomBg";
import ConfirmDialog from '../../views/compounds/common/confirmDialog.vue';
import Utils from "../../assets/js/util.js";
export default {
  name: "luckyIndex",
  data() {
    return {
      // 开启关闭 弹窗列表选择武器
      selectClose: false,
      // 玩法介绍
      playingMethod: false,
      // 进度条
      successRate: 0,
      // 用于点击开启显示的东西
      oprnClickIsShow: false,
      // 选中饰品的数据
      SelectedPic: null,
      // 开启动画 关闭动画 true:开   false：关
      animationOFF: true,
      // 开箱结果
      resData: {},
      // 结果弹窗
      svgaIsShow: false,
      // 转动的指针全数
      successRateTow: 30,
      // 显示成功失败
      loing: false,
      // 阻止再次点击
      ClickAgain: false,
      // 显示历史掉落
      historyList: false,
      boxIds:[],
      // 点开历史掉落显示的ID
      SelectedPicID: 0,
      marksSlider: {
        0: '0',
        5: '5',
        10: '10',
        15: '15',
        20: '20',
        25: '25',
        30: '30',
        35: '35',
        40: '40',
        45: '45',
        50: '50',
        55: '55',
        60: '60',
        65: '65',
        70: '70',
        75: '75',
      },
      // 弹框组件参数
      isDialogVisible: false,
      dialogMessage: '确定要回收吗？'
    }
  },
  components: {
    luckyPup, linearGradient, bottomBg2, history, bottomBg,ConfirmDialog,Utils
  },
  created() {
  },
  mounted() {
    console.log(sUserAgent.sUserAgent())
  },
  computed: {
    money() {
      return ((this.successRate / 100) * (this.SelectedPic ? this.SelectedPic.price * 1 : 0)*1.1).toFixed(2)
    },
    ua() {
      console.log('sUserAgent')
      console.log(sUserAgent)
      return sUserAgent.sUserAgent()
    }
  },
  methods: {
    codeBack(Bon) {
      this.historyList = Bon
    },
    // 点击我的记录
    luckyHistory() {
      this.historyList = true
      this.SelectedPicID = 0
    },
    // 点击饰品显示的记录
    luckyHistoryQ() {
      this.historyList = true
      this.SelectedPicID = this.SelectedPic.id
    },

    // 点击回收
    async recycle(item) {
      
      // console.log(1);
      // this.dialogMessage = '确定要回收吗？';
      // this.isDialogVisible = true;
      // this.handleConfirm(item)
      this.boxIds.push(item.player_skins_id);
      // console.log("666666", this.boxIds);
      let param = {
        player_id: this.$store.state.id,
        player_skins_ids: item.id,
      };
      this.$post("/api/Users/exchangeToMoney", param)
        .then((res) => {
          if (res.status == 1) {
            this.$store.commit("getMoney", res.data.total_amount);
            Utils.$emit("money", res.data.total_amount);
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.svgaIsShow=false
            this.ClickAgain = false
            this.isDialogVisible = false;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 组件功能
    handleCancel() {
      this.isDialogVisible = false;
      this.ClickAgain = true
      // 处理取消操作
    },
    
    async handleConfirm(item) {
      let arr = []
      arr.push(item.id)
      
      let { message, code } = await $url.getRecycle({ data: arr })
      if (code == 500) return
      this.$notify({
        message: message,
        type: 'success',
        position: 'top-left'
      });
      this.svgaIsShowOff()
      this.$store.dispatch('userInfo')
      this.ClickAgain = false
      this.isDialogVisible = false;
      // 处理确认操作
    },

    // 两个数字之间的值
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    // 点击开箱
    async openAward(SelectedPic = null, successRate) {
      if (this.successRate < 5) {
        this.$message('成功率不得低于5%')
        return
      }
      // 没有选择饰品的情况
      if (SelectedPic == null) {
        this.$message('请选择饰品')
        return
      }
      if (this.ClickAgain) {
        this.$message('正在开箱，稍后......')
        return
      }
      this.ClickAgain = true
      // 发出请求

      let time = parseInt(new Date().getTime() / 1000);
      let sign = this.$md5("cs#.go" + time);
      let { data,code} = await $api.getluckyOpen({ id: SelectedPic.id, percent: (successRate / 100).toFixed(2),sign,time})
      if(code == 200){
        this.$store.commit("getMoney", data.total_amount);
        Utils.$emit("money", data.total_amount);
      }
      if (code != 200) {
        // Utils.$emit("money",money);
        this.$message('积分不足')
        this.ClickAgain = false
        return;
      }
      // // 得到结果
      this.all_skin_id=data.all_skin_id
      console.log(SelectedPic.id,data.all_skin_id);

      if (SelectedPic.id == data.all_skin_id) { // 暂时没用
        this.successRateTow = 360 * 3 + 360 * (this.successRate / 100) - Math.floor(Math.random() * (360 * (this.successRate / 100)))
      } else {
        this.successRateTow = 360 * 3 + 360 * (this.successRate / 100) + this.getRandomNumber(360 * (this.successRate / 100) + 1, 360) - 360 * (this.successRate / 100)
      }
      this.resData = data
      // 更新金币
      this.$store.dispatch('userInfo')
      // 动画关闭的时候
      if (!this.animationOFF) {
        this.oprnClickIsShow = false
        window.scrollTo(0, 1000);
        this.svgaIsShow = true
        this.$store.dispatch('userInfo')
      } else {
        this.oprnClickIsShow = true
        // 声音是六秒
        // document.getElementById('luckey').play()
        setTimeout(() => {
          this.loing = true
          // this.successRate = 0
        }, 6000)
        setTimeout(() => {
          this.svgaIsShow = true
          this.oprnClickIsShow = false
          // this.successRate = 0
          this.loing = false
        }, 8000)
      }


    },
    // 点击列表内的武器返回的单个武器数据
    pitchOn(item) {
      this.SelectedPic = item.item
      setTimeout(() => { this.selectClose = false }, 500)
    },
    // 关闭结果
    svgaIsShowOff() {
      this.svgaIsShow = false
      this.loing = false
      this.ClickAgain = false
      // this.oprnClickIsShow = false
      // this.successRate = 0
    }
  }
}
</script>

<style scoped lang="less">
.award-box{
     width:  250px !important;
  }
.bacdraw{
  background-size: 100% 100% !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.lucky-title-top {
  @media (max-width: 1000px) {
    font-size: 1.25rem !important;
    .d-flex{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.lucky-fontSize {
  @media (max-width: 1000px) {
    font-size: .8rem !important;
    position: relative;
    z-index: 9;
    .animationOFF {
      transform: scale(.7);
    }
  }
}

.lucky-error-succeed {
  width: 100px;
  height: auto;
}

.lucky-error {
  background-image: url("../../assets/img/lucky/upgrade__bg__faild.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 30px;
}

.lucky-succeed {
  background-image: url("../../assets/img/lucky/upgrade__bg__succeed.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 30px;
}

.content {
  width: 80%;
  margin: 40px auto;
  color: #fff;
  &-top {
    width: 100%;
    height: 360px;
    background-size: 100% 100%;
    background-image: url("../../assets/img/lucky/upgrade__skins.png");

    @media (max-width: 1200px) {
      height: auto;
      background-image: none;

      & .phone-therr {
        display: none !important;
      }
    }

    @media (max-width: 960px) {
      transform-origin: top;
      transform: scale(.9);
    }

    @media (max-width: 750px) {
      transform-origin: top;
      transform: scale(.9);
      flex-direction: column-reverse !important;
    }

    &-yuan {
      width: 500px;
      height: 360px;

      @media (max-width: 1000px) {
        transform: scale(.8)
      }

      @media (max-width: 960px) {
        height: 210px;
      }

      &-xian {
        transition: all .5s;
      }

      /* 这里动画多少秒就是多少秒 */
      &-kai-jiang {
        transition: all 6s;
      }
    }

    &-active {
      animation: animationName 3s infinite;
    }

    &-left {
      @media (max-width: 960px) {
        padding: 5px;
        margin-top: 65px;
        background-image: linear-gradient(0deg, #1d2027, #13161c);
        background-blend-mode: normal, normal;
        border-radius: 10px;
        border: 1px solid #1c2029;
        transform-origin: top;
        transform: scale(.9);

        &-img {
          width: 120px !important;
          height: 92px !important;
          &-ico{
            width: 60px !important;
            height: 60px !important;
            position: absolute;
            top: -100px;
            left: -30px;
          }
        }
      }
    }

  }

  &-bottom {
    padding: 25px 100px 0;
    margin-top: 80px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    height: 386px;
    background-image: linear-gradient(0deg, #1d2027, #13161c);
    background-blend-mode: normal, normal;
    border-radius: 5px;
    border: 1px solid #1c2029;

    &-btn {
      margin: 27px auto 0;
      width: 360px;
      height: 72px;
      /*border-radius: 36px;*/
      background-size: contain;
      display: flex;
      align-items: center;
      justify-content: center;
      //flex-direction: column;
      transition-duration: .3s;
      -webkit-transition-duration: .3s;
      cursor: pointer;

      @media (max-width: 1000px) {
        font-size: 0.8rem;
        Width: 60%;
        margin-top: 10px;
        Height: 50px;
        background-size: 100% 100%;

      }
    }

    &-btn1 {
      background-image: url("../../assets/imgV2/lucky/upgrade__btn__bg.png");
    }

    &-btn2 {
      background-image: url("../../assets/imgV2/lucky/upgrade__btn__bg__active.png");
    }

    &-slide {
      width: 100%;
      margin: 0 auto;
      /*height: 88px;*/
      background-color: #212328;
      border-radius: 4px;
      padding: 30px 30px 60px 30px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;

      @media (max-width: 1200px) {
        width: 100%;
      }

      &-slider-img {
        height: 40px;

        @media (max-width: 1200px) {
          height: auto;

        }
      }
    }

    @media (max-width: 1200px) {
      margin-top: -40px;
      padding: 10px 10px 0;
      font-size: 0.8rem !important;
      height: 210px;

      &-slide {
        padding: 0 20px;
        height: 55px;
      }
      .d-flex{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    }
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
}

/* 弹窗列表也面 */
.select {
  position: fixed;
  top: 15vh;
  left: 20%;
  width: 60%;
  z-index: 999;

  &-top {
    background-color: #161721;
    border: 1px solid #454759;
    border-bottom: 0;
    /*width: 100%;// 由于下面列表是90  所以改成九十*/
    /*background-image: url("../../assets/img/lucky/shop_top.png");*/
    /*background-size: 100% 100%;*/
  }

  &-lucky {
    /*background-image: url("../../assets/img/lucky/img_tancuan.png");*/
    /*background-size: 100% 100%;*/
    background-color: #161721;
    border: 1px solid #454759;
    border-top: 0;
  }

  @media (max-width: 1200px) {
    top: 5vh;
    width: 90%;
    left: 5%;

    &-slide {
      padding: 10px 5px;
    }

  }
}

.upDom {
  animation: animationName 5s infinite;
}

.anShanDian {
  animation: animationShanDian .3s infinite;
}

.awardListPop {
  width: 700px;
  left: calc(50% - 350px);
  top: 100px;
  z-index: 1001;
  position: relative;

  @media (max-width:1000px) {
    width: 98%;
    left: 1%;
  }

  &-box {

    margin: 0 auto;

    @media (max-width:1200px) {
      overflow: auto;
    }


    &::-webkit-scrollbar {
      display: none
    }
  }
}

@keyframes animationName {
  0% {
    transform: translateY(-20%);
  }

  50% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-20%);
  }
}

@keyframes animationShanDian {
  10% {
    transform: rotate(10deg) scale(0);
  }

  10% {
    transform: rotate(45deg) scale(.5);
  }

  20% {
    transform: rotate(90deg) scale(0);
  }

  30% {
    transform: rotate(125deg) scale(.6);
  }

  40% {
    transform: rotate(160deg) scale(0);
  }

  50% {
    transform: rotate(185deg) scale(.8);
  }

  60% {
    transform: rotate(220deg) scale(0);
  }

  70% {
    transform: rotate(255deg) scale(.4);
  }

  80% {
    transform: rotate(290deg) scale(0);
  }

  0% {
    transform: rotate(300deg) scale(.3);
  }

  90% {
    transform: rotate(330deg) scale(0);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }

}
</style>
