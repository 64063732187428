<template>
  <div class="lucky">
    <!-- <myinform></myinform> -->
    <div>
      <div class="lucky-warp">
        <div class="top">
          <div class="pay-nav">
            <div
              class="nav_item"
              :class="nav == 0 ? 'active' : ''"
              @click="changeNav(0)"
            >
              盲盒对战
            </div>
            <div
              class="nav_item"
              :class="nav == 1 ? 'active' : ''"
              @click="changeNav(2)"
            >
              战场记录
            </div>
            <div
              class="nav_item"
              @click="openDrawer"
            >
              创建活动
            </div>
            <!-- <div
              class="nav_item"
              :class="nav == 2 ? 'active' : ''"
              @click="changeNav(1)"
            >
              我的记录
            </div> -->
          </div>
          <div class="right">
            <!-- <span class="right-span right-span1" @click="goLuckyRule"
              >活动规则</span
            > -->
            <!-- <span class="right-span right-span1" @click="goLuckyHistory"
              >活动历史</span
            > -->
            <!-- <span class="right-span right-span2" @click="openDrawer"
              >创建活动</span
            > -->
          </div>
        </div>
        <img
          class="duizhangang"
          src="../assets/newimg/duizhangang.png"
          alt=""
        />
        <div class="luc-list luc-list1">
          <ul :style="{ width: 100 * boxList.length + 'px' }">
            <li v-for="(item, index) in boxList" :key="index">
              <div class="list-warp" @click="getBot(item.name, item.id)">
                <!-- <div class="list-img2">
                  <img :src="item.img_active" />
                </div> -->
                <div class="list-img">
                  <img :src="item.img_main" />
                </div>
                <div class="list-money">
                  <img src="../assets/img/money.png" />
                  <span>{{ item.price }}</span>
                </div>
                <div class="list-name">{{ item.name }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="titlelist d-flex justify-content-around">
          <div v-for="(item, index) in resdatalist" :key="index" style="border:1px solid #ccc;padding:5px" class="pl-1 pr-1" @click="titleclik(item.id)" :class="ids==item.id?'actives':''">
            <div style="color:#fff;font-size:14px">
              {{ item.type_name }}
            </div>
          </div>
        </div>
        <div class="luc-list luc-list2">
          <ul>
            <li v-for="(item, index) in boxList" :key="index">
              <div class="list-warp" @click="getBot(item.name, item.id)">
                <div class="list-img2">
                  <img :src="item.img_main" />
                </div>
                <div class="list-img">
                  <img :src="item.img_active" />
                </div>
                <div class="list-money">
                  <img src="../assets/img/money.png" />
                  <span>{{ item.price }}</span>
                </div>
                <div class="list-name">{{ item.name }}</div>
              </div>
            </li>
          </ul>

          <!-- <el-carousel
            indicator-position="outside"
            height="150px"
            :autoplay="false"
          >
            <el-carousel-item v-for="(item, index) in boxList2" :key="index">
              <ul>
                <li v-for="(item1, index1) in item" :key="index1">
                  <div class="list-warp" @click="getBot(item1.name, item1.id)">
                    <div class="list-img">
                      <img :src="item1.img_main" />
                    </div>
                    <div class="list-money">
                      <img src="../assets/img/money.png" />
                      <span>{{ item1.price }}</span>
                    </div>
                    <div class="list-name">{{ item1.name }}</div>
                  </div>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel> -->
        </div>
        <img
          class="duizhangang"
          src="../assets/newimg/duizhangang.png"
          alt=""
        />
        <div class="clear"></div>

        <!-- <div class="hint">
          <span
            >每局活动，玩家连续打开相同盲盒，奖品总价最高的玩家可获得全部奖品</span
          >
          <span>{{ list.length }} 个房间等待您加入</span>
        </div> -->

        <div class="room">
          <!-- <div class="ranking">
            <div class="rank-top">
              <div class="one">对战之神</div>
              <div class="two">
                <div class="winner-warp">
                  <img
                    class="winner"
                    :class="star.img ? 'img-block' : 'img-none' "
                    src="../assets/img/winner-bg.svg"
                    alt=""
                  />
                  <img class="winner1" :src="star.img" />
                </div>
                <span>Lv1</span>
              </div>
              <div class="three">
                <span>{{ star.name }}</span>
              </div>
              <div class="four">{{ star.total_consume }}</div>
            </div>
            <div class="rank-bot">
              <div class="title">排行榜</div>
              <div class="ranking-list">
                <ul>
                  <li v-for="(item, index) in list1" :key="index">
                    <div class="ripple" @click="touchstart">
                      <div class="list-left">
                        <div class="list-left1">
                          <img :src="item.img" /><span>Lv1</span>
                        </div>
                        <div class="list-left2">
                          <span>{{ item.name }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="list-right">{{ item.total_consume }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="clear"></div> -->
          <div class="room-box">
            <ul class="room-box-ul">
              <!-- class="box-li" -->
              <li
                v-for="(item, index) in list"
                :key="index"
                @click="goLuckyRoom(item.id)"
                :class="{
                  'box-li':
                    1 <= Number(item.skins_price).toFixed(2) &&
                    Number(item.skins_price).toFixed(2) <= 100,
                  'box-li box-li3':
                    100 < Number(item.skins_price).toFixed(2) &&
                    Number(item.skins_price).toFixed(2) <= 500,
                  'box-li box-li2': Number(item.skins_price).toFixed(2) > 500,
                }"
              >
                <div
                  :class="{
                    'roombox-warp two1':
                      1 <= Number(item.skins_price).toFixed(2) &&
                      Number(item.skins_price).toFixed(2) <= 100,
                    'roombox-warp two3':
                      100 < Number(item.skins_price).toFixed(2) &&
                      Number(item.skins_price).toFixed(2) <= 500,
                    'roombox-warp two2':
                      Number(item.skins_price).toFixed(2) > 500,
                  }"
                >
                  <div
                    :class="{
                      'one two1 ':
                        1 <= Number(item.skins_price).toFixed(2) &&
                        Number(item.skins_price).toFixed(2) <= 100,
                      'one two3':
                        100 < Number(item.skins_price).toFixed(2) &&
                        Number(item.skins_price).toFixed(2) < 501,
                      'one two2': Number(item.skins_price) > 501,
                    }"
                  >
                    <span style="" v-if="item.status == 1" class="span1"
                      >等待中</span>
                    <span class="span2" v-if="item.status == 2">进行中</span>
                    <!-- 已结束 -->
                    <span v-if="item.status == 3" class="span3">已结束</span>
                    <!-- <span>{{ item.player_info[0].people }}</span> -->
                    <img
                      style="width: '66px'; height: 29px; margin-right: 10px"
                      v-if="item.type == 1 && item.status == 2"
                      src="../assets/img/AI.png"
                      alt=""
                    />
                    <img
                      style="width: '66px'; height: 29px; margin-right: 10px"
                      v-if="item.type == 1 && item.status == 1"
                      src="../assets/img/AI2.png"
                      alt=""
                    />
                    <img
                      style="width: '66px'; height: 29px; margin-right: 10px"
                      v-if="item.type == 2 && item.status == 2"
                      src="../assets/img/wanjia.png"
                      alt=""
                    />
                    <img
                      style="width: '66px'; height: 29px; margin-right: 10px"
                      v-if="item.type == 2 && item.status == 1"
                      src="../assets/img/wanjia2.png"
                      alt=""
                    />
                    <!-- item.player_info[0].is_anchor == 1 -->
                    <img
                      style="width: '66px'; height: 29px; margin-right: 10px"
                      v-if="item.type == 3 && item.status == 2"
                      src="../assets/img/zhubo.png"
                      alt=""
                    />
                    <img
                      style="width: '66px'; height: 29px; margin-right: 10px"
                      v-if="item.type == 3 && item.status == 1"
                      src="../assets/img/zhubo.png"
                      alt=""
                    />
                    <span class="span3">{{ item.boxInfo.length }}回合</span>
                  </div>
                  <div
                    :class="{
                      'two two1 ': item.status == 1,
                      'two two3': item.status == 2,
                      'two two2': item.status == 3,
                    }"
                  >
                    <div class="two-top">
                      <img src="../assets/img/money.png" />
                      <span>{{ item.skins_price }}</span>
                    </div>
                    <div class="two-bot">
                      <div class="room-peo" v-if="item.mode == 2">
                        <div
                          class="pk-warp"
                          v-for="(item2, index2) in item.player_info"
                          :key="index2"
                        >
                          <div class="pk-box">
                            <span
                              class="pk-tou"
                              :class="
                                item2.class == 'pk-false' ? 'pk-tou-false' : ''
                              "
                              ><img
                                style="object-fit: cover"
                                :class="item2.class"
                                :src="item2.img"
                            /></span>
                          </div>
                        </div>
                      </div>

                      <div class="room-peo" v-if="item.mode == 3">
                        <div
                          class="pk-warp"
                          v-for="(item3, index3) in item.play1"
                          :key="index3"
                        >
                          <div class="pk-box">
                            <span
                              class="pk-tou"
                              :class="
                                item3.class == 'pk-false' ? 'pk-tou-false' : ''
                              "
                              ><img
                                :class="item3.class"
                                style="object-fit: cover"
                                :src="item3.img"
                            /></span>
                          </div>
                        </div>
                        <div class="pk-warp">
                          <div
                            class="pk-box"
                            v-for="(item31, index31) in item.play2"
                            :key="index31"
                          >
                            <span
                              class="pk-tou"
                              :class="
                                item31.class == 'pk-false' ? 'pk-tou-false' : ''
                              "
                            >
                              <img
                                :class="item31.class"
                                style="object-fit: cover"
                                :src="item31.img"
                            /></span>
                          </div>
                        </div>
                      </div>

                      <div class="room-peo" v-if="item.mode == 4">
                        <div class="pk-warp">
                          <div
                            class="pk-box"
                            v-for="(item4, index4) in item.play1"
                            :key="index4"
                          >
                            <span
                              class="pk-tou"
                              :class="
                                item4.class == 'pk-false' ? 'pk-tou-false' : ''
                              "
                              ><img
                                :class="item4.class"
                                style="object-fit: cover"
                                :src="item4.img"
                            /></span>
                          </div>
                        </div>
                        <div class="pk-warp">
                          <div
                            class="pk-box"
                            v-for="(item41, index41) in item.play2"
                            :key="index41"
                          >
                            <span
                              class="pk-tou"
                              :class="
                                item41.class == 'pk-false' ? 'pk-tou-false' : ''
                              "
                              ><img
                                :class="item41.class"
                                style="object-fit: cover"
                                :src="item41.img"
                            /></span>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="room-btn">
                        <span
                          class="span1"
                          @click="goLuckyRoom(item.id)"
                          v-if="item.status == 1"
                          >挑战</span
                        >
                        <span
                          class="span2"
                          @click="goLuckyRoom(item.id)"
                          v-if="item.status != 1"
                          >查看详情</span
                        >
                      </div> -->
                    </div>
                  </div>
                  <div
                    :class="{
                      'three two1 ': item.status == 1,
                      'three two3': item.status == 2,
                      'three two2': item.status == 3,
                    }"
                  >
                    <div v-for="(item1, index1) in item.boxInfo" :key="index1">
                      <span>{{ item1.imgNum }}</span>
                      <img class="img1" :src="item1.img_active" />
                      <img class="img2" :src="item1.img_main" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <div class="clear"></div>
            <div class="more-btn">
              <span @click="moveList()">查看更多</span>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>

      <!--  右侧创建房间盒子 -->
      <!-- background: url(../assets/newimg/cebiuanlan.png) no-repeat;
    background-size: 100% 100%; -->

      <el-drawer
        :visible.sync="drawer"
        direction="rtl"
        size="548px"
        class="sty create-activity"
      >
      <div class="box-img-icons" @click="drawer = false">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
        <div class="sty-warp">
         
          <div class="sty-two">
            <div class="sty-three">
              <div class="sty-three-hint">
              <span
                  >已选择: <strong>{{ currentBoxNum }}</strong
                  >/12</span
                >
                <span
                  >价值: <strong>{{ totalPrice }}</strong></span
                >
              </div>
            
              <div  class="sty-two-list">
                <div class="sty-three-list">
                  <ul>
                    <li
                      v-for="(item, index) in selectList"
                      :key="index"
                      @click="remBox(item.name, item.price)"
                    >
                      <div class="threelist-warp">
                        <div v-if="item.state">
                          <div class="threelist-img">
                            <img :src="item.img_active" />
                          </div>
                          <div class="threelist-img2">
                            <img :src="item.img_main" />
                          </div>
                          <div class="twolist-bots">
                            <p style="color:#fff;text-align:center;font-size:12px">{{ item.name }}</p>
                          </div>
                          <!-- <div class="threelist-name">
                            <img src="../assets/img/money.png" style="width:10px;height:10px" />
                            <span style="font-size:10px">{{ item.price }}</span>
                          </div> -->
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
           
            <div style="display: flex; align-items: center" class="mt-2 mb-2">
              <div class="sty-two-hint1">单击选择盲盒</div>
              <div class="sty-two-hint2">你能选择最多12 件盲盒</div>
            </div>
            <div class="titlelist d-flex justify-content-around">
              <div v-for="(item, index) in resdatalist" :key="index" style="padding:8px" class=" noactive" @click="titleclik(item.id)" :class="ids==item.id?'activess':''">
                <div style="color:#fff;font-size:14px">
                  {{ item.type_name }}
                </div>
              </div>
            </div>
            <div class="sty-two-list">
              <ul class="uls">
                <li
                class="lis"
                  v-for="(item, index) in boxList"
                  :key="index"
                  :class="item.num > 0 ? 'active' : ''"
                  @click="addBox(index, item.price)"
                >
                  <div class="twolist-warp">
                    <div class="twolist-top2">
                      <img :src="item.img_main" />
                    </div>
                    <div class="twolist-top">
                      <img :src="item.img_active" />
                    </div>
                    <div class="twolist-bots">
                      <p style="color:#fff;text-align:center;font-size:12px">{{ item.name }}</p>
                    </div>
                    <div class="twolist-bot">
                      <img src="../assets/img/money.png" style="width:10px;height:10px" />
                      <span style="font-size:10px">{{ item.price }}</span>
                    </div>

                    <!-- <div :class="item.num > 0 ? 'twolist-num' : 'twolist-num1'">
                      x{{ item.num }}
                    </div> -->
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="sty-three">
          
            <div class="sty-one">
            <div class="sty-one-hint">设置房间模式</div>
            <div class="sty-one-pattern">
              <span
                v-for="(item, index) in patternList"
                :key="index"
                :class="item.state ? '' : 'span1'"
                @click="selectPattern(item.name)"
                >{{ item.name }}</span
              >
            </div>
          </div>
            <div class="sty-three-btn1">
              <!-- <div
                :disabled="loading2"
                class="sty-three-btn"
                @click="createRoom()"
              >
                <i v-if="loading2" class="el-icon-loading"></i>设置密码
              </div> -->
              <div
                :disabled="loading2"
                class="sty-three-btn"
                @click="createRoom()"
              >
                <i v-if="loading2" class="el-icon-loading"></i>创建活动
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawerBot"
      :with-header="false"
      direction="btt"
      class="box-bot"
      size="772px"
      @close="closeBot"
    >
      <div class="box-img">
        <div class="box-img-title">
          <span class="span1">{{ drawerName }}</span>
        </div>
        <div class="box-img-icon" @click="drawerBot = false">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
        <div class="box-img-list">
          <ul ref="ulLiList">
            <li v-for="(item, index) in drawerList" :key="index" ref="listLi">
              <!-- <div
                :style="{ backgroundColor: item.color }"
                class="top-gang"
              ></div> -->
              <div style="width: 100%; text-align: right; padding-right: 10px">
                {{ item.probability }}%
              </div>
              <div class="box-img1">
                <img :src="item.img" />
              </div>
              <div class="box-img-price">
                <img src="../assets/img/money.png" />
                <span>{{ item.price }}</span>
              </div>
              <div class="box-img-name" :title="item.name">{{ item.name }}</div>
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import myinform from "@/components/my_inform.vue";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import Utils from "./../assets/js/util.js";

import { battle } from "@/api/socket.js";

export default {
  components: { myinform },
  data() {
    return {
      boxImgList: [],
      nav: 0,
      loading2: false,
      drawerList: [],
      drawerName: "",
      drawerBot: false,
      loading1: false,
      sockBattleData: "",
      loading: true,
      websock: "",
      timer: "",
      awaitRoom: 0,
      currentBoxNum: 0,
      currentBoxTotalPrice: 0,
      totalPrice: 0,
      mode: "2",
      page: 1,
      pageSize: 12,
      boxInfo: [],
      list: [],
      star: {},
      list1: [],
      boxList: [],
      boxList2: [],
      resdatalist:[],
      ids:'',
      selectList: [
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
      ],
      drawer: false, //右侧导航状态
      patternList: [
        //选择模式
        { name: "双人模式", state: true, val: 2 },
        { name: "三人模式", state: false, val: 3 },
        // { name: "四人模式", state: false, val: 4 },
      ],
      peopleObj: {
        img: require("../assets/img/jiapeople.png"),
        class: "pk-false",
        border: "1px dashed #e9b10e",
      },
      img: require("../assets/img/15mdpi.png"),
      arrs:[]
    };
  },
  watch: {
    currentBoxTotalPrice(val) {
      // console.log("创建失败", val);
      let _this = this;
      _this.totalPrice = val.toFixed(2);
    },
  },
  methods: {
    titleclik(item){
      console.log(item);
      this.ids=item
      let param = {
        title_id:item
      };
      let _this = this;
      _this
        .$post("/api/battle/battleBoxList", param)
        .then((res) => {
          res.data.forEach((element) => {
            element.num = 0;
          });
          _this.boxList = res.data;
          // console.log("_this.boxList6666", _this.boxList);
          // _this.loading = true;

          // let arr = [];
          // for (let i = 0; i < this.boxList.length / 10; i++) {
          //   this.boxList2.push(arr);
          // }
          // for (let i = 0; i < this.boxList2.length; i++) {
          //   this.boxList2[i] = this.boxList.slice(i * 10, (i + 1) * 10);
          // }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closeBot() {
      this.drawerList = [];
    },
    changeNav(index) {
      this.nav = index;
      if (index == 1) {
        this.$router.push({
          path: `/LuckyHistory`,
          query: {
            type: 1,
          },
        });
      }
      if (index == 2) {
        this.$router.push({
          path: `/LuckyHistory`,
          query: {
            type: 2,
          },
        });
      }
    },
    getBot(name, id) {
      this.drawerBot = true;
      this.drawerName = name;
      let param = {
        box_id: id,
      };
      this.$axios
        .post("/index/Box/boxInfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.drawerList = data.data.box_skins;
            this.$nextTick(() => {
              for (let index = 0; index < this.$refs.listLi.length; index++) {
                if (index * 5 + 1 <= this.$refs.listLi.length) {
                  let item = this.$refs.listLi[index * 5 + 1];
                  // item.style.backgroundImage =
                  //   'url("http://csgo.admin.17xiubei.com/images/img_106.png")';
                  // console.log(item);
                }
                if (index * 5 + 2 <= this.$refs.listLi.length) {
                  let item = this.$refs.listLi[index * 5 + 2];
                  // item.style.backgroundImage =
                  //   'url("http://csgo.admin.17xiubei.com/images/img_106.png")';
                  // console.log(item);
                }
              }
            });
          }
        });
    },
    //对战排行
    rankList() {
      this.$axios.post("/index/Battle/ranking").then((res) => {
        var data = res.data;
        if (data.status == 1) {
          this.list1 = data.data.rank;
          this.star = data.data.star;
          for (let i = 0; i < this.list1.length; i++) {
            this.list1[i].total_consume =
              this.list1[i].total_consume.toFixed(2);
          }
          if (this.star) {
            this.star.total_consume = this.star.total_consume.toFixed(2);
          }
        }
      });
    },

    //推送
    initWebSocket() {
      const wsuri = battle();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    websocketonopen() {
      let actions = { test: "12345" };
      this.websocketsend(JSON.stringify(actions));
    },

    websocketonerror() {
      this.initWebSocket();
    },

    websocketonmessage(d) {
      // console.log("接收66666:", JSON.parse(d.data));
      // console.log(JSON.parse(d.data));
      this.sockBattleList(d.data);
    },

    websocketsend(Data) {
      if (this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(Data);
      }
    },

    websocketclose(e) {
      // console.log("close:", e);
    },

    updateBattleList(item) {
      this.list.forEach((ele, index) => {
        if (ele.id == item.id) {
          this.list[index].class = "";
          this.$set(this.list, index, item);
          console.log("更新：", this.list);
        }
      });
      //
     
      // setTimeout(() => {
      //   this.getList();
      // }, 10000)
    },

    addBattleList(item) {
      if (this.list.length == 0) {
        // console.log("add1111", item);
        this.list.unshift(item);
        return;
      }
      let insert = false;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].status == 3 && this.list.length >= 12) {
          item.class = "replace-room";
          this.$set(this.list, i, item);
          insert = true;
          break;
        }
      }
      let e = this.list.filter((ele, index, arr) => {
        return ele.id == item.id;
      });
      if (!insert && e.length == 0) {
        this.list.unshift(item);
      }
    },

    sockBattleList(d) {
      let data = JSON.parse(d);
      // console.log("sockBattleList000", data);

      if (data.info == undefined) {
        return;
      }
      if (data.state == "update") {
        // console.log("updateBattleList666666", data.info);
        const newList = [];
        const imgarr = [];
        data.info.boxInfo.forEach((ele1) => {
          if (imgarr.indexOf(ele1.box_id) != -1) {
            newList.forEach((v) => {
              if (v.box_id == ele1.box_id) {
                // console.log("updateBattleList666666", v);
                v.imgNum++;
              }
            });
          } else {
            ele1.imgNum = 1;
            newList.push(ele1);
            imgarr.push(ele1.box_id);
          }
        });
        data.info.boxInfo = newList;
        this.updateBattleList(data.info);
      } else if (data.state == "add") {
        console.log("addBattleList666666", data.info);
        const newList = [];
        const imgarr = [];
        data.info.boxInfo.forEach((ele1) => {
          if (imgarr.indexOf(ele1.box_id) != -1) {
            newList.forEach((v) => {
              if (v.box_id == ele1.box_id) {
                // console.log("updateBattleList666666", v);
                v.imgNum++;
              }
            });
          } else {
            ele1.imgNum = 1;
            newList.push(ele1);
            imgarr.push(ele1.box_id);
          }
        });
        data.info.boxInfo = newList;
        this.addBattleList(data.info);
      } else if (data.state == "logoutroom") {
        console.log("logout_room666", data.info);
        this.getList();
      } else if (data.state == "delroom") {
        console.log("delroom0000", data.info);
        this.list.forEach((ele, index) => {
          if (ele.id == data.info.id) {
            this.list.splice(index, 1);
          }
        });
      }

      let _this = this;
      this.awaitRoom = 0;
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].boxInfo = this.list[i].boxInfo;
        this.list[i].price = 0;
        var info = this.list[i].boxInfo;
        for (let j = 0; j < info.length; j++) {
          this.list[i].price += Number(info[j].price);
        }
        this.list[i].price = this.list[i].price.toFixed(2);
        if (this.list[i].status == "1") {
          this.awaitRoom = this.awaitRoom + 1;
        }
      }
      //几个人参与
      for (let i = 0; i < this.list.length; i++) {
        let play = JSON.parse(JSON.stringify(this.list[i].player_info));
        let mode = this.list[i].mode;
        for (let j = 0; j < mode - this.list[i].player_info.length; j++) {
          play.push(this.peopleObj);
        }
        for (let x = 0; x < play.length; x++) {
          if (play[x].id) {
            play[x].class = "pk-true";
            play[x].img = play[x].img;
          }
        }
        this.list[i].player_info = play;

        if (mode == "3") {
          this.list[i].play1 = [];
          this.list[i].play2 = [];
          this.list[i].play1.push(play[0]);
          this.list[i].play2.push(play[1]);
          this.list[i].play2.push(play[2]);
        }
        if (mode == "4") {
          this.list[i].play1 = [];
          this.list[i].play2 = [];
          this.list[i].play1.push(play[0]);
          this.list[i].play1.push(play[1]);
          this.list[i].play2.push(play[2]);
          this.list[i].play2.push(play[3]);
        }
      }
      //排序
    },

    touchstart(event) {
      // console.log("touch");
      var style = document.createElement("style");
      style.innerHTML = `.ripple::after{top:${event.offsetY - 25}px;left:${
        event.offsetX - 180
      }px;}`;
      document.head.appendChild(style);
    },

    //查看更多
    moveList() {
      // console.log(this.pageSize);
      let _this = this;
      let param = {
        page: this.page,
        pageSize: this.pageSize + 12,
      };
      _this
        .$post("/api/battle/battleList", param)
        .then((res) => {
          var data = res;
          if (data.status == 1 && data.data != null) {
            this.pageSize = this.pageSize + 12;
            //let list = data.data.battleList;
            // console.log("666666", data);
            data.data.battleList.forEach((ele) => {
              const newList = [];
              const imgarr = [];
              ele.boxInfo.forEach((ele1) => {
                // console.log("imgarr222" + ele1.box_id, imgarr);
                if (imgarr.indexOf(ele1.box_id) != -1) {
                  newList.forEach((v) => {
                    if (v.box_id == ele1.box_id) {
                      // console.log("v00000", v);
                      v.imgNum++;
                    }
                  });
                } else {
                  ele1.imgNum = 1;
                  newList.push(ele1);
                  imgarr.push(ele1.box_id);
                  // console.log("ele3333", newList);
                }
              });
              ele.boxInfo = newList;
            });

            this.list = data.data.battleList;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].boxInfo = this.list[i].boxInfo;
              this.list[i].price = 0;
              var info = this.list[i].boxInfo;
              for (let j = 0; j < info.length; j++) {
                this.list[i].price += Number(info[j].price);
                // console.log("this.list[i].price2222", this.list[i].price);
              }
              this.list[i].price = this.list[i].price.toFixed(2);
              if (this.list[i].status == "1") {
                this.awaitRoom = this.awaitRoom + 1;
              }
            }
            //几个人参与
            for (let i = 0; i < this.list.length; i++) {
              let play = JSON.parse(JSON.stringify(this.list[i].player_info));
              let mode = this.list[i].mode;
              for (let j = 0; j < mode - this.list[i].player_info.length; j++) {
                play.push(this.peopleObj);
              }
              for (let x = 0; x < play.length; x++) {
                if (play[x].id) {
                  play[x].class = "pk-true";
                  play[x].img = play[x].img;
                }
              }
              this.list[i].player_info = play;

              if (mode == "3") {
                this.list[i].play1 = [];
                this.list[i].play2 = [];
                this.list[i].play1.push(play[0]);
                this.list[i].play2.push(play[1]);
                this.list[i].play2.push(play[2]);
              }
              if (mode == "4") {
                this.list[i].play1 = [];
                this.list[i].play2 = [];
                this.list[i].play1.push(play[0]);
                this.list[i].play1.push(play[1]);
                this.list[i].play2.push(play[2]);
                this.list[i].play2.push(play[3]);
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //对战列表
    getList() {
      let _this = this;
      let param = {
        page: this.page,
        pageSize: this.pageSize,
      };
      _this
        .$post("/api/battle/battleList", param)
        .then((res) => {
          var data = res;
          const boxImgList = res.data.battleList.map((item) => {
            return item.boxInfo;
          });
          res.data.battleList.forEach((ele) => {
            if (ele.player_info[0].type != 1) {
              ele.player_info[0].people = "AI";
            } else if (
              ele.player_info[0].type == 1 &&
              ele.player_info[0].is_anchor == 1
            ) {
              ele.player_info[0].people = "主播";
            } else {
              ele.player_info[0].people = "玩家";
            }
            const newList = [];
            const imgarr = [];
            ele.boxInfo.forEach((ele1) => {
              // console.log("imgarr222" + ele1.box_id, imgarr);
              if (imgarr.indexOf(ele1.box_id) != -1) {
                newList.forEach((v) => {
                  if (v.box_id == ele1.box_id) {
                    // console.log("v00000", v);
                    v.imgNum++;
                  }
                });
              } else {
                ele1.imgNum = 1;
                newList.push(ele1);
                imgarr.push(ele1.box_id);
              }
            });
            // return newList;
            ele.boxInfo = newList;
          });
          _this.list = data.data.battleList;
          if (data.status == 1 && data.data != null) {
            for (let i = 0; i < _this.list.length; i++) {
              _this.list[i].boxInfo = _this.list[i].boxInfo;
              _this.list[i].price = 0;
              var info = _this.list[i].boxInfo;
              for (let j = 0; j < info.length; j++) {
                _this.list[i].price += Number(info[j].price);
                // console.log("this.list[i].price", _this.list[i].price);
              }
              _this.list[i].price = _this.list[i].price.toFixed(2);
              if (_this.list[i].status == "1") {
                _this.awaitRoom = _this.awaitRoom + 1;
              }
            }
            //几个人参与
            for (let i = 0; i < _this.list.length; i++) {
              let play = JSON.parse(JSON.stringify(_this.list[i].player_info));
              let mode = _this.list[i].mode;
              for (
                let j = 0;
                j < mode - _this.list[i].player_info.length;
                j++
              ) {
                play.push(_this.peopleObj);
              }
              for (let x = 0; x < play.length; x++) {
                if (play[x].id) {
                  play[x].class = "pk-true";
                  play[x].img = play[x].img;
                }
              }
              _this.list[i].player_info = play;

              if (mode == "3") {
                _this.list[i].play1 = [];
                _this.list[i].play2 = [];
                _this.list[i].play1.push(play[0]);
                _this.list[i].play2.push(play[1]);
                _this.list[i].play2.push(play[2]);
              }
              if (mode == "4") {
                _this.list[i].play1 = [];
                _this.list[i].play2 = [];
                _this.list[i].play1.push(play[0]);
                _this.list[i].play1.push(play[1]);
                _this.list[i].play2.push(play[2]);
                _this.list[i].play2.push(play[3]);
              }
            }
            //排序
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //去重方法
    qc(arr) {
      var newArr = [];
      for (var j = 0; j < arr.length; j++) {
        if (arr.indexOf(arr[j]) != arr.lastIndexOf(arr[j])) {
          newArr.push(arr[j]);
        }
      }
      var countArrs = [];
      let numArr = newArr.reduce(function (prev, next) {
        prev[next] = prev[next] + 1 || 1;
        countArrs.push({ prev });
        return prev;
      }, {});
      // console.log(numArr);
      // console.log("numArr是最终结果");
    },
    getBattleBoxListitem() {
      let _this = this;
      _this
        .$post("/api/battle/boxlist")
        .then((res) => {
         console.log(res.data);
         this.resdatalist=res.data;
         console.log(this.resdatalist);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //盲盒选择列表
    getBattleBoxList() {
      let _this = this;
      _this
        .$post("/api/battle/battleBoxList")
        .then((res) => {
          res.data.forEach((element) => {
            element.num = 0;
          });
          _this.boxList = res.data;
          // console.log("_this.boxList6666", _this.boxList);
          // _this.loading = true;

          // let arr = [];
          // for (let i = 0; i < this.boxList.length / 10; i++) {
          //   this.boxList2.push(arr);
          // }
          // for (let i = 0; i < this.boxList2.length; i++) {
          //   this.boxList2[i] = this.boxList.slice(i * 10, (i + 1) * 10);
          // }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //创建活动
    createRoom() {
      if (!this.$store.state.id) {
        this.$store.commit("getLogin", true);
        return;
      }

      let _this = this;
      _this.selectList.forEach((e) => {
        if (e.state) {
          e.num = 1;
          _this.boxInfo.push(e);
        }
      });
      if (_this.boxInfo.length == 0) {
        _this.$message({
          message: "请选择箱子",
          type: "warning",
        });
        return;
      }
      this.loading2 = true;
      // console.log(_this.boxInfo);
      let param = {
        mode: _this.mode,
        player_id: this.$store.state.id,
        boxInfo: _this.boxInfo,
      };
      _this
        .$post("/api/battle/createRoom", param)
        .then((res) => {
          // console.log(res);
          let data = res;
          // console.log(data);
          if (data.status == 1) {
            console.log(_this.drawer)
            _this.drawer = false;
            console.log(_this.drawer)
            this.loading2 = false;
            _this.boxInfo = [];
            let selectListCopy = [
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
            ];
            _this.selectList = selectListCopy;
            _this.totalPrice = 0;
            _this.currentBoxNum = 0;
            for (let i = 0; i < this.boxList.length; i++) {
              this.boxList[i].num = 0;
            }
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("money", data.data.total_amount);
            this.getList();
            //_this.$bus.$emit("loading", true);

            //发送
            /* let sendData = {
            state: "add",
            battle_id: data.data.battle_id,
          };
          _this.websocketsend(JSON.stringify(sendData));*/
            _this.websocketsend(JSON.stringify(sendData));
            _this.$router.push({
              path: `/LuckyRoom`,
              query: {
                id: data.data.battle_id,
              },
            });
          } else {
            //创建失败
            _this.$message({
              message: data.msg,
              type: "warning",
            });
            this.loading2 = false;
            _this.boxInfo = [];
            let selectListCopy = [
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
            ];
            _this.selectList = selectListCopy;
            _this.currentBoxNum = 0;
            _this.totalPrice = 0;
            // console.log(this.boxList);
            for (let i = 0; i < this.boxList.length; i++) {
              this.boxList[i].num = 0;
            }
            this.currentBoxTotalPrice = 0;
            this.getList();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //点击挑战房间
    goLuckyRoom(id) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log("gerenxinxi6666", userInfo);
      if (userInfo.tradeUrl == "") {
        this.$message({
          title: "提示",
          message: "请绑定steam链接",
        });
        return;
      }
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          if (this.list[i].status == 1) {
            if (!this.$store.state.id) {
              this.$store.commit("getLogin", true);
            } else {
              this.$router.push({
                path: `/LuckyRoom`,
                query: {
                  id: id,
                },
              });
            }
          } else {
            this.$router.push({
              path: `/LuckyRoom`,
              query: {
                id: id,
              },
            });
          }
        }
      }
    },
    //跳转至活动规则
    goLuckyRule() {
      this.$router.push({
        path: `/LuckyRule`,
      });
    },
    //跳转至活动历史
    goLuckyHistory() {
      this.$router.push({
        path: `/LuckyHistory`,
        query: {
          type: 2,
        },
      });
    },
    //右侧创建房间
    openDrawer() {
      this.drawer = true;
    },
    //选择几人模式
    selectPattern(name) {
      for (let i = 0; i < this.patternList.length; i++) {
        if (this.patternList[i].name == name) {
          this.patternList[i].state = true;
          this.mode = this.patternList[i].val;
        } else {
          this.patternList[i].state = false;
        }
      }
      // console.log(this.mode);
    },
    //选择盒子
    addBox(index, price) {
      // console.log("this.boxList9999", this.boxList);
      
      this.arrs.push(this.boxList[0])
      console.log( this.arrs);

      if (this.currentBoxNum < 12) {
        this.currentBoxNum++;
        this.currentBoxTotalPrice = this.currentBoxTotalPrice + Number(price);
      }
      var boxnum = 0;
      for (let i = 0; i < this.boxList.length; i++) {
        boxnum += this.boxList[i].num;
      }
      // console.log("boxnum5555", boxnum);
      if (boxnum < 12) {
        this.boxList[index].num += 1;
        //console.log(this.selectList)
        for (let i = 0; i < this.selectList.length; i++) {
          if (this.selectList[i].state == false) {
            this.selectList[i].state = true;
            this.selectList[i].box_id = this.boxList[index].id;
            this.selectList[i].name = this.boxList[index].name;
            this.selectList[i].price = this.boxList[index].price;
            this.selectList[i].img_active = this.boxList[index].img_active;
            this.selectList[i].img_main = this.boxList[index].img_main;
            break;
          }
        }
      } else {
        this.$notify({
          title: "提示",
          message: "盲盒数量已达上限",
        });
      }
      // console.log("this.selectList", this.selectList);
    },
    //去掉盒子
    remBox(name, price) {
      if (name) {
        if (this.currentBoxNum > 0) {
          this.currentBoxNum--;
          this.currentBoxTotalPrice = this.currentBoxTotalPrice - Number(price);
        }
      }
 
      for (let i = 0; i < this.boxList.length; i++) {
        if (name == this.boxList[i].name) {
          this.boxList[i].num--;
        }
      }
      for (let i = 0; i < this.selectList.length; i++) {
        if (this.selectList[i].name == name) {
          this.selectList[i].name = "";
          this.selectList[i].state = false;
          break;
        }
      }
      this.selectList.sort((a, b) => b.state - a.state);
    },
  },
  mounted() {
    let _this = this;
    _this.getBattleBoxList();
    _this.getBattleBoxListitem();
    _this.getList();
    //_this.rankList();
    this.$bus.$emit("loading", false);

    Utils.$on("update", function (data) {
      _this.sockBattleList(data);
    });
    Utils.$on("del_room", function (data) {
      _this.sockBattleList(data);
    });
  },
  beforeDestroy: function () {},
  created() {
    this.initWebSocket();
    setTimeout(() => {
      console.log(this.resdatalist);
      this.titleclik(this.resdatalist[5].id)
    }, 1000)
  },
  destroyed() {
    //clearInterval(this.timer);
    this.websock.close();
  },
};
</script>

<style lang="less" scoped>
.noactive{
  background: url(../assets/newimg/sanren.png) no-repeat;
  background-size: 100% 100% !important;
}
.activess{
  background: url(../assets/newimg/shuangren.png) no-repeat;
  background-size: 100% 100% !important;
}
.three{
  background: #1c1c1c;
}
.one{
  background: linear-gradient(180deg, #070f2e, #1d9310) !important;
}
.actives{
  background: rgb(180, 167, 31);
}
.box-img-icons{
  right: 30px;
  top: 10px;
  position: absolute;
  width: 20px;
  height: 20px;
  img{
    width: 100%;
    height: 100%;
  }
}
.lucky {
  /deep/ .el-drawer__wrapper {
    top: 0;
  }
  /deep/ .el-drawer.btt {
    background: none;
    width: 100%;
    bottom: 50px;
    .box-img {
      color: #fff;
      width: 1293px;
      height: 772px;
      background: url(../assets/newimg/img_88.png) no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      position: relative;
      .box-img-icon {
        position: absolute;
        right: 30px;
        top: 30px;
        img {
          width: 52px;
          height: 45px;
        }
      }
    }
  }
}

.replace-room {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-animation-name: flip;
  animation-name: flip;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes flip {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.lucky {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  .lucky-warp {
    // width: 1200px;
    max-width: 1200px;
    height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
    .pay-nav {
      height: 51px;
      //margin-top:20px;
      color: #fff;
      display: flex;
      align-items: center;
      position: relative;
    }
    .nav_item {
      width: 164px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      background-size: contain;
      font-weight: 700;
      cursor: pointer;
    }
    .nav_item:first-child {
      // background: url(../assets/newimg/manghe.png) no-repeat 50%;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ffffff;
    }
    .nav_item:first-child.active {
      height: 68px;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #000;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      line-height: 68px;
    }
    .nav_item:nth-child(2).active {
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #000;
      background-size: 100% 100%;
    }
    .nav_item:nth-child(2) {
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      margin-left: -20px;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ffffff;
    }
    .nav_item:nth-child(3).active {
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #000;
      background-size: 100% 100%;
    }
    .nav_item:nth-child(3) {
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      margin-left: -20px;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ffffff;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 3px solid #ffc400;
      .left {
        color: #c3c3e2;
        font-size: 24px;
      }
      .right {
        display: flex;
        align-items: center;

        .right-span {
          padding: 9px 22px;
          border-radius: 5px;
          font-size: 15px;
          font-weight: 600;
        }
        .right-span1 {
          margin-right: 10px;
          width: 88px;
          height: 36px;
          background: url(../assets/newimg/guize.png) no-repeat 50%;
          background-size: 100% 100%;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;
          line-height: 36px;
          text-align: center;
        }
        .right-span1:hover {
          // background: rgba(255, 255, 255, 0.25);
          cursor: pointer;
        }
        .right-span2 {
          width: 100px;
          height: 45px;
          background: url(../assets/newimg/chuangjian.png) no-repeat 50%;
          background-size: 100% 100%;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;
          line-height: 45px;
          text-align: center;
        }
        .right-span2:hover {
          // background-color: #ffc400;
          cursor: pointer;
        }
      }
    }
    .duizhangang {
      width: 100%;
      margin-top: 10px;
    }
    .luc-list {
      width: 1200px;
      // max-width: 1200px;
      height: 232px;
      margin: 0 auto;
      // margin-top: 30px;
      // border-bottom: 3px solid #ffc400;
      padding-bottom: 25px;
      //background-color: #2b2c37;
      background: url(../assets/newimg/duizhankuang.png) no-repeat 50%;
      background-size: 100% 100%;
      overflow: hidden;
      // padding: 20px;
      ul {
        width: 90%;
        height: 60%;
        margin: 0 auto;
        margin-top: 68px;
        // background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        padding: 8px 0;
        display: flex;
        // flex-wrap: nowrap;
        box-sizing: border-box;
        // white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        display: -webkit-box;
        li {
          height: 100%;
          // float: left;
          width: 12%;
          .list-warp {
            height: 100%;
            border-right: 1px solid #707070;
            position: relative;
            .list-img2 {
              text-align: center;
              width: 100%;
              height: 70%;
              img {
                width: 100%;
                height: 100%;
                // width: 60%;
                // height: 75px;
              }
            }
            .list-img {
              text-align: center;
              position: absolute;
              top: 5px;
              left: 20px;
              img {
                // height: 80px;
                width: 80%;
                height: 70px;
              }
            }
            .list-money {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              img {
                height: 12px;
                width: auto;
                margin-right: 5px;
              }
              span {
                color: #fff;
              }
            }
            .list-name {
              text-align: center;
              margin-top: 5px;
              color: #fff;
              padding-bottom: 10px;
              font-size: 13px;
            }
          }
        }
        li:last-child {
          .list-warp {
            border: none;
          }
        }
        li:hover {
          cursor: pointer;
          //background-color: #30313f;
          .list-money {
            span {
              color: #ffc400;
            }
          }
          .list-name {
            color: #ffc400;
          }
        }
      }
      ul::-webkit-scrollbar {
        height: 8px;
      }
      ul::-webkit-scrollbar-thumb {
        // height: 2px;
        border-radius: 10px;
        background: rgba(210, 206, 206, 0.2);
      }
      ul::-webkit-scrollbar-track {
        border-radius: 0;
        background: rgba(58, 59, 61, 0.2);
      }
    }

    .luc-list1 {
      display: none;
      height: 150px;
      margin-top: 20px;
      background-color: #2b2c37;
      width: 100%;

      //overflow: hidden;
      // overflow-x: scroll;

      ul {
        width: 1000px;
        // width: 500px;
        background-color: #2b2c37;
        border-radius: 5px;
        overflow-x: scroll;
        li {
          background-color: #2b2c37;
          float: left;
          width: 100px;

          .list-warp {
            border-right: 1px solid #1a1c24;
            .list-img {
              text-align: center;
              img {
                // height: 80px;
                width: 100px;
                height: auto;
              }
            }
            .list-money {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 15px;
                width: auto;
                margin-right: 5px;
              }
              span {
                color: #e9b10e;
              }
            }
            .list-name {
              text-align: center;
              margin-top: 5px;
              color: #c3c3e2;
              padding-bottom: 10px;
            }
          }
        }
        li:hover {
          cursor: pointer;
          background-color: #30313f;
        }
      }
    }

    .luc-list /deep/ .el-carousel__indicators--outside {
      display: none;
    }

    /* .luc-list::-webkit-scrollbar {

      width: 2000px;
      height: 10px;
    }
    .luc-list::-webkit-scrollbar-thumb {

      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }
    .luc-list::-webkit-scrollbar-track {

      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ededed;
    } */

    .hint {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      span {
        color: #fff;
        font-size: 14px;
      }
    }
    .room {
      padding-bottom: 50px;
      margin-top: 20px;
      display: flex;

      .ranking {
        flex-grow: 1;
        flex-basis: 0;
        margin-right: 16px;
        .rank-top {
          background-color: #2b2c37;
          // padding: 20px;
          border-radius: 5px;
          padding: 35px 0;
          .one {
            text-align: center;
            font-size: 24px;
            color: #c3c3e2;
          }
          .two {
            // width: 60px;
            height: 60px;
            text-align: center;
            margin-top: 15px;
            position: relative;
            background: url(../assets/img/maisui.svg) center no-repeat;

            .winner-warp {
              width: 60px;
              height: 60px;
              // border-radius: 50%;
              //overflow: hidden;
              position: absolute;
              top: 0;
              left: 50%;
              margin-left: -30px;
              z-index: 66;
            }
            .winner1 {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              overflow: hidden;
              position: absolute;
              top: 0;
              left: 0;
              // margin-left: -30px;
              z-index: 66;
            }
            .winner {
              width: 200px;
              height: 200px;
              position: absolute;
              top: -70px;
              left: -75px;
              animation: jss163 5000ms linear infinite;
              user-select: none;
              pointer-events: none;
              z-index: 2;
            }
            @-webkit-keyframes jss163 {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(360deg);
              }
            }
            span {
              position: absolute;
              left: 50%;
              bottom: 0;
              font-size: 12px;
              border-radius: 2px;
              padding: 0 6px;
              background-color: #858493;
              margin-left: -14px;
              z-index: 88;
            }
          }
          .three {
            margin-top: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            span {
              margin-left: 5px;
              color: #c3c3e2;
              font-size: 14px;
            }
            .img1 {
              width: 15px;
              height: 15px;
            }
            .img2 {
              width: 15px;
              height: 15px;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .four {
            text-align: center;
            margin-top: 5px;
            font-size: 16px;
            color: #e9b10e;
          }
        }
        .rank-bot {
          margin-top: 16px;
          background-color: #2b2c37;
          padding: 20px 10px;
          border-radius: 5px;
          .title {
            text-align: center;
            font-size: 20px;
            color: #c3c3e2;
          }
          .ranking-list {
            ul li {
              padding: 10px 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
              // transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

              .list-left {
                display: flex;
                align-items: center;
                .list-left1 {
                  margin-right: 10px;
                  position: relative;
                  img {
                    border-radius: 50%;
                    width: 45px;
                    height: 45px;
                  }
                  span {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    font-size: 12px;
                    border-radius: 2px;
                    padding: 0 6px;
                    background-color: #858493;
                    margin-left: -14px;
                  }
                }
                .list-left2 {
                  display: flex;
                  align-items: center;
                  img {
                    margin-right: 5px;
                    width: 15px;
                    height: 15px;
                  }
                  span {
                    font-size: 14px;
                    color: #c3c3e2;
                  }
                }
              }
            }
            ul li:hover {
              cursor: pointer;
              background-color: #292a35;
            }
            /* ul li:active{
              background-color: #fff;
            } */
          }
          .list-right {
            font-size: 14px;
            color: #e9b10e;
          }
        }
      }
      .room-box {
        flex-grow: 3;
        flex-basis: 0;
        .room-box-ul {
          margin-top: -8px;
          .box-li {
            float: left;
            width: 46% !important;
            // height: 200px;
            // background: url(../assets/newimg/roombg.png) no-repeat 50%;
            background: rgb(47, 54, 77);
            background-size: 100% 100%;
            margin-left: 2%;
            margin-bottom: 16px;
            .roombox-warp.two1 {
              .one {
                // background: #00aeff;
                .span1 {
                  // color: #000;
                  .span-dian {
                    background: #000;
                  }
                }
                .span3 {
                  color: #fff;
                  width: 80px !important;
                }
              }
              .two {
                // background-image: linear-gradient(#45baf0, #3d2b0a);
              }
              .three {
                // background: #2b1d07;
                div {
                  position: relative;
                  span {
                    width: 15px;
                    height: 15px;
                    right: 0;
                    top: 0;
                    text-align: center;
                    line-height: 15px;
                    display: block;
                    color: #fff;
                    position: absolute;
                    background-color: rgb(233, 125, 125);
                    border-radius: 50%;
                    z-index: 100;
                    font-size: 12px;
                    // position: relative;
                  }
                }
              }
            }
            .roombox-warp.two2 {
              .one {
                // background: #e7d945;
                .span3 {
                  width: 80px;
                  color: #e1e1e1;
                }
              }
              .two {
                // background-image: linear-gradient(#e6e078, #3d2b0a);
              }
              .three {
                // background: #2b1d07;
                div {
                  position: relative;
                  span {
                    width: 10px;
                    height: 10px;
                    
                    right: 0;
                    top: 0;
                    text-align: center;
                    line-height: 10px;
                    display: block;
                    color: #fff;
                    position: absolute;
                    background-color: rgb(233, 125, 125);
                    border-radius: 50%;
                    // z-index: 99999999;
                  }
                }
              }
            }
            .roombox-warp.two3 {
              .one {
                // background: #992b53;
                .span3 {
                  width: 80px;
                  color: #fff;
                }
              }

              .two {
                // background-image: linear-gradient(#ca5ca0, #3d2b0a);
              }
              .three {
                // background: #2b1d07;
                div {
                  position: relative;
                  span {
                    width: 20px;
                    height: 20px;
                    right: 0;
                    top: 0;
                    text-align: center;
                    line-height: 20px;
                    display: block;
                    color: #fff;
                    position: absolute;
                    background-color: rgb(233, 125, 125);
                    border-radius: 50%;
                    z-index: 10;
                    // position: relative;
                    // display: block;
                  }
                  // img{
                  //   height: 50px;
                  // }
                }
              }
            }
            .roombox-warp {
              // margin: 0 8px;
              // background-color: #22222d;
              border-radius: 5px;
              .one {
                // padding: 15px;
                display: flex;
                justify-content: space-between;
                // background-color: #243438;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                font-size: 12px;
                img {
                }
                .span2{
                  width: 76px;
                  // width: 30%;
                  // color: #000;
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #9074ee;
                  display: flex;
                  align-items: center;
                  // margin-top: -25px !important;
                  margin-left: 10px !important;
                  .span-dian {
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    animation: jss684 500ms linear infinite alternate;
                    // box-shadow: 0 0  4px rgb(2 191 77 / 20%);
                    box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
                    margin-left: 10px;
                    margin-right: 10px;
                    border-radius: 4px;
                    vertical-align: middle;
                    background-color: #000;
                  }
                  @keyframes jss684 {
                    0% {
                      box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
                    }
                    100% {
                      box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
                    }
                  }
                }
                .span1 {
                  width: 76px;
                  // width: 30%;
                  // color: #000;
                  font-size: 12px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #00baff;
                  display: flex;
                  align-items: center;
                  margin-top: 0 !important;
                  .span-dian {
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    animation: jss684 500ms linear infinite alternate;
                    // box-shadow: 0 0  4px rgb(2 191 77 / 20%);
                    box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
                    margin-left: 10px;
                    margin-right: 10px;
                    border-radius: 4px;
                    vertical-align: middle;
                    background-color: #000;
                  }
                  @keyframes jss684 {
                    0% {
                      box-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
                    }
                    100% {
                      box-shadow: 0px 0px 15px rgba(255, 255, 255, 1);
                    }
                  }
                }
                .span3 {
                  color: #000;
                  font-size: 14px;
                  line-height: 30px;
                }

                .status2 {
                  width: 76px;
                  display: flex;
                  align-items: center;
                  .span2 {
                    color: #ae7bfe;
                    font-size: 14px;
                    margin-right: 5px;
                  }
                  .img1 {
                    animation: jss163 1000ms linear infinite;
                  }
                  @keyframes jss163 {
                    0% {
                      transform: rotate(0deg);
                    }
                    100% {
                      transform: rotate(360deg);
                    }
                  }
                }
              }
              .two3 {
                // background-image: linear-gradient(#2d2d36, #483856);
                // background-color: #f00;
              }
              .two {
                // padding: 10px 0;
                .two-top {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 5px;
                  span {
                    margin-left: 5px;
                    font-size: 14px;
                    color: #ffc400;
                  }
                  img {
                    height: 14px;
                    width: auto;
                  }
                }
                .two-bot {
                  // margin-top: 10px;
                  .room-peo {
                    min-height: 80px;
                    display: flex;
                    //flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    .pk-warp:last-child {
                      .pk-box:last-child {
                        border: none;
                      }
                    }
                    .pk-warp {
                      // margin-top: 10px;
                      display: flex;
                      .pk-box {
                        // border-right: 2px solid #ffc400;
                        padding-right: 5px;
                      }

                      span:first-child {
                        margin-left: 10px;
                      }
                      .pk-tou {
                        margin: 0 5px;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        //border: 1px dashed #e9b10e;
                        overflow: hidden;

                        .pk-true {
                          width: 50px;
                          height: 50px;
                        }
                        .pk-false {
                          width: 25px;
                          height: 25px;
                        }
                      }
                      .pk-tou-false {
                        border: 1px dashed #e9b10e;
                      }
                    }
                  }
                  .room-btn {
                    // margin-top: 20px;
                    display: flex;
                    justify-content: center;
                    span {
                      width: 124px;
                      height: 68px;
                      // border: 2px solid #ffc400;
                      background: url(../assets/newimg/chakan.png) no-repeat 50%;
                      background-size: 100% 100%;
                      text-align: center;
                      line-height: 68px;
                      font-size: 12px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #ffffff;
                    }
                    /* span:hover {
                      cursor: pointer;
                    }*/
                    .span1:hover {
                      cursor: pointer;
                      // background: #ffc400;
                      // color: #000;
                    }
                    .span2:hover {
                      cursor: pointer;
                      // background: #ffc400;
                      color: #000;
                    }
                  }
                }
              }
              .three {
                margin: 0 auto;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                // background-color: #243438;
                display: flex;
                flex-wrap: nowrap;
                overflow-x: scroll;
                overflow-y: hidden;
                position: relative;
                padding-top: 10px;
                padding-bottom: 10px;

                .img1 {
                  height: 23px;
                  aspect-ratio: 59/44;
                  position: relative;
                  z-index: 10;
                  margin-top: 6px !important;
                  margin-left: 15px !important;
                }
                .img2 {
                  aspect-ratio: 90/65;
                  width: 54px;
                  position: absolute;
                  top: 0;
                  left: 0;
                  z-index: 0;
                }
              }
              .three::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 10px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 6px;
              }

              .three::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                background-color: #4b575c;
              }

              .three::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                // background: #243438;
              }
            }
          }
          .box-li3 {
            float: left;
            width: 46%;
            // background: url(../assets/newimg/roombg2.png) no-repeat 50%;
            background-size: 100% 100%;
            // margin-right: 14px;
            margin-bottom: 16px;
            .one{
              background: linear-gradient(180deg, #070f2e, #6a0da8) !important;
            }
          }
          .box-li2 {
            float: left;
            width: 46%;
            // background: url(../assets/newimg/roombg3.png) no-repeat 50%;
            background-size: 100% 100%;
            // margin-right: 14px;
            margin-bottom: 16px;
            .one{
              background: linear-gradient(180deg, #070f2e, #ada00f) !important;
            }
          }
          .box-li:nth-child(4n) {
            margin-right: 0;
          }
        }
      }

      .more-btn {
        margin-top: 16px;
        display: flex;
        justify-content: center;

        span {
          background: url(../assets/newimg/img_15.png) no-repeat;
          background-size: 100% 100%;

          padding: 9px 25px;
          border-radius: 5px;

          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;
        }
        span:hover {
          background-color: #3a3f50;
          cursor: pointer;
        }
      }
    }
  }
}
.sty {
  height: 100%;
  .sty-warp {
    //padding: 30px 0px;
    box-sizing: border-box;
  }
  .sty-one {
    .sty-one-hint {
      font-size: 14px;
      padding-bottom: 5px;
      color: #fff;
    }
    .sty-one-pattern {
      display: flex;
      margin-top: 15px;
      span {
        width: 33.33%;
        text-align: center;
        background: url(../assets/newimg/shuangren.png) no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #e0e5fa;
        line-height: 32px;
        text-shadow: 0px 2px 8px rgba(5, 28, 55, 0.42);
        padding: 10px 0;

        margin-right: 15px;
      }
      :last-child {
        margin-right: 0;
      }
      :hover {
        cursor: pointer;
      }
      .span1 {
        background: url(../assets/newimg/sanren.png) no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #e0e5fa;
        line-height: 32px;
        text-shadow: 0px 2px 8px rgba(5, 28, 55, 0.42);
      }
    }
  }
  .sty-two {
    margin-top: 30px;
    .sty-two-hint1 {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #e0e5fa;
    }
    .sty-two-hint2 {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #e0e5fa;
      margin-left: 15px;
    }
    
    .sty-two-list {
      // min-height: 200px;
      // max-height: 350px;
      max-height: 250px;
      overflow: hidden;
      overflow-y: scroll;
      // margin-top: 12px;
      ul {
        display: flex;
        justify-content: space-around;
        flex-flow: row wrap;
        background: rgb(29, 29, 38);
        li:nth-child(3n) {
          // margin-right: 0;
        }
        li.active {
          // background: url(../assets/newimg/selebg.png) no-repeat;
          background-size: 100% 100%;
          // background: linear-gradient(180deg, #2c2316 0%, #010101 100%);
          // border: 1px solid #ffc400;
        }
        li {
          margin-top: 5px;
          width: 25.25%;
          margin-right: 4%;
          margin-bottom: 3%;
          // background: url(../assets/newimg/selebg.png) no-repeat;
          background-size: 100% 100%;
          opacity: 0.8;
          position: relative;
          padding: 10px 0;
          position: relative;
          .twolist-warp {
            height: 100%;
            .twolist-top2 {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: -1;
              img {
                width: 100%;
                object-fit: fill;
              }
            }
            .twolist-top {
              width: 90%;
              margin: 0 auto;
              margin-top: 5px;
              img {
                width: 100%;
                height: 60px;
              }
            }
            .twolist-bot {
              padding: 8px 0 4px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: auto;
                height: 15px;
              }
              span {
                margin-left: 5px;
                color: #ffc400;
              }
            }
            .twolist-num {
              position: absolute;
              top: 5px;
              right: 10px;
              color: #ffc400;
              font-size: 14px;
            }
            .twolist-num1 {
              display: none;
            }
          }
        }
        :hover {
          cursor: pointer;
        }
      }
      .uls{
        background: none;
        .lis{
          background: rgb(29, 29, 38);
        }
      }
    }
    /*滚动条样式*/
    .sty-two-list::-webkit-scrollbar {
      width: 4px;
      /*height: 4px;*/
    }
    .sty-two-list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    .sty-two-list::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .sty-three {
    margin-top: 10px;
    .sty-three-hint {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        color: #fff;
        font-size: 14px;
        strong {
          color: #ffc400;
          font-weight: 200;
        }
      }
    }
    .sty-three-list {
      margin-top: 15px;
      border-top: 1px solid #dfa617;
      padding-bottom: 10px;
      padding-top: 5px;
      ul {
        height: 86px;
        white-space: nowrap;
        overflow-x: auto;
        margin: 0 -2px;
        display: block!important;
        // flex-flow: row wrap;
        li:nth-child(3n) {
          margin-right: 0;
        }
        li {
          display: inline-block;
          width: 16.6% !important;
          margin-right: 4%;
          margin-bottom: 3%;
          // background: url(../assets/newimg/selebg.png) no-repeat;
          background-size: 100% 100%;
          opacity: 0.8;
          padding: 5px 5px 10px;
          height: 60px !important;
          box-sizing: border-box;
          position: relative;
          vertical-align: top;
          .threelist-warp {
            .threelist-img {
              width: 53px;
              height: 46px;
              display: flex;
              overflow: hidden;
              align-items: flex-start;
              img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
              }
            }
            .threelist-img2 {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: -1;
              img {
                width: 100%;
                // height: 100px;
              }
            }
            .threelist-name {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: auto;
                height: 15px;
              }
              span {
                margin-left: 5px;
                color: #e9b10e;
              }
            }
          }
        }
        :hover {
          cursor: pointer;
        }
      }
    }
    .sty-three-btn1 {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }
    .sty-three-btn {
      text-align: center;
      padding: 15px 35px;
      // border-radius: 5px;
      background: url(../assets/newimg/chuangjianbtn.png) no-repeat;
      background-size: 100% 100%;
      margin-top: 25px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      // border-color: #e9b10e;
    }
    .sty-three-btn:hover {
      cursor: pointer;
    }
  }
}

.sty {
  /deep/ .el-drawer {
    background: none;
  }
  /deep/ .el-drawer__wrapper {
    top: 60px;
  }
  /deep/ .el-drawer__header {
    display: none;
  }
  /deep/ .el-drawer__body {
    // background: url(../assets/newimg/selebox.png) no-repeat;
    background: rgb(13, 13, 14);
    background-size: 100% 100%;
  }
}

.ripple {
  position: relative;
  //隐藏溢出的径向渐变背景
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  //设置径向渐变
  background-image: radial-gradient(
    circle,
    rgb(163, 162, 162) 10%,
    transparent 10.01%
  );
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(20, 20);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  //设置初始状态
  transition: 0s;
}

.lucky /deep/ .el-drawer__open .el-drawer.rtl {
  overflow-y: scroll;
}

//底部弹框
.box-bot {
  .box-img {
    padding: 50px;
    box-sizing: border-box;

    .box-img-title {
      .span1 {
        font-size: 20px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #ffc400;
        border-bottom: 1px solid #ffc400;
        padding-bottom: 15px;
      }
    }

    .box-img-list {
      margin-top: 40px;
      overflow-y: scroll;
      max-height: 625px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li:nth-child(5n) {
          margin-right: 0;
        }
        li {
          width: 220px;
          margin-right: 22px;
          // background-image: url("../assets/newimg/img_48.png");
          background-size: 100% 100%;
          padding-bottom: 20px;
          margin-bottom: 20px;
          background: url(../assets/newimg/img_48.png) no-repeat;
          background-size: 100% 100%;
          .top-gang {
            width: 100%;
            height: 12px;
            // background-color: #fff;
            border-radius: 20px 20px 0 0;
          }
          .box-img1 {
            width: 220px;
            height: 150px;
            img {
              width: 200px;
              height: 150px;
            }
          }
          .box-img-price {
            display: flex;
            align-items: center;
            justify-content: center;
            // margin: 10px 0;
            img {
              width: 12px;
              height: 12px;
            }
            span {
              color: #fff;
              font-size: 12px;
              margin-left: 7px;
            }
          }
          .box-img-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
  .box-img-list::-webkit-scrollbar {
    width: 4px;
  }
  .box-img-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
  }
  .box-img-list::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 767px) {
  .box-img-list {
    ul {
      li {
        width: 30% !important;
        height: 34vw !important;
        margin-right: 10px !important;

        .box-img-price{
          margin-top: -10px !important;
        }

        .box-img1{
          margin: 0 auto;
          width: 80% !important;
          height: auto !important;
          object-fit: contain;

          img{
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }
  }
  //  .el-drawer .btt{
  //   height: 200px !important;
  //  }
}
</style>
