<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="roomleft-num">
        <div class="nav_item" style="cursor: pointer" @click="goLucky">
          <i class="back el-icon-arrow-left"></i>
          返回
        </div>
      </div>
      <p v-if="type == 1" style="color: #fff; margin-top: 30px">用户协议</p>
      <p v-if="type == 2" style="color: #fff; margin-top: 30px">隐私政策</p>
      <p v-if="type == 3" style="color: #fff; margin-top: 30px">反洗钱协议</p>
      <div class="rule-text">
        <div class="border"></div>
        <div v-if="type == 1" class="text-title">用户协议</div>
        <div v-if="type == 2" class="text-title">隐私政策</div>
        <div v-if="type == 3" class="text-title">反洗钱协议</div>
        <div class="con" v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      content: "",
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.getData();
  },
  methods: {
    goLucky() {
      this.$router.push({ path: "/" });
    },
    getData() {
      let _this = this;
      _this
        .$post("/api/common/get_config")
        .then((res) => {
          if (res.code == 1) {
            if (_this.type == 1) {
              _this.content = res.data.user_agreement;
            } else if (_this.type == 2) {
              _this.content = res.data.privacy_agreement;
            } else {
              _this.content = res.data.fanxiqian;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.doubt {
  // width: 100%;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  .doubt-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }

  .rule-text {
    margin-top: 20px;
    height: 777px;
   
    // border: 3px solid #ffc400;
    background: url(../assets/newimg/rulebg.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 50px;
    // padding-top: 200px;
    .border {
      width: 100%;
      height: 16px;
      // background: #ffc400;
      margin-bottom: 20px;
    }
    .text-title {
      padding: 10px 0;
      color: #fff;
      font-size: 16px;
    }
    p {
      font-size: 14px;
      color: #fff;
      padding: 8px 0;
    }
  }
 
  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 16px;
    // border-bottom: 3px solid #ffc400;
    .nav_item {
      width: 156px;
      height: 61px;
      text-align: center;
      line-height: 61px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
      cursor: pointer;
      position: relative;
      .back {
        position: absolute;
        left: 15px;
        font-size: 24px;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }
  }
  .con {
    height: 78%;
    overflow-x: hidden;
    overflow-y: scroll;
    color: #fff;
  }
  .con::-webkit-scrollbar {
    height: 8px;
  }
  .con::-webkit-scrollbar-thumb {
    // height: 2px;
    border-radius: 10px;
    background: rgba(210, 206, 206, 0.2);
  }
  .con::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(58, 59, 61, 0.2);
  }
}
</style>
