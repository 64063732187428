<template>
  <div class="spread">
    <div class="spread-warp">
      <div class="spread-name">
        <div class="nav_item">推广中心</div>
      </div>

      <div class="spread-input">
        <div class="input-con">
          <div style="display: flex;">
            <div class="input-top">推广链接</div>
            <div class="input-bot">
              <input type="text" :value="valueUrl" disabled />
            </div>
          </div>
          <div class="fuzhi">
            <span @click="copyText(valueUrl)">复制</span>
          </div>
        </div>
        <div class="input-con input-con1">
          <div style="display: flex;">
            <div class="input-top">我的推广码</div>
            <div class="input-bot">
              <input type="text" v-model="valueCode" disabled />
            </div>
          </div>
          <div class="fuzhi">
            <span @click="copyText(valueCode)">复制</span>
          </div>
          
        </div>
      </div>
      <div class="list">
        <div class="head">
          <div class="li">推广人数</div>
          <div class="li">佣金比例</div>
          <div class="li">我的佣金</div>
          <div class="li">推广充值人数</div>
          <div class="li">推广充值总金额</div>
        </div>
        <div class="tablist">
          <div class="tab">{{ table.people_num }}</div>
          <div class="tab">{{ table.ratio }}</div>
          <div class="tab">{{ table.invite_commission }}</div>
          <div class="tab">{{ table.top_up_num }}</div>
          <div class="tab">{{ table.money_count }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valueUrl: "https://zeroskins.com/invite/M2nKD64beM",
      valueCode: "",
      table:{},
    };
  },
  mounted() {
    this.getList();
    this.getList1(7);
  },
  methods: {
    //我的推广链接和邀请码
    getList() {
      let _this = this;
      _this.$post('/api/invite/inviteInfo').then(res => {
        if (res.status==1) {
          _this.valueUrl = res.data.invite_url;
          _this.valueCode = res.data.invite_code;
        }
      }).catch(function (error) {
        console.log(error);
      });
      
    },
    //我的下线7天/30天/今天/全部
    getList1(days) {
      let _this = this;
      _this.$post('/api/invite/offline').then(res => {
        if (res.code==1) {
          this.table = res.data;
        }
      }).catch(function (error) {
        console.log(error);
      });
      
    },

    //复制
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },

    
  },
};
</script>

<style lang="less" scoped>
.spread {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  .spread-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.3);
  }
  .spread-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // border-bottom: 3px solid #FFC400;
    .nav_item {
      width:156px;
      height:61px;
      text-align: center;
      line-height:61px;
      font-size:16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
    }
  }
  .spread-input {
    margin-top: 30px;
    .input-con {
      width:100%;
      height:57px;
      background: url(../assets/newimg/img_61.png) no-repeat 50%;
      background-size: 100% 100%;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      .fuzhi{
        width:151px;
        height:57px;
        background: url(../assets/newimg/img_52.png) no-repeat 50%;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 16px;
      }
      .input-top {
        font-size: 16px;
        padding: 0 30px;
        color: #fff;
        border-right: 2px solid;
        border-image:linear-gradient(180deg, rgba(221,255,0,0) 0%, #DDFF00 51%, rgba(221,255,0,0) 100%) 2 2 2 2;
      
      }
      .input-bot {
        input {
          padding: 0 30px;
          border-radius: 5px;
          width:600px;
          color: #fff;
          font-size: 16px;
          outline: none;
          box-sizing: border-box;
          border: none;
          background: none;
        }
        span {
          display: inline-table;
          height: 40px;
          margin-left: 20px;
          line-height: 40px;
          padding: 0 20px;
          background-color: #e9b10e;
          border-radius: 5px;
          font-weight: 600;
          color: #1a1c24;
          font-size: 15px;
        }
        span:hover {
          cursor: pointer;
          background-color: #f5c432;
        }
      }
    }
  }
  .list {
    margin-top: 30px;
    .head{
      display: flex;
      .li{
        padding:30px 0;
        width: 20%;
        text-align: center;
        font-size: 16px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
    .tablist{
      width: 100%;
      height: 77px;
      background: url("../assets/newimg/img_95.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      .tab{
        width: 20%;
        text-align: center;
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        border-right: 2px solid;
        border-image:linear-gradient(180deg, rgba(221,255,0,0) 0%, #DDFF00 51%, rgba(221,255,0,0) 100%) 2 2 2 2;
      }
    }
  }
  
}
</style>