<template>
  <div>
    <!-- 第三方链接 -->
    <iframe
      style="border: none"
      :width="searchTableWidth"
      :height="searchTableHeight"
      v-bind:src="reportUrl"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reportUrl: "",
      searchTableHeight: 0,
      searchTableWidth: 0,
    };
  },
  watch: {
    $route: function () {
      // 监听路由变化
      this.reportUrl = this.$route.query.url;
    },
  },
  mounted() {
    this.reportUrl = this.$route.query.url;
    console.log("28999999", this.$route.query.url);
    window.onresize = () => {
      this.widthHeight(); // 自适应高宽度
    };
    this.$nextTick(() => {
      this.widthHeight();
    });
  },
  methods: {
    widthHeight() {
      this.searchTableHeight = window.innerHeight;
      this.searchTableWidth = window.innerWidth;
    },
  },
};
</script>

<style></style>
