import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: localStorage.getItem("id"),
    name: "",
    img: "",
    money: "",
    integral_money: "",
    mobile: "",
    loginState: false,
    informState: true,
    id1: localStorage.getItem("csgoNum"),
    websockReadyState: 2,
    axiosCancelArr: [],
    scro: ''
  },
  mutations: {
    getId(state, obj) {
      state.id = obj.id;
      state.name = obj.name;
      state.img = obj.img;
      state.money = obj.total_amount;
      state.integral_money = obj.score
    },
    getMoney(state, money) {
      state.money = money;
    },
    getScroAll(state, integral_money) {
      state.integral_money = integral_money;
    },
    getScroAllNum(state, scro) {
      state.scro += scro;
    },
    getLogin(state, loginState) {
      state.loginState = loginState;
    },
    getInform(state, informState) {
      state.informState = informState;
    },
    getWebsock(state, websockReadyState) {
      state.websockReadyState = websockReadyState;
    },
    PUSH_CANCEL(state, cancel) {
      state.axiosCancelArr.push(cancel.cancelToken);
    },
  },
  actions: {
    pushCancel({ commit }, cancel) {
      commit('PUSH_CANCEL', cancel)
    },
  },
  modules: {
  }
})

//export default store
