<template>
  <div class="arena">
    <div class="arena-warp">
      <div class="top">
        <div class="pay-nav">
          <div
            class="nav_item"
            :class="nav == 0 ? 'active' : ''"
            @click="changeNav(0)"
          >
            所有ROLL房
          </div>
          <div
            class="nav_item"
            :class="nav == 1 ? 'active' : ''"
            @click="changeNav(1)"
          >
            我的ROLL房
          </div>
        </div>
        <div class="right">
          <el-input
            class="input"
            v-model="content"
            placeholder="请输入关键词"
          ></el-input>
          <p class="search" @click="searchBtn">
            <i class="el-icon-search"></i>
          </p>
        </div>
      </div>
      <img class="duizhangang" src="../assets/newimg/duizhangang.png" alt="" />
      <div class="list-nav" v-if="nav == 0">
        <div
          class="nav"
          :class="status == 1 ? 'active' : ''"
          @click="changeTab(1)"
        >
          进行中
        </div>
        <div
          class="nav"
          :class="status == 2 ? 'active' : ''"
          @click="changeTab(2)"
        >
          已结束
        </div>
        <div class="wfjs" @click="toInfo">玩法介绍</div>
      </div>
      <div class="list-nav" v-if="nav == 1">
        <div
          class="nav"
          :class="status == 1 ? 'active' : ''"
          @click="changeTab2(1)"
        >
          进行中
        </div>
        <div
          class="nav"
          :class="status == 2 ? 'active' : ''"
          @click="changeTab2(2)"
        >
          已结束
        </div>
        <div class="wfjs" @click="toInfo">玩法介绍</div>
      </div>
      <div class="list" v-if="nav == 0">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
            @click="jionRoom(item.id)"
          >
            <div class="title">{{ item.room_name }}</div>
            <div class="list-warp">
              <!-- <div class="tou-warp">
                <img src="../assets/newimg/img_01.png" />
                <div class="tou-warp-back"></div>
              </div> -->

              <div class="tou">
                <div style="display: flex; align-items: center">
                  <div class="zhubo">
                    <span>{{ item.type==1?'官方':'主播' }}</span>
                  </div>
                  <!-- <img src="../assets/newimg/img_79.png" alt="" /> -->
                  <!-- <img :src="item.img" /> -->
                  <!-- {{ item.type == 1 ? "官方" : "主播" }} -->
                  <!-- <span>{{ item.room_name }}</span> -->
                </div>
                <img
                  class="join-img"
                  src="../assets/newimg/img_80.png"
                  alt=""
                />
              </div>
              <div class="time">
                开奖时间 :
                <span style="color: #d800ff">{{ item.run_lottery_time }}</span>
              </div>
              <div class="num">
                <div class="num-box">
                  <!-- <img src="../assets/newimg/img_81.png" alt="" /> -->
                  <span>饰品数量</span>
                  <span class="num-span">{{ item.count }}</span>
                </div>
                <div class="num-box">
                  <!-- <img src="../assets/newimg/img_82.png" alt="" /> -->
                  <span>参与人数</span>
                  <span class="num-span">{{ item.person_num }}</span>
                </div>
                <div class="num-box">
                  <!-- <img src="../assets/img/money.png" style="width: 15px" /> -->
                  <span>饰品总价值</span>
                  <span class="num-span">{{ item.pool }}</span>
                </div>
              </div>
              <div class="img">
                <!-- <div class="imgs"> -->
                <div
                  class="img-box"
                  v-for="(item1, index1) in item.skin_list"
                  :key="index1"
                >
                  <img :src="item1.imageUrl" />
                </div>
                <!-- </div> -->
              </div>
              <!-- <div class="btn">
                <div
                  class="btn-warp"
                  @click="jionRoom(item.id)"
                  v-if="item.status == 1"
                >
                  <img
                    v-if="item.condition_type == 1 || item.condition_type == 3"
                    src="../assets/img/suo.png"
                  />
                  <span>加入房间</span>
                </div>
                <div class="btn-warp" v-if="item.status == 2">
                  <span @click="jionRoom(item.id)">已结束</span>
                </div>
              </div> -->
              <!-- <div
                class="back1"
                v-if="item.status == 2"
                @click="jionRoom(item.id)"
              ></div> -->
            </div>
          </li>
        </ul>
      </div>
      <div class="list" v-if="nav == 1">
        <ul>
          <li v-for="(item, index) in list1" :key="index" @click="jionRoom(item.id)">
            <div class="title">{{ item.room_name }}</div>
            <div class="list-warp">
              <!-- <div class="tou-warp">
                <img :src="item.img" />
                <div class="tou-warp-back"></div>
              </div> -->
              <div class="tou">
                <div style="display: flex; align-items: center">
                  <div class="zhubo">
                    <span>{{ item.type==1?'官方':'主播' }}</span>
                  </div>
                </div>
                <img
                  class="join-img"
                  src="../assets/newimg/img_80.png"
                  alt=""
                />
              </div>
              <div class="time">
                开奖时间 :
                <span style="color: #ffc400">{{ item.run_lottery_time }}</span>
              </div>
              <div class="num">
                <div class="num-box">
                  <img src="../assets/newimg/img_81.png" alt="" />
                  <span>饰品数量</span>
                  <span class="num-span">{{ item.count }}</span>
                </div>
                <div class="num-box">
                  <img src="../assets/newimg/img_82.png" alt="" />
                  <span>参与人数</span>
                  <span class="num-span">{{ item.person_num }}</span>
                </div>
                <div class="num-box">
                  <img src="../assets/img/money.png" style="width: 15px" />
                  <span>饰品总价值</span>
                  <span class="num-span">{{ item.pool }}</span>
                </div>
              </div>
              <div class="img">
                <div
                  class="img-box"
                  v-for="(item1, index1) in item.skin_list"
                  :key="index1"
                >
                  <img :src="item1.imageUrl" />
                </div>
              </div>
              <!-- <div class="btn">
                <div class="btn-warp" v-if="item.status == 1">
                  <span @click="jionRoom(item.id)">查看房间</span>
                </div>
                <div class="btn-warp" v-if="item.status == 2">
                  <span @click="jionRoom(item.id)">已结束</span>
                </div>
              </div>
              <div class="time">开奖时间 : {{ item.run_lottery_time }}</div>

              <div
                class="back1"
                v-if="item.status == 2"
                @click="jionRoom(item.id)"
              ></div> -->
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="more-data">
        加载更多
      </div> -->
    </div>
  </div>
</template>
<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
export default {
  components: { myhomebot, myinform },
  data() {
    return {
      activeName: "first",
      list: [],
      list1: [],
      nav: 0,
      status: 1,
      content: "",
    };
  },
  methods: {
    changeNav(index) {
      this.nav = index;
      if (this.nav == 0) {
        this.getList();
      } else {
        this.getMyList();
      }
    },
    changeTab(index) {
      this.status = index;
      this.page = 1;
      this.list = [];
      this.getList();
    },
    changeTab2(index) {
      this.status = index;
      this.page = 1;
      this.list1 = [];
      this.getMyList();
    },
    searchBtn() {
      this.page = 1;
      this.list = [];
      this.getList();
    },
    handleClick(tab, event) {
      if (tab.name == "first") {
        this.getList();
      } else {
        this.getMyList();
      }
    },
    //免费皮肤房间列表
    getList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 50,
        status: _this.status,
        keywork: _this.content,
      };
      _this
        .$post("/api/free/freeRoomList", param)
        .then((res) => {
          var data = res;
          if (data.status == 1) {
            this.list = data.data.list;
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].person_num == null) {
                this.list[i].person_num = 0;
              }
              if (this.list[i].count == null) {
                this.list[i].count = 0;
              }
              if (this.list[i].pool == null) {
                this.list[i].pool = 0;
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //我参与的房间列表
    getMyList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 50,
        status: _this.status,
      };
      _this.$post("/api/free/myFreeRoom", param).then((res) => {
        var data = res;
        if (data.status == 1) {
          this.list1 = data.data.list;
          for (let i = 0; i < this.list1.length; i++) {
            if (this.list1[i].person_num == null) {
              this.list1[i].person_num = 0;
            }
            if (this.list1[i].count == null) {
              this.list1[i].count = 0;
            }
            if (this.list1[i].pool == null) {
              this.list1[i].pool = 0;
            }
          }
        }
      });
    },
    //加入房间
    jionRoom(id) {
      if (!this.$store.state.id) {
        this.$store.commit("getLogin", true);
        return;
      }
      this.$router.push({
        path: `/ArenaRoom`,
        query: {
          id: id,
        },
      });
    },
    toInfo() {
      this.$router.push({ path: `/ArenaInfo` });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.arena {
  width: 100;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .arena-warp {
    // width: 1400px;
    max-width: 1200px;
    // width: 90%;
    min-height: 100%;
    padding: 30px 0;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: wrap;
      // border-bottom: 3px solid #ffc400;
      .right {
        width: 252px;
        height: 36px;
        border: 2px solid #fc00ff;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /deep/ .el-input__inner {
          background: none;
          border: none;
          height: 36px;
          line-height: 36px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;
        }
        .search {
          width: 73px;
          height: 36px;
          background: #fc00ff;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    .duizhangang {
      width: 100%;
      margin-top: 10px;
    }
    .pay-nav {
      height: 51px;
      //margin-top:20px;
      color: #fff;
      display: flex;
      align-items: center;
      position: relative;
    }
    .nav_item {
      width: 164px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 18px;
      background-size: contain;
      font-weight: 700;
      cursor: pointer;
    }
    .nav_item:first-child {
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ffffff;
    }
    .nav_item:first-child.active {
      height: 68px;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #000;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      line-height: 68px;
    }
    .nav_item:nth-child(2).active {
      height: 68px;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #000;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      line-height: 68px;
    }
    .nav_item:nth-child(2) {
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      margin-left: -15px;
      background-size: 100% 100%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ffffff;
    }

    .tabs /deep/ .el-tabs__item.is-active {
      color: #c3c3e2;
    }
    .tabs /deep/ .el-tabs__item {
      color: #848492;
      font-size: 20px;
    }
    .tabs /deep/ .el-tabs__nav-wrap::after {
      background-color: #1a1c24;
    }
    .more-data {
      width: 109px;
      height: 36px;
      background: url(../assets/newimg/img_15.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      margin: 0 auto;
    }
    .list-nav {
      display: flex;
      align-items: center;
      margin: 15px 0;
      position: relative;
      .wfjs {
        width: 103px;
        height: 36px;
        background: #302007;
        border-radius: 6px 6px 6px 6px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
        position: absolute;
        right: 0;
        cursor: pointer;
      }
      .nav {
        width: 84px;
        height: 34px;
        // /chuangjian
        background: url(../assets/newimg/guize.png) no-repeat 50%;
        background-size: 100% 100%;
        text-align: center;
        line-height: 34px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #ffffff;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;
        text-align: center;
        justify-content: center;
      }
      .active {
        width: 98px;
        height: 42px;
        line-height: 43px;
        background: url(../assets/newimg/chuangjian.png) no-repeat 50%;
        background-size: 100% 100%;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        font-style: italic;
        color: #ffffff;
      }
    }
    .list {
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 45%;
          position: relative;
          background: url(../assets/newimg/img_78.png) no-repeat;
          background-size: 100% 100%;
          padding: 60px 40px 25px;
          box-sizing: border-box;
          position: relative;
          margin-bottom: 10px;
          .title {
            // position: absolute;
            // top: 0;
            // left: 40%;
            width: 100%;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            font-style: italic;
            color: #68017a;
            // margin: 0 auto;
            text-align: center;
            // margin-top: -58px;
            position: absolute;
            top: 6px;
            left: 0;
          }
          .list-warp {
            overflow: hidden;
            position: relative;
        
            .tou-warp {
              width: 100%;
              height: 52px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .tou-warp-back {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 130px;
              }
            }

            .back1 {
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
              position: absolute;
              top: 0;
              left: 0;
            }
            .tou {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 20px;
              .join-img {
                width: 39px;
                height: 42px;
                cursor: pointer;
              }
              .zhubo {
                width: 112px;
                height: 61px;
                background: url(../assets/newimg/img_79.png) no-repeat;
                background-size: 100% 100%;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                span {
                  font-style: italic;
                  color: #ff5cfd;
                  line-height: 61px;
                  text-align: right;
                  width: 100%;
                  padding-left: 45px;
                }
              }

              span {
                color: #fff;
                font-size: 14px;
                margin-left: 18px;
              }
            }
            .name {
              position: relative;
              z-index: 66;
              padding: 0 16px;
              margin-top: 5px;
              font-size: 16px;
              color: #c3c3e2;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .num {
              position: relative;
              z-index: 66;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .num-box {
                display: flex;
                align-items: center;
                img {
                  margin-right: 10px;
                }
                span {
                  color: #fff;
                  font-size: 14px;
                }
                .num-span {
                  color: #d800ff;
                  margin-left: 15px;
                }
              }
            }
            .img {
              width: 100%;
              margin-top: 20px;
              display: flex;
              flex-wrap: wrap;
              background: url(../assets/newimg/guanfangkuang.png) no-repeat;
              background-size: 100% 100%;
              padding-top: 8px;
              .img-box:nth-child(3n) {
                margin-right: 0;
              }
              
              .img-box {
                width: 30.33%;
                position: relative;
                text-align: center;
                border-radius: 10px 10px 10px 10px;
                background: linear-gradient(
                  180deg,
                  rgba(10, 156, 211, 0.3),
                  rgba(24, 50, 48, 0.3)
                );
                border: 2px solid #0a8ed3;
                margin-right: 9px;
                margin-bottom: 9px;

                img {
                  width: 100%;
                  height: 82px;
                }
                span {
                  position: absolute;
                  top: 15px;
                  left: 50%;
                  margin-left: -25px;
                  color: #fff;
                  padding: 0 10px;
                  border-radius: 20px;
                  background-color: rgba(0, 0, 0, 0.3);
                  font-size: 10px;
                }
              }
              .img-box:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                margin-left: 10px;
              }
              .img-box:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
              }

              // padding: 0 5px;
            }
            .btn {
              padding: 0 16px;
              margin-top: 20px;
              display: flex;
              justify-content: center;
              .btn-warp {
                display: flex;
                align-items: center;
                border: 1px solid #8a6f22;
                padding: 5px 25px;
                border-radius: 20px;

                img {
                  width: 16px;
                  height: 16px;
                }
                span {
                  margin-left: 10px;
                  font-size: 14px;
                  color: #8a6f22;
                }
              }
              .btn-warp:hover {
                cursor: pointer;
                border: 1px solid#e9b10e;
                background-color: #e9b20e31;
              }
              .btn-warp:hover span {
                color: #e9b10e;
              }
            }

            .time {
              font-size: 14px;
              color: #fff;
              margin-top: 20px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
