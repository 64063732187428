<template>
  <div
    id="app"
    v-title
    data-title="v8steam csgo开箱 csgo开箱网站 csgo免费开箱_公平开箱"
  >
    <router-view v-if="reload" />
  </div>
</template>

<script>
import Utils from "./assets/js/util.js";
import { log } from "util";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
      userInfo: {},
    };
  },
  watch: {
    "$route.path": {
      handler(toPath, fromPath) {
        const token = localStorage.getItem("token");
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log("上一个路由地址66666：", userInfo);
        if (toPath != "/Index" && token == null) {
          this.$store.commit("getLogin", true);
        }
      },
      deep: true, // 深度监听
      // immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  // this.$bus.$on("openQQnum", () => {
  //     console.log('666666');
  //     this.dialogBox2=true
  //   });
  data() {
    return {
      isRouterAlive: true,
    };
  },
  mounted() {
    let _this = this;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    _this.userInfo = userInfo;
    _this.getUserInfo();
    Utils.$on("pid", function (pid) {
      console.log(pid);
      _this.userInfo.id = pid;
      _this.getUserInfo();
    });
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getUserInfo() {
      let param = {
        player_id: this.userInfo ? this.userInfo.id : "",
      };
      if (param.player_id) {
        this.$axios
          .post("/index/User/getPlayerInfo", this.$qs.stringify(param))
          .then((res) => {
            // console.log(res.data.data);
            if (res.data.data.status == 1) {
              // console.log(res.data.data.total_amount);
              Utils.$emit("money", res.data.data.total_amount);
              Utils.$emit("state", res.data.data.state);
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            }
          });
      }
    },
  },
  components: {},
};
</script>

<style lang="less">
@import "./assets/css/panda.css";
@import "./assets/css/bootstrap.min.css";
@import "./assets/css/common.css";
@import "./assets/css/element.css";
@import "./assets/css/media.less";
//改
div,
blockquote,
body,
html,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

html,
body,
#app {
  //  height: 100%;
  height: calc(~"100vh - 75px");
  //  height: calc(~"100vh");
  font-family: "Microsoft YaHei";
  // overflow-y: scroll;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body::-webkit-scrollbar {
  display: none;
}
.clear {
  clear: both;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
html /deep/ .v-modal {
  top: 60px;
}

//邮箱弹框 样式修改
html /deep/ .el-message-box {
  background-color: #333542;
  border: none;
  .el-message-box__title {
    color: #c3c3e2;
  }
}
a {
  text-decoration: none;
}
.el-popup-parent--hidden {
  padding: 0 !important;
}
.dota-select {
  // border: 1px solid #ffc400 !important;
  // background: #221c12 !important;
  background: transparent;
  min-width: 178px !important;
  background: url(./assets/newimg/selectbg.png) no-repeat;
  background-size: 100% 100%;
  .el-select-dropdown__item {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00e4ff;
    // color: #fff !important;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background: none;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover span {
    color: #00e4ff;
  }
  .el-select-dropdown__item.selected {
    color: #00e4ff !important;
  }
  .popper__arrow {
    display: none !important;
  }
}
</style>
