/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import router from '../router';
import store from '../store/index';
import { Message } from 'element-ui';
import base from './base';
import md5 from 'js-md5';

function throttle(func, delay) {
  let timerId;
  return function() {
    if (!timerId) {
      timerId = setTimeout(() => {
        func.apply(this, arguments);
        timerId = null;
      }, delay);
    }
  };
}

const throttledScroll = throttle(()=>{
  Message({
    message: '请登录后操作',
    type: 'error',
    duration: 3 * 1000,
    dangerouslyUseHTMLString: false
  });
}, 500);

axios.defaults.baseURL = base.sq;

axios.interceptors.request.use(
  config => {
    config.headers.token = localStorage.getItem('token')
    config.cancelToken = new axios.CancelToken((cancel) => {
      //使用vuex 定义pushCancel，请求进来存入
      store.dispatch('pushCancel', { cancelToken: cancel })
    });

    return config
  },
  error => {
    console.log(error)
  }
)
//响应拦截器
axios.interceptors.response.use((response) => {
  // console.log('6666', response.config.url,response)
  if (response.status === 200) {
    // console.log('响应拦截6666', response);
    // if(response,)
    let result = response.data
    if (result.code == 0) {
      const msg = result.msg;
      Message({
        message: msg,
        type: 'error'
      });
      return
    }
    if (result.code !== 1 && result.code) {
      const msg = result.msg;
      //判断登陆状态， 取消后续请求
      if (result.code === -1) {
        localStorage.removeItem("token");
        localStorage.removeItem("csgoNum");
        localStorage.removeItem("csgoPass");
        localStorage.removeItem("userInfo");
        store.commit("getId", { name: "", id: "", img: "", money: "" });
        Message({
          message: msg,
          type: 'error'
        });

        router.push({ name: "Index" });
        return false
      } else if (result.code == -100) {
        return response
      }
      Message({
        message: msg,
        type: 'error'
      });
    } else {
      return response
    }
  } else {
    console.log(`Error Message:${response.status} ${response.statusText}`);
  }
}, (error) => {
  if (axios.isCancel(error)) {
    // 使用isCancel 判断是否是主动取消请求
    return new Promise(() => { });
  } else {
    let _errorMsg = error.response ? error.response.data.msg : error.message;
    if (_errorMsg !== '' && _errorMsg !== null) {
     if(error.response.data.code==401){
        throttledScroll();
     }else{
      Message({
        message: _errorMsg,
        type: 'error',
        duration: 3 * 1000,
        dangerouslyUseHTMLString: false
      });
     }
    }
    return Promise.reject(error);
  }

})


export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    let time = parseInt(new Date().getTime() / 1000);
    let sign = md5('cs#.go' + time);
    data.sign = sign;
    data.time = time;
    axios.get(url, {
      params: params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    let time = parseInt(new Date().getTime() / 1000);
    let sign = md5('cs#.go' + time);
    data.sign = sign;
    data.time = time;
    axios.post(url, data)
      .then(response => {
        // console.log('data11111', response);
        resolve(response.data);
      }, err => {
        reject(err)
      })
  })
}
