<template>
  <div class="openbox">
    <div class="mask" v-if="winStateMask"></div>
    <!--中奖完成后盒子-->
    <div class="win-box" v-if="winState">
      <div class="win">
        <div class="win-top">
          <img src="../assets/newimg/img_50.png" />
        </div>
        <div class="win-con">
          <ul>
            <li v-for="item in mhInfo" :key="item.id">
              <div class="win-warp">
                <div class="win-img">
                  <img :src="item.img" />
                </div>
                <p style="color: #fff">{{ item.name }}</p>
                <div
                  style="
                    height: 28px;
                    display: flex;
                    align-self: center;
                    align-items: center;
                  "
                >
                  <img
                    src="../assets/img/money.png"
                    style="margin-right: 10px; margin-top: 5px"
                  />
                  <p style="color: #fff">{{ item.price }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="win-text">
          <!-- <p>{{ mhInfo.name }}</p> -->
          <p style="color: #ddff00">如看商品请到背包中查看！</p>
        </div>
        <div class="win-x" @click="winX">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
      </div>
    </div>

    <div class="dhImg" v-if="winStateBtnX">
      <img src="../assets/newimg/img_54.png" alt="" />
    </div>
    <div class="dhImg" v-if="winStateBtnY">
      <img src="../assets/newimg/img_53.png" alt="" />
    </div>
    <div class="kai_bg">
      <div class="top">
        <div class="pay-nav">
          <div
            class="nav_item"
            :class="nav == 0 ? 'active' : ''"
            @click="changeNav(0, 3)"
          >
            节日盲盒
          </div>
          <div
            class="nav_item"
            :class="nav == 1 ? 'active' : ''"
            @click="changeNav(1, 4)"
          >
            注册盲盒
          </div>
          <div
            class="nav_item"
            :class="nav == 2 ? 'active' : ''"
            @click="changeNav(2, 5)"
          >
            等级盲盒
          </div>
        </div>
        <div class="right">
          <span class="right-span" @click.stop="informShow = true"
            >玩法介绍</span
          >
        </div>
      </div>
      <!-- 等级盲盒 -->
      <div v-if="nav == 2">
        <div class="lever-box">
          <div
            class="box"
            v-for="(item, index) in djList"
            :key="index"
            @click="changTab(index, item)"
          >
            <div class="item" :class="tab == index ? 'active' : ''">
              <p>{{ item.name }}</p>
              <div class="img1">
                <img v-if="item.box" :src="item.box.img_main" alt="" />
              </div>
              <div class="img2">
                <img
                  v-if="item.box"
                  :src="item.box.img_active"
                  alt=""
                />
              </div>
            </div>
            <div class="btn" v-if="item.is_get == 0 && item.difference != 0">
            还需充值<span style="color: #ffc400">{{ item.difference }}</span
              >获取
            </div>
            <div
              class="btn2"
              style=""
              @click="getPay(item.box_id, item.id)"
              v-if="item.is_get == 0 && item.difference == 0"
            >
              <span style="color: #fff">开启</span>
            </div>
            <div class="btn" v-if="item.is_get == 1">已领取</div>
          </div>
        </div>
        <div class="lever-kai">
          <div class="conlist-warp">
            <div class="conlist-box">
              <img v-if="djData.box" :src="djData.box.img_main" />
            </div>
            <div v-if="djData.box" class="conlist-box1">
              <img :src="djData.box.img_active" />
            </div>
          </div>
          <div class="con-bottom">
            <div
              class="btn"
              v-if="djData.is_get == 0 && djData.difference != 0"
            >
              还需充值<span style="color: #ffc400">{{ djData.difference }}</span
              >获取
            </div>
            <div class="btn" v-if="djData.is_get == 1">已领取</div>
            <div
              class="kai-btn"
              v-if="
                Number(myBalance) > Number(djData.money) &&
                djData.is_get == 0 &&
                djData.difference == 0
              "
              @click="getPay(djData.box_id, djData.id)"
            >
              开启
            </div>
          </div>
        </div>
      </div>

      <div class="kai-box" v-if="nav == 0 || nav == 1">
        <div class="kai-box-open2" v-if="openBoxState && kaiBox == 1">
          <div class="kai-box-bg">
            <div class="kaibox-line">
              <img src="../assets/newimg/img_56.png" alt="" />
            </div>
            <div class="kai-warp">
              <ul>
                <li v-for="(item, index) in imgList" :key="index">
                  <img :src="item.img" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="kai-top" v-if="nav == 0">
          <span>{{ jrData.name }}</span>
        </div>
        <div class="kai-con">
          <div class="con-main" :class="nav == 0 ? 'bg' : ''">
            <div class="con-list">
              <ul>
                <li>
                  <div class="conlist-warp">
                    <div class="conlist-box">
                      <img
                        v-if="jrData.box"
                        :src="jrData.box.img_main"
                      />
                    </div>
                    <div v-if="jrData.box" class="conlist-box1">
                      <img :src="jrData.box.img_active" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="con-bottom">
              <div class="con-num" v-if="nav == 0">
                <ul>
                  <li>
                    <span class="num">{{ day }}</span>
                    <p>天</p>
                  </li>
                  <li>
                    <span class="num">{{ hr }}</span>
                    <p>时</p>
                  </li>
                  <li>
                    <span class="num">{{ min }}</span>
                    <p>分</p>
                  </li>
                  <li>
                    <span class="num">{{ sec }}</span>
                    <p>秒</p>
                  </li>
                </ul>
              </div>
              <div class="con-input" v-if="nav == 1">
                <div class="list-input">
                  <!-- <span class="list-span1" @click="remInput"
                    ><i class="el-icon-minus"></i
                  ></span> -->
                  <el-input class="input" v-model="boxpwd" />
                  <!-- @input="getInput" -->
                  <!-- :disabled="true" -->
                  <!-- <span class="list-span2" @click="addInput"
                    ><i class="el-icon-plus"></i
                  ></span> -->
                </div>
                <!-- <div class="big" @click="bigInput">最大</div> -->
              </div>
              <!-- <p class="sy-box" v-if="nav == 1">
                您还剩余<span style="color: #ffc400">{{
                  jrData.promotion_box_num
                }}</span
                >个盒子
              </p> -->
            </div>
          </div>
          <!-- && showBtn -->
          <el-button
            v-if="nav == 0 && showBtn"
            class="con-btn2"
            @click="getPay(jrData.box_id, jrData.id)"
            >开启</el-button
          >
          <el-button
            v-if="nav == 1"
            class="con-btn2"
            @click="getBox(jrData.box_id, jrData.id)"
            >开启</el-button
          >
        </div>
      </div>
      <div class="box-list">
        <div class="boxlist-top">
          <div class="left">
            <span class="span">包含以下皮肤</span>
          </div>
        </div>
        <div class="clear"></div>

        <div class="boxlist-bot">
          <ul>
            <li v-for="(item, index) in skin_list" :key="index">
              <!-- <div
                :style="{ backgroundColor: item.all_skin.bg_color }"
                class="top-gang"
              ></div> -->
              <div class="boxlist-warp">
                <div class="boxlist1-top">
                  <p class="bi">{{ item.probability }}%</p>
                  <img
                    v-if="item.all_skin.imageUrl"
                    :src="item.all_skin.imageUrl"
                  />
                </div>
                <div class="boxlist1-price">
                  <img src="../assets/img/money.png" />
                  <span>{{ item.all_skin.price }}</span>
                </div>
                <div class="boxlist1-bot" :title="item.all_skin.itemName">
                  {{ item.all_skin.itemName }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="clear"></div>

        <div class="clear"></div>
      </div>
    </div>
    <!-- 玩法介绍 -->
    <div class="box-inform" v-if="informShow">
      <div class="informs">
        <div class="new-x" @click="closeBot">
          <img src="../assets/newimg/img_02.png" alt="" />
        </div>
        <div class="box-img-inform" style="color: #fff" v-html="playInfo"></div>
      </div>
    </div>
    <!-- 分享-->
    <el-dialog
      title="分享链接"
      :visible.sync="dialogFormVisible"
      width="200px"
      class="share-hide"
    >
      <div class="share-btn">
        <el-input v-model="url" autocomplete="off"></el-input>
        <el-button class="btn" type="warning" @click="copyUrl()"
          >复制</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <audio controls ref="notify" class="audio">
      <source src="../assets/audio/open_box2.mp3" />
    </audio>

    <audio controls ref="notify1" class="audio" loop="loop">
      <source src="../assets/audio/open_box1.mp3" />
    </audio>
  </div>
</template>

<script>
import mywin from "@/components/my_win.vue";
import Utils from "./../assets/js/util.js";
export default {
  components: { mywin },
  data() {
    return {
      informShow: false, //玩法介绍
      playInfo: "",
      openPhone: true,
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      cartState: false,
      skin_list1: [],
      winBoxState: false,
      loading: false,
      url: window.location.href,
      dialogFormVisible: false,
      funState: true,
      winState: false,
      winStateMask: false,
      winStateBtnX: false,
      winStateBtnY: false,
      openBoxState: false,
      box_id: "",
      box_name: "",
      box_obj: {},
      price: 0,
      totalPrice: 0,
      skin_list: [],
      skins_types: [],
      value: true,
      kaiBox: 1,
      kaiBoxNum: [
        { num: 1, state: true },
        { num: 2, state: false },
        { num: 3, state: false },
        { num: 4, state: false },
        { num: 5, state: false },
      ],
      winList: [],
      listBox: [],
      imgList: [],
      imgList1: [],
      nav: 0,
      zdyMoney: 1,
      boxpwd: "",
      jrData: {
        box: {
          img_main: "",
        },
      },
      djList: [],
      djData: {
        box: {
          img_main: "",
          img_active: "",
        },
      },
      tab: 0,
      mhType: 3,
      mhInfo: [],
      endTime: "",
      day: "00",
      hr: "00",
      min: "00",
      sec: "00",
      showBtn: false,
      myBalance: 0,
    };
  },
  watch: {
    kaiBox(val) {
      let _this = this;
      _this.totalPrice = (this.price * val).toFixed(2);
    },
  },
  created() {
    this.getJieRi();
  },
  mounted() {
    //判断屏幕宽度
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 600) {
      this.openPhone = false;
    }

    let _this = this;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    _this.myBalance = userInfo.total_amount;
    console.log(userInfo.total_amount);
    // _this.getJieRi();
    // _this.setStyle(5,5)
  },
  methods: {
    closeBot() {
      this.informShow = false;
    },
    futureDay() {
      //this.endTime
      const end = Date.parse(new Date(this.endTime.replace(/-/g, "/")));
      const now = Date.parse(new Date());
      const msec = end - now;

      if (msec >= 0) {
        this.showBtn = true;
        let day = parseInt(msec / 1000 / 60 / 60 / 24);
        let hr = parseInt((msec / 1000 / 60 / 60) % 24);
        let min = parseInt((msec / 1000 / 60) % 60);
        let sec = parseInt((msec / 1000) % 60);
        this.day = day > 9 ? day : "0" + day;
        this.hr = hr > 9 ? hr : "0" + hr;
        this.min = min > 9 ? min : "0" + min;
        this.sec = sec > 9 ? sec : "0" + sec;
        setTimeout(() => {
          this.futureDay();
        }, 1000);
      } else {
        this.showBtn = false;
      }
    },
    changTab(index, item) {
      console.log("666666", item);
      this.tab = index;
      this.djData = item;
      this.skin_list = item.box_skins;
    },
    getInput() {},
    //节日盲盒
    getJieRi() {
      var that = this;
      that
        .$post("/api/Festival_box/box_list")
        .then((res) => {
          if (res.code == 1) {
            console.log("节日盲盒", res);
            that.playInfo = res.data.Festival_inc;
            that.jrData = res.data;
            console.log("that.jrData", that.jrData);
            that.skin_list = res.data.box_skins;
            that.endTime = res.data.end_time_text;
            that.futureDay();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //注册盲盒
    getTui() {
      var that = this;
      that
        .$post("/api/promotion_box/box_list")
        .then((res) => {
          if (res.code == 1) {
            console.log("注册盲盒", res);
            that.playInfo = res.data.promotion_inc;
            that.jrData = res.data;
            console.log(" that.jrData2222", that.jrData);
            that.skin_list = res.data.box_skins;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //等级盲盒
    getLevel() {
      var that = this;
      that
        .$post("/api/grade_box/box_list")
        .then((res) => {
          if (res.code == 1) {
            that.djList = res.data.data?res.data.data:[];
            that.playInfo = res.data.grade_inc;
            console.log("that.djList", that.djList);
            that.skin_list = res.data.data[0].box_skins;
            that.djData = res.data.data[0];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getBox(box_id) {
      let _this = this;
      _this
        .$post("/api/award/get_box", {
          box_id: box_id,
          pwd: this.boxpwd,
        })
        .then((res) => {
          if (res.code == 1) {
            _this.mhInfo = res.data.skins_info;
            console.log(" _this.mhInfo", _this.mhInfo);
            _this.winStateMask = true;
            _this.winState = true;
            _this.boxpwd = "";
            // _this.$message({
            //   message: res.msg,
            //   type: "success",
            // });
          }
        });
    },
    //支付
    getPay(id, ids) {
      console.log("id, ids", id, ids);
      let _this = this;
      _this
        .$post("/api/receive_welfare/get_box", {
          box_id: id,
          type: _this.mhType,
          id: ids,
        })
        .then((res) => {
          if (res.code == 1) {
            //this.$store.commit("getMoney",Number(res.data.data.total_amount).toFixed(2) );
            //Utils.$emit("money", res.data.data.total_amount);
            _this.mhInfo = res.data.skins_info;
            console.log(" _this.mhInfo", _this.mhInfo);
            _this.winStateMask = true;
            _this.winState = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    winX() {
      this.winState = false;
      this.winStateMask = false;
      if (this.mhType == 3) {
        this.getJieRi();
      } else if (this.mhType == 4) {
        this.getTui();
      } else {
        this.getLevel();
      }
    },
    remInput() {
      if (this.zdyMoney > 1) {
        this.zdyMoney--;
      } else {
        return;
      }
    },
    addInput() {
      if (Number(this.zdyMoney) >= Number(this.jrData.promotion_box_num)) {
        return;
      }
      this.zdyMoney++;
    },
    bigInput() {
      this.zdyMoney = this.jrData.promotion_box_num;
    },
    changeNav(index, type) {
      this.nav = index;
      this.mhType = type;
      if (index == 0) {
        this.getJieRi();
      } else if (index == 1) {
        this.getTui();
      } else {
        this.getLevel();
      }
    },

    //最近掉落
    getWinPeo() {
      this.winBoxState = true;
      let param = {
        page: 1,
        pageSize: 32,
        box_id: this.box_id,
      };
      this.$axios
        .post("/index/Box/lately", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.skin_list1 = data.data.list;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
//玩法介绍
.box-inform {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  .informs {
    width: 668px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    background: url(../assets/newimg/rulebg.png) no-repeat;
    background-size: 100% 100%;
    height: 360px;
    padding: 40px;
    .new-x {
      position: absolute;
      top: -12%;
      right: -20px;
      width: 52px;
      height: 45px;
      img {
        width: 52px;
        height: 45px;
      }
    }
  }
}
// .win {
//   width: 668px;
//   //padding: 20px;
//   //background-color: rgba(0, 0, 0, 0.2);
//   position: relative;
//   .win-top {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     margin-bottom: 30px;
//     img {
//       width: 185px;
//       height: 55px;
//     }
//   }
//   .win-con {
//     display: flex;
//     justify-content: center;
//     width: 536px;
//     height: 310px;
//     background: url(../assets/newimg/img_51.png) no-repeat;
//     background-size: 100% 100%;
//     margin: 0 auto;
//     padding-top: 25px;
//     padding-bottom: 25px;
//     box-sizing: border-box;
//     ul {
//       width: 60%;
//       display: flex;
//       justify-content: center;
//       li {
//         width: 80%;
//         border-radius: 5px;
//         overflow: hidden;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         .win-warp {
//           width: 100%;
//           position: relative;
//           .win-img {
//             padding: 10px;
//             img {
//               width: 100%;
//               height: auto;
//             }
//           }
//         }
//       }
//     }
//   }
//   .win-x {
//     position: absolute;
//     top: 7%;
//     right: 0px;
//     width: 52px;
//     height: 45px;
//     cursor: pointer;
//     img {
//       width: 52px;
//       height: 45px;
//     }
//   }
//   .win-text {
//     color: #fff;
//     margin-top: 20px;
//     text-align: center;
//     font-size: 16px;
//     p {
//       margin-bottom: 20px;
//     }
//   }
// }
.openbox {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
  }
  .dhImg {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .win-box {
    position: absolute;
    z-index: 99;
    top: 16%;
    left: 50%;
    z-index: 100;
    transform: translateX(-50%);
    // transform: translate(-50%, -50%) 8s;
    .win {
      width: 668px;
      //padding: 20px;
      //background-color: rgba(0, 0, 0, 0.2);
      position: relative;
      .win-top {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        img {
          width: 185px;
          height: 55px;
        }
      }
      .win-con {
        display: flex;
        justify-content: center;
        width: 400px;
        height: auto;
        aspect-ratio: 800/606;
        background: url(../assets/newimg/img_51.png) no-repeat;
        background-size: 100% 100%;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 10px;
        box-sizing: border-box;
        ul {
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          li {
            width: 80%;
            border-radius: 5px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .win-warp {
              width: 100%;
              position: relative;
              display: flex;
              flex-direction: column;
              p{
                text-align: center;
              }
              .win-img {
                padding: 10px;
                img {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }
      }
      .win-x {
        position: absolute;
        top: 10%;
        right: 5%;
        width: 52px;
        height: 45px;
        cursor: pointer;
        img {
          width: 30px;
          height: auto;
          object-fit: fill;
        }
      }
      .win-text {
        color: #fff;
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
  .switch-name {
    color: #848492;
    font-size: 16px;
    margin-right: 5px;
  }
  .switch2 {
    margin-right: 10px;
  }

  .audio {
    display: none;
  }
  .kai_bg {
    // width: 90%;
    max-width: 1200px;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
    .lever-kai {
      width: 100%;
      height: 1024px;
      background: url(../assets/newimg/img_86.png) no-repeat;
      background-size: 100% 100%;
      margin-top: -150px;
      position: relative;
      .conlist-warp {
        width: 20%;
        max-width: 300px;
        position: relative;
        //padding: 14% 0;
        // margin: 0 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        margin: 0px auto;
        padding-top: 265px;
        .conlist-box {
          width: 500px;
          height: auto;
          text-align: center;
          img {
            width: 500px;
            
          }
        }
        .conlist-box1 {
          width: 100%;
          position: absolute;
          left: 0;
          top: 55%;
          left: 0;
          img {
            width: 100%;
          }
        }
      }
      .con-bottom {
        position: absolute;
        bottom: 8%;
        left: 50%;
        transform: translate(-50%, 0);
        .btn {
          width: 149px;
          height: 44px;
          background: url(../assets/newimg/money.png) no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 25px auto 0;
          padding: 0 6px;
        }
        .kai-btn {
          width: 149px;
          height: 44px;
          background: url(../assets/newimg/img_15.png) no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-size: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          margin-top: 30px;
          cursor: pointer;
        }
      }
      .conlist-warp:hover {
        cursor: pointer;
      }
    }
    .lever-box {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;
      position: relative;
      z-index: 99;
      .box:nth-child(5n) {
        margin-right: 0;
      }
      .box {
        width: 17.6%;
        margin-right: 3%;
        cursor: pointer;
        .btn {
          width: 149px;
          height: 44px;
          background: url(../assets/newimg/money.png) no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 25px auto 0;
          padding: 0 6px;
        }
        .btn2 {
          width: 149px;
          height: 44px;
          background: url(../assets/newimg/img_15.png) no-repeat;
          background-size: 100% 100%;
          font-size: 14px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 25px auto 0;
          padding: 0 6px;
        }
        .item.active {
          background: url(../assets/newimg/img_96.png) no-repeat;
          background-size: 100% 100%;
        }
        .item {
          width: 100%;
          background: url(../assets/newimg/img_97.png) no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding: 15px 0 0;
          box-sizing: border-box;
          color: #ffffff;
          p {
            padding: 0 30px;
            z-index: 100;
          }
          .img1 {
            display: flex;
            justify-content: center;
            margin-top: -28px;

            img {
              width: 100%;
            }
          }
          .img2 {
            width: 100%;
            display: flex;
            justify-content: center;
            position: absolute;
            top: 30%;
            img {
              width: 60%;
              height: 60%;
            }
          }
        }
      }
      .box:hover {
        .item {
          background: url(../assets/newimg/img_96.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .pay-nav {
      height: 51px;
      //margin-top:20px;
      color: #fff;
      display: flex;
      align-items: center;
      position: relative;
    }
    .nav_item {
      width: 164px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      background-size: contain;
      font-weight: 700;
      cursor: pointer;
    }
    .nav_item:first-child {
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      background-size: 100% 100%;
    }
    .nav_item:first-child.active {
      width: 145px;
      height: 61px;
      line-height: 61px;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      background-size: 100% 100%;
    }
    .nav_item:nth-child(2).active {
      width: 145px;
      height: 61px;
      line-height: 61px;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      background-size: 100% 100%;
    }
    .nav_item:nth-child(2) {
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      margin-left: 11px;
      background-size: 100% 100%;
    }
    .nav_item:nth-child(3).active {
      width: 145px;
      height: 61px;
      line-height: 61px;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      font-style: italic;
      color: #ff5cfd;
      background-size: 100% 100%;
    }
    .nav_item:nth-child(3) {
      background: url(../assets/newimg/manghe.png) no-repeat 50%;
      margin-left: 11px;
      background-size: 100% 100%;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 3px solid #ffc400;
      .right {
        display: flex;
        align-items: center;

        .right-span {
          width: 103px;
          height: 36px;
          cursor: pointer;
          color: #ffffff;
          text-align: center;
          line-height: 36px;
          background: url(../assets/newimg/guize.png) no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
  .kai-box {
    position: relative;
    margin-top: 80px;
    .kai-share {
      float: right;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
      padding: 9px 22px;
      background-color: #333542;
      border-radius: 5px;
    }
    .kai-share:hover {
      background-color: #3a3f50;
      cursor: pointer;
    }
  }

  //测试开盒子 单个
  .kai-box-open2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;
    .kai-box-bg {
      width: 1060px;
      height: 210px;
      background-image: url("../assets/newimg/img_55.png");
      background-size: 100% 100%;
      position: absolute;
      top: 30%;
      left: 50%;
      margin-left: -540px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .kaibox-line {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 99;
        img {
          width: auto;
          height: 210px;
        }
      }
    }
    .kai-warp {
      width: 1000px;
      height: 125px;
      overflow: hidden;
      position: relative;
      ul {
        position: absolute;
        left: 0;
        top: 0;
        width: 16000px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        z-index: 20;
        animation: run4 10s;
        animation-timing-function: ease; //动画慢 快 慢
        animation-iteration-count: 1; //播放几次动画
        animation-delay: 0s; //动画运行前等待时间
        animation-fill-mode: forwards; //动画结束 是否保持

        li {
          width: 200px;
          height: 125px;
          background-image: url("../assets/newimg/img_59.png");
          background-size: 100% 100%;

          img {
            margin-left: 10%;
            margin-top: 10%;
            width: 80%;
            height: 80%;
          }
        }
        li:nth-child(3n) {
          background-image: url("../assets/newimg/img_57.png");
          background-size: 100% 100%;
        }
        li:nth-child(3n + 1) {
          background-image: url("../assets/newimg/img_58.png");
          background-size: 100% 100%;
        }
        .li1 {
          background-image: url("../assets/newimg/img_58.png");
        }
        .li2 {
          background-image: url("../assets/newimg/img_59.png");
        }
      }
      // @keyframes run4 {
      //   0% {
      //     left: 0;
      //   }
      //   100% {
      //     left: -12000px;
      //   }
      // }
    }
  }
  //测试开多个盒子
  .kai-box-open3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;
    // .kai-box-bg{
    //   width: 100%;
    //   height:210px;
    //   background-image: url("../assets/newimg/img_55.png");
    //   background-size: 100% 100%;
    // }
    .kai-warp1 {
      position: absolute;
      width: 100%;
      height: 200px;
      top: 30%;
      left: 0;
      display: flex;
      justify-content: center;

      .kaibox-line {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 99px;
        left: 0;
        z-index: 99;
        background-color: #e9b10e;
      }
      .kaibox-warp {
        overflow: hidden;
        width: 100%;
        .kaibox-ul {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          .kaibox-li {
            //overflow: hidden;
            width: 15%;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;
                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }
              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }
              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }
      .kaibox-warp2 {
        //width: 600px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 35%;
            animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 49.5%;
            animation-delay: 0.5s !important;
          }
        }
      }
      .kaibox-warp3 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 27.5%;
            animation-delay: 0.15s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 42%;
            animation-delay: 0.3s !important;
          }
          .kaibox-li:nth-child(3) {
            left: 57%;
            animation-delay: 0.45s !important;
          }
        }
      }
      .kaibox-warp4 {
        //width: 1200px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 20%;
            animation-delay: 0.15s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 34.5%;
            animation-delay: 0.3s !important;
          }
          .kaibox-li:nth-child(3) {
            left: 49%;
            animation-delay: 0.45s !important;
          }
          .kaibox-li:nth-child(4) {
            left: 63.5%;
            animation-delay: 0.6s !important;
          }
        }
      }
      .kaibox-warp5 {
        //width: 1500px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 12.5%;
            animation-delay: 0.15s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 27%;
            animation-delay: 0.3s !important;
          }
          .kaibox-li:nth-child(3) {
            left: 41.5%;
            animation-delay: 0.45s !important;
          }
          .kaibox-li:nth-child(4) {
            left: 56%;
            animation-delay: 0.6s !important;
          }
          .kaibox-li:nth-child(5) {
            left: 70.5%;
            animation-delay: 0.75s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  .kai-box-open4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;
    .kai-warp1 {
      position: absolute;
      width: 100%;
      height: 120px;
      top: 30%;
      left: 0;
      display: flex;
      justify-content: center;

      .kaibox-line {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 59px;
        left: 0;
        z-index: 99;
        background-color: #e9b10e;
      }
      .kaibox-warp {
        overflow: hidden;
        width: 100%;
        .kaibox-ul {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          .kaibox-li {
            //overflow: hidden;
            width: 20%;
            position: absolute;
            top: 0;
            left: 0;
            animation: run6 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持
            ul {
              li {
                width: 100%;
                height: 120px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;
                // border: 1px solid #fff;
                img {
                  // margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 100px;
                }
              }
              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }
              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }
      .kaibox-warp2 {
        //width: 600px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 30%;
            animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 49.5%;
            animation-delay: 0.5s !important;
          }
        }
      }
      .kaibox-warp3 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 20%;
            animation-delay: 0.15s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 39.5%;
            animation-delay: 0.3s !important;
          }
          .kaibox-li:nth-child(3) {
            left: 59%;
            animation-delay: 0.45s !important;
          }
        }
      }
      .kaibox-warp4 {
        //width: 1200px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 10%;
            animation-delay: 0.15s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 29.5%;
            animation-delay: 0.3s !important;
          }
          .kaibox-li:nth-child(3) {
            left: 49%;
            animation-delay: 0.45s !important;
          }
          .kaibox-li:nth-child(4) {
            left: 68.5%;
            animation-delay: 0.6s !important;
          }
        }
      }
      .kaibox-warp5 {
        //width: 1500px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }
          .kaibox-li:nth-child(2) {
            left: 19.9%;
            animation-delay: 0.3s !important;
          }
          .kaibox-li:nth-child(3) {
            left: 39.8%;
            animation-delay: 0.45s !important;
          }
          .kaibox-li:nth-child(4) {
            left: 59.7%;
            animation-delay: 0.6s !important;
          }
          .kaibox-li:nth-child(5) {
            left: 79.6%;
            animation-delay: 0.75s !important;
          }
        }
      }

      // @keyframes run6 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -6000px;
      //   }
      // }
    }
  }

  //中奖后盒子

  .kai-top {
    width: 80%;
    height: 89px;
    background: url(../assets/newimg/img_37.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 100px;
    span {
      font-size: 25px;
      color: #ffffff;
      line-height: 89px;
    }
  }
  .kai-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-top: 20px;
    .con-main.bg {
      background: url(../assets/newimg/img_42.png) no-repeat;
    }
    .con-main {
      width: 1094px;
      height: 400px;
      background: url(../assets/newimg/img_84.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
    }
    .con-bottom {
      position: absolute;
      bottom: 2%;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .con-list {
      width: 100%;
      position: relative;
      // top: -22%;
      ul {
        width: 100%;
        display: flex;
        justify-content: center;
        li {
          width: 20%;
          max-width: 300px;
          .conlist-warp {
            position: relative;
            //padding: 14% 0;
            // margin: 0 5px;
            height: 100px;
            text-align: center;
            display: flex;
            justify-content: center;

            .conlist-box {
              width: 500px;
              height: auto;
              text-align: center;
              img {
                width: 500px;
                
              }
            }
            .conlist-box1 {
              width: 100%;
              position: absolute;
              left: 0;
              top: 35%;
              left: 0;
              img {
                width: 100%;
                height: 80%;
              }
            }
          }
        }
        li:hover {
          cursor: pointer;
        }
        li:hover .conlist-box1 {
          position: absolute;
          top: 35%;
          animation: boxhover 1.5s linear 0s infinite alternate;
        }
        @keyframes boxhover {
          0% {
            top: 35%;
          }
          50% {
            top: 20%;
          }
          100% {
            top: 35%;
          }
        }
      }
    }
    .con-num {
      margin-top: 20px;
      ul {
        display: flex;
        justify-content: center;
        li:last-child {
          margin-right: 0;
        }
        li {
          margin-right: 30px;
          span {
            width: 50px;
            height: 63px;
            line-height: 63px;
            text-align: center;
            margin: 0 4px;
            background: url(../assets/newimg/img_441.png) no-repeat;
            background-size: 100% 100%;
            font-size: 30px;
            color: #66ffff;
            display: inline-block;
          }
          p {
            font-size: 16px;
            color: #66ffff;
            text-align: center;
            margin-top: 10px;
          }
        }
        li:hover {
          cursor: pointer;
          // background-color: #444659;
          opacity: 0.8 !important;
        }
        .con-num-check {
          background: url(../assets/newimg/img_431.png) no-repeat;
          background-size: 100% 100%;
          span {
            color: #ffc400;
          }
        }
      }
    }
    .sy-box {
      text-align: center;
      color: #fff;
      font-size: 16px;
      margin-top: 24px;
    }
    .con-input {
      display: flex;
      .list-input {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 66;
        width: 256px;
        height: 45px;
        background: url(../assets/newimg/img_83.png) no-repeat;
        background-size: 100% 100%;
        padding: 12px 0;
        box-sizing: border-box;
        /deep/ .el-input {
          width: auto;
        }
        .input /deep/ .el-input__inner {
          color: #fff;
          background: none;
          border: none;
          text-align: center;
        }

        span {
          color: #fff;
          padding: 0 16px;
        }
        .list-span1 {
          margin-right: 20px;
          border-right: 2px solid #ffc400;
          cursor: pointer;
        }
        .list-span2 {
          margin-left: 20px;
          border-left: 2px solid #ffc400;
          cursor: pointer;
        }
      }
      .big {
        width: 94px;
        height: 45px;
        background: url(../assets/newimg/img_85.png) no-repeat;
        background-size: 100% 100%;
        font-size: 20px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 23px;
        cursor: pointer;
      }
    }
  }
  .con-btn2 {
    width: 149px;
    height: 44px;
    border: none;
    background: url(../assets/newimg/money.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    font-size: 14px;
  }
  .con-btn2:hover {
    cursor: pointer;
  }
  .kai-num {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 16px;
      color: #848492;
      display: flex;
      align-items: center;
    }
    i {
      color: #e9b10e;
      font-size: 20px;
    }
    h6 {
      font-size: 16px;
      color: #848492;
      font-weight: 200;
    }
  }

  .box-list {
    // width: 1320px;
    margin: 100px auto 0;
    padding-bottom: 60px;
    .boxlist-top {
      padding: 0 16px 0 0;
      height: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 3px solid #ffc400;
      .left {
        width: 154px;
        height: 61px;
        background: url(../assets/newimg/manghe-active.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        color: #fff;
        span {
          width: 156px;
          line-height: 61px;
          text-align: center;
        }
        .span1 {
          background: url(../assets/newimg/img_47.png) no-repeat;
          background-size: 100% 100%;
          color: #000;
        }
        span:hover {
          cursor: pointer;
        }
      }
    }
    .boxlist-bot {
      ul {
        display: flex;
        // justify-content: space-around;
        flex-wrap: wrap;
        justify-content: space-around;
        li:nth-child(5n) {
          // margin-right: 0;
        }
        li {
          width: 17%;
          margin-right: 30px;
          margin-top: 25px;
          background: url(../assets/newimg/img_48.png) no-repeat;
          background-size: 100% 100%;
          height: 205px;
          .top-gang {
            width: 100%;
            height: 10px;
            // background-color: #fff;
            border-radius: 20px 20px 0 0;
          }
          .boxlist-warp {
            .bi {
              text-align: right;
              color: #ffffff;
              // opacity: 0.5;
              font-size: 14px;
            }
            .boxlist1-top {
              height: 125px;
              padding: 15px 20px 0;
              background-size: 100% 100%;
              text-align: center;
              // border-bottom: 3px solid #f1a920;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 75%;
                height: auto;
              }
            }
            .boxlist1-price {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #fff;
              justify-content: center;
              margin-bottom: 10px;
              img {
                width: 14px;
                height: 14px;
                margin-right: 7px;
              }
            }
            .boxlist1-bot {
              padding: 0 8px;
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              text-align: center;
            }
            .boxlist1-bot1 {
              padding: 8px;
              display: flex;
              align-items: center;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }
              span {
                margin-left: 8px;
                color: #c3c3e2;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
  /* .share-hide {
    //height: 300px;
  }*/
  .share-btn {
    display: flex;
    .btn {
      margin-left: 10px;
    }
  }
  /deep/ .el-dialog__footer {
    display: none;
  }
  /deep/ .el-dialog__title {
    color: #848492;
  }
  /deep/ .el-dialog__body {
    padding: 5px 20px;
  }
  /deep/ .el-dialog {
    min-height: none;
  }
}
@media screen and (max-width: 767px) {

  .openbox .kai_bg .lever-box .box .btn2{
    width: auto !important;
    margin-top: 10px !important;
  }

  .openbox .win-box{
    left: 0 !important;
    width: 100% !important;
    transform: translateX(0) !important;

    .win{
      width: 100% !important;
      .win-x{
        right: 5vw !important;
        width: 30px !important;

        img{
          width: 100% !important;
          height: auto !important;
        }
      }

      .win-top{
        img{
          height: 50px !important;
          margin-left: 10px !important;
        }
      }

      .win-con{
        margin-top: -20% !important;
        margin-left: -20% !important;
        transform: scale(0.65) translateX(25%) !important;
        ul{
          width: 70% !important;
        }

        .win-warp{
          .win-img{
            text-align: center;
            img{
              width: 80% !important;
            }
          }
          &>p{
            margin-top: -20px !important;
            margin-bottom: 10px !important;
            font-size: 20px !important;
          }
        }
      }
    }
  }
  .kai-box{
    padding-bottom: 420px !important;
  }

  .openbox .kai-con .con-main{
    background-size: 70% 55% !important;
    background-position: 56% 80% !important;

    .conlist-warp{
      
    }
  }

  .box-list{
      margin-top: -380px !important;
  }
  .lever-kai {
    .conlist-warp {
      width: 100% !important;
      .conlist-box1 {
        width: 100% !important;
      }
    }
  }
  .informs{
    width: 100% !important;
    background: none !important;
    .new-x{
      right: 45% !important;
    }
    .box-img-inform{
      width: 100% !important;
      // padding-left: 10px !important;
      // padding-right: 10px !important;
    }
  }
}


</style>
