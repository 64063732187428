<template>
	<div class="home">
		<div class="top">
			<el-row class="top-el-row">
				<div class="top-btn" @click="showMenu" v-if="showMenuState">
					<img src="../assets/img/menu1.png" />
				</div>
				<div class="top-l-r">
					<el-col :span="16" class="logo logo1">
						<div class="logo-left">
							<img class="img1 logo-span" src="../assets/img/logo11.png" />
							<!-- <p>v8steam</p> -->
						</div>

						<!-- <img class="zm" src="../assets/img/1.png" /> -->
						<!-- <span class="span-line logo-span"></span> -->
						<!-- <img class="img3 logo-span" src="../assets/img/6mdpi.png" /> -->
						<!-- 顶部导航 -->

						<div class="nav2" v-if="showNav" v-clickoutside="clickOut">
							<ul>
								<li v-for="(item, index) in menu" :key="index" @click="goMenu(item.id, item.path)">
									<img :class="item.selState ? '' : 'img1'" v-if="item.selState" :src="item.img1" />
									<img class="img2" v-if="!item.selState" :src="item.img2" />
									<img :class="item.selState ? 'img4' : 'img3'" :src="item.img1" />
									<div :class="item.selState ? 'item-val' : ''">
										{{ item.value }}
									</div>
								</li>
								<!-- <img style="
                    margin-left: 50px;
                    width: 50px;
                    height: 45px;
                    cursor: pointer;
                  " class="img3" src="../assets/img/fuli.png" @click.stop="isFuliShow" /> -->
							</ul>
						</div>
					</el-col>
					<el-col :span="8" class="top-name">
						<!-- <div class="top-con"> -->
						<!-- <span>{{ name }}</span> -->
						<!-- </div> -->
						<div class="top-right" v-if="loginfalse">
							<div class="btn" @click="showLogin(1)">注册</div>
							<div class="btn btn1" @click="showLogin(0)">登录</div>
						</div>
						<div class="top-right1" v-if="!loginfalse" @click="drawerFun">
							<div class="mess">
								<!-- v-if="me.is_anchor == 1" -->
								<!-- <div class="anchor" v-if="me.is_anchor == 1">
									<img src="../assets/newimg/header.png" />
								</div>
								<div class="anchor" v-else>
									<img src="../assets/newimg/header2.png" />
								</div> -->
								<div class="mess-left" style="position: relative" :class="me.is_anchor == 1?'zhubo':'yonghu'">
									<span v-if="unreadCount" style="
									position: absolute;
									right: -6px;
									top: -6px;
									text-align: center;
									height: 20px;
									padding: 0 6px !important;
									border-radius: 10px;
									background: red;
									font-size: 12px;
									line-height: 20px;
									color: white;">{{ unreadCount > 99 ? '99+' : unreadCount }}</span>
									<img :src="me.img" />
								</div>
								<div class="mess-right">
									<span class="mess-span1">{{ me.name }}</span>
									<span class="mess-span2">
										<img src="../assets/img/money.png" />
										<!-- <strong>{{ Number($store.state.money).toFixed(2) }}</strong> -->
										<strong>{{ Number(money).toFixed(2) }}</strong>
									</span>
										<!-- <strong>{{ Number($store.state.money).toFixed(2) }}</strong> -->

									<!-- <span class="mess-span2">
										<img src="../assets/img/moneyRed.png" />
										<strong>{{ Number(integral_money).toFixed(2) }}</strong>
									</span> -->
								</div>
							</div>
							<div class="p-r" :class="loginfalse ? 'isLogin' : ''">
								<!-- <div class="top-bag logo-span"  @click="gopath('Dota')">
                  <img src="../assets/img/top1.png" />
                  <span class="span text">饰品背包</span>
                </div> -->
								<div class="top-pay logo-span">
									<img src="../assets/newimg/img_30.png" @click.stop="goPaySub" />
									<!-- <span class="span text" >充值</span>
                  <span v-if="giveBillie > 0" class="pay-span">+送{{giveBillie}}%</span> -->
								</div>
							</div>
							<div class="top-right1-ico">
								<img src="../assets/img/menu.png" />
							</div>
						</div>
					</el-col>
				</div>
			</el-row>
		</div>

		<div class="bot">
			<div class="bot-right">
				<div v-if="loading" class="el-loading-spinner">
					<i class="el-icon-loading"> </i>
					<p class="el-loading-text">正在加载中...</p>
				</div>
				<router-view ref="child"></router-view>
			</div>
			<myhomebot></myhomebot>
		</div>

		<div class="nav3" v-clickoutside="clickOut">
			<ul>
				<li v-for="(item, index) in menu" :key="index+'s'" @click="goMenucopy(item.id, item.path)">
					<img :class="item.selState ? '' : 'img1'" v-if="item.selState" :src="item.img1" />
					<img class="img2" v-if="!item.selState" :src="item.img2" />
					<!-- <img :class="item.selState ? 'img4' : 'img3'" :src="item.img1" /> -->
					<div :class="item.selState ? 'item-val' : ''">
						{{ item.value }}
					</div>
				</li>
			</ul>
		</div>

		<!--登录盒子-->
		<div class="login" v-if="$store.state.loginState">
			<div class="Mask_content">
				<img class="banner" src="../assets/newimg/img_01.png" alt="" />
				<div class="close" @click="hideLogin"></div>
				<div class="Mask_nav">
					<div class="nav_item" :class="nav == 0 ? 'active' : ''" @click="changeNav(0)">
						登录
					</div>
					<div class="nav_item" :class="nav == 1 ? 'active' : ''" @click="changeNav(1)">
						注册
					</div>
				</div>
				<!-- <div class="switch_box">
          <div class="switchs" v-if="nav == 0" @click="showPhone = !showPhone">
            {{ showPhone ? "账号密码登录" : "手机验证码登录" }}
          </div>
          <div
            class="switchs"
            v-if="nav == 1"
            @click="showEmail = !showEmail"
            style="position: absolute; right: 14%; top: 10%"
          >
            {{ showEmail ? "手机号注册" : "邮箱注册" }}
          </div>
        </div> -->
				<div class="subject" :class="nav == 1 ? 'regs' : ''">
					<div class="normal">
						<el-form ref="form" :model="loginForm" label-width="80px">
							<div class="form2">
								<el-form-item v-if="nav == 0">
									<div slot="label" style="display: flex; align-items: center">
										<!-- <img
                    class="icon_img"
                    src="../assets/newimg/img_06.png"
                    alt=""
                  /> -->
										<div class="icon_img">账号</div>
										<p class="icon_b"></p>
									</div>
									<el-input v-model="loginForm.account"
										:placeholder="showPhone ? '请输入手机号' : '请输入账号'"></el-input>
								</el-form-item>
								<el-form-item v-if="nav == 1">
									<div slot="label" style="display: flex; align-items: center">
										<div class="icon_img">
											{{ showEmail ? "邮箱" : "手机号" }}
										</div>
										<p class="icon_b"></p>
									</div>
									<el-input style="width: 60%" v-model="loginForm.account"
										:placeholder="showEmail ? '请输入邮箱' : '请输入手机号'"></el-input>
									<!-- <div
                    style="
                      width: 91px;
                      height: 50px;
                      background: #372f0d;
                      border: 1px solid #fff32b;
                      border-radius: 6px;
                      font-size: 16px;
                      font-family: Microsoft YaHei;
                      font-weight: 400;
                      color: #fffc00;
                      text-align: center;
                      line-hight: 50px;
                    "
                  ></div> -->
								</el-form-item>
								<el-form-item v-if="showPhone || nav == 1">
									<div slot="label" style="display: flex; align-items: center">
										<div class="icon_img">验证码</div>
										<p class="icon_b"></p>
									</div>
									<el-input v-model="loginForm.phoneinput3" placeholder="请输入验证码"
										style="width: 60%"></el-input>
									<div class="yzm">
										<p class="icon_b"></p>
										<div class="getCode" @click="getphoneCode" :disabled="!phonecodeState">
											{{ phonecodeState ? "获取验证码" : phonecodeTime + " s" }}
										</div>
									</div>
								</el-form-item>
								<el-form-item v-if="!showPhone || showEmail">
									<div slot="label" style="display: flex; align-items: center">
										<div class="icon_img">密码</div>
										<p class="icon_b"></p>
									</div>
									<el-input v-model="loginForm.password" type="password" maxlength="16" :placeholder="nav == 0 ? '请输入您的密码' : '请输入不超过16位的密码'
										"></el-input>
								</el-form-item>
								<el-form-item v-if="nav == 1">
									<div slot="label" style="display: flex; align-items: center">
										<div style="font-size: 14px;" class="icon_img">身份证号</div>
										<p class="icon_b"></p>
									</div>
									<el-input v-model="loginForm.card_id" placeholder="请输入身份证号"></el-input>
								</el-form-item>
								<el-form-item style="
                    border: none;
                    background: none;
                    display: flex;
                    align-items: center;
                  " v-if="nav == 1">
									<el-col :span="15" style="margin-left: -80px; margin-right: 21px">
										<div style="
                        display: flex;
                        align-items: center;
                        width: 40%;
                        padding-left: 8px;
                        position: relative;
                      ">
											<div class="icon_img">昵称</div>
											<p class="icon_b"></p>
										</div>
										<el-input v-model="loginForm.nickname" placeholder="请输入昵称（选填）"></el-input>
									</el-col>
									<el-col :span="16">
										<div style="
                        display: flex;
                        align-items: center;
                        width: 40%;
                        padding-left: 8px;
                        position: relative;
                      ">
											<div class="icon_img">邀请码</div>
											<p class="icon_b"></p>
										</div>
										<el-input v-model="loginForm.phoneinput4" placeholder="请输入邀请码（选填）"></el-input>
									</el-col>
								</el-form-item>
								<!-- <el-form-item v-if="nav == 1">
                  <div slot="label" style="display: flex; align-items: center">
                    <div class="icon_img">邀请码</div>
                    <p class="icon_b"></p>
                  </div>
                  <el-input
                    v-model="loginForm.phoneinput4"
                    placeholder="请输入邀请码（选填）"
                  ></el-input>
                </el-form-item> -->
							</div>
							<div class="all-box">
								<div class="agreement">
									<span @click="goForget">忘记密码</span>
								</div>
								<div class="switch_box">
									<div class="switchs" v-if="nav == 0" @click="showPhone = !showPhone">
										{{ showPhone ? "账号密码登录" : "手机验证码登录" }}
									</div>
									<!-- <div class="switchs" v-if="nav == 1" @click="showEmail = !showEmail">
										{{ showEmail ? "手机号注册" : "邮箱注册" }}
									</div> -->
								</div>
							</div>
							<div class="agreement_icon_box">
								<div class="agreement_icon" @click="checked = !checked">
									<img v-if="checked" src="../assets/newimg/img_14.png" alt="" />
									<img v-else src="../assets/newimg/img_13.png" alt="" />
								</div>
								<p style="color: #25aaff">
									我已满18岁，并阅读和同意<span style="color: #25aaff; cursor: pointer"
										@click="toxieyi(1)">《用户协议》</span>、<span style="color: #25aaff; cursor: pointer"
										@click="toxieyi(2)">《隐私政策》</span>和<span style="color: #25aaff; cursor: pointer"
										@click="toxieyi(3)">《反洗钱协议》</span>承诺理性消费
								</p>
							</div>
							<div class="form-btn">
								<div class="btn" v-if="nav == 0" @click="getLogin">登录</div>
								<div class="btn" v-if="nav == 1" @click="regBtn">注册</div>
							</div>
						</el-form>
						<!-- <div class="goLogin" @click="goLogin1" v-if="nav == 1">去登录</div> -->
					</div>
				</div>
			</div>
		</div>

		<!-- 忘记密码1 -->
		<div class="login" v-if="forgetBox">
			<div class="Mask_content">
				<img class="banner" src="../assets/newimg/img_01.png" alt="" />
				<div class="close" @click="hideForgetBox"></div>
				<div class="Mask_nav">
					<div class="nav_item active">找回密码</div>
				</div>
				<!-- <div class="switch_box" style="margin-top: 0">
					<div class="switchs" style="position: absolute; right: 14%; top: 10%" @click="showEmails = !showEmails">
						{{ showEmails ? "手机号验证找回" : "邮箱验证找回" }}
					</div>
				</div> -->
				<div class="subject">
					<div class="normal">
						<el-form ref="form" class="form2" :model="mmForm" label-width="80px">
							<el-form-item>
								<div slot="label" style="display: flex; align-items: center">
									<div class="icon_img" v-if="!showEmails">手机号</div>
									<div class="icon_img" v-else>邮箱</div>
									<p class="icon_b"></p>
								</div>
								<el-input v-model="mmForm.forgetNum"
									:placeholder="showEmails ? '请输入邮箱' : '请输入手机号'"></el-input>
							</el-form-item>
							<el-form-item>
								<div slot="label" style="display: flex; align-items: center">
									<div class="icon_img">验证码</div>
									<p class="icon_b"></p>
								</div>
								<el-input v-model="mmForm.forgetCode" placeholder="请输入验证码" style="width: 60%"></el-input>
								<div class="yzm">
									<p class="icon_b"></p>
									<div class="getCode" @click="getForgetCode"
										:disabled="!forgetcodeState">
										{{ forgetcodeState ? "获取验证码" : forgetcodeTime + " s" }}
									</div>

									<!-- <div
                    v-else
                    class="getCode"
                    @click="getForgetCode"
                    :disabled="!emilcodeState"
                  >
                    {{ emilcodeState ? "获取验证码" : emilcodeTime + " s" }}
                  </div> -->
								</div>
							</el-form-item>
							<el-form-item>
								<div slot="label" style="display: flex; align-items: center">
									<div class="icon_img">密码</div>
									<p class="icon_b"></p>
								</div>
								<el-input v-model="mmForm.forgetPass" type="password" maxlength="16"
									placeholder="请输入不超过16位的密码"></el-input>
							</el-form-item>
							<el-form-item>
								<div slot="label" style="display: flex; align-items: center">
									<div class="icon_img">密码</div>
									<p class="icon_b"></p>
								</div>
								<el-input v-model="mmForm.forgetNPass" type="password" maxlength="16"
									placeholder="请重复密码"></el-input>
							</el-form-item>
							<div class="form-btn">
								<div class="btn" @click="reset">重置密码</div>
								<p class="go-login" @click="goLogin1">去登录</p>
							</div>
						</el-form>
					</div>
				</div>
			</div>
		</div>

		<!--登录完成右边导航-->
		<el-drawer :visible.sync="drawer" direction="rtl" :before-close="handleClose" size="350px" class="sty menu">
			<!-- <div class="sty-btn">
        <span @click="goRoll">饰品商城</span>
        <span @click="goPayment">充值</span>
      </div> -->
			<div class="sty-menu">
				<el-menu class="el-menu-vertical-demo" background-color="transparent" text-color="#fff" @select="gopath1"
					active-text-color="#fff">
					<el-menu-item index="Arena">
						<img v-if="indexSele == 'Arena'" style="width: 28px; height: 28px; margin-right: 19px"
							src="../assets/newimg/img_27.png" alt="" />
						<img v-else style="width: 28px; height: 28px; margin-right: 19px" src="../assets/newimg/img_26.png"
							alt="" />
						<span slot="title">Roll</span>
					</el-menu-item>
					<el-menu-item index="Dota">
						<img v-if="indexSele == 'Dota'" style="
                width: 28px;
                height: 28px;
                margin-right: 19px;
                background-color: transparent;
              " src="../assets/newimg/Dota-active.png" alt="" />
						<img v-else style="width: 28px; height: 28px; margin-right: 19px" src="../assets/newimg/Dota.png"
							alt="" />

						<span slot="title">我的背包</span>
					</el-menu-item>
					<el-menu-item index="Bill">
						<img v-if="indexSele == 'Bill'" style="width: 28px; height: 28px; margin-right: 19px"
							src="../assets/newimg/Bill-active.png" alt="" />
						<img v-else style="width: 28px; height: 28px; margin-right: 19px" src="../assets/newimg/Bill.png"
							alt="" />
						<span slot="title">我的账单</span>
					</el-menu-item>
					<el-menu-item index="Spread">
						<img v-if="indexSele == 'Spread'" style="width: 28px; height: 28px; margin-right: 19px"
							src="../assets/newimg/Spread-active.png" alt="" />
						<img v-else style="width: 28px; height: 28px; margin-right: 19px" src="../assets/newimg/Spread.png"
							alt="" />
						<span slot="title">推广中心</span>
					</el-menu-item>
					<el-menu-item index="Me">
						<img v-if="indexSele == 'Me'" style="width: 28px; height: 28px; margin-right: 19px"
							src="../assets/newimg/Me-active.png" alt="" />
						<img v-else style="width: 28px; height: 28px; margin-right: 19px" src="../assets/newimg/Me.png"
							alt="" />
						<span slot="title">个人中心</span>
					</el-menu-item>
					<el-menu-item index="Inform">
						<img v-if="indexSele == 'Inform'" style="width: 28px; height: 28px; margin-right: 19px"
							src="../assets/newimg/Inform-active.png" alt="" />
						<img v-else style="width: 28px; height: 28px; margin-right: 19px" src="../assets/newimg/Inform.png"
							alt="" />
						<span slot="title">消息通知</span>
					</el-menu-item>
				</el-menu>
				<div class="sty-next" @click="nextLogin">退出登录</div>
			</div>
		</el-drawer>

		<!-- <div :class="[rightBar ? 'bar-show' : 'bar-hidden', 'right-bar']">
      <div class="switch" @click="switchBar()">
        <img src="../assets/img/menu-show.svg" alt="" />
      </div>
      <div class="btn-group">
        <div class="btn-hong" @click="openHongbao">
          <img src="../assets/img/rightpop/265.gif" />
        </div>
        <div class="btn" @click="qqGroup()">
          <img src="../assets/img/qq.svg" alt="" />
          <span>加群有奖</span>
        </div>
        <div class="btn" @click="backAction('click')">
          <div class="tip-num" v-if="num>0">{{num}}</div>
          <img src="../assets/img/back.svg" alt="" />
          <span>取回助手</span>
        </div>
      </div>
    </div> -->
		<el-drawer title="我是标题" :visible.sync="rightBarDrawer" direction="rtl" :size="quhuiSize" :before-close="handleClose"
			class="quhui-box">
			<div class="give-box">
				<ul v-for="(item, index) in tableData" :key="index">
					<li>
						<div class="give-true" v-if="item.steamNameAnother">
							<div class="give-left">
								<div class="give-img">
									<img :src="item.steamAvatarAnother" />
								</div>
								<div class="give-text">
									<span>{{ item.steamNameAnother }}</span>
									<span>加入steam时间：{{ item.steamCreateTimeAnother }}</span>
								</div>
							</div>
							<div class="give-right" @click="takeOffer(item.steam_receive_url)">
								接受报价
							</div>
						</div>
						<div class="give-false" v-if="!item.steamNameAnother">
							<div class="give-false-left">
								<img :src="item.img" />
								<span>{{ item.name }}</span>
							</div>
							<div class="give-false-right">待发货</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="give-box1" v-if="tableData.length == 0">没有进行中的报价</div>
			<div class="func">
				<div class="hidden" @click="funcHidden">全部隐藏</div>
				<div class="refresh" @click="funcRefresh">
					<img src="../assets/img/refresh.svg" />
				</div>
			</div>
		</el-drawer>

		<!-- 新人红包home -->
		<div ref="newShow" class="new-box" v-if="newShow">
			<div class="new">
				<div class="new-top">
					<img src="../assets/newimg/img_89.png" />
				</div>
				<div @click="isNewShow" class="new-x">
					<img src="../assets/newimg/cha.png" alt="" />
				</div>
				<!-- <div
          class="open-money"
          v-if="nav == 2 || nav == 3"
          @click="openMoney"
        ></div> -->
				<div class="new-con">
					<div class="hongbao">
						<img src="../assets/newimg/hongbaopic.png" alt="" />
					</div>
					<div class="hbtitle">S18-能量炮</div>
					<div class="con-box">
						<div class="box-1" v-if="nav == 0">
							<div class="newRed-packet">
								<div class="kai-box">
									<div class="kai-box2" :class="goBtnId == 0 ? 'kai-box1' : ''" @click="goBtn(0)">
										盲盒开箱
									</div>
									<div class="kai-box2" :class="goBtnId == 1 ? 'kai-box1' : ''" @click="goBtn(1)">
										盲盒对战
									</div>
								</div>
								<img class="gang" src="../assets/newimg/henggang.png" alt="" />
								<div v-if="goBtnId == 0" class="bao-list" v-for="(item, index) in awardList" :key="item.id">
									<div class="bao-info">
										完成开箱金额{{ item.money }}元。领取游戏福利({{
											item.complete
										}}/{{ item.money }})
									</div>
									<div @click="getAward(item.id)" class="goBtn">
										{{ item.draw }}
									</div>
								</div>
								<!-- <div style="color: #12e3eb" v-if="goBtnId == 0">
                  今日已领取{{ rechargeAmount }}元
                </div> -->
								<div v-if="goBtnId == 1" class="bao-list" v-for="(item, index) in awardList2"
									:key="item.id">
									<div class="bao-info">
										完成开箱金额{{ item.money }}元。领取游戏福利({{
											item.complete
										}}/{{ item.money }})
									</div>
									<div class="goBtn" @click="getAward2(item.id)">
										{{ item.draw }}
									</div>
								</div>
								<!-- <div style="color: #12e3eb" v-if="goBtnId == 1">
                  今日已领取{{ rechargeAmount2 }}元
                </div> -->
							</div>
						</div>
						<div class="box-3" v-if="nav == 1">
							<div class="hbbanner">
								<img src="../assets/newimg/qdbanner.png" alt="" />
							</div>
							<div class="nowqd" v-if="is_sign <= 0 || is_sign == 1" @click="goChongzhi">
								立即签到
							</div>
							<div class="nowqd" v-else>已签到</div>
						</div>
						<div class="box-1" v-if="nav == 2">
							<div class="newRed-packet2">
								<img class="gang" src="../assets/newimg/henggang.png" alt="" />
								<div class="newRedList" v-for="item in activityRedPacket" :key="item.id">
									<div class="bao-list">
										<div class="bao-info">{{ item.name }}</div>
										<div class="goBtn" v-if="item.receive==1" @click="item.status=!item.status">
												领取
										</div>
										<div class="goBtn" v-else-if="item.is_open" @click="openMoney(item.id,false)">
											领取
										</div>
										<div class="goBtn" v-else>
											不可领取
										</div>
									</div>
									<div class="putinnder" v-if="item.status">
										<el-input @keyup.enter.native="openMoney(item.id)" v-model="inputMoney"
											placeholder="请输入口令"></el-input>
									</div>
								</div>
							</div>
						</div>
						<div class="fuli-con" v-if="nav == 3">
							<div class="fanlihongbao">口令红包</div>
							<el-input v-model="inputMoney" placeholder="请输入口令"></el-input>
							<div class="guize">红包口令规则</div>
							<div class="lingqu" @click="openMoney">领取</div>
						</div>
					</div>
					<div class="con-bottom">
						<div class="pay-nav">
							<div class="nav_item" :class="nav == 0 ? 'actives' : ''" @click="changeNav(0)">
								福利专区
							</div>
							<div class="nav_item" :class="nav == 1 ? 'actives' : ''" @click="changeNav(1)">
								签到红包
							</div>
							<div class="nav_item" :class="nav == 2 ? 'actives' : ''" @click="changeNav(2)">
								活动红包
							</div>
							<div class="nav_item" :class="nav == 3 ? 'actives' : ''" @click="changeNav(3)">
								返利红包
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 协议勾选 -->
		<div class="dh_main" v-if="dialogBox">
			<div class="new-x2" @click="closeBot2">
				<img src="../assets/newimg/img_02.png" alt="" />
			</div>
			<p class="title">请阅读以下协议并勾选同意后,点击按钮进入充值</p>
			<div class="checks">
				<div class="hobby1">
					<el-checkbox v-model="checked1"></el-checkbox>
					<span class="mask">本人已满18岁且具有完全民事行为能力</span>
				</div>
				<div class="hobby1">
					<el-checkbox v-model="checked2"></el-checkbox>
					<span class="mask">本人仅在经济承受范围内消费,且将消费承担后果</span>
				</div>
				<div class="hobby1">
					<el-checkbox v-model="checked3"></el-checkbox>
					<span class="mask">本人已知道通过充值购买的虚拟物品不可退货或换货,且不根据七天无理由退换货主张权利</span>
				</div>
				<div class="hobby1">
					<el-checkbox v-model="checked4"></el-checkbox>
					<span class="mask">本人已知道饰品发起提取流程后,通常在24小时完成</span>
				</div>
				<div class="btn" @click.stop="gopath('Payment')">我知道了</div>
			</div>
		</div>

		<div class="dh_main2" v-if="dialogBox2">
			<div class="new-x2" @click="closeBot3">
				<img src="../assets/newimg/img_02.png" alt="" />
			</div>
			<p class="title">{{ qqNum }}</p>
		</div>

		<!-- 左侧导航 -->
		<!-- <div class="nav2" v-if="showMenuState">
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          @click="goMenu(item.id, item.path)"
        >
          <img
            :class="item.selState ? '' : 'img1'"
            v-if="item.selState"
            :src="item.img1"
          />
          <img class="img2" v-if="!item.selState" :src="item.img2" />
          <img :class="item.selState ? 'img4' : 'img3'" :src="item.img1" />
        </li>
      </ul>
    </div> -->
	</div>
</template>

<script>
// 16712854999
import myhomebot from "@/components/my_homebot.vue";
import Clickoutside from "element-ui/src/utils/clickoutside";
import Utils from "./../assets/js/util.js";
export default {
	name: "Home",
	components: {
		myhomebot
	},
	directives: {
		Clickoutside
	},
	data() {
		return {
			unreadCount: 0,
			inputMoney2: "",
			awardList: [],
			awardList2: [],
			is_sign: 0,
			nav: 0,
			newShow: false,
			inputMoney: "",
			activityRedPacket: [], //活动红包
			newRedPacket: [], //新人红包
			signInRedPacket: [], //签到红包
			checked1: false,
			checked2: false,
			checked3: false,
			checked4: false,
			dialogBox: false,
			loadingReg: false,
			hongbaoData: [],
			my_hongbao: "",
			hongbaoid: "",
			quhuiSize: "680px",
			windowWidth: document.body.clientWidth,
			showMenuState: false,
			hongbaoText: "",
			hongbaoState1: false,
			hongbaoState2: false,
			tableData: [],
			rightBarDrawer: false,
			rightBar: true,
			loading: false,
			forgetHint: false,
			forgetHintText: "",
			forgetBox: false,
			mmForm: {
				forgetNum: "",
				forgetPass: "",
				forgetNPass: "",
				forgetCode: "",
			},

			forgetcodeState: true,
			forgetcodeTime: 60,
			forgettimer: null,

			loginHintState: false,
			loginHintText: "",
			regHint: false,
			regHintText: "",
			Index: "Index",
			name: "CS:GO盲盒",
			regBox: false,
			phoneregBox: true,
			emilregBox: false,
			phonecodeState: true,
			phonecodeTime: 60,
			phonetimer: null,
			emilcodeState: true,
			emilcodeTime: 60,
			emiltimer: null,
			loginForm: {
				account: "",
				password: "",
				nickname: "",
				card_id: "",
				phoneinput3: "",
				phoneinput4: "",
			},

			phoneinput1: "",
			phoneinput2: "",
			emilinput1: "",
			emilinput2: "",
			emilinput3: "",
			emilinput4: "",

			me: {},
			checked: false, //用户协议
			loginfalse: "true", //登录状态
			loginChecked: false, //登录
			loginBox: false,
			drawer: false, //右侧导航状态

			language: false, //语言
			languageImg: require("../assets/img/13mdpi.png"),
			languageText: "简体中文",
			languageList: [{
				url: require("../assets/img/13mdpi.png"),
				name: "简体中文"
			},
			{
				url: require("../assets/img/yinguo.png"),
				name: "English"
			},
			],
			menu: [{
				id: 1,
				img1: require("../assets/newimg/img_19.png"),
				img2: require("../assets/newimg/img_18.png"),
				selState: true,
				path: "Index",
				child: ["Index", "Openbox"],
				value: "首页",
			},
			{
				id: 2,
				img1: require("../assets/newimg/img_21.png"),
				img2: require("../assets/newimg/img_20.png"),
				selState: false,
				path: "Lucky",
				child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
				value: "竞技场",
			},
			{
				id: 2,
				img1: require("../assets/newimg/img_25.png"),
				img2: require("../assets/newimg/img_24.png"),
				selState: false,
				path: "Dreams",
				child: ["Dreams", "DreamsRoom", "DreamsRule", "DreamsHistory"],
				value: "幸运追梦",
			},
			{
				id: 3,
				img1: require("../assets/newimg/img_23.png"),
				img2: require("../assets/newimg/img_22.png"),
				selState: false,
				path: "Ornament",
				child: ["Ornament", "OrnamentOpen", "OrnamentHistory"],
				value: "V8商城", //幸运饰品
			},
			// {
			// 	id: 4,
			// 	img1: require("../assets/newimg/img_25.png"),
			// 	img2: require("../assets/newimg/img_24.png"),
			// 	selState: false,
			// 	path: "Game",
			// 	child: ["Game", "GameRoom"],
			// 	value: "福临门", //幸运饰品
			// },
			// {
			// 	id: 5,
			// 	img1: require("../assets/newimg/img_27.png"),
			// 	img2: require("../assets/newimg/img_26.png"),
			// 	selState: false,
			// 	path: "Arena",
			// 	child: ["Arena", "ArenaRoom"],
			// 	value: "Roll",
			// },
			{
				id: 6,
				img1: require("../assets/newimg/img_29.png"),
				img2: require("../assets/newimg/img_28.png"),
				selState: false,
				path: "Welfare",
				child: ["Welfare"],
				value: "回血包",
			},
			],
			showNav: true,
			code: "",
			inputCode: true,
			giveBillie: 0,
			num: 0,
			money: 0,
			integral_money: 0,
			loging: "",
			loginReturn: [],
			showPhone: false,
			showEmail: false,
			showEmails: false,
			nav: 0,
			indexSele: "",
			goBtnId: 0,
			page: 1,
			dialogBox2: false,
			qqNum: "",
			rechargeAmount: 0,
			rechargeAmount2: 0,
		};
	},
	watch: {
		//监听路由
		$route(to, from) {
			var path = to.name;
			if (path == "Index") {
				this.name = "CS:GO盲盒";
			} else if (path == "Lucky") {
				this.name = "盲盒对战";
			} else if (path == "Dreams") {
				this.name = "盲盒对战";
			} else if (path == "Arena") {
				this.name = "免费皮肤";
			} else if (path == "Roll") {
				this.name = "饰品商城";
			} else if (path == "Ornament") {
				this.name = "幸运饰品";
			}
			for (let i = 0; i < this.menu.length; i++) {
				// console.log("this.menu[i]", this.menu[i]);
				if (this.menu[i].child.includes(path)) {
					this.menu[i].selState = true;
				} else {
					this.menu[i].selState = false;
				}
			}
		},
		//监听手机验证码
		phonecodeState(val) {
			if (val == false) {
				this.phonetimer = setInterval(() => {
					this.phonecodeTime--;
				}, 1000);
			}
		},
		//监听手机验证码时间
		phonecodeTime(val) {
			if (val == 0) {
				this.phonecodeState = true;
				this.phonecodeTime = 60;
				clearInterval(this.phonetimer);
			}
		},
		//监听邮箱验证码
		emilcodeState(val) {
			if (val == false) {
				this.emiltimer = setInterval(() => {
					this.emilcodeTime--;
				}, 1000);
			}
		},
		//监听邮箱验证码时间
		emilcodeTime(val) {
			if (val == 0) {
				this.emilcodeState = true;
				this.emilcodeTime = 60;
				clearInterval(this.emiltimer);
			}
		},
		//监听忘记密码验证码
		forgetcodeState(val) {
			if (val == false) {
				this.forgettimer = setInterval(() => {
					this.forgetcodeTime--;
				}, 1000);
			}
		},
		//监听忘记密码验证码时间
		forgetcodeTime(val) {
			if (val == 0) {
				this.forgetcodeState = true;
				this.forgetcodeTime = 60;
				clearInterval(this.forgettimer);
			}
		},
	},
	created() {
		//this.selfLogin();
	},
	mounted() {
		this.loginForm.phoneinput4 = this.$route.query.code;
		this.getUnreadCount();
		// setInterval(this.getUnreadCount, 5000);
		this.$bus.$on("openQQnum", () => {
			console.log("666666");
			this.dialogBox2 = true;
		});
		this.initFun();
		this.$nextTick(() => {
			// this.$refs.child.isNewShow(); //这里要注意判断 有的子路由没有getData()这个方法的话会报错
		});
		let codeInfo = this.GetUrlParam("code");
		this.code = codeInfo ? codeInfo.split("#")[0] : "";
		this.inputCode = this.code ? false : true;
		//this.getActive();
		this.backAction();
		//判断屏幕宽度
		if (this.windowWidth < 1024) {
			this.quhuiSize = "100%";
			this.showMenuState = true;
			this.showNav = false;
		}
		this.$bus.$on("loading", (e) => {
			this.loading = e;
		});

		// console.log(this.showMenuState,this.show768);
		//查看缓存 自动登录
		this.selfLogin();
		//路由名称
		this.Index = this.$route.name;

		for (let i = 0; i < this.menu.length; i++) {
			if (this.menu[i].child.includes(this.Index)) {
				this.menu[i].selState = true;
			} else {
				this.menu[i].selState = false;
			}
		}
		let _this = this;
		Utils.$on("money", function (money) {
			// console.log(money);
			// this.money = money
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			userInfo.total_amount = money;
			// userInfo.total_amount = money;
			localStorage.setItem("userInfo", JSON.stringify(userInfo));
			_this.selfLogin();
		});

		Utils.$on("integral_money", function (integral_money) {
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			userInfo.score = integral_money;
			localStorage.setItem("userInfo", JSON.stringify(userInfo));
			_this.selfLogin();
		});
		Utils.$on("img", function (img) {
			// console.log(img);
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			userInfo.img = img;
			localStorage.setItem("userInfo", JSON.stringify(userInfo));
			_this.selfLogin();
		});
		Utils.$on("login", function (data) {
			// console.log(data);
			_this.selfLogin();
		});
	},

	methods: {
		//加载方法
		initFun(){
			//盲盒开箱
			this.$nextTick(function () {
				this.$nextTick(() => {
					this.$post("/api/award/open_box_award_list", {
						token: localStorage.getItem("token"),
					}).then((res) => {
						if (res.code == 1) {
							this.awardList = res.data.list;
							this.rechargeAmount = res.data.rechargeAmount;
							this.awardList.forEach((ele) => {
								if (ele.draw == 1) {
									ele.draw = "不可领取";
								} else if (ele.draw == 2) {
									ele.draw = "可领取";
								} else {
									ele.draw = "已领取";
								}
							});
						}
					});
				});
			});

			//盲盒对战
			this.$nextTick(function () {
				this.$nextTick(() => {
					this.$post("/api/award/battle_award_list", {
						token: localStorage.getItem("token"),
					}).then((res) => {
						if (res.code == 1) {
							this.awardList2 = res.data.list;
							this.rechargeAmount2 = res.data.rechargeAmount;
							this.awardList2.forEach((ele) => {
								if (ele.draw == 1) {
									ele.draw = "不可领取";
								} else if (ele.draw == 2) {
									ele.draw = "可领取";
								} else {
									ele.draw = "已领取";
								}
							});
						}
					});
				});
			});
			this.$nextTick(function () {
				this.$nextTick(() => {
					this.$post("/api/envelope/existEnvelope", {
						token: localStorage.getItem("token"),
					}).then((res) => {
						// console.log("existEnvelope", res);
						this.activityRedPacket = res.data.activityRedPacket;
						this.is_sign = res.data.is_sign;
						this.activityRedPacket.forEach((ele) => {
							ele.status = false;
						});
						this.newRedPacket = res.data.newRedPacket;
						this.signInRedPacket = res.data.signInRedPacket;
						this.qqNum = res.data.official_group;
					});
				});
				// this.newShow = true;
			});
		
		},
		getUnreadCount() {
			console.log(this.me.id);
			if (!this.me.id) {
				return;
			}
			this.$post("/api/user/user_message_num", {}).then((res) => {
				if (res.code == 1) {
					this.unreadCount = res.data;
				}
			});
		},
		openQQ() { },
		goChongzhi() {
			// const that=this

			this.$post("/api/index/sing_receive", {
				// token: localStorage.getItem("token"),
				player_id: this.$store.state.id,
			}).then((res) => {
				console.log("签到红包6666", res);
				if (res.code == 1) {
					this.is_sign = 2;
				} else if (res.code == -100) {
					this.$router.push({
						path: "/Payment",
					});
				} else {
					this.$message({
						message: res.msg,
						type: "none",
					});
				}
			});
			this.newShow = false;
		},
		getAward(id) {
			this.$post("/api/award/open_box_award", {
				token: localStorage.getItem("token"),
				id: id,
			}).then((res) => {
				if (res.code == 1) {
					this.money = res.data;
					this.$message({
						message: res.msg,
						type: "success",
						customClass: "mzindex",
					});
					this.$post("/api/award/open_box_award_list", {
						token: localStorage.getItem("token"),
					}).then((res) => {
						if (res.code == 1) {
							this.awardList = res.data.list;
							this.rechargeAmount = res.data.rechargeAmount;
							this.awardList.forEach((ele) => {
								if (ele.draw == 1) {
									ele.draw = "不可领取";
								} else if (ele.draw == 2) {
									ele.draw = "可领取";
								} else {
									ele.draw = "已领取";
								}
							});
						}
					});
				}
			});
		},
		getAward2(id) {
			this.$post("/api/award/battle_award", {
				token: localStorage.getItem("token"),
				id: id,
			}).then((res) => {
				if (res.code == 1) {
					this.money = res.data;
					this.$message({
						message: res.msg,
						type: "success",
						customClass: "mzindex",
					});
					this.$post("/api/award/battle_award_list", {
						token: localStorage.getItem("token"),
					}).then((res) => {
						if (res.code == 1) {
							this.awardList2 = res.data.list;
							this.rechargeAmount2 = res.data.rechargeAmount;
							this.awardList2.forEach((ele) => {
								if (ele.draw == 1) {
									ele.draw = "不可领取";
								} else if (ele.draw == 2) {
									ele.draw = "可领取";
								} else {
									ele.draw = "已领取";
								}
							});
						}
					});
				}
			});
		},
		goBtn(e) {
			this.goBtnId = e;
		},
		isNewShow() {
			this.newShow = false;
		},
		//红包领取
		openMoney(id,needInput=true) {
			const _this = this;
			// console.log("6666666");
			if (_this.inputMoney == ""&&needInput) {
				_this.$message({
					message: "请复制口令",
					type: "info",
				});
				return;
			}
			if (_this.nav == 3) {
				_this.$nextTick(() => {
					_this
						.$post("/api/rebates/get_rebate", {
							token: localStorage.getItem("token"),
							password: _this.inputMoney,
						})
						.then((res) => {
							// console.log("get_rebate", res);
							_this.$store.commit(
								"getMoney",
								Number(res.data.total_amount).toFixed(2)
							);
							Utils.$emit("money", res.data);
							_this.inputMoney = "";
							_this.$message({
								message: res.msg,
								type: "success",
								customClass: "mzindex",
							});
						});
				});
			}
			if (_this.nav == 2) {
				// const activi = _this.activityRedPacket.filter((item) => {
				// 	return item.pass === _this.inputMoney;
				// });
				// console.log("activi", activi);
				_this
					.$post("/api/envelope/envelope", {
						token: localStorage.getItem("token"),
						envelope_id: id,
						password: _this.inputMoney,
					})
					.then((res) => {
						// console.log("envelope", res);
						if (res.code == 1) {
							_this.$message({
								message: res.msg,
								type: "succes",
								customClass: "mzindex",
							});
							_this.inputMoney = "";
						} else {
							console.log("领取失败66666");
							_this.$message({
								message: res.msg,
								type: "info",
								customClass: "mzindex",
							});
						}
						_this.inputMoney = "";
						// console.log(" this.inputMoney", _this.inputMoney);
					});
			}
		},
		// isNewShow() {
		//   this.newShow = false;
		// },
		closeBot2() {
			this.dialogBox = false;
		},
		closeBot3() {
			this.dialogBox2 = false;
		},
		goPaySub() {
			// console.log("11111");
			this.dialogBox = true;
		},
		isFuliShow() {
			// this.$refs.child.isNewShow2();
			this.newShow = true;
		},
		toxieyi(type) {
			this.$store.commit("getLogin", false);
			this.$router.push({
				path: `/Privacy`,
				query: {
					type: type,
				},
			});
		},
		changeNav(index) {
			this.nav = index;
			this.loginForm = {
				phoneinput4: this.$route.query.code,
			};
			if (index == 1) {
				this.showPhone = false;
				this.regBox = true;
			} else {
				this.showEmail = false;
			}
		},
		clickOut() {
			if (this.windowWidth < 1024) {
				this.showNav = false;
			}
		},
		//左侧导航
		goMenu(id, path) {
			// console.log("导航id6666", id, path);
			if (this.windowWidth < 1024) {
				this.showNav = !this.showNav;
			}

			for (let i = 0; i < this.menu.length; i++) {
				if (id == this.menu[i].id) {
					this.menu[i].selState = true;
				} else {
					this.menu[i].selState = false;
				}
			}
			this.$router.push({
				path: `/${path}`,
			});
		},
		goMenucopy(id, path) {
			for (let i = 0; i < this.menu.length; i++) {
				if (id == this.menu[i].id) {
					this.menu[i].selState = true;
				} else {
					this.menu[i].selState = false;
				}
			}
			this.$router.push({
				path: `/${path}`,
			});
		},
		switchBar() {
			this.rightBar = !this.rightBar;
		},
		qqGroup() {
			window.open("https://jq.qq.com/?_wv=1027&k=DRGtGaXu");
		},
		getActive() {
			let param = {
				player_id: localStorage.getItem("id"),
			};
			this.$axios
				.post("/index/User/giveAboutRecharge", this.$qs.stringify(param))
				.then((res) => {
					if (res.data.status == 1) {
						if (res.data.data.new) {
							res.data.data.recharge_activity.forEach((e) => {
								e.type == 1 ? (this.giveBillie = e.billie) : "";
								e.type == 2 ? (this.giveBillie = e.billie) : "";
							});
						} else {
							res.data.data.recharge_activity.forEach((e) => {
								e.type == 2 ? (this.giveBillie = e.billie) : "";
							});
						}
					}
				});
		},
		//取回助手
		backAction(click) {
			if (click) {
				this.rightBarDrawer = true;
			}
			let param = {
				player_id: this.$store.state.id,
				page: 1,
				pageSize: 10,
			};
			this.$axios
				.post("/index/User/getRetrieveStatus", this.$qs.stringify(param))
				.then((res) => {
					let data = res.data;
					//  console.log(data);
					if (data.status == 1) {
						this.tableData = data.data.list;
						this.num = data.data.list.length;
					} else {
						this.tableData = [];
						this.num = 0;
					}
				});
		},
		//单个取回
		takeOffer(url) {
			window.open(url, "_blank");
		},
		tableRowStyle({
			row,
			rowIndex
		}) {
			return "background-color: red";
		},
		handleClose() { },
		funcHidden() {
			this.rightBarDrawer = false;
		},
		funcRefresh() {
			this.backAction();
		},
		//选取语言
		getLanguage() {
			this.language = !this.language;
		},
		//改变语言
		changeLanguage(index) {
			this.languageImg = this.languageList[index].url;
			this.languageText = this.languageList[index].name;
			this.language = false;
		},
		//菜单路径跳转
		gopath(key) {
			if (this.checked1 == false) {
				this.$message({
					message: "请勾选协议",
					type: "none",
				});
				return;
			}
			if (this.checked2 == false) {
				this.$message({
					message: "请勾选协议",
					type: "none",
				});
				return;
			}
			if (this.checked3 == false) {
				this.$message({
					message: "请勾选协议",
					type: "none",
				});
				return;
			}
			if (this.checked4 == false) {
				this.$message({
					message: "请勾选协议",
					type: "none",
				});
				return;
			}
			if (this.$store.state.id) {
				this.$router.push({
					path: `/${key}`,
				});
				this.dialogBox = false;
			} else {
				this.$store.commit("getLogin", true);
				this.dialogBox = false;
			}
		},
		//跳转到疑问解答
		goDoubt() {
			this.$router.push({
				path: `/Doubt`,
			});
		},
		//右侧菜单跳转饰品商城
		goRoll() {
			this.$router.push({
				path: `/Roll`,
			});
			this.drawer = false;
		},
		//跳转充值界面
		goPayment() {
			this.$router.push({
				path: `/Payment`,
			});
			this.drawer = false;
		},
		//右侧菜单路径
		gopath1(key, keyPath) {
			console.log("key6666", key);
			this.indexSele = key;
			this.$router.push({
				path: `/${key}`,
			});
			this.drawer = false;
		},
		//注册盒子显示隐藏
		showReg() {
			this.regBox = true;
		},
		hideReg() {
			this.regBox = false;
		},
		//点击手机或邮箱注册
		phoneReg() {
			this.phoneregBox = true;
			this.emilregBox = false;
		},
		emilReg() {
			this.phoneregBox = false;
			this.emilregBox = true;
		},

		//忘记密码
		goForget() {
			//this.loginBox = false;
			this.$store.commit("getLogin", false);
			this.forgetBox = true;
		},
		goLogin1() {
			console.log("12345");
			//this.loginBox = true;
			this.mmForm = {};
			this.$store.commit("getLogin", true);
			this.forgetBox = false;
		},
		hideForgetBox() {
			this.mmForm = {};
			this.forgetBox = false;
		},
		getForgetCode() {
			if (!this.mmForm.forgetNum) {
				if (this.showEmails) {
					this.$message({
						message: "请输入邮箱",
						type: "error",
					});
				} else {
					this.$message({
						message: "请输入手机号",
						type: "error",
					});
				}
				return;
			}
			let time = parseInt(new Date().getTime() / 1000);
			let sign = this.$md5("cs#.go" + time);
			let param = {
				account: this.mmForm.forgetNum,
				type: 3,
				time: time,
				sign: sign,
			};
			let url = this.showEmails ?
				"api/Register/get_email_code" :
				"api/Register/getCode";
			this.$axios.post(url, this.$qs.stringify(param)).then((res) => {
				let data = res.data;
				// console.log(data);
				if (data.status == 1) {
					this.forgetcodeState = false;
					this.forgetHint = false;
				} else {
					this.$message({
					message: data.msg,
					type: "error",
				});
					this.forgetHint = true;
					this.forgetHintText = data.msg;
				}
			});
		},
		reset() {
			var _this = this;
			if (!_this.mmForm.forgetNum) {
				this.$message({
					message: "请输入手机号或邮箱",
					type: "error",
				});
				return;
			}
			if (!_this.mmForm.forgetCode) {
				this.$message({
					message: "请输入验证码",
					type: "error",
				});
				return;
			}
			if (!_this.mmForm.forgetPass) {
				this.$message({
					message: "请输入新密码",
					type: "error",
				});
				return;
			}
			if (_this.mmForm.forgetNPass != _this.mmForm.forgetPass) {
				this.$message({
					message: "两次密码输入不一致",
					type: "error",
				});
				return;
			}
			let time = parseInt(new Date().getTime() / 1000);
			let sign = _this.$md5("cs#.go" + time);
			let param = {
				account: _this.mmForm.forgetNum,
				code: _this.mmForm.forgetCode,
				newpassword: _this.mmForm.forgetPass,
				newrepassword: _this.mmForm.forgetNPass,
				account_type: _this.showEmails ? 2 : 1,
				time: time,
				sign: sign,
			};
			this.$axios
				.post("api/User/resetpwd", this.$qs.stringify(param))
				.then((res) => {
					let data = res.data;
					//  console.log(data);
					if (data.code == 1) {
						this.$message({
							message: data.msg,
							type: "success",
						});
						this.forgetBox = false;
						// this.loginBox = true;
						this.$store.commit("getLogin", true);
					} else {
						this.$message({
							message: data.msg,
							type: "error",
						});
					}
				});
		},

		//获取验证码
		getphoneCode() {
			//this.loadingReg = true;
			let _this = this;
			if (!_this.loginForm.account) {
				this.$message({
					message: "请输入手机号或邮箱",
					type: "error",
				});
				return;
			}
			let type = _this.nav == 0 ? 1 : 2;
			let time = parseInt(new Date().getTime() / 1000);
			let sign = _this.$md5("cs#.go" + time);
			// console.log(type);
			let param = {
				account: _this.loginForm.account,
				type: type,
				sign: sign,
				time: time,
			};
			let url = _this.showEmail ?
				"/api/Register/get_email_code" :
				"/api/Register/getCode";
			_this.$axios.post(url, _this.$qs.stringify(param)).then((res) => {
				//  console.log(res);
				let data = res.data;
				if (data.status == 1) {
					this.loadingReg = false;
					this.phonecodeState = false;
					this.regHint = false;
				} else {
					this.loadingReg = false;
					this.regHint = true;
					this.regHintText = data.msg;
				}
			});
		},

		// 获取参数
		GetUrlParam(name) {
			var url = window.location.href;
			let params = url.substr(url.lastIndexOf("?") + 1).split("&");
			for (let i = 0; i < params.length; i++) {
				let param = params[i];
				let key = param.split("=")[0];
				let value = param.split("=")[1];
				if (key === name) {
					return value;
				}
			}
		},

		//点击注册按钮
		regBtn() {
			let _this = this;
			if (!_this.loginForm.account) {
				this.$message({
					message: "请输入手机号或邮箱",
					type: "error",
				});
				return;
			}
			if (!_this.loginForm.phoneinput3) {
				this.$message({
					message: "请输入验证码",
					type: "error",
				});
				return;
			}
			if (!_this.loginForm.password) {
				this.$message({
					message: "请输入密码",
					type: "error",
				});
				return;
			}
			if (!_this.loginForm.card_id) {
				this.$message({
					message: "请输入身份证号",
					type: "error",
				});
				return;
			}
			if (!_this.checked) {
				this.$message({
					message: "请阅读并勾选《用户协议》和《隐私政策》",
					type: "error",
				});
				return;
			}
			//var a = this.GetUrlParam("code");
			//var code = a.split("#")[0];

			var param = {
				account: _this.loginForm.account,
				code: _this.loginForm.phoneinput3,
				password: _this.loginForm.password,
				nickname: _this.loginForm.nickname,
				card_id: _this.loginForm.card_id,
				invite_code: _this.loginForm.phoneinput4,
				account_type: _this.showEmail ? 2 : 1,
				type: 2,
			};
			_this
				.$post("/api/Register/checkCode", param)
				.then((res) => {
					if (res.code == 1) {
						this.$message({
							message: res.msg,
							type: "success",
						});
						this.regBox = false;
						this.$store.commit("getLogin", false);
						this.loginfalse = false;
						this.regHint = false;
						localStorage.setItem("token", res.data.token);
						localStorage.setItem("csgoNum", this.loginForm.account);
						localStorage.setItem("csgoPass", this.loginForm.password);
						localStorage.setItem("id", res.data.id);
						localStorage.setItem("userInfo", JSON.stringify(res.data));
						this.selfLogin();
						this.$store.state.mobile = res.data.mobile;
						this.$store.commit("getId", res.data);
						this.me = res.data;
					} else {
						// this.regHint = true;
						// this.regHintText = data.msg;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		getemilCode() {
			//this.emilcodeState = false;
			this.$message({
				message: "后续将会开放邮箱注册",
				type: "warning",
			});
		},

		//显示隐藏登录框
		showLogin(index) {
			//this.loginBox = true;
			this.nav = index;
			this.$store.commit("getLogin", true);
		},
		hideLogin() {
			//this.loginBox = false;
			this.loginForm = {
				phoneinput4: this.$route.query.code,
			};
			this.$store.commit("getLogin", false);
		},

		//点开登录 注册盒子
		goLogin() {
			this.regBox = false;
			//this.loginBox = true;
			this.$store.commit("getLogin", true);
		},
		goReg() {
			this.regBox = true;
			//this.loginBox = false;
			this.$store.commit("getLogin", false);
		},
		//自动登录
		// selfLogin() {
		//   let num = localStorage.getItem("csgoNum");
		//   let pass = localStorage.getItem("csgoPass");
		//   if (num) {
		//     let param = {
		//       account: num,
		//       password: pass,
		//     };
		//     this.$axios
		//       .post("/index/Login/Login", this.$qs.stringify(param))
		//       .then((res) => {
		//         let data = res.data;
		//         //  console.log(data);
		//         if (data.status == 1) {
		//           localStorage.setItem("csgoNum", num);
		//           localStorage.setItem("csgoPass", pass);
		//           localStorage.setItem("id", data.data.id);
		//           // this.loginBox = false;
		//           this.$store.state.mobile = data.data.mobile;
		//           this.$store.commit("getLogin", false);
		//           this.loginfalse = false;
		//           this.regHint = false;
		//           this.$store.commit("getId", data.data);
		//           this.me = data.data;
		//         }
		//       });
		//   }
		// },
		selfLogin() {
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			if (userInfo) {
				this.me = userInfo;
				this.money = userInfo.total_amount;
				this.integral_money = userInfo.score;
				this.loginfalse = false;
				this.regHint = false;
			}
		},
		//steam登录
		login_steam() {
			let _this = this;
			let param = {
				steam_login: "steam_login",
			};
			_this.$axios
				.post("/index/Login/steam_login", _this.$qs.stringify(param))
				.then((res) => {
					// console.log(res.data.data);
					let re = _this.isUrl(res.data.data);
					if (re) {
						// window.open(res.data.data,'_blank');
						window.location.href = res.data.data;
						return;
					}
					return;
					// window.location.href='/'
					let data = res.data;
					if (res.data.status == 1) {
						localStorage.setItem("csgoNum", _this.account);
						localStorage.setItem("csgoPass", _this.password);
						localStorage.setItem("id", data.data.id);
						localStorage.setItem("userInfo", JSON.stringify(res.data.data));
						//_this.loginBox = false;
						setTimeout(() => {
							_this.$store.state.mobile = data.data.mobile;
							_this.$store.commit("getLogin", false);
							_this.loginfalse = false;
							_this.regHint = false;
							_this.$store.commit("getId", data.data);
							_this.me = data.data;
							_this.selfLogin();
							_this.loging = "";
						}, 500);
					} else {
						_this.loginHintState = true;
						_this.loginHintText = data.msg;
						setTimeout(() => {
							_this.loging = "";
						}, 500);
					}
				});
		},
		isUrl(url) {
			var reg = "[a-zA-z]+://[^\s]*"; //正则
			if (url.length > 0) {
				var reg_test = new RegExp(reg);
				var result = reg_test.test(url);
				// console.log(result);
				if (result != 1) {
					return false;
				} else {
					return true;
				}
			}
		},
		//登录按钮 记住账号密码
		getLogin() {
			let _this = this;
			if (!_this.loginForm.account) {
				this.$message({
					message: _this.showPhone ? "请输入手机号" : "请输入账号",
					type: "error",
				});
				return;
			}
			if (!_this.showPhone) {
				if (!_this.loginForm.password) {
					this.$message({
						message: "请输入密码",
						type: "error",
					});
					return;
				}
			}
			if (_this.showPhone) {
				if (!_this.loginForm.phoneinput3) {
					this.$message({
						message: "请输入验证码",
						type: "error",
					});
					return;
				}
			}
			if (!_this.checked) {
				this.$message({
					message: "请阅读并勾选《用户协议》和《隐私政策》",
					type: "error",
				});
				return;
			}
			if (_this.showPhone) {
				//手机号登录
				var param = {
					account: _this.loginForm.account,
					code: _this.loginForm.phoneinput3,
					account_type: 1,
				};
			} else {
				//密码登录
				var param = {
					account: _this.loginForm.account,
					password: _this.loginForm.password,
				};
			}
			let url = _this.showPhone ? "/api/Login/code_login" : "/api/Login/login";
			_this.loging = "loging";
			_this
				.$post(url, param)
				.then((res) => {
					_this.loginReturn = res.data;
					if (res.code == 1) {
						localStorage.setItem("token", res.data.token);
						localStorage.setItem("csgoNum", this.loginForm.account);
						localStorage.setItem("csgoPass", this.loginForm.password);
						localStorage.setItem("id", res.data.id);
						localStorage.setItem("userInfo", JSON.stringify(res.data));
						// _this.$bus.$emit("newShow", true);
						_this.$bus.$emit("informShow", true);
						//_this.loginBox = false;
						_this.initFun();
						setTimeout(() => {
							_this.$store.state.mobile = res.data.mobile;
							_this.$store.commit("getLogin", false);
							_this.loginfalse = false;
							_this.regHint = false;
							_this.$store.commit("getId", res.data);
							_this.me = res.data;
							_this.selfLogin();
							_this.loging = "";
							_this.loginForm = {
								phoneinput4: _this.$route.query.code,
							};
						}, 500);
					} else {
						this.loginHintState = true;
						this.$message({
							message: data.msg,
							type: "warning",
						});
						setTimeout(() => {
							_this.loging = "";
						}, 500);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		//退出登录
		nextLogin() {
			this.loginfalse = true;
			this.drawer = false;
			localStorage.removeItem("token");
			localStorage.removeItem("csgoNum");
			localStorage.removeItem("csgoPass");
			localStorage.removeItem("userInfo");
			this.$store.commit("getId", {
				name: "",
				id: "",
				img: "",
				money: "",
				integral_money: "",
			});
			this.$router.push({
				path: `/Index`,
			});
		},

		//红包
		//打开红包
		openHongbao() {
			let param = {
				player_id: this.$store.state.id,
			};
			this.$axios
				.post("index/Activity/existEnvelope", this.$qs.stringify(param))
				.then((res) => {
					let data = res.data;
					//  console.log(data);
					if (data.status == 1) {
						this.hongbaoid = data.data.id;
						//未抢
						if (data.data.status == 0) {
							this.hongbaoState1 = true;
						} else {
							this.hongbaoState2 = true;
							this.hongbaoList();
						}
					} else {
						if (data.msg == "参数错误") {
							this.$message({
								message: "请先登录",
								type: "warning",
							});
							this.$store.commit("getLogin", true);
						} else {
							this.$message({
								message: "暂时没有红包哦",
								type: "warning",
							});
						}
					}
				});
		},
		//红包数据
		hongbaoList() {
			let param = {
				player_id: this.$store.state.id,
				envelope_id: this.hongbaoid,
			};
			this.$axios
				.post("index/Activity/getDetails", this.$qs.stringify(param))
				.then((res) => {
					let data = res.data;
					//  console.log(data);
					if (data.status == 1) {
						let hongbaoDataCopy = data.data.details;
						this.my_hongbao = data.data.my_envelope;
						if (hongbaoDataCopy.length > 4) {
							for (let i = 0; i < hongbaoDataCopy.length; i++) {
								if (i < 5) {
									this.hongbaoData.push(hongbaoDataCopy[i]);
								}
							}
						} else {
							this.hongbaoData = hongbaoDataCopy;
						}
					}
				});
		},
		//领取红包
		getHongbao() {
			if (!this.hongbaoText) {
				this.$message({
					message: "请输入红包口令",
					type: "warning",
				});
				return;
			}
			// this.hongbaoState1 = false;
			// this.hongbaoState2 = true;
			let param = {
				player_id: this.$store.state.id,
				envelope_id: this.hongbaoid,
				password: this.hongbaoText,
			};
			this.$axios
				.post("index/Activity/envelope", this.$qs.stringify(param))
				.then((res) => {
					let data = res.data;
					//  console.log(data);
					if (data.status == 1) {
						this.hongbaoState1 = false;
						this.hongbaoState2 = true;
						this.hongbaoList();
					} else {
						this.$message({
							message: data.msg,
							type: "warning",
							customClass: "mzindex",
						});
					}
				});
		},

		hideHongbao1() {
			this.hongbaoState1 = false;
			this.hongbaoText = "";
		},
		hideHongbao2() {
			this.hongbaoState2 = false;
		},

		//手机端打开左侧菜单
		showMenu() {
			this.showNav = !this.showNav;
			// if (this.windowWidth < 1024) {
			//   this.showNav = true;
			// }
		},

		//右侧导航关闭
		handleClose(done) {
			done();
		},
		drawerFun() {
			this.drawer = !this.drawer;
		},
		//领取红包
		getAwardList() {
			// if (!this.hongbaoText) {
			//   this.$message({
			//     message: "请输入红包口令",
			//     type: "warning",
			//   });
			//   return;
			// }
			let param = {
				page: this.page,
			};
			this.$axios
				.post("award/open_box_award_list", this.$qs.stringify(param))
				.then((res) => {
					let data = res.data;
					if (data.status == 1) { } else {
						this.$message({
							message: data.msg,
							type: "warning",
							customClass: "mzindex",
						});
					}
				});
		},
	},

	created() {
		var _this = this;
		document.onkeydown = (e) => {
			let e1 =
				e || event || window.event || arguments.callee.caller.arguments[0];
			if (e1 && e1.keyCode == 13) {
				if (this.$store.state.loginState) {
					_this.getLogin();
				}
			}
		};
	},
};
</script>

<style lang="less" scoped>
.zhubo{
	background: url('../assets/newimg/header.png') no-repeat center center;
	background-size: 100% 100%;
	padding: 5px !important;
}
.yonghu{
	background: url('../assets/newimg/header2.png') no-repeat center center;
	background-size: 100% 100%;
	padding: 5px !important;
}
.mzindex {
	z-index: 9999999 !important;
}

.home {
	width: 100%;
	height: 100vh;
	overflow-y: scroll;

	.new-box {
		position: fixed;
		z-index: 999;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 99;

		.new {
			width: 56%;
			position: absolute;
			top: 60%;
			left: 43%;
			transform: translate(-50%, -50%);
			position: relative;

			.open-money {
				opacity: 0;
				position: absolute;
				top: 30%;
				left: 42%;
				width: 100px;
				height: 100px;
				background: #c42727;
				z-index: 100;
			}

			.new-top {
				width: auto;
				// display: flex;
				// justify-content: center;
				position: absolute;
				right: 0;
				top: -80px;

				// margin-bottom: 30px;
				img {
					width: 470px;
					height: 138px;
				}
			}

			.new-con.xr {
				background: url(../assets/newimg/img_93.png) no-repeat;
				background-size: 100% 100%;
				height: 360px;
				padding: 40px;

				.xr-box {
					font-size: 16px;
					color: #ffffff;
					line-height: 36px;
				}
			}

			.new-con.hb {
				background: url(../assets/newimg/img_92.png) no-repeat;
				background-size: 100% 100%;
				padding-left: 32px;
				padding-right: 32px;

				.hb-title {
					font-size: 16px;
					font-family: Source Han Sans CN-Bold, Source Han Sans CN;
					font-weight: bold;
					color: #ffc400;
				}

				.hb-box {
					font-size: 16px;
					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
					font-weight: 400;
					color: #ffffff;
					line-height: 36px;
					margin-top: 20px;
					overflow-y: scroll;
				}

				.hb-box::-webkit-scrollbar {
					width: 4px;
				}

				.hb-box::-webkit-scrollbar-thumb {
					border-radius: 10px;
					background: rgba(0, 0, 0, 0.2);
				}

				.hb-box::-webkit-scrollbar-track {
					border-radius: 0;
					background: rgba(0, 0, 0, 0.1);
				}
			}

			.new-con {
				width: 1216px;
				height: 65vh;
				background: url(../assets/newimg/img_90.png) no-repeat;
				background-size: 100% 100%;
				margin: 0 auto;
				// padding: 45px 20px 20px 20px;
				// box-sizing: border-box;
				position: relative;
				border: 1px solid transparent;
				// overflow: hidden;
				display: flex;
    			justify-content: center;
				.hongbao {
					width: 604px;
					height: 472px;
					position: absolute;
					top: 15px;
					left: 160px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.hbtitle {
					width: auto;
					font-size: 32px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					font-style: italic;
					color: #f3d8ff;
					position: absolute;
					bottom: 120px;
					left: 260px;
				}

				.con-box {
					width: 40%;
					height: 80%;
					margin-left: 50%;
					margin-top: 6%;

					.fuli-con {
						width: 522px;
						height: 80%;
						// background-color: #ccc;
						margin-left: 35px;
						margin-top: 80px;
						background: url(../assets/newimg/fanli.png) no-repeat;
						background-size: 100% 100%;
						overflow: hidden;
						position: relative;

						.fanlihongbao {
							width: 90%;
							text-align: center;
							font-size: 18px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #4f1701;
							margin: 0 auto;
							margin-top: 133px;
							margin-right: 35px;
						}

						.lingqu {
							width: 179px;
							height: 49px;
							background: url(../assets/newimg/lingqu.png) no-repeat;
							background-size: 100% 100%;
							position: absolute;
							bottom: 22px;
							left: 162px;
							text-align: center;
							line-height: 49px;
							font-size: 18px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #5a0273;
						}

						.guize {
							font-size: 16px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #9b46cb;
							text-align: center;
							margin-top: 55px;
						}

						.el-input {
							width: 208px;
							display: block;
							// margin:0 auto;
							margin-top: 40px;
							margin-left: 28%;

							/deep/.el-input__inner {
								width: 208px;
								height: 47px;
								background: rgba(0, 8, 52, 0.15);
								border: 1px solid #8597f7;
								border-radius: 10px;

								// margin-right: 35px;
							}
						}
					}

					.box-3 {
						width: 539px;
						height: auto;
						margin-left: 35px;
						margin-top: 80px;

						.hbbanner {
							width: 100%;
							height: 308px;

							// background-color: #ccc;
							img {
								width: 100%;
								height: 100%;
							}
						}

						.nowqd {
							width: 200px;
							height: 100px;
							background: url(../assets/newimg/nowqd.png) no-repeat;
							background-size: 100% 100%;
							text-align: center;
							line-height: 100px;
							font-size: 20px;
							font-family: Source Han Sans CN;
							font-weight: 500;
							color: #4f1701;
							margin-left: 113px;
							margin-top: 25px;
						}
					}

					.newRed-packet {
						width: 553px;
						height: 100%;
						background: url(../assets/newimg/hongbaokuang.png) no-repeat;
						background-size: 100% 100%;
						padding: 10px;

						// opacity: 0.7;
						.kai-box {
							width: 330px;
							height: 61px;
							display: flex;
							justify-content: space-between;
							background: url(../assets/newimg/kaibg.png) no-repeat;
							background-size: 100% 100%;
							font-size: 30px;
							font-family: Adobe Heiti Std;
							font-weight: normal;
							color: #01104e;

							.kai-box1 {
								width: 210px;
								height: 80px !important;
								background: url(../assets/newimg/kaiSele.png) no-repeat;
								background-size: 100% 100%;
								line-height: 80px !important;
								text-align: center;
								margin-top: -15px;
								margin-left: -15px;
								font-size: 22px !important;
								font-family: Adobe Heiti Std !important;
								font-weight: normal !important;
								color: #01104e !important;
							}

							.kai-box2 {
								width: 220px !important;
								height: 100%;
								text-align: center;
								line-height: 61px;
								font-size: 20px;
								font-family: Adobe Heiti Std;
								font-weight: normal;
								color: #d5e4ff;
							}
						}

						.gang {
							width: 100%;
							display: block;
							margin-top: -2px;
						}

						.bao-list {
							width: 100%;
							height: 43px;
							display: flex;
							justify-content: space-between;
							margin-top: 10px;
							.bao-info {
								width: 400px;
								height: 43px;
								background: url(../assets/newimg/baolist.png) no-repeat;
								background-size: 100% 100%;
								padding-left: 14px;
								display: flex;
								align-items: center;
								font-family: Adobe Heiti Std;
								font-weight: normal;
								color: #00ffe4;
							font-size: 12px !important;

							}

							.goBtn {
								width: 116px;
								height: 43px;
								background: url(../assets/newimg/gobtn.png) no-repeat;
								background-size: 100% 100%;
								line-height: 43px;
								text-align: center;
								font-size: 13px;
								font-family: Adobe Heiti Std;
								font-weight: normal;
								color: #12e3eb;
							}
						}
					}

					.newRed-packet2 {
						width: 553px;
						height: 448px;
						background: url(../assets/newimg/hongbaokuang.png) no-repeat;
						background-size: 100% 100%;
						padding: 10px;
						// opacity: 0.7;
						position: relative;

						.gang {
							width: 100%;
							display: block;
							margin-top: -2px;
						}

						.bao-list {
							width: 100%;
							height: 43px;
							display: flex;
							justify-content: space-between;
							margin-top: 10px;

							.bao-info {
								width: 400px;
								height: 43px;
								background: url(../assets/newimg/baolist.png) no-repeat;
								background-size: 100% 100%;
								padding-left: 14px;
								display: flex;
								align-items: center;
								font-size: 16px;
								font-family: Adobe Heiti Std;
								font-weight: normal;
								color: #00ffe4;
							}

							.goBtn {
								width: 116px;
								height: 43px;
								background: url(../assets/newimg/gobtn.png) no-repeat;
								background-size: 100% 100%;
								line-height: 43px;
								text-align: center;
								font-size: 13px;
								font-family: Adobe Heiti Std;
								font-weight: normal;
								color: #12e3eb;
							}
						}

						.putinnder {
							width: 193px;
							height: 71px;
							display: block;
							background: url(../assets/newimg/put.png) no-repeat;
							background-size: 100% 100%;
							position: absolute;
							right: -25px;
							margin-top: -114px;
							z-index: 10001;

							.el-input {
								width: 193px;
								height: 80%;
								display: flex;
								align-items: center;

								/deep/.el-input__inner {
									background: transparent;
									border: none;
									color: #fff;
									// width: 193px;
									// height: 71px;
								}
							}
						}
					}
				}

				.con-top {
					.con-1 {
						width: 350px;
						height: 40px;
						background: rgba(225, 225, 225, 0.5);
						border-radius: 84px;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #fff;
						font-size: 14px;
						margin: 0 auto;

						span {
							color: #ffc400;
						}

						img {
							width: 14px;
							height: 14px;
							margin: 0 8px;
						}
					}

					.con-2 {
						margin-top: 25px;
						text-align: center;

						/deep/ .el-input__inner {
							width: 222px;
							height: 40px;
							background: #ffffff;
							border-radius: 84px 84px 84px 84px;
						}
					}
				}

				.con-bottom {
					position: absolute;
					top: 15%;
					color: #fff;
					width: 326px;
					height: auto;
					right: -235px;
					padding: 0 20px;
					box-sizing: border-box;
					z-index: 9999;

					// position: relative;
					.title {
						text-align: center;

						span {
							color: #ffc400;
						}
					}

					// .con-box {
					//   width: 100%;
					//   height: 320px;
					//   // border: 2px solid #ffc400;
					//   margin-top: 15px;
					//   .box-1 {
					//     padding: 25px;
					//     box-sizing: border-box;
					//     overflow-y: scroll;
					//     .newRed-packet {
					//       width: 553px;
					//       height: 448px;
					//       border: 2px solid #5197ff;
					//       opacity: 0.7;
					//       position: relative;
					//     }
					//     // ul {
					//     //   li {
					//     //     display: flex;
					//     //     border-bottom: 1px solid #ffc400;
					//     //     padding-bottom: 15px;
					//     //     margin-bottom: 15px;
					//     //     .left {
					//     //       width: 92px;
					//     //       height: 92px;
					//     //       border: 1px solid #ffc400;
					//     //       display: flex;
					//     //       align-items: center;
					//     //       justify-content: center;
					//     //       .box {
					//     //         width: 79px;
					//     //         height: 78px;
					//     //         border: 1px solid #ffc400;
					//     //         display: flex;
					//     //         align-items: center;
					//     //         justify-content: center;
					//     //         font-size: 13px;
					//     //         text-align: center;
					//     //       }
					//     //     }
					//     //     .right {
					//     //       margin-left: 25px;
					//     //       font-size: 13px;
					//     //       width: calc(100% - 117px);
					//     //       .titles {
					//     //         display: flex;
					//     //         align-items: center;
					//     //         justify-content: space-between;
					//     //       }
					//     //       .dl {
					//     //         margin-top: 15px;
					//     //         .dd {
					//     //           margin-bottom: 6px;
					//     //         }
					//     //       }
					//     //     }
					//     //   }
					//     // }
					//   }
					//   .box-1::-webkit-scrollbar {
					//     width: 4px;
					//   }
					//   .box-1::-webkit-scrollbar-thumb {
					//     border-radius: 10px;
					//     background: rgba(0, 0, 0, 0.2);
					//   }
					//   .box-1::-webkit-scrollbar-track {
					//     border-radius: 0;
					//     background: rgba(0, 0, 0, 0.1);
					//   }
					//   .box-3 {
					//     .title {
					//       padding-top: 38px;
					//       text-align: center;
					//       font-size: 16px;
					//       color: #ffffff;
					//       span {
					//         color: #ffc400;
					//       }
					//     }
					//     .ewm {
					//       width: 194px;
					//       height: 194px;
					//       background: #ffffff;
					//       margin: 30px auto;
					//     }
					//     p {
					//       color: #ffc400;
					//       text-align: center;
					//     }
					//   }
					// }
					.pay-nav {
						height: 37px;
						margin-top: 40px;
						color: #fff;
						position: relative;
						z-index: 100000;
						margin-left: 20px;
					}

					.nav_item {
						width: 256px;
						height: 88px;
						text-align: center;
						line-height: 88px;
						font-size: 14px !important;
						background-size: contain;
						font-weight: 700;
						cursor: pointer;
						padding-left: 15px;
					}

					.nav_item:first-child {
						background: url(../assets/newimg/img_04.png) no-repeat 50%;
						background-size: 100% 100%;
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #ffffff;
					}

					.nav_item:first-child.actives {
						background: url(../assets/newimg/img_05.png) no-repeat 50%;
						color: #000;
						background-size: 100% 100%;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #000e4d;
					}

					.nav_item:nth-child(2).actives {
						background: url(../assets/newimg/img_05.png) no-repeat 50%;
						color: #000;
						background-size: 100% 100%;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #000e4d;
					}

					.nav_item:nth-child(2) {
						background: url(../assets/newimg/img_04.png) no-repeat 50%;
						// margin-left: -20px;
						background-size: 100% 100%;
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #ffffff;
					}

					.nav_item:nth-child(3).actives {
						background: url(../assets/newimg/img_05.png) no-repeat 50%;
						color: #000;
						background-size: 100% 100%;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #000e4d;
					}

					.nav_item:nth-child(3) {
						background: url(../assets/newimg/img_04.png) no-repeat 50%;
						// margin-left: -20px;
						background-size: 100% 100%;
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #ffffff;
					}

					.nav_item:nth-child(4).actives {
						background: url(../assets/newimg/img_05.png) no-repeat 50%;
						color: #000;
						background-size: 100% 100%;
						font-size: 36px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #000e4d;
					}

					.nav_item:nth-child(4) {
						background: url(../assets/newimg/img_04.png) no-repeat 50%;
						// margin-left: -20px;
						background-size: 100% 100%;
						font-size: 30px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #ffffff;
					}
				}
			}

			.new-x {
				position: absolute;
				top: -5%;
				right: -90px;
				width: 52px;
				height: 45px;

				img {
					width: 100px;
					height: 100px;
				}
			}
		}
	}

	.dh_main {
		position: fixed;
		top: 20%;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 570px;
		height: 450px;
		background: url(../assets/newimg/selectbg.png) no-repeat;
		background-size: 100% 100%;
		padding: 50px;
		box-sizing: border-box;
		z-index: 20;

		.new-x2 {
			position: absolute;
			top: -40px;
			right: 0px;
			width: 52px;
			height: 45px;

			img {
				width: 52px;
				height: 45px;
			}
		}

		.checks {
			.hobby1 {
				width: 85%;
				height: 57px;
				// background: url(../assets/newimg/img_64.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				padding: 0 20px;
				display: flex;
				align-items: center;
				margin: 0 auto;

				// text-align: center;
				// justify-content: space-between;
				// margin-right: 70px;
				.mask {
					display: block;
					color: #fff;
					margin-left: 20px;
				}
			}

			.btn {
				width: 157px;
				height: 44px;
				background: url(../assets/newimg/img_15.png) no-repeat;
				background-size: 100% 100%;
				margin: 0 auto;
				cursor: pointer;
				text-align: center;
				line-height: 30px;
				// line-height: 44px;
				margin-top: 20px;
				color: #fff;
			}
		}

		.title {
			color: #fff;
			text-align: center;
			font-size: 18px;
			margin-bottom: 20px;
		}

		.goods_img {
			text-align: center;

			img {
				width: auto;
				height: 235px;
			}
		}

		.goods_price {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 16px;

			img {
				width: 20px;
				height: 20px;
				margin-right: 8px;
			}
		}

		.goods_btn {
			display: flex;
			justify-content: space-around;
			margin-top: 30px;

			.btn {
				width: 157px;
				height: 44px;
				background: url(../assets/newimg/img_15.png) no-repeat;
				background-size: 100% 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}
	}

	.dh_main2 {
		position: fixed;
		top: 30%;
		left: 50%;
		transform: translate(-50%, 0%);
		width: 350px;
		height: 180px;
		background: url(../assets/newimg/selectbg.png) no-repeat;
		background-size: 100% 100%;
		padding: 50px;
		box-sizing: border-box;
		z-index: 20;

		.new-x2 {
			position: absolute;
			top: -50px;
			right: 0px;
			width: 52px;
			height: 45px;

			img {
				width: 42px;
				height: 35px;
			}
		}

		.checks {
			.hobby1 {
				width: 85%;
				height: 57px;
				// background: url(../assets/newimg/img_64.png) no-repeat;
				background-size: 100% 100%;
				box-sizing: border-box;
				padding: 0 20px;
				display: flex;
				align-items: center;
				margin: 0 auto;

				// text-align: center;
				// justify-content: space-between;
				// margin-right: 70px;
				.mask {
					display: block;
					color: #fff;
					margin-left: 20px;
				}
			}

			.btn {
				width: 157px;
				height: 44px;
				background: url(../assets/newimg/img_15.png) no-repeat;
				background-size: 100% 100%;
				margin: 0 auto;
				cursor: pointer;
				text-align: center;
				line-height: 44px;
				margin-top: 20px;
				color: #fff;
			}
		}

		.title {
			color: #fff;
			text-align: center;
			font-size: 18px;
			margin-bottom: 20px;
		}

		.goods_img {
			text-align: center;

			img {
				width: auto;
				height: 235px;
			}
		}

		.goods_price {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 16px;

			img {
				width: 20px;
				height: 20px;
				margin-right: 8px;
			}
		}

		.goods_btn {
			display: flex;
			justify-content: space-around;
			margin-top: 30px;

			.btn {
				width: 157px;
				height: 44px;
				background: url(../assets/newimg/img_15.png) no-repeat;
				background-size: 100% 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}
	}

	.nav2 {
		// position: fixed;
		// top: 50%;
		// left: 0;
		height: 42px;

		// z-index: 999;
		ul {
			display: flex;

			li {
				float: left;
				display: flex;
				align-items: center;
				margin-left: 40px;
				cursor: pointer;

				img {
					display: block;
					height: 30px;
					width: auto;
				}

				.img3 {
					display: none;
					position: relative;
					cursor: pointer;
					// left: -40px;
				}

				.img4 {
					display: none;
				}

				div {
					color: #fff;
					font-size: 18px;
					line-height: 38px;
					margin-left: 10px;
					white-space: nowrap;
				}
			}

			li:nth-child(1) {
				margin-left: 0px;
			}

			li:hover {
				.img3 {
					display: block;
					// animation: run 0.5s;
					// animation-iteration-count: 1; //播放几次动画
					// animation-delay: 0s; //动画运行前等待时间
					// animation-fill-mode: forwards; //动画结束 是否保持

					// @keyframes run {
					//   0% {
					//     left: -40px;
					//   }
					//   100% {
					//     left: 0;
					//   }
					// }
				}

				.img1 {
					display: none;
				}

				.img2 {
					display: none;
				}
			}
		}
	}

	.item-val {
		color: #ff5cfd !important;
	}

	.top {
		// background-image: url("../assets/img/nav/nav10.png");
		background-color: #181921;
		// background-size: 100% 100%;
		box-shadow: 0px 5px 8px #333333;
		height: 75px;
		color: #fff;
		position: fixed;
		top: 0;
		z-index: 999;
		width: 100%;

		.top-l-r {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		// width: calc(100% - 17px);
		.top-el-row {
			display: flex;
			height: 100%;
		}

		.top-btn {
			position: absolute;
			display: none;
		}

		.logo {
			display: flex;
			// justify-content: center;
			align-items: center;
			width: calc(100% - 350px);

			//margin-left: 10px;
			.top-bag,
			.top-pay {
				margin-top: 10px;
				margin-left: 10px;
				display: flex;
				align-items: center;

				img {
					width: 15px;
					height: auto;
					margin-right: 4px;
				}

				.span {
					font-size: 15px;
					color: #848492;
				}

				.span:hover {
					color: #e9b10e;
					cursor: pointer;
				}
			}

			.top-pay {
				.pay-span {
					margin-left: 10px;
					color: #1a1c24;
					display: inline-block;
					padding: 0 10px;
					font-size: 14px;
					border-radius: 5px;
					background-color: #02bf4d;
					position: relative;
				}

				.pay-span::after {
					top: 50%;
					left: -4px;
					color: #02bf4d;
					width: 0;
					content: "";
					position: absolute;
					border-top: 4px solid transparent;
					margin-top: -4px;
					border-right: 4px solid;
					border-bottom: 4px solid transparent;
				}
			}

			.logo-left {
				width: 150px;
				text-align: center;

				.img1 {
					height: 52px;
					// margin-top: 5px;
					width: 59px;
				}
			}

			.zm {
				height: 40px;
				margin-top: 10px;
				width: auto;
			}

			.img2 {
				height: 30px;
				width: auto;
			}

			.img3 {
				height: 34px;
				width: auto;
			}

			.span-line {
				height: 42px;
				width: 2px;
				background-color: #999999;
				margin: 10px 15px;
			}
		}

		.top-name {
			width: max-content;
			padding-right: 10px;
			justify-content: space-between;
			align-items: center;
			margin-top: 2px;
			float: right;
			min-width: 190px;
			display: flex;

			.top-con {
				padding-left: 20px;
				border-left: 3px solid #999;
				display: flex;
				align-items: center;

				span {
					padding-left: 20px;
					color: #999;
				}
			}

			.top-right {
				display: flex;
				align-items: center;

				.btn {
					height: 48px;
					line-height: 30px;
					white-space: nowrap;
					margin-right: 20px;
					padding: 8px 25px;
					// border: 1px solid #ff9b0b;
					color: #fff;
					// border-radius: 5px;
					background: url(../assets/newimg/btn1.png) no-repeat;
					background-size: 100% 100%;
				}

				.btn:hover {
					cursor: pointer;
					background-color: #cacecc09;
				}

				.btn1 {
					width: 80px;
					height: 48px;
					border: none;
					text-align: center;
					line-height: 30px;
					color: #fff !important;
					// background-image: linear-gradient(to right, #ff571b, #ff9b0b);
					background: url(../assets/newimg/denglu.png) no-repeat;
					background-size: 100% 100%;
				}
			}

			.top-right1 {
				width: auto;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.mess {
					display: flex;
					align-items: center;
					position: relative;

					.anchor {
						position: absolute;
						left: 11px;

						// top: 0;
						img {
							width: 65px;
							height: 65px;
							border-radius: 50%;
						}
					}

					.mess-left {
						margin-left: 15px;
						height: 40px;
						width: 40px;
						img {
							width: 57px;
							height: 57px;
							border-radius: 50%;
						}
					}

					.mess-right {
						margin-left: 5px;
						display: flex;
						flex-direction: column;

						.mess-span1 {
							font-size: 14px;
							color: #fff;
							font-weight: 600;
						}

						.mess-span2 {
							display: flex;
							align-items: center;

							img {
								width: auto;
								height: 14px;
							}

							strong {
								margin-left: 5px;
								font-size: 14px;
								color: #fff;
							}
						}
					}
				}

				.top-right1-ico {

					// margin-right: 20px;
					img {
						width: 67px;
						height: 66px;
					}
				}
			}

			.top-right1:hover {
				cursor: pointer;
			}

			.p-r {
				display: flex;
				color: #848492;
				justify-content: flex-start;
				margin-top: 5px;

				.logo-span {
					cursor: pointer;
					display: flex;
					margin: 0 30px;
					//height:19px;
				}

				.logo-span:hover {
					.text {
						color: #e9b10e;
					}
				}

				.top-pay:nth-child(2) {
					margin-left: 12px;
				}

				.span,
				.pay-span {
					font-size: 14px;
					margin-left: 6px;
					white-space: nowrap;
				}

				.pay-span {
					margin-left: 10px;
					color: #1a1c24;
					display: inline-block;
					padding: 0 10px;
					font-size: 14px;
					border-radius: 5px;
					background-color: #02bf4d;
					position: relative;
				}

				.pay-span::after {
					top: 50%;
					left: -4px;
					color: #02bf4d;
					width: 0;
					content: "";
					position: absolute;
					border-top: 4px solid transparent;
					margin-top: -4px;
					border-right: 4px solid;
					border-bottom: 4px solid transparent;
				}
			}
		}
	}

	.isLogin {
		display: none !important;
	}
	.nav3{
		display: none;
	}
	.bot {
		margin-top: 75px;
		height: 100%;
		//display: flex;
		background-color: #25252f;

		.bot-left {
			height: 100%;
			min-width: 290px;
			max-width: 290px;
			background-color: #000;
			position: relative;
			box-shadow: 5px 0px 8px #262626;
			overflow: hidden;
			overflow-y: scroll;
			// background-image: url('../assets/img/navbg.png');
			-ms-overflow-style: none;

			.imgtop {
				position: absolute;
				right: 4%;
				top: 0;
				width: 90%;

				img {
					width: 100%;
				}
			}

			.img {
				height: 45%;
				position: absolute;

				img {
					//height: 100%;
					width: 240px;
					height: 266px;
				}

				img:hover {
					cursor: pointer;
				}

				.img11 {
					position: absolute;
					height: 50%;
					width: auto;
					top: -35%;
				}

				.img12 {
					right: -27%;
				}

				.img13 {
					left: -27%;
				}

				.class3 {
					position: absolute;
					height: 50%;
					width: auto;
					top: 7%;
					right: -67%;
				}

				.img-bot {
					height: 30%;
					position: absolute;
					bottom: -15%;
					right: 20%;
					display: flex;
					flex-direction: column;

					img {
						height: 100%;
						width: auto;
					}

					img:last-child {
						margin-top: 10px;
					}
				}
			}

			.img1 {
				top: -25px;
				left: -22px;
			}

			.img2 {
				top: 150px;
				left: 73px;
			}

			.img3 {
				top: 320px;
				left: -22px;
			}

			.img4 {
				top: 490px;
				left: 73px;
			}

			.img5 {
				top: 660px;
				left: -22px;
			}
		}

		.bot-left1 {
			display: block;
			position: fixed;
			top: 60px;
			left: 0;
			z-index: 666;
		}

		//设置左侧滚动条
		.bot-left::-webkit-scrollbar {
			width: 0px;
		}

		/* .bot-left::-webkit-scrollbar-track {
      -webkit-box-shadow: inset006pxrgba(255, 255, 255, 0.3);
      border-radius: 10px;
    }*/

		.bot-right {
			width: 100%;
			height: auto;
			// height: calc(100vh - 75px);
		}

		.el-loading-spinner {
			position: relative;
		}
	}

	//注册
	.reg,
	.login {
		background-color: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		z-index: 999;
		width: 100%;
		height: 100%;

		.reg-warp {
			padding: 30px;
			display: block;
			color: #848492;
			width: 25%;
			background-color: #1a1c24;
			border-radius: 5px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -180px;
			margin-top: -210px;

			.btn-x {
				width: 20px;
				height: 20px;
				text-align: center;
				line-height: 20px;
				border-radius: 50%;
				position: absolute;
				top: 2px;
				right: 4px;
				font-size: 15px;
				font-weight: bold;
			}

			.btn-x:hover {
				background-color: #444659;
				cursor: pointer;
			}

			.reg-sel {
				display: flex;
				justify-content: space-around;
				align-items: center;
				font-size: 20px;

				span:hover {
					cursor: pointer;
				}

				.span1 {
					//color: #848492;
					color: #e9b10e;
					padding-bottom: 4px;
					border-bottom: 2px solid #e9b10e;
				}

				.span2 {
					color: #848492;
				}
			}

			.reg-hint {
				margin-top: 30px;
				padding: 10px 10px;
				background-color: #5c3c47;
				border-radius: 5px;
				color: #ff5c5c;
				font-size: 14px;
			}

			.input {
				// margin-top: 10px;

				.input1-warp {
					position: relative;
				}

				.input1 /deep/ input.el-input__inner {
					margin-top: 15px;
					background-color: #24252f;
					border: none;
					color: currentColor;
				}
			}

			.reg-btn {
				width: 100%;
				margin-top: 25px;

				.btn-sub {
					width: 100%;
					background-color: #e9b10e;
					color: #1a1c24;
					font-weight: 600;
					font-size: 16px;
					opacity: 0.9;
					border: 1px;
				}

				.btn-sub:hover {
					// background-color: #05e05d;
					opacity: 1;
					transition: 0.3s;
				}
			}

			.reg-deal {
				margin-top: 20px;
				font-size: 14px;
				color: #848492;

				strong {
					color: #e9b10e;
					cursor: pointer;
				}
			}
		}
	}

	.login {
		.login-title {
			display: flex;
			justify-content: center;
			font-size: 20px;
			color: #c3c3e2;
		}

		.login-pass {
			margin-top: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.login-pass1 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
			.el-checkbox__input.is-indeterminate .el-checkbox__inner {
				background-color: #e9b10e;
				border-color: #e9b10e;
			}

			.login-pass1 /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
				color: #e9b10e;
			}

			span {
				font-size: 14px;
			}

			span:hover {
				cursor: pointer;
			}
		}

		.login-rest {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;

			span {
				font-size: 14px;
			}

			img {
				margin-top: 20px;
				width: 35px;
				height: 35px;
			}
		}

		.login-hint {
			height: 90px;
			width: 100%;
			position: absolute;
			bottom: -90px;
			text-align: center;
			left: 0;
			line-height: 20px;
			background-color: #24252f;
			display: flex;
			flex-direction: column;
			justify-content: center;
			color: #848492;
			font-size: 12px;
		}

		.Mask_content {
			// width: 1060px;
			width: 60%;
			// min-height: 645px;
			min-height: 65%;
			background: url(../assets/newimg/denglubanner.png) no-repeat 100%;
			// background: url(../assets/newimg/img_01.png) no-repeat 100%;
			background-size: 100% 100%;
			padding: 55px 68px 30px;
			box-sizing: border-box;
			position: relative;
			color: #fff;
			margin: 0 auto;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 999;

			.banner {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0px;
				top: 0px;
				z-index: -10;
			}

			.go-login {
				text-align: center;
				font-size: 16px;
				color: #ff5cfd;
				position: absolute;
				right: 0;
			}

			.go-login:hover {
				text-decoration: underline;
				cursor: pointer;
			}

			.close {
				width: 10%;
				height: 10%;
				background: url(../assets/newimg/img_02.png) no-repeat 50%;
				position: absolute;
				right: -8%;
				top: -3%;
				-webkit-transition: 0.3s;
				-o-transition: 0.3s;
				transition: 0.3s;
			}

			.Mask_nav {
				width: 100%;
				height: 51px;
				//margin-top:20px;
				position: absolute;
				left: 60%;
				top: -20px;
				color: #fff;
				display: flex;
				align-items: center;
				position: relative;
			}

			.nav_item {
				width: 164px;
				height: 50px;
				text-align: center;
				line-height: 50px;
				font-size: 18px;
				background-size: contain;
				font-weight: 700;
				position: relative;
				z-index: 9999999;
				cursor: pointer;
			}

			.nav_item:first-child {
				// width: 173px;
				height: 89px;
				background: url(../assets/newimg/btn1.png) no-repeat 100%;
				background-size: 100% 100%;
				line-height: 89px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #0087e5;
			}

			.nav_item:first-child.active {
				// width: 173px;
				height: 89px;
				background: url(../assets/newimg/denglu.png) no-repeat 100%;
				color: #000;
				background-size: 100% 100%;
				line-height: 89px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #f600ff;
			}

			.nav_item:nth-child(2).active {
				// width: 173px;
				height: 89px;
				background: url(../assets/newimg/denglu.png) no-repeat 100%;
				color: #000;
				background-size: 100% 100%;
				line-height: 89px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #f600ff;
			}

			.nav_item:nth-child(2) {
				// width: 173px;
				height: 89px;
				background: url(../assets/newimg/btn1.png) no-repeat 100%;
				// margin-left: -20px;
				background-size: 100% 100%;
				line-height: 89px;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #0087e5;
			}

			.subject.regs {
				height: 75%;

				// margin-top: 20px;
				.agreement_icon_box {
					margin-top: 20px;
				}

				.form-btn {
					width: 100%;
					margin-top: 20px;
				}
			}

			.subject {
				width: 45%;
				height: 75%;
				// width: 100%;
				// margin: 47px auto 0;
				margin-left: 55%;

				.normal {
					height: 100%;
				}

				//  .el-form{
				//   height: 62%;
				//  }
				// margin: 47px 0 537px 47px;
				.form2 {
					height: 100%;
					border: 1px solid #024d70;
					border-radius: 20px;
					padding: 29px 25px 0px 25px;

					.el-form-item {
						// width: 437px;
						width: 100%;
						height: 50px;

						background: #0d1d37;
						border: 1px solid #2bbcff;
						border-radius: 6px;
					}

					/deep/ .el-form-item__content {
						margin-left: 0;
						display: flex;
					}

					.el-col {
						border: 1px solid #2bbcff;
						background: #0d1d37;
						border-radius: 6px;
						display: flex;

						/deep/ .icon_b {
							position: absolute !important;
							right: 0 !important;
							width: 2px !important;
							height: 26px !important;
							background: #98d5ff !important;
						}

						/deep/ .el-form-item__label {
							// width: 10%;
							text-align: center;
							display: flex;
							align-items: center;
							height: 100%;
							justify-content: space-between;

							position: relative;
							padding: 0;

							/deep/ .el-form-item__label {
								// width: 10%;
								text-align: center;
								display: flex;
								align-items: center;
								height: 100%;
								justify-content: center;
								position: relative;
								padding: 0;
							}
						}
					}

					/deep/ .el-input__inner {
						// width: 100%;
						height: 50px;
						line-height: 50px;
						background: none;
						border: none;
						color: #fff;
					}

					/deep/ .el-form-item__label {
						// width: 10%;
						text-align: center;
						display: flex;
						align-items: center;
						height: 100%;
						justify-content: center;
						position: relative;
						padding: 0;
					}

					.icon_img {
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #98d5ff;
					}

					.icon_b {
						position: absolute;
						right: 0;
						width: 2px;
						height: 26px;
						background: #98d5ff;
					}
				}

				.icon_img {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #98d5ff;
				}

				.icon_b {
					position: absolute;
					right: 0;
					width: 2px;
					height: 26px;
					background: #98d5ff;
				}

				.yzm {
					position: absolute;
					right: 0;
					top: 0;
					display: flex;
					align-items: center;
					height: 100%;

					.icon_b {
						left: 0;
					}

					.getCode {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ff5cfd;
						border: none;
						outline: none;
						margin: 0 20px;
					}

					.getCode:hover {
						cursor: pointer;
					}
				}

				.all-box {
					height: 40rpx;
					margin-top: 20px;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.switch_box {
						display: flex;
						justify-content: flex-end;

						// margin-top: 20px;
						.switchs {
							width: 122px;
							height: 40px;
							background: #2a8ab6;
							// opacity: 0.2;
							text-align: center;
							line-height: 41px;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #25aaff;
							cursor: pointer;
							background: url(../assets/newimg/phonedenglu.png) no-repeat 50%;
							// margin-left: -20px;
							background-size: 100% 100%;
						}
					}

					.agreement {
						text-align: left;

						// margin-top: 47px;
						span {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #25aaff;
							cursor: pointer;
						}
					}
				}

				.agreement_icon_box {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					margin-top: 30px;

					.agreement_icon {
						width: 22px;
						height: 22px;
						margin-right: 12px;

						img {
							width: 100%;
							height: 100%;
						}
					}
				}

				.form-btn {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 45px;
					position: relative;

					.btn {
						width: 80%;
						height: 49px;
						background: url(../assets/newimg/quedingbg.png) no-repeat;
						background-size: 100% 100%;
						font-size: 30px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
						display: flex;
						align-items: center;
						justify-content: center;
						cursor: pointer;
					}
				}
			}

			.goLogin {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ff5cfd;
				text-align: right;
				margin-top: -20px;
				position: relative;
			}
		}
	}
}

//右侧导航

.sty {
	height: 75%;
	max-height: 510px;

	/deep/.el-drawer.rtl {
		background: url(../assets/newimg/cebiuanlan.png) no-repeat;
		background-size: 100% 100%;
	}

	/deep/.el-menu.el-menu-vertical-demo {
		background-color: none;
	}

	/deep/.el-menu.el-menu-vertical-demo.el-menu-item:hover {
		background-color: none;
		background-color: transparent;
	}

	.el-menu-vertical-demo:deep(.el-menu-item:hover) {
		background-color: transparent !important;
	}

	.sty-menu {
		width: 80%;
		height: calc(100% - 25px);
		margin: 0 auto;
		margin-top: 25px;
		padding-left: 20px;
		background-color: transparent;

		// background-color: #000000;
		// background: url(../assets/newimg/cebiuanlan.png) no-repeat;
		// background-size: 100% 100%;
		/deep/.el-menu.el-menu-vertical-demo.el-menu-item:hover {
			background-color: none;
			background-color: transparent;
		}

		.el-menu-vertical-demo:deep(.el-menu-item:hover) {
			background-color: transparent !important;
		}

		/deep/.el-menu-item:hover {
			background-color: transparent !important;
		}
	}

	.sty-btn {
		padding: 8px;
		// background-color: #000;
		display: flex;
		justify-content: space-between;

		span {
			color: #1a1c24;
			font-size: 12px;
			font-weight: 600;
			width: 100px;
			text-align: center;
			height: 40px;
			line-height: 40px;
			border-radius: 5px;
		}

		img {
			width: 28px;
			height: 28px;
		}

		span:hover {
			cursor: pointer;
		}

		span:first-child:hover {
			background-color: #15bcf8;
		}

		span:first-child {
			background-color: #17b4ede7;
		}

		span:last-child {
			background-color: #e9b20ee3;
		}

		span:last-child:hover {
			background-color: #f1b80a;
		}
	}

	.sty-next {
		width: 238px;
		height: 78px;
		background: url(../assets/newimg/tuichubg.png) no-repeat;
		background-size: 100% 100%;
		text-align: center;
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		color: #fff;
		// margin-left: 25px;
		margin-top: 100px;
		line-height: 78px;
	}

	.sty-next:hover {
		cursor: pointer;
		// background-color: #2b2c37;
		// color: #c3c3e2;
	}
}

/deep/ .el-drawer__wrapper {
	top: 75px;
}

/deep/ .el-drawer__header {
	display: none;
}

// /deep/ .el-drawer__body {
//   background-color: #30313f;
// }
/deep/.el-menu {
	border-right: none;
	background-color: none;
}
</style>

<style lang="less">
.right-bar {
	top: 50%;
	right: 0px;
	z-index: 1200;
	position: fixed;
	background: #000;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
	background-color: #30313f;
	padding: 10px 0;
	border-radius: 1px;
}

.bar-show {
	width: 60px;
	transition: width 0.7s ease-out;
	min-height: 250px;
}

.bar-hidden {
	width: 0;
	transition: width 0.7s ease-out;
	min-height: 250px;
}

.switch {
	top: 50%;
	left: -20px;
	cursor: pointer;
	position: absolute;
	margin-top: -35px;
}

.switch img {
	width: 20px;
	vertical-align: top;
}

.btn-group {
	min-width: 60px;

	.btn-hong {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		img {
			width: 80px;
			height: 80px;
		}
	}

	.btn-hong:hover {
		cursor: pointer;
	}

	.btn {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin-top: 30px;
		cursor: pointer;
		padding: 10px 0 10px 0;
		color: #c3c3e2;
		font-weight: 500;

		>img {
			width: 32px;
			height: 32px;
		}

		>span {
			font-size: 12px;
			margin-top: 8px;
		}

		position: relative;

		.tip-num {
			position: absolute;
			background-color: red;
			width: 20px;
			height: 20px;
			text-align: center;
			line-height: 20px;
			border-radius: 10px;
			color: #fff;
			font-size: 12px;
			top: 5px;
			left: 8px;
		}
	}

	.btn:hover,
	.btn:active {
		color: #1a1c24;
		background-color: #e9b10e;
	}
}

.func {
	position: absolute;
	display: flex;
	height: 44px;
	width: 100%;
	bottom: 0px;
	text-align: center;
	justify-content: space-between;
	align-items: center;
	font-weight: 500;
	border-top: 1px solid #444659;

	.hidden {
		flex: 1;
		height: 44px;
		line-height: 44px;
		background-color: #30313f;
		cursor: default;
		color: #c3c3e2;
	}

	.hidden:hover {
		background-color: #3a4050;
		color: #c3c3e2;
		cursor: pointer;
	}

	.refresh {
		flex: 1;
		height: 44px;
		position: relative;
		background-color: #30313f;

		>img {
			height: 44px;
			width: 25px;
			height: 25px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.refresh:hover {
		color: #1a1c24;
		background-color: #3a4050;
		cursor: pointer;
	}

	.refresh:active {
		background-color: #1a1c24;
	}
}

//取回列表
.give-box {
	//background-color: #fff;
	background-color: #30313f;
	width: 100%;
	height: 150px;
	position: absolute;
	bottom: 40px;
	overflow: hidden;
	overflow-y: scroll;

	ul {
		padding: 0 10px;

		li {
			margin-top: 10px;

			.give-true {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.give-left {
					display: flex;
					align-items: flex-start;

					.give-img {
						img {
							width: 40px;
							height: 40px;
							border-radius: 50%;
						}
					}

					.give-text {
						margin-left: 10px;
						display: flex;
						flex-direction: column;

						span:first-child {
							font-size: 14px;
							color: #c3c3e2;
						}

						span:last-child {
							font-size: 12px;
							color: #848492;
						}
					}
				}

				.give-right {
					font-size: 14px;
					background-color: #e9b10e;
					padding: 8px 22px;
					border-radius: 5px;
					color: #1a1c24;
				}

				.give-right:hover {
					background-color: #f5c432;
					cursor: pointer;
				}
			}

			.give-false {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.give-false-left {
					display: flex;
					align-items: center;

					img {
						width: 40px;
						height: 40px;
						border-radius: 50%;
					}

					span {
						margin-left: 10px;
						font-size: 14px;
						color: #c3c3e2;
					}
				}

				.give-false-right {
					padding: 8px 22px;
					font-size: 14px;
					color: #c3c3e2;
				}
			}
		}
	}
}

.give-box1 {
	margin-top: 30px;
	background-color: #30313f;
	width: 100%;
	height: 150px;
	line-height: 150px;
	position: absolute;
	bottom: 40px;
	display: flex;
	justify-content: center;
	color: #c3c3e2;
	font-size: 16px;
}

//红包 未领取
.hongbao {
	position: fixed;
	top: 20%;
	left: 50%;
	width: 400px;
	margin-left: -150px;
	z-index: 666;

	.hong-x {
		position: absolute;
		z-index: 20;
		right: 10px;
		top: 10px;

		i {
			color: #fac3aa;
			font-size: 30px;
		}

		i:hover {
			cursor: pointer;
		}
	}

	.hongbao-input {
		position: relative;

		img {
			width: 100%;
			height: auto;
		}

		.input {
			position: absolute;
			top: 35%;
			left: 50%;
			width: 220px;
			margin-left: -110px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.span1 {
				font-size: 14px;
				color: #fff;
				white-space: nowrap;

				strong {
					color: #faa710;
				}
			}

			.span2 {
				margin-top: 8px;
				letter-spacing: 2px;
				font-size: 20px;
				color: #faa710;
			}

			input {
				margin-top: 8px;
				height: 30px;
				// background: none;
				outline: none;
				border: 1px solid #ccc;
				color: #faa710;
			}

			input:focus {
				border: none;
			}
		}
	}

	.hongbao-btn {
		img {
			width: 100%;
			height: auto;
		}
	}

	.hongbao-btn:hover {
		cursor: pointer;
	}
}

.hongbao1 {
	position: fixed;
	top: 20%;
	left: 50%;
	width: 400px;
	margin-left: -150px;
	z-index: 666;

	.hong-x {
		position: absolute;
		z-index: 20;
		right: 15px;
		top: 30px;

		i {
			color: #fac3aa;
			font-size: 30px;
		}

		i:hover {
			cursor: pointer;
		}
	}

	.hong-list {
		position: relative;

		.hong-back {
			width: 100%;
			height: auto;
		}

		.hong-text {
			position: absolute;
			top: 15%;
			left: 50%;
			width: 220px;
			margin-left: -110px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.hong1 {
				font-size: 25px;
				color: #faa710;
				letter-spacing: 2px;
			}

			.hong2 {
				margin-top: 20px;
				display: flex;
				align-items: center;

				img {
					width: 50px;
					height: auto;
				}

				span {
					margin-left: 10px;
					font-size: 20px;
					color: #faa710;
				}
			}

			.hong3 {
				margin-top: 20px;
				font-size: 14px;
				color: #fff;
				white-space: nowrap;

				strong {
					color: #faa710;
				}
			}

			.hong4 {
				margin-top: 20px;
			}

			.hong5 {
				margin-top: 20px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.hong5-ul {
					margin: 10px 0;

					.hong5-img {
						height: 20px;
						width: auto;
					}

					li {
						display: flex;
						align-items: center;
						padding-top: 5px;

						span {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size: 16px;
							color: #f3836d;

							strong {
								color: #faa710;
								font-weight: 200;
							}
						}

						img {
							margin-left: 5px;
							width: 16px;
							height: auto;
						}
					}
				}
			}
		}
	}
}
</style>

<style>
.quhui-box .el-drawer__body .el-drawer .el-drawer.ltr,
.quhui-box .el-drawer.rtl,
.quhui-box .el-drawer__container .el-drawer__body {
	background-color: transparent !important;
	/* flex: none !important; */
	box-shadow: none !important;
}

/*.el-table {
  position: fixed !important ;
  bottom: 44px !important ;
  background-color: #30313f !important;
}
.el-table th,
.el-table tr {
  background-color: #30313f !important;
}*/
</style>

<style scoped lang="less">
.ripple {
	position: relative;
	/* //隐藏溢出的径向渐变背景 */
	overflow: hidden;
	/* width: 200;
    height: 100; */
}

.ripple:after {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	pointer-events: none;
	/* //设置径向渐变 */
	background-image: radial-gradient(circle, #666 15%, transparent 15.01%);
	background-repeat: no-repeat;
	background-position: 50%;
	transform: scale(15, 15);
	opacity: 0;
	transition: transform 0.8s, opacity 0.8s;
}

.ripple:active:after {
	transform: scale(0, 0);
	opacity: 0.6;
	/* //设置初始状态 */
	transition: 0s;
}

.loging {
	background-color: #808080 !important;
}

@media screen and (max-width: 767px) {

	.reg,
	.login {
		background-color: #030919 !important;

	}

	.getCode {
		font-size: 16px;
	}

	.switch_box {
		height: auto !important;
	}

	.agreement_icon_box {
		padding: 0 30px;
	}


	.all-box {
		flex-direction: row;
		align-items: center;
		padding-left: 30px;
	}

	.Mask_content {
		width: 100% !important;
		background: none !important;
	}

	.new {
		top: 30% !important;
		transform: none !important;
		min-width: 100% !important;
		/* position: static !important; */
		left: 0 !important;

		.new-x {
			top: -28% !important;
			right: 50px !important;
		}

		.new-top {
			display: none !important;
		}

		.new-con {
			width: 100% !important;
			background: none !important;

			.hongbao {
				display: none !important;
			}

			.hbtitle {
				display: none !important;
			}

			.con-box {
				width: 90% !important;
				margin-left: 0 !important;

				.newRed-packet {
					width: 100% !important;
					background: none !important;
				}

				.box-3 {
					width: 100% !important;
					margin-left: 0 !important;

					.nowqd {
						width: 40%;
						margin: 0 auto !important;
						/* margin-left: 0 !important; */
					}
				}

				.box-1 {
					width: 100% !important;

					.newRed-packet2 {
						width: 100% !important;
						background: none !important;
					}
				}

				.fuli-con {
					width: 100% !important;
					margin-left: 0 !important;
					margin-top: 0 !important;

					.fanlihongbao {
						font-size: 14px !important;
						margin-top: 110px !important;
					}

					.el-input {
						width: 40vw !important;
						margin-left: 25vw !important;
						margin-top: 4vw !important;

						/* margin-left: 10px !important; */
						/deep/.el-input__inner {
							width: 100% !important;
							height: 36px !important;
						}
					}

					.guize {
						font-size: 14px !important;
						margin-top: 5px !important;
						margin-right: 10px !important;
					}

					.lingqu {

						left: 25% !important;
						bottom: 5px !important;
					}
				}
			}

			.con-bottom {
				background: #030919 !important;
				width: 100vw !important;
				/* position: static !important; */
				padding: 0 !important;
				/* bottom: 0 !important; */
				top: -50px !important;
				left: 0 !important;
				right: 0 !important;

				.pay-nav {
					width: auto !important;
					margin-top: 0 !important;
					display: flex !important;
					height: auto !important;
					flex-direction: row !important;

					.nav_item {
						width: auto !important;
						background: none !important;
						font-size: 18px !important;
					}

					.actives {
						color: #f00 !important;
					}
				}
			}
		}
	}
}
</style>