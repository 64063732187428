const axios = require('axios')
import { getToken } from '@/utils/auth'
import { Message } from 'element-ui';
import store from '../store'
import router from '../router'
import common from '@/assets/js/common.js'
// 导入一些获取token等等方法
import { removeToken } from '@/utils/auth'
//git请求
export function request(config) {
  // 定义一个url为空，开发环境为‘/api’，非开发环境为线上地址
  // let apiurl = 'https://api.v8steam.com/api'
  let apiurl = '/'
  // 判断环境
  if(process.env.NODE_ENV === 'development'){
    // apiurl = 'http://localhost:8080/api'; // 这是本地之地
  }else {
    // apiurl = 'http://api.v8steam.com/api'//这是线上地址 打包后的
    apiurl = '/'//这是线上地址 打包后的
  }

  // 1.1.创建axios的实例
  const instance = axios.create({
    baseURL: apiurl,
    // withCredentials:true
  })
  // 2.axios的拦截器
  // 2.1.请求拦截的作用

  instance.interceptors.request.use(config => {

    let token = localStorage.getItem('token');
    console.log(token);
    config.headers = {
      // 添加到请求头上方
      'token':token
    }
    return config
  },err => {
    return err.data
  })

  

  // axios.interceptors.request.use(
  //   config => {
  //     config.headers.token = localStorage.getItem('token')
  //     config.cancelToken = new axios.CancelToken((cancel) => {
  //       //使用vuex 定义pushCancel，请求进来存入
  //       store.dispatch('pushCancel', { cancelToken: cancel })
  //     });
  
  //     return config
  //   },
  //   error => {
  //     console.log(error)
  //   }
  // )
  // 2.2.响应拦截
  instance.interceptors.response.use(res => {
    /**
     * 返回来的数据，由于返回数据很多，截取code和data重要输出数据返回到页面进行渲染
     **/
    if(res.data.code == 500 && res.config.url.indexOf('/red') == -1 ){
      Message.closeAll()
      /* 500 服务器错误 */
      Message.error({
        message: res.data.message
      });

    }
    if(res.data.code == 401){

      Message.closeAll()
      /* 登陆失败 */
      Message.error({
        message:  store.state.lang == 'en'? 'login': res.data.message
      });
      removeToken()
      store.commit('USER_INFO',{})
      store.commit('LOGIN_IS_SHOW',true)
      router.push('/')
      return
    }
    return res.data
  },err => {
    if(JSON.stringify(err).indexOf('403') != -1){
      return err.data
    }


    // 登录失败 401
    if(JSON.stringify(err).indexOf('401') != -1){
      Message.closeAll()
      /* 登陆失败 */
      Message.error({
        message: store.state.lang == 'en'? 'login': '未登录'
      });
      removeToken()
      store.commit('LOGIN_IS_SHOW',true)
      store.commit('USER_INFO',{})
    }
    return err.data
  })

  // 3.发送真正的网络请求
  return instance(config)
}
