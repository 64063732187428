
import {request} from "../index";

// 宝箱列表
function getBoxList(){
    return request({
        url: `/api/box/list`,
        method:'get'
    })
}
// 开箱记录
function getBoxHistory(id,typeId){
    if(id==undefined && typeId==undefined){
        return request({
            // url: `/api/box/history`,
            url: `/api/top_history`,
            method:'get'
        })
    }else {
        return request({
            url: `/api/box/history?id=${id}&type_id=${typeId}`,
            method:'get'
        })
    }


}
// 开箱记录
function getBoxDetail(id){
    return request({
        url: `/api/box/detail?id=${id}`,
        method:'get'
    })
}
// 打开箱子
function getBoxOpen(data){
    return request({
        url: `/api/box/open`,
        method:'post',
        data
    })
}
// 回收箱子
function getRecycle(data) {
    return request({
        url: `/api/cash`,
        method:'post',
        data
    })
}
// ROLL列表
function getROllListthree() {
    return request({
        url: `/api/rooms?status=0`,
        method:'get',

    })
}
// 热门宝箱
function get_box_hot() {
    return request({
        url: `/api/box/hot`,
        method:'get',

    })
}
// banner
function get_banners() {
    return request({
        url: `/api/welfare_center/activity/list`,
        method:'get',

    })
}


export default {
    getBoxList,
    getBoxHistory,
    getBoxDetail,
    getBoxOpen,
    getRecycle,
    getROllListthree,
    get_box_hot,
    get_banners

}
