<template>
  <div class="openbox">
    <div class="mask" v-if="winStateMask"></div>
    <!--中奖完成后盒子-->
    <div class="win-box" v-if="winState">
      <mywin :winList="winList" :winState="winState" :scoreAll="scoreAll" @winexchange="winexchange($event)"
        @winget="winget($event)" @winX="winX($event)" @Showindex="Showindex"></mywin>
    </div>
    <div class="dhImg" v-if="winStateBtnX">
      <img src="../assets/newimg/img_54.png" alt="" />
    </div>
    <div class="dhImg" v-if="winStateBtnY">
      <img src="../assets/newimg/img_53.png" alt="" />
    </div>
    <div class="kai_bg">
      <img src="../assets/img/new/open/yuan.png" alt="" class="yuan">
      <myslide ref="child"></myslide>
      <div class="kai-box">
        <!-- 开盒子 -->
        <!-- 单个盒子打开动画 v-if="openBoxState && kaiBox == 1" -->

        <div class="kai-box-open2" v-if="openBoxState && kaiBox == 1">
          <!-- v-if="openBoxState && kaiBox == 1" -->
          <div class="kai-box-bg">
            <div class="kaibox-line">
              <img src="../assets/newimg/44.png" alt="" />
            </div>
            <div class="kai-warp">
              <ul>
                <li :style="'background-image: url('+item.background + ')'" v-for="(item, index) in imgList" :key="index" style="text-align:center">
                  <span style="color:#fff;width:100%;text-align:left;font-size:20px"><img src="../assets/img/money.png" style="width:15px;height:15px;margin-top:-20px" />{{ item.price }}</span>
                  <img :src="item.img" />
                  <span style="color:#fff;width:100%;text-align:center">{{ item.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 开盒子 -->
        <!-- 多个个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->
        <div :class="openPhone ? 'kai-box-open3' : 'kai-box-open4'" v-if="openBoxState && kaiBox != 1">
          <div class="kai-box-bg">
            <div class="kai-warp1">
              <div class="kaibox-line"></div>
              <div :class="{
                'kaibox-warp kaibox-warp2': kaiBox == 2,
                'kaibox-warp kaibox-warp3': kaiBox == 3,
                'kaibox-warp kaibox-warp4': kaiBox == 4,
                'kaibox-warp kaibox-warp5': kaiBox == 5,
              }">
                <ul class="kaibox-ul">
                  <li class="kaibox-li" v-for="(item, index) in imgList1" :key="index" :style="{
                    animation: 'run-li' + index + ' 10s',
                  }">
                    <ul>
                      <!-- :style="{
                        backgroundColor: item1.b_color || item1.bg_color,
                      }"  -->
                      <!--  :style="'background-image: url('+item1.background + ')'" -->
                      <li v-for="(item1, index1) in item" :key="index1" 
                      :style="'background-image: url('+item1.background + ')'"
                       style=""
                       :data="item1.name">
                       <span style="color:#fff;font-size:8px">{{ item1.price }}</span>
                        <!--  backgroundImage: 'url(' + item1.bgc + ')', -->
                        <!--  style="margin-top:-3px !important" -->
                        <img :src="item1.img" :class="{
                          'kaibox-img2': kaiBox == 2,
                          'kaibox-img3': kaiBox == 3,
                          'kaibox-img4': kaiBox == 4,
                          'kaibox-img5': kaiBox == 5,
                        }" />
                        <!-- <span style="color:#fff;font-size:8px;margin-top:-10px !important">{{ item1.name }}</span> -->
                        <div>{{ item.bg_color }}</div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="kai-share" @click="dialogFormVisible = true">
          <i class="el-icon-s-promotion"></i>
          <span>分享</span>
        </div>
        <div class="clear"></div> -->
        <div class="kai-con">
          <div class="con-name">
            <span>{{ box_name }}</span>
          </div>
          <div class="con-main">
            <div class="con-list">
              <ul>
                <!-- v-for="item in kaiBox" :key="item" -->
                <li>
                  <div class="conlist-warp">
                    <!-- @mouseleave="mouseleave"
                    @mouseenter="mouseenter" -->
                    <div class="conlist-box">
                      <img :src="box_obj.img_main" />
                    </div>
                    <div class="conlist-box1">
                      <img :src="box_obj.img_active" />
                    </div>
                    <!-- <div class="conlist-box2">
                      <img src="../assets/img/beijing.png" />
                    </div> -->
                  </div>
                </li>
              </ul>
            </div>
            <div class="con-bottom">
              <div class="con-num">
                <ul>
                  <!-- :class="item.state ? 'con-num-check' : ''" -->
                  <li v-for="(item, index) in kaiBoxNum" :key="index" @click="chooseNum(item.num)"  :class="kaiBox==item.num?'actives':''">
                    <!-- <img class="img1" :src="item.img1" alt="" />
                    <img v-if="item.state" class="img2" :src="item.img2" alt="" /> -->
                    <span>{{ item.num }}</span>
                      
                  </li>
                </ul>
              </div>
              <div class="con-btn">
                <div class="con-btn1">
                  <img src="../assets/img/money.png" /><span>{{
                    (price * kaiBox).toFixed(2)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="con-btn2" @click="buyBox()">
            <img src="../assets/newimg/kaiqi.png" />
          </div>
          <!-- <el-button class="con-btn2" @click="buyBox()" :disabled="loading"
            ><i v-if="loading" class="el-icon-loading"></i>开启</el-button
          > -->
        </div>
        <div class="kai-num">
          <span></span>
          <!-- <span>已开 5602</span> -->
        </div>
      </div>
    </div>
    <!-- <div class="recently-box" v-if="openPhone">
      <div class="title">最近掉落</div>
      <ul>
        <li v-for="(item, index) in skin_list1" :key="index">
          <div class="boxlist-warp">
            <div class="pic2">
              <img :src="item.player_img" alt="" />
              <div class="anchor" v-if="item.is_anchor == 1">
                <img src="../assets/newimg/header.png" />
              </div>
              <div class="anchor" v-if="item.is_anchor == 0">
                <img src="../assets/newimg/header2.png" />
              </div>
            </div> -->
            <!-- <div class="boxlist1-top"> -->
            <!-- <p class="bi">0.2%</p> -->
            <!-- <img :src="item.imageUrl" /> -->
            <!-- </div> -->
            <!-- <div class="boxlist1-price">
              <img src="../assets/img/money.png" />
              <span>{{ item.price }}</span>
            </div>
            <div class="boxlist1-bot" :title="item.skin_name">
              {{ item.skin_name }}
            </div>
          </div>
        </li>
      </ul>
    </div> -->
    <div class="box-list">
      <div class="boxlist-top">
        <div class="left">
          <span @click="() => {
            if (!openPhone) {
              selectTabIndex = 0
            }
          }" class="span1" :class="{ active: selectTabIndex == 0 }">包含以下皮肤</span>
          
          <!-- <span @click="() => {
            if (!openPhone) {
              selectTabIndex = 1
            }
          }" v-if="!openPhone" class="span1" :class="{ active: selectTabIndex == 1 }">最近掉落</span> -->
        </div>
        <div class="right">
          <div class="music" style="margin-right: 54px">
            <img v-if="musicShow == true" src="../assets/newimg/img_14.png" alt="" @click="gbMusic" />
            <img v-if="musicShow == false" @click="kqMusic" src="../assets/newimg/img_13.png" alt="" />
            <div class="tlt">开启声音</div>
          </div>
          <!-- <div class="music">
            <img v-if="dhShow == true" src="../assets/newimg/img_13.png" alt="" @click="gbAnimation" />
            <img v-if="dhShow == false" @click="kqAnimation" src="../assets/newimg/img_14.png" alt="" />
            <div class="tlt">跳过动画</div>
          </div> -->
        </div>
        <!-- <div class="right">
          <div class="right-one">
            <div class="right-data" v-if="value">
              <div v-for="(item, index) in skins_types" :key="index">
                <div class="percent">
                  <span
                    :style="{
                      backgroundColor: item.color,
                    }"
                  ></span>
                  <h6
                    :style="{
                      color: item.color,
                    }"
                  >
                    {{ item.skins_type_probability }}%
                  </h6>
                </div>
              </div>
            </div>
            <div class="switch-name">掉落概率</div>
            <div class="switch1 switch2">
              <el-switch
                v-model="value"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
          </div>

          <div class="right-two">
            <div class="switch-name">跳过动画</div>
            <div class="switch1">
              <el-switch
                v-model="cartState"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
          </div>
        </div> -->
      </div>
      <div class="clear"></div>

      <div class="boxlist-bot" v-if="winBoxState && selectTabIndex == 0">
        <ul>
          <!-- :style="{
              backgroundImage: 'url(' + item.background + ')',
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
            }" -->
          <li v-for="(item, index) in skin_list" :key="index">
            <!-- <div
              :style="{ backgroundColor: item.bg_color }"
              class="top-gang"
            ></div> -->
            <div class="boxlist-warp" :style="'background-image: url('+item.background + ')'" style="background-size: 100% 100%;">
              <div class="boxlist1-top" >
                <p class="bi">{{ item.probability }}%</p>
                <img :src="item.img" />
              </div>
              <div class="boxlist1-price">
                <img src="../assets/img/money.png" />
                <span>{{ item.price }}</span>
              </div>
              <div class="boxlist1-bot" :title="item.name">{{ item.name }}</div>
            </div>
          </li>
        </ul>
      </div>

      <div class="recently-box" v-if="!openPhone && selectTabIndex == 1">
        <div class="title">最近掉落</div>
        <ul>
          <li v-for="(item, index) in skin_list1" :key="index">
            <div class="boxlist-warp">
              <div class="pic2">
                <img :src="item.player_img" alt="" />
                <div class="anchor" v-if="item.is_anchor == 1">
                  <img src="../assets/newimg/header.png" />
                </div>
                <div class="anchor" v-if="item.is_anchor == 0">
                  <img src="../assets/newimg/header2.png" />
                </div>
              </div>
              <!-- <div class="boxlist1-top"> -->
              <!-- <p class="bi">0.2%</p> -->
              <!-- <img :src="item.imageUrl" /> -->
              <!-- </div> -->
              <div class="boxlist1-price">
                <img src="../assets/img/money.png" />
                <span>{{ item.price }}</span>
              </div>
              <div class="boxlist1-bot" :title="item.skin_name">
                {{ item.skin_name }}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="clear"></div>

      <!-- 最近掉落 -->
      <!-- <div class="boxlist-bot" v-if="winBoxState">
        <ul>
          <li v-for="(item, index) in skin_list" :key="index">
            <div class="boxlist-warp">
              <div class="boxlist1-top">
                <p class="bi">0.2%</p>
                <img :src="item.imageUrl" />
              </div>
              <div class="boxlist1-price">
                <img src="../assets/img/money.png" />
                <span>354</span>
              </div>
              <div class="boxlist1-bot" :title="item.skin_name">
                {{ item.skin_name }}
              </div>
            </div>
          </li>
        </ul>
      </div> -->

      <div class="clear"></div>
    </div>

    <!-- 分享-->
    <el-dialog title="分享链接" :visible.sync="dialogFormVisible" width="200px" class="share-hide">
      <div class="share-btn">
        <el-input v-model="url" autocomplete="off"></el-input>
        <el-button class="btn" type="warning" @click="copyUrl()">复制</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
      </div>
    </el-dialog>

    <audio controls ref="notify" class="audio">
      <source src="../assets/audio/open_box2.mp3" />
    </audio>

    <audio controls ref="notify1" class="audio" loop="loop">
      <source src="../assets/audio/kai_newbox.mp3" />
    </audio>
    <audio controls ref="notify2" class="audio" loop="loop">
      <source src="../assets/audio/y704.mp3" />
    </audio>
  </div>
</template>

<script>
import myslide from "@/components/seamless.vue";
import mywin from "@/components/my_win.vue";
import Utils from "./../assets/js/util.js";
import img_57 from "../assets/newimg/img_57.png";
import img_58 from "../assets/newimg/img_58.png";
import img_59 from "../assets/newimg/img_59.png";
import { setTimeout } from "timers";
import { request } from "https";
import { battle } from "@/api/socket.js";
import md5 from "js-md5";
export default {
  components: { myslide, mywin },
  data() {
    return {
      bgcList: [
        img_57,
        img_58,
        img_59,
        // require("../assets/newimg/img_58.png"),
        // require("../assets/newimg/img_57.png"),
        // require("../assets/newimg/img_59.png"),
      ],
      openPhone: true,
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      cartState: false,
      skin_list1: [],
      winBoxState: false,
      loading: false,
      url: window.location.href,
      dialogFormVisible: false,
      funState: true,
      winState: false,
      winStateMask: false,
      winStateBtnX: false,
      winStateBtnY: false,
      openBoxState: false,
      box_id: this.$route.query.box_id,
      box_name: "",
      box_obj: {},
      price: 0,
      totalPrice: 0,
      skin_list: [],
      skins_types: [],
      value: true,
      kaiBox: 1,
      kaiBoxNum: [
        {
          num: 1,
          state: true,
          img1: require("../assets/newimg/img_44.png"),
          img2: require("../assets/newimg/1kai.png"),
        },
        {
          num: 2,
          state: false,
          img1: require("../assets/newimg/two.png"),
          img2: require("../assets/newimg/2kai.png"),
        },
        {
          num: 3,
          state: false,
          img1: require("../assets/newimg/three.png"),
          img2: require("../assets/newimg/3kai.png"),
        },
        {
          num: 4,
          state: false,
          img1: require("../assets/newimg/four.png"),
          img2: require("../assets/newimg/4kai1.png"),
        },
        {
          num: 5,
          state: false,
          img1: require("../assets/newimg/five.png"),
          img2: require("../assets/newimg/5kai.png"),
        },
      ],
      winList: [],
      listBox: [],
      imgList: [],
      imgList1: [],
      scoreAll: "", //积分
      musicShow: true, //开启声音
      dhShow: true, //跳过动画
      websock: "",
      isProgress: false,
      selectTabIndex: 0,
    };
  },

  watch: {
    kaiBox(val) {
      let _this = this;
      _this.totalPrice = (this.price * val).toFixed(2);
    },
    // 解决路由跳转同一个页面，携带不同参数，页面不刷新问题
    $route(to, from) {
      if (to.query) {
        this.$router.go(0);
      }
      // this.getBoxInfo();
      // console.log("111111111");
      // this.getBack();
      // this.getWinPeo();
      // this.getBoxOrn();
    },
  },
  methods: {
    Showindex(index) {
      let obj = this.winList.skins_info[index];
      obj.check = !obj.check;
      this.$set(this.winList.skins_info, index, obj);
      // this.$emit('total_prices')
    },
    //推送
    initWebSocket() {
      const wsuri = battle();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //let actions = { test: "12345" };
      //this.websocketsend(JSON.stringify(actions));
    },
    websocketonerror() {
      //this.initWebSocket();
    },
    websocketonmessage(d) {
      // console.log("接收:6666", JSON.parse(d.data));
      // console.log(JSON.parse(d.data));
      if (this.cartState) {
        // console.log("动画关闭66666");
        this.getWinPeo();
      } else {
        // console.log("动画打开77777");
        setTimeout(() => {
          this.getWinPeo();
        }, 11000);
      }
    },
    websocketsend(Data) {
      if (this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(Data);
      }
    },

    websocketclose(e) {
      // console.log("close:", e);
    },
    // 开启声音
    kqMusic() {
      this.musicShow = true;
      this.$refs.notify.muted = false;
      this.$refs.notify1.muted = false;
      this.$refs.notify2.muted = false;
    },
    //关闭声音
    gbMusic() {
      this.musicShow = false;
      this.$refs.notify.muted = true;
      this.$refs.notify1.muted = true;
      this.$refs.notify2.muted = true;
      // this.playAlarm2();
    },
    //开启动画
    kqAnimation() {
      this.dhShow = true;
      this.cartState = false;
      // setTimeout(() => {
      this.$refs.notify1.muted = false;
      // }, 1000);
    },
    //关闭动画
    gbAnimation() {
      this.dhShow = false;
      this.cartState = true;
      this.$refs.notify1.muted = true;
      // this.playAlarm2();
    },
    mouseenter() {
      // console.log("播放音乐");
      this.playHover();
    },
    mouseleave() {
      // console.log("tingzhi音乐");
      this.playHover2();
    },
    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    //音乐 结果
    playAlarm() {
      this.$refs.notify.pause();
      if (!this.musicShow) {
        return
      }
      this.$refs.notify.play();
    },
    //音乐 过程
    playAlarm1() {
      this.$refs.notify1.pause();
      if (!this.musicShow) {
        return
      }
      // console.log("开箱声音开启");
      this.$refs.notify1.play();
    },
    //音乐 过程暂停
    playAlarm2() {
      // console.log("开箱声音关闭");
      this.$refs.notify1.pause();
    },
    playHover() {
      this.$refs.notify2.pause();
      if (!this.musicShow) {
        return
      }
      this.$refs.notify2.play();
    },
    playHover2() {
      this.$refs.notify2.pause();
    },
    //点击包含以下皮肤
    getBoxOrn() {
      this.winBoxState = false;
      //this.getBoxInfo();
      let _this = this;
      let time = parseInt(new Date().getTime() / 1000);
      let sign = _this.$md5("cs#.go" + time);
      let param = {
        box_id: _this.box_id,
        time: time,
        sign: sign,
      };
      _this.$axios
        .post("/api/box/boxInfo", _this.$qs.stringify(param))
        .then((res) => {
          if (res.data) {
            _this.skin_list = res.data.data.box_skins;
          }
        });
    },
    //最近掉落
    getWinPeo() {
      const _this = this;
      _this.winBoxState = true;
      let time = parseInt(new Date().getTime() / 1000);
      let sign = md5("cs#.go" + time);
      // data.sign = sign;
      // data.time = time;
      let param = {
        page: 1,
        pageSize: 20,
        box_id: this.box_id,
        time: time,
        sign: sign,
      };
      _this.$axios
        .post("/api/Box/lately", _this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log("0000000000000000", data);
          if (data.status == 1) {
            _this.skin_list1 = data.data.list;
          }
        });
    },
    //分享复制
    copyUrl() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = window.location.href; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
      this.dialogFormVisible = false;
    },
    //盲盒信息
    getBoxInfo() {
      let _this = this;
      // console.log("66666677", _this.box_id);
      let param = {
        box_id: _this.box_id,
      };
      _this
        .$post("/api/box/boxInfo", param)
        .then((res) => {
          if (res.data) {
            this.box_obj = res.data;
            // console.log("1111111112", this.box_obj);
            _this.box_name = res.data.name;
            _this.price = _this.totalPrice = res.data.price;
            _this.skin_list = res.data.box_skins;
            _this.skins_types = res.data.skins_types;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //购买盲盒
    buyBox() {
      if (this.isProgress) {
        return
      }
      this.isProgress = true;
      this.loading = true;
      this.playAlarm1();
      setTimeout(() => {
        this.playAlarm2();
      }, 10500);
      let _this = this;
      let param = {
        box_id: _this.box_id,
        num: _this.kaiBox,
        //player_id: this.$store.state.id,
        //cartState:this.cartState
      };
      _this
        .$post("/api/box/buyBox", param)
        .then((res) => {
          if (res.status == 1) {
            // this.scoreAll = res.data.scoreAll;
            this.$store.commit(
              "getMoney",
              Number(res.data.total_amount).toFixed(2)
            );
            this.imgList1 = [];
            res.data.skins_info.forEach((element) => {
              var random1 = Math.round(Math.random() * (2 - 0) + 0);
              element.bgc = this.bgcList[random1];
            });
            Utils.$emit("money", res.data.total_amount);
            Utils.$emit("integral_money", res.data.score);
            // this.$refs.child.getBoxHistory();
            // console.log("85299999", this.$refs.child.getList());
            let imgCopy = JSON.parse(JSON.stringify(this.skin_list));
            imgCopy.forEach((element) => {
              var random1 = Math.round(Math.random() * (2 - 0) + 0);
              element.bgc = this.bgcList[random1];
            });
            if (this.kaiBox == 1) {
              let imgCopy1 = [];
              for (let i = 0; i < Math.floor(80 / imgCopy.length) + 1; i++) {
                for (let j = 0; j < imgCopy.length; j++) {
                  imgCopy1.push(imgCopy[j]);
                }
              }
              imgCopy1 = imgCopy1.slice(0, 80);
              imgCopy1 = this.getRandomArr(imgCopy1, 80);
              imgCopy1.forEach((element) => {
                var random1 = Math.round(Math.random() * (2 - 0) + 0);
                element.bgc = this.bgcList[random1];
              });
              this.imgList = imgCopy1;
            } else {
              let imgCopy2 = [];
              for (let i = 0; i < this.kaiBox; i++) {
                this.imgList1.push(imgCopy);
                this.imgList1.forEach((element) => {
                  var random1 = Math.round(Math.random() * (2 - 0) + 0);
                  element.bgc = this.bgcList[random1];
                });
              }
              for (
                let i = 0;
                i < Math.floor(60 / this.imgList1[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.imgList1[0].length; j++) {
                  imgCopy2.push(this.imgList1[0][j]);
                }
              }
              imgCopy2 = imgCopy2.slice(0, 60);
              imgCopy2.forEach((element) => {
                var random1 = Math.round(Math.random() * (2 - 0) + 0);
                element.bgc = this.bgcList[random1];
              });
              for (let i = 0; i < this.imgList1.length; i++) {
                this.imgList1[i] = imgCopy2;
                this.imgList1[i] = this.getRandomArr(this.imgList1[i], 60);
              }
            }
            //开盲盒
            //_this.getBoxResult(player_box_skin_id);
            this.loading = false;
            if (res.data.skins_info.length == 1) {
              this.imgList[62] = res.data.skins_info[0];
              _this.setStyle(4);
            } else {
              let data = res.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));
              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][50] = data[i];
              }
              this.imgList1 = imgListCopy;
            }
            // res.data.skins_info.forEach((ele) => {
            //   ele.check = false;
            // });
            _this.winList = res.data;
            _this.winList.skins_info.forEach((ele) => {
              ele.check = true;
            });
            // _this.scoreAll = res.data.scoreAll;
            //是否开启动画
            if (this.cartState) {
              _this.winState = true;
              _this.winStateMask = true;
              this.playAlarm();
              this.getWinPeo();
              this.isProgress = false;
            } else {
              _this.openBoxState = true;
              var length = res.data.skins_info.length;
              _this.setStyle("", length);
              setTimeout(() => {
                // Utils.$emit("integral_money", res.data.score);
                this.isProgress = false;
                _this.openBoxState = false;
                _this.winState = true;
                _this.winStateMask = true;
                this.imgList = [];
                this.imgList1 = [];
                this.getWinPeo();
                this.playAlarm();
              }, 11000 + 200 * length);//200
            }
          } else if (res.status == -1) {
            this.$message(res.msg);
          } else {
            this.loading = false;
            let msg = res.msg;
            if (msg.indexOf("当前余额不足") != -1) {
              this.$message({ message: msg, type: "warning" });
              this.$refs.notify.muted = true;
              this.$refs.notify1.muted = true;
              this.$refs.notify2.muted = true;
              return;
            }
            if (msg == "玩家信息错误") {
              this.$message({ message: "请先登录", type: "warning" });
              this.$store.commit("getLogin", true);
              this.$refs.notify.muted = true;
              this.$refs.notify1.muted = true;
              this.$refs.notify2.muted = true;
              return;
            }
            if (msg == "购买失败，请联系客服") {
              this.$message("购买失败，请联系客服处理");
              return
            }
            this.$message({ message: msg, type: "warning" });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    chooseNum(num) {
      this.kaiBox = num;
      for (let i = 0; i < this.kaiBoxNum.length; i++) {
        if (this.kaiBoxNum[i].num == num) {
          this.kaiBoxNum[i].state = true;
        } else {
          this.kaiBoxNum[i].state = false;
        }
      }
    },
    //子组件修改父组件
    winexchange(msg) {
      var that = this;
      that.winState = msg;
      // that.winStateBtnX = true;
      setTimeout(function () {
        // that.winStateBtnX = false;
        that.winStateMask = false;
      }, 1000);
    },
    winget(msg) {
      var that = this;
      that.winState = msg;
      this.$message({
        message: '加入成功',
        type: "success",
      });
      // that.winStateBtnY = true;
      setTimeout(function () {
        // that.winStateBtnY = false;
        that.winStateMask = false;
      }, 1000);
    },
    winX(msg) {
      this.winState = msg;
      this.winStateMask = false;
    },
    setStyle(index, num) {
      let style = document.createElement("style");
      style.setAttribute("type", "text/css");
      document.head.appendChild(style);
      let sheet = style.sheet;
      if (index == 4) {
        let random = Math.floor(Math.random() * 190) + 11905;
        // console.log(random);
        sheet.insertRule(
          `@keyframes run` +
          index +
          `{
            0% {
              left: 0;
            }
            100% {
              left: -` +
          random +
          `px
            }
        }`,
          0
        );
      } else {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = Math.floor(Math.random() * 60) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = Math.floor(Math.random() * 50) + 6020;
          }
          sheet.insertRule(
            `@keyframes run-li` +
            i +
            `{
              0% {
                top: 0;
              }
              100% {
                top: -` +
            random +
            `px
              }
          }`,
            0
          );
        }

        // this.imgList1.forEach((element, index) => {
        //   if(this.openPhone == true){
        //     var random = Math.floor(Math.random()*190)+9905;
        //   }else{
        //     //屏幕小于600的宽度
        //     var random = Math.floor(Math.random()*190)+5905;
        //   }
        //   let style = document.createElement('style');
        //   style.setAttribute('type', 'text/css');
        //   document.head.appendChild(style);
        //   let sheet = style.sheet;
        //   //根据每一个时钟的数据为页面添加不同的keyframes
        //   var time = 1;
        //   var timer = setInterval(() => {
        //     if(time>num){
        //       clearInterval(timer)
        //     }
        //     console.log(time);
        //     time++
        //     sheet.insertRule(
        //       `@keyframes run-li` +(index) +`{
        //         0% {
        //           top: 0;
        //         }
        //         100% {
        //           top: -`+random+`px
        //         }
        //     }`,0);
        //   }, 200);
        // });
      }
    },
  },
  created() {
    // this.initWebSocket();
  },
  mounted() {
    //最近掉落
    // this.getWinPeo();
    this.getBoxOrn();
    //判断屏幕宽度
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 600) {
      this.openPhone = false;
    }
    let _this = this;
    _this.getBoxInfo();
    // console.log("111111111");
    _this.getBack();
    _this.getWinPeo();
    // _this.getBoxOrn();
    // _this.setStyle(5,5)
  },
};
</script>

<style lang="less" scoped>
.openbox {
  overflow: hidden;
  // overflow-y: scroll;
  background: #20222b;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: relative;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
  }

  .dhImg {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .win-box {
    position: absolute;
    z-index: 99;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .switch-name {
    color: #848492;
    font-size: 16px;
    margin-right: 5px;
  }

  .switch2 {
    margin-right: 10px;
  }

  .audio {
    display: none;
  }

  .kai_bg {
    width: 100%;
    //height: 988px;
    background-image: url("../assets/newimg/img_4555.jpg");
    background-size: 100% 165%;
  }

  .kai-box {
    position: relative;
    margin-top: 20px;

    .kai-share {
      float: right;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
      padding: 9px 22px;
      background-color: #333542;
      border-radius: 5px;
    }

    .kai-share:hover {
      background-color: #3a3f50;
      cursor: pointer;
    }
  }

  //测试开盒子 单个
  .kai-box-open2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;

    .kai-box-bg {
      width: 1000px;
      height: 210px;

      background-image: url("../assets/newimg/img_55.png");
      // background-image: url("../assets/newimg/newkaibox.png");
      background-size: 100% 100%;
      position: absolute;
      top: 30%;
      left: 50%;
      margin-left: -500px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .kaibox-line {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 99;

        img {
          width: auto;
          height: 210px;
        }
      }
    }

    .kai-warp {
      // width: 1000px;
      width: 100%;
      height: 125px;
      margin-bottom: 8px;
      overflow: hidden;
      position: relative;

      ul {
        position: absolute;
        left: 0;
        top: 0;
        width: 16000px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        z-index: 20;
        animation: run4 10s;
        animation-timing-function: ease; //动画慢 快 慢
        animation-iteration-count: 1; //播放几次动画
        animation-delay: 0s; //动画运行前等待时间
        animation-fill-mode: forwards; //动画结束 是否保持

        li {
          width: 200px;
          height: 120px;
          box-sizing: border-box;
          // border: 1px solid #bfbfb8;
          background: url(../assets/newimg/kailistbg.png) no-repeat;
          background-size: 100% 100%;

          img {
            margin-left: 10%;
            margin-top: 10%;
            width: 80%;
            height: 80%;
          }
        }

        li:first-child {
          margin-left: 90px;
        }

        li:nth-child(3n) {
          // background-image: url("../assets/newimg/img_57.png");
          background-size: 100% 100%;
        }

        li:nth-child(3n + 1) {
          // background-image: url("../assets/newimg/img_58.png");
          background-size: 100% 100%;
        }

        .li1 {
          background-image: url("../assets/newimg/img_58.png");
        }

        .li2 {
          background-image: url("../assets/newimg/img_59.png");
        }
      }

      // @keyframes run4 {
      //   0% {
      //     left: 0;
      //   }
      //   100% {
      //     left: -12000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;

    // .kai-box-bg{
    //   width: 100%;
    //   height:210px;
    //   background-image: url("../assets/newimg/img_55.png");
    //   background-size: 100% 100%;
    // }
    .kai-warp1 {
      position: absolute;
      width: 100%;
      height: 200px;
      top: 30%;
      left: 0;
      display: flex;
      justify-content: center;

      // background-image: url("../assets/newimg/kaibox5.png");
      // background-size: 100% 100%;
      .kaibox-line {
        width: 80%;
        height: 2px;
        position: absolute;
        top: 99px;
        left: 8%;
        z-index: 99;
        background-color: #ff5cfd;
      }

      .kaibox-warp {
        overflow: hidden;
        width: 90%;
        height: 200px;
        // padding: 40px;
        // border: 3px solid rgb(56, 118, 193);
        // background-image: url("../assets/newimg/kaibox.png");
        background-size: 100% 100%;

        .kaibox-ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          overflow: hidden;

          .kaibox-li {
            //overflow: hidden;
            width: 15%;

            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;

                img {
                  // margin-left: 2%;
                  // margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp2 {
        // width: 600px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox.png");

        width: 600px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");

        // .kaibox-line {
        //   width: 30%;
        //   height: 2px;
        //   position: absolute;
        //   top: 99px;
        //   left: 20%;
        //   z-index: 99;
        //   background-color: #ff5cfd;
        // }
        .kaibox-ul {
          // height: 200px;
          // margin-top: 40px;

          height: 200px;
          margin-top: 103px;
          margin-left: 14px;

          .kaibox-li {
            overflow: hidden;
            width: 41% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;

                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8%;
            animation-delay: 0.25s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 50.5%;
            animation-delay: 0.5s !important;
          }
        }
      }

      .kaibox-warp3 {
        width: 900px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");
        background-size: 100% 100%;

        // width: 900px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox3.png");
        // background-size: 100% 100%;
        .kaibox-ul {
          height: 200px;
          margin-top: 103px;
          margin-left: 14px;

          .kaibox-li {
            //overflow: hidden;
            width: 26.5% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;

                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 36.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 65%;
            animation-delay: 0.45s !important;
          }
        }
      }

      .kaibox-warp4 {
        // width: 1200px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox4.png");
        // background-size: 100% 100%;

        width: 1200px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");
        background-size: 100% 100%;

        .kaibox-ul {
          // height: 200px;
          // margin-top: 40px;

          height: 200px;
          margin-top: 103px;
          margin-left: 14px;

          .kaibox-li {
            //overflow: hidden;
            width: 20.5% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;

                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8.5%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 29.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 50.5%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 71.5%;
            animation-delay: 0.6s !important;
          }
        }
      }

      .kaibox-warp5 {
        // width: 1300px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox5.png");

        width: 1300px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");
        background-size: 100% 100%;

        .kaibox-ul {
          // height: 200px;
          // margin-top: 40px;

          height: 200px;
          margin-top: 103px;
          margin-left: 15px;

          .kaibox-li {
            //overflow: hidden;
            width: 16% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;

                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 25%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 42%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 59%;
            animation-delay: 0.6s !important;
          }

          .kaibox-li:nth-child(5) {
            left: 76%;
            animation-delay: 0.75s !important;
          }
        }
      }

      @keyframes run5 {
        0% {
          top: 0;
        }
        100% {
          //50
          top: -10000px;
        }
      }
    }
  }

  .kai-box-open4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;

    .kai-warp1 {
      position: absolute;
      width: 100%;
      // height: 120px;
      top: 30%;
      left: 0;
      display: flex;
      justify-content: center;

      .kaibox-line {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 59px;
        left: 0;
        z-index: 99;
        background-color: #e9b10e;
      }

      .kaibox-warp {
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 20%;
            position: absolute;
            top: 0;
            left: 0;
            animation: run6 8s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            ul {
              li {
                width: 100%;
                height: 100px;
                // background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;

                img {
                  // margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 100px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp2 {

        //width: 600px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 30%;
            animation-delay: 0.25s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 49.5%;
            animation-delay: 0.5s !important;
          }
        }
      }

      .kaibox-warp3 {

        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 20%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 39.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 59%;
            animation-delay: 0.45s !important;
          }
        }
      }

      .kaibox-warp4 {

        //width: 1200px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 10%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 29.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 49%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 68.5%;
            animation-delay: 0.6s !important;
          }
        }
      }

      .kaibox-warp5 {

        //width: 1500px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 19.9%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 39.8%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 59.7%;
            animation-delay: 0.6s !important;
          }

          .kaibox-li:nth-child(5) {
            left: 79.6%;
            animation-delay: 0.75s !important;
          }
        }
      }

      // @keyframes run6 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -6000px;
      //   }
      // }
    }
  }

  //中奖后盒子

  .kai-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    // padding-top: 45px;
    .con-name {
      display: flex;
      justify-content: center;
      font-size: 60px;
      color: #fff;
      // width: 1400px;
      width: 439px;
      height: 150px;
      background: url(../assets/newimg/img_37.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;

      span {
        // position: absolute;
        // top: 0;
        font-size: 50px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .con-main {
      width: 1161px;
      height: 650px;
      background: url(../assets/newimg/img_42.png) no-repeat;
      background-size: 100% 100%;
      margin-top: 20px;
      position: relative;
    }

    .con-bottom {
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translate(-50%, 0);
      // background: url(../assets/newimg/img_42.png) no-repeat;
      // background-size: 100% 100%;
    }

    .con-list {
      width: 100%;
      position: relative;
      top: -16%;

      ul {
        width: 100%;
        display: flex;
        justify-content: center;

        li {
          width: 20%;
          max-width: 300px;

          .conlist-warp {
            position: relative;
            //padding: 14% 0;
            // margin: 0 5px;
            text-align: center;
            display: flex;
            justify-content: center;

            .conlist-box {
              width: 500px;
              height: auto;
              text-align: center;
              z-index: 2;

              img {
                width: 500px;
                height: 120%;
                transform: scale(0.84);
              }
            }

            .conlist-box2 {
              width: 500px;
              height: auto;
              // text-align: center;
              position: absolute;
              z-index: 1;
              top: 18%;
              left: -70%;

              img {
                width: 380px;
                height: 100%;
              }
            }

            .conlist-box1 {
              width: 100%;
              position: absolute;
              top: 43%;
              left: 0;
              z-index: 3;

              img {
                width: 100%;
                height: 80%;
              }
            }
          }
        }

        li:hover {
          cursor: pointer;
        }

        li:hover .conlist-box1 {
          position: absolute;
          top: 35%;
          animation: boxhover 1.5s linear 0s infinite alternate;
        }

        @keyframes boxhover {
          0% {
            top: 35%;
          }

          50% {
            top: 20%;
          }

          100% {
            top: 35%;
          }
        }
      }
    }

    .con-num {
      margin-top: 40px;

      ul {
        display: flex;
        justify-content: center;

        li {
          width: 74px;
          height: 74px;
          text-align: center;
          margin: 0 8px;
          position: relative;

          .img1 {
            width: 100%;
            height: 100%;
          }

          .img2 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        li:first-child {
          .img2 {
            width: 62px;
            height: 40px;
            position: absolute;
            top: -8px;
            right: 6px;
          }
        }

        li:nth-child(2n) {
          .img2 {
            width: 77px;
            height: 69px;
            position: absolute;
            top: -8px;
            right: -9px;
          }
        }

        li:nth-child(3n) {
          .img2 {
            width: 82px;
            height: 96px;
            position: absolute;
            top: -10px;
            right: -12px;
          }
        }

        li:nth-child(4n) {
          .img2 {
            width: 88px;
            height: 92px;
            position: absolute;
            top: -8px;
            right: -9px;
          }
        }

        li:nth-child(5n) {
          .img2 {
            width: 92px;
            height: 92px;
            position: absolute;
            top: -8px;
            right: -9px;
          }
        }

        li:hover {
          cursor: pointer;
          // background-color: #444659;
          opacity: 0.8 !important;
        }

        .con-num-check {

          // background: url(../assets/newimg/img_43.png) no-repeat;
          // background-size: 100% 100%;
          span {
            color: #ffc400;
          }
        }
      }
    }

    .con-btn {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .con-btn1 {
        padding: 4px 16px;
        display: flex;
        align-items: center;

        span {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
          margin-left: 5px;
        }

        img {
          width: auto;
          height: 25px;
        }
      }
    }
  }

  .con-btn2 {
    width: 277px;
    height: 59px;
    // border: none;
    // background: url(../assets/newimg/img_15.png) no-repeat;
    // background-size: 100% 100%;
    // color: #000;
    // font-size: 14px;
    margin: 0 auto;

    // margin-right: -1px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .con-btn2:hover {
    cursor: pointer;
  }

  .kai-num {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
      color: #848492;
      display: flex;
      align-items: center;
    }

    i {
      color: #e9b10e;
      font-size: 20px;
    }

    h6 {
      font-size: 16px;
      color: #848492;
      font-weight: 200;
    }
  }

  .box-list {
    // width: 1320px;
    width: 90%;
    margin: 100px auto 0;
    padding-bottom: 60px;
    align-items: center !important;

    .boxlist-top {
      padding: 0 16px 0 0;
      height: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // border-bottom: 3px solid #ffc400;
      .left {
        width: 311px;
        height: 40px;
        // background: url(../assets/newimg/img_46.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-content: center;
        // justify-content: center;
        color: #fff;

        span {
          width: 154px;
          line-height: 40px;
          text-align: center;
        }

        .span1 {
          width: 154px;
          height: 61px;
          text-align: center;
          line-height: 61px;
          background: url(../assets/newimg/manghe.png) no-repeat;
          background-size: 100% 100%;
          transform: scale(0.8);
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;

          &.active {
            background: url(../assets/newimg/manghe-active.png) no-repeat;
            background-size: 100% 100%;
            color: #ff5cfd;
            transform: scale(1);
          }
        }

        span:hover {
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .music {
          display: flex;

          .tlt {
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin-top: 3px;
          }

          img {
            width: 27px;
            height: 27px;
            display: block;
            margin-right: 22px;
          }
        }
      }
    }

    .boxlist-bot {
      ul {
        // width: 95%;
        // margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        // li:nth-child(7n) {
        //   margin-right: 0;
        // }
        li {
          width: 13%;
          margin-right: 24px;
          margin-top: 25px;
          background: url(../assets/newimg/img_48.png) no-repeat;
          background-size: 100% 100%;
          height: 205px;

          // border-radius: 30px;
          .top-gang {
            width: 100%;
            height: 10px;
            // background-color: #fff;
            border-radius: 20px 20px 0 0;
          }

          .boxlist-warp {
            .bi {
              text-align: right;
              color: #ffffff;
              // opacity: 0.5;
              font-size: 14px;
            }

            .boxlist1-top {
              height: 125px;
              padding: 15px 20px 0;
              background-size: 100% 100%;
              text-align: center;
              // border-bottom: 3px solid #f1a920;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 75%;
                height: auto;
              }

              .bi {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-style: italic;
                color: #ffffff;
              }
            }

            .boxlist1-price {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #fff;
              justify-content: center;
              margin-bottom: 10px;

              img {
                width: 14px;
                height: 14px;
                margin-right: 7px;
              }
            }

            .boxlist1-bot {
              padding: 0 8px;
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              text-align: center;
            }

            .boxlist1-bot1 {
              padding: 8px;
              display: flex;
              align-items: center;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }

              span {
                margin-left: 8px;
                color: #c3c3e2;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .recently-box {
    width: 320px;
    height: 455px;
    background: url(../assets/img/recently.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 30px;
    top: 18%;
    padding: 15px;
    filter: opacity(0.8);

    // opacity: 0.7;
    // overflow: hidden;
    .title {
      width: 100%;
      margin: 5px auto;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }

    ul {
      width: 78%;
      height: 85%;
      margin: 0 auto;
      margin-top: 20px;
      overflow: hidden;
      overflow-y: scroll;
      /*滚动条样式*/

      li {
        width: 100%;
        height: 16%;
        margin-bottom: 10px;
        border-bottom: 2px solid #0485a9;
        overflow: hidden;

        .boxlist-warp {
          width: 100%;
          height: 100%;
          display: flex;
          // justify-content: space-between;
          align-items: center;

          .pic2 {
            width: 27px;
            height: 27px;
            // margin-top: 5px;
            border-radius: 50%;
            background-color: #ccc;
            flex-shrink: 0;
            margin-right: 10px;
            position: relative;

            .anchor {
              width: 29px;
              height: 29px;
              position: absolute;
              top: -1px;
              left: -1px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            img {
              // display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .boxlist1-top {
            display: flex;
            height: 100%;
            // padding: 15px 20px 0;
            background-size: 100% 100%;
            text-align: center;
            display: flex;
            align-items: center;

            // margin-right: 10px;
            p {
              margin-top: 20px;
            }
          }

          .boxlist1-price {
            // width: 30%;
            height: 100%;
            display: flex;
            margin-right: 10px;

            img {
              // display: block;
              width: 14px;
              height: 14px;
              margin-top: 20px;
              margin-right: 5px;
            }

            span {
              display: block;
              margin-top: 18px;
              color: #ffffff;
            }
          }

          .boxlist1-bot {
            width: 52%;
            // padding: 0 8px;
            color: #fff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
            // margin-top: 16px;
          }
        }
      }
    }

    ul::-webkit-scrollbar {
      width: 4px;
      /*height: 4px;*/
    }

    ul::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    ul::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  /* .share-hide {
    //height: 300px;
  }*/
  .share-btn {
    display: flex;

    .btn {
      margin-left: 10px;
    }
  }

  /deep/ .el-dialog__footer {
    display: none;
  }

  /deep/ .el-dialog__title {
    color: #848492;
  }

  /deep/ .el-dialog__body {
    padding: 5px 20px;
  }

  /deep/ .el-dialog {
    min-height: none;
  }
}

@media screen and (max-width: 767px) {

  .kai-box-open2 {
    height: 600% !important;
  }

  .recently-box {}

  .openbox .box-list {
    margin-top: 30px !important;
  }

  .openbox .kai-box-open4 .kai-warp1 .kaibox-warp .kaibox-ul .kaibox-li ul li {
    background: url(../assets/newimg/kailistbg.png) no-repeat;
    background-size: 100% 100%;
  }

  .openbox .kai-con .con-main {
    background: none !important;
  }

  .openbox .kai_bg {
    height: 600px !important;
    background-size: 130% 100% !important;
    background-position: top;
		background-repeat:no-repeat
    // background: rgba(0,0,0,0.6) !important;
  }

  .openbox .kai-box-open4 .kai-warp1 {
    height: 120px;
    transform: translateY(0%);
  }

  .con-name {
    transform: translate(0, 60%);
    width: 50% !important;
    height: 60px !important;

    span {
      font-size: 20px !important;
      position: absolute !important;
    }
  }

  .kaibox-line {
    // width:  100% !important;
    // left: 0 !important;
  }

  .con-main {
    position: absolute !important;
    top: 120%;
    margin-top: 0 !important;
    width: 100% !important;
    height: 200px !important;

    .con-list {
      ul {
        li {
          width: 100% !important;

          // max-width: 350px !important;
          .conlist-box {
						animation: move-up-and-down 2s linear infinite;

            img {
              width: 100% !important;
            }
          }
					
					@keyframes move-up-and-down {
					  0%, 100% {
					    transform: translateY(0);
					  }
					  50% {
					    transform: translateY(100px);
					  }
					}

          .conlist-box1 {
            top: 40% !important;
            left: 30% !important;
            width: 50% !important;

            img {
              width: 100% !important;
            }
          }

          .conlist-box2 {
            width: 110px !important;
            left: 5px !important;

            img {
              width: 100% !important;
            }
          }
        }
      }
    }

    .con-bottom {
      width: 100% !important;
      bottom: -45px !important;
      transform: translate(-50%, 80%) !important;

      .con-num {
        width: 80% !important;
        margin: 0 auto;
        margin-top: 20px !important;

        ul {
          li {
            width: 40px !important;
            height: 50px !important;

            span {
              display: block !important;
              line-height: 50px !important;
            }

            .img1 {
              flex-shrink: 0 !important;
            }

            // .img2{
            //   width: 30px !important;
            //   height: 32px !important;
            //   right: 5px !important;
            // }
          }

          li:first-child {
            .img2 {
              width: 30px !important;
              height: 32px !important;
              right: 5px !important;
            }
          }

          li:nth-child(5n) {
            .img2 {
              width: 50px !important;
              height: 65px !important;
              right: -6px !important;
            }
          }

          li:nth-child(3n) {
            .img2 {
              width: 50px !important;
              height: 70px !important;
              right: -10px !important;
            }
          }

          li:nth-child(2n) {
            .img2 {
              width: 42px !important;
              height: 50px !important;
              right: -5px !important;
            }
          }

          li:nth-child(4n) {
            .img2 {
              width: 46px !important;
              height: 65px !important;
              right: -5px !important;
            }
          }
        }
      }
    }
  }

  .con-btn2 {
    width: 40% !important;
    height: 40px !important;
    margin-top: 170px !important;
    position: absolute !important;
    top: 270px !important;
  }

  .recently-box {
    // width: 100% !important;
    position: static !important;
  }

  .openbox .win-box {
    top: 14% !important;
  }
}
@keyframes move-up-and-down {
	0%, 100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(100px);
	}
}
.openbox {
  overflow: hidden;
  // overflow-y: scroll;
  background: #20222b;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: relative;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
  }

  .dhImg {
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .win-box {
    position: absolute;
    z-index: 99;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .switch-name {
    color: #848492;
    font-size: 16px;
    margin-right: 5px;
  }

  .switch2 {
    margin-right: 10px;
  }

  .audio {
    display: none;
  }

  .kai_bg {
    width: 100%;
    //height: 988px;
    // background-image: url("../assets/newimg/img_4555.jpg");
    background: url(../assets/img/new/open/bacs.png) no-repeat center;
    background-size: 100% 100%;
    background-position: center center;
    .yuan{
      position: absolute;
      width: 140px;
      height: 140px;
      left: calc( 50% - 72px );
      top: calc( 50% - 30px );
      animation: rotate 3s linear infinite;
    }
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
  }

  .kai-box {
    position: relative;
    margin-top: 20px;

    .kai-share {
      float: right;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
      padding: 9px 22px;
      background-color: #333542;
      border-radius: 5px;
    }

    .kai-share:hover {
      background-color: #3a3f50;
      cursor: pointer;
    }
  }

  //测试开盒子 单个
  .kai-box-open2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;

    .kai-box-bg {
      width: 1000px;
      height: 210px;

      background-image: url("../assets/newimg/img_55.png");
      // background-image: url("../assets/newimg/newkaibox.png");
      background-size: 100% 100%;
      position: absolute;
      top: 5%;
      left: 50%;
      margin-left: -500px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .kaibox-line {
        position: absolute;
        left: 50%;
        top: 0;
        z-index: 99;

        img {
          width: auto;
          height: 210px;
        }
      }
    }

    .kai-warp {
      // width: 1000px;
      width: 100%;
      height: 300px;
      // height: 100%;
      // margin-bottom: 8px;
      overflow: hidden;
      position: relative;

      ul {
        height: 300px;
        position: absolute;
        left: 0;
        top: 0;
        width: 16000px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        z-index: 20;
        animation: run4 10s;
        animation-timing-function: ease; //动画慢 快 慢
        animation-iteration-count: 1; //播放几次动画
        animation-delay: 0s; //动画运行前等待时间
        animation-fill-mode: forwards; //动画结束 是否保持

        li {
          width: 200px;
          height: 60%;
          box-sizing: border-box;
          border-left: 1px solid #bfbfb8;
          background: url(../assets/newimg/kailistbg.png) no-repeat;
          background-size: 100% 100%;

          img {
            margin-left: 10%;
            margin-top: 10%;
            width: 80%;
            height: 80%;
          }
        }

        li:first-child {
          margin-left: 90px;
        }

        li:nth-child(3n) {
          // background-image: url("../assets/newimg/img_57.png");
          background-size: 100% 100%;
        }

        li:nth-child(3n + 1) {
          // background-image: url("../assets/newimg/img_58.png");
          background-size: 100% 100%;
        }

        .li1 {
          background-image: url("../assets/newimg/img_58.png");
        }

        .li2 {
          background-image: url("../assets/newimg/img_59.png");
        }
      }

      // @keyframes run4 {
      //   0% {
      //     left: 0;
      //   }
      //   100% {
      //     left: -12000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;

    // .kai-box-bg{
    //   width: 100%;
    //   height:210px;
    //   background-image: url("../assets/newimg/img_55.png");
    //   background-size: 100% 100%;
    // }
    .kai-warp1 {
      position: absolute;
      width: 100%;
      height: 200px;
      top: 30%;
      left: 0;
      display: flex;
      justify-content: center;

      // background-image: url("../assets/newimg/kaibox5.png");
      // background-size: 100% 100%;
      .kaibox-line {
        width: 80%;
        height: 2px;
        position: absolute;
        top: 99px;
        left: 8%;
        z-index: 99;
        background-color: #ff5cfd;
      }

      .kaibox-warp {
        overflow: hidden;
        width: 90%;
        height: 200px;
        // padding: 40px;
        // border: 3px solid rgb(56, 118, 193);
        // background-image: url("../assets/newimg/kaibox.png");
        background-size: 100% 100%;

        .kaibox-ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: nowrap;
          position: relative;
          overflow: hidden;

          .kaibox-li {
            //overflow: hidden;
            width: 15%;

            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;

                img {
                  // margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp2 {
        // width: 600px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox.png");

        width: 600px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");

        // .kaibox-line {
        //   width: 30%;
        //   height: 2px;
        //   position: absolute;
        //   top: 99px;
        //   left: 20%;
        //   z-index: 99;
        //   background-color: #ff5cfd;
        // }
        .kaibox-ul {
          // height: 200px;
          // margin-top: 40px;

          height: 200px;
          margin-top: 103px;
          margin-left: 14px;

          .kaibox-li {
            overflow: hidden;
            width: 41% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;

                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8%;
            animation-delay: 0.25s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 50.5%;
            animation-delay: 0.5s !important;
          }
        }
      }

      .kaibox-warp3 {
        width: 900px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");
        background-size: 100% 100%;

        // width: 900px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox3.png");
        // background-size: 100% 100%;
        .kaibox-ul {
          height: 200px;
          margin-top: 103px;
          margin-left: 14px;

          .kaibox-li {
            //overflow: hidden;
            width: 26.5% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }
              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 36.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 65%;
            animation-delay: 0.45s !important;
          }
        }
      }

      .kaibox-warp4 {
        // width: 1200px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox4.png");
        // background-size: 100% 100%;

        width: 1200px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");
        background-size: 100% 100%;

        .kaibox-ul {
          // height: 200px;
          // margin-top: 40px;

          height: 200px;
          margin-top: 103px;
          margin-left: 14px;

          .kaibox-li {
            //overflow: hidden;
            width: 20.5% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;

                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8.5%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 29.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 50.5%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 71.5%;
            animation-delay: 0.6s !important;
          }
        }
      }

      .kaibox-warp5 {
        // width: 1300px;
        // height: 280px;
        // margin-top: -40px;
        // background-image: url("../assets/newimg/kaibox5.png");

        width: 1300px;
        height: 420px;
        margin-top: -103px;
        background-image: url("../assets/newimg/newkaibox.png");
        background-size: 100% 100%;

        .kaibox-ul {
          // height: 200px;
          // margin-top: 40px;

          height: 200px;
          margin-top: 103px;
          margin-left: 15px;

          .kaibox-li {
            //overflow: hidden;
            width: 16% !important;
            // display: flex;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 10s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            ul {
              li {
                width: 100%;
                height: 200px;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;
                background: url(../assets/newimg/kailistbg.png) no-repeat;
                background-size: 100% 100%;

                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }

          .kaibox-li:nth-child(1) {
            left: 8%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 25%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 42%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 59%;
            animation-delay: 0.6s !important;
          }

          .kaibox-li:nth-child(5) {
            left: 76%;
            animation-delay: 0.75s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  .kai-box-open4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 66;

    .kai-warp1 {
      position: absolute;
      width: 100%;
      // height: 120px;
      top: 30%;
      left: 0;
      display: flex;
      justify-content: center;

      .kaibox-line {
        width: 100%;
        height: 2px;
        position: absolute;
        top: 59px;
        left: 0;
        z-index: 99;
        background-color: #e9b10e;
      }

      .kaibox-warp {
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          position: relative;

          .kaibox-li {
            //overflow: hidden;
            width: 20%;
            position: absolute;
            top: 0;
            left: 0;
            animation: run6 8s;
            animation-timing-function: ease !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持
            text-align: center;
            ul {
              li {
                width: 100%;
                height: 121px;
                // background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;
                box-sizing: border-box;
                border: 1px solid #bfbfb8;

                img {
                  // margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 100px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp2 {

        //width: 600px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 30%;
            animation-delay: 0.25s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 49.5%;
            animation-delay: 0.5s !important;
          }
        }
      }

      .kaibox-warp3 {

        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 20%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 39.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 59%;
            animation-delay: 0.45s !important;
          }
        }
      }

      .kaibox-warp4 {

        //width: 1200px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 10%;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 29.5%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 49%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 68.5%;
            animation-delay: 0.6s !important;
          }
        }
      }

      .kaibox-warp5 {

        //width: 1500px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 19.9%;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 39.8%;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 59.7%;
            animation-delay: 0.6s !important;
          }

          .kaibox-li:nth-child(5) {
            left: 79.6%;
            animation-delay: 0.75s !important;
          }
        }
      }

      // @keyframes run6 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -6000px;
      //   }
      // }
    }
  }

  //中奖后盒子

  .kai-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    // padding-top: 45px;
    .con-name {
      display: flex;
      justify-content: center;
      font-size: 60px;
      color: #fff;
      // width: 1400px;
      width: 439px;
      height: 150px;
      background: url(../assets/newimg/img_37.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;

      span {
        // position: absolute;
        // top: 0;
        font-size: 50px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #ffffff;
      }
    }

    .con-main {
      width: 1161px;
      height: 650px;
      background: url(../assets/newimg/img_42.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
    }

    .con-bottom {
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translate(-50%, 0);
      // background: url(../assets/newimg/img_42.png) no-repeat;
      // background-size: 100% 100%;
    }

    .con-list {
      width: 100%;
      position: relative;
      top: -16%;

      ul {
        width: 100%;
        display: flex;
        justify-content: center;

        li {
          width: 20%;
          max-width: 300px;

          .conlist-warp {
            position: relative;
            //padding: 14% 0;
            margin: 40px 5px;
            text-align: center;
            display: flex;
            justify-content: center;

            .conlist-box {
              width: 500px;
              height: auto;
              text-align: center;
              z-index: 2;
              animation: Updown 1s infinite alternate;
              img {
                width: 500px;
                height: 100%;
                transform: scale(0.84);
              }
            }
            @keyframes Updown {
                from {
                    margin-top: 74px;
                }

                to {
                    margin-top: 64px;
                }
            }
            .conlist-box2 {
              width: 500px;
              height: auto;
              // text-align: center;
              position: absolute;
              z-index: 1;
              top: 18%;
              left: -70%;

              img {
                width: 380px;
                height: 100%;
              }
            }

            .conlist-box1 {
              width: 100%;
              position: absolute;
              top: 43%;
              left: 0;
              z-index: 3;

              img {
                width: 80% !important;
                height: 70%;
              }
            }
          }
        }

        li:hover {
          cursor: pointer;
        }

        li:hover .conlist-box1 {
          position: absolute;
          top: 35%;
          animation: boxhover 1.5s linear 0s infinite alternate;
        }

        @keyframes boxhover {
          0% {
            top: 35%;
          }

          50% {
            top: 20%;
          }

          100% {
            top: 35%;
          }
        }
      }
    }

    .con-num {
      margin-top: 40px;

      ul {
        display: flex;
        justify-content: center;
        .actives{
          background: url(../assets/img/new/open/leftactive.png) !important;
          background-size: 100% 100% !important;
          color:white !important;
          span{
            color: #000 !important;
          }
        }
        li {
          width: 74px;
          height: 74px;
          text-align: center;
          margin: 0 8px;
          position: relative;
          span{
            color: #fff !important;
          }
          .img1 {
            width: 100%;
            height: 100%;
          }

          .img2 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        li:first-child {
          background: url(../assets/img/new/open/left.png);
          background-size: 100% 100%;
          width: 80px !important;
          height: 40px !important;
          .img2 {
            width: 80px;
            height: 40px;
            position: absolute;
            top: -8px;
            right: 6px;
          }
        }

        li:nth-child(2n) {
          background: url(../assets/img/new/open/left.png);
          background-size: 100% 100%;
          width: 80px !important;
          height: 40px !important;
          .img2 {
            width: 77px;
            height: 69px;
            position: absolute;
            top: -8px;
            right: -9px;
          }
        }

        li:nth-child(3n) {
          background: url(../assets/img/new/open/left.png);
          background-size: 100% 100%;
          width: 80px !important;
          height: 40px !important;
          .img2 {
            width: 82px;
            height: 96px;
            position: absolute;
            top: -10px;
            right: -12px;
          }
        }

        li:nth-child(4n) {
          background: url(../assets/img/new/open/left.png);
          background-size: 100% 100%;
          width: 80px !important;
          height: 40px !important;
          .img2 {
            width: 88px;
            height: 92px;
            position: absolute;
            top: -8px;
            right: -9px;
          }
        }

        li:nth-child(5n) {
          background: url(../assets/img/new/open/left.png);
          background-size: 100% 100%;
          width: 80px !important;
          height: 40px !important;
          .img2 {
            width: 92px;
            height: 92px;
            position: absolute;
            top: -8px;
            right: -9px;
          }
        }

        li:hover {
          cursor: pointer;
          // background-color: #444659;
          opacity: 0.8 !important;
        }

        .con-num-check {

          // background: url(../assets/newimg/img_43.png) no-repeat;
          // background-size: 100% 100%;
          span {
            color: #ffc400;
          }
        }
      }
    }

    .con-btn {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .con-btn1 {
        padding: 4px 16px;
        display: flex;
        align-items: center;

        span {
          font-size: 15px;
          color: #fff;
          font-weight: 600;
          margin-left: 5px;
        }

        img {
          width: auto;
          height: 25px;
        }
      }
    }
  }

  .con-btn2 {
    width: 277px;
    height: 59px;
    // border: none;
    // background: url(../assets/newimg/img_15.png) no-repeat;
    // background-size: 100% 100%;
    // color: #000;
    // font-size: 14px;
    margin: 0 auto;

    // margin-right: -1px;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .con-btn2:hover {
    cursor: pointer;
  }

  .kai-num {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
      color: #848492;
      display: flex;
      align-items: center;
    }

    i {
      color: #e9b10e;
      font-size: 20px;
    }

    h6 {
      font-size: 16px;
      color: #848492;
      font-weight: 200;
    }
  }

  .box-list {
    // width: 1320px;
    width: 90%;
    margin: 100px auto 0;
    padding-bottom: 60px;
    align-items: center !important;

    .boxlist-top {
      padding: 0 16px 0 0;
      height: 37px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      // border-bottom: 3px solid #ffc400;
      .left {
        width: 311px;
        height: 40px;
        // background: url(../assets/newimg/img_46.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-content: center;
        // justify-content: center;
        color: #fff;

        span {
          width: 154px;
          line-height: 40px;
          text-align: center;
        }

        .span1 {
          width: 154px;
          height: 61px;
          text-align: center;
          line-height: 61px;
          background: url(../assets/newimg/manghe.png) no-repeat;
          background-size: 100% 100%;
          transform: scale(0.8);
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;

          &.active {
            background: url(../assets/newimg/manghe-active.png) no-repeat;
            background-size: 100% 100%;
            color: #ff5cfd;
            transform: scale(1);
          }
        }

        span:hover {
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .music {
          display: flex;

          .tlt {
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            margin-top: 3px;
          }

          img {
            width: 27px;
            height: 27px;
            display: block;
            margin-right: 22px;
          }
        }
      }
    }

    .boxlist-bot {
      ul {
        // width: 95%;
        // margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        // li:nth-child(7n) {
        //   margin-right: 0;
        // }
        li {
          width: 13%;
          margin-right: 24px;
          margin-top: 25px;
          background: url(../assets/newimg/img_48.png) no-repeat;
          background-size: 100% 100%;
          height: 205px;

          // border-radius: 30px;
          .top-gang {
            width: 100%;
            height: 10px;
            // background-color: #fff;
            border-radius: 20px 20px 0 0;
          }

          .boxlist-warp {
            .bi {
              text-align: right;
              color: #fff;
              font-size: 10px !important;
            }

            .boxlist1-top {
              height: 125px;
              padding: 15px 20px 0;
              background-size: 100% 100%;
              text-align: center;
              // border-bottom: 3px solid #f1a920;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 75%;
                height: auto;
              }

              .bi {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-style: italic;
                color: #ffffff;
              }
            }

            .boxlist1-price {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #fff;
              justify-content: center;
              margin-bottom: 10px;

              img {
                width: 14px;
                height: 14px;
                margin-right: 7px;
              }
            }

            .boxlist1-bot {
              padding: 0 8px;
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              text-align: center;
            }

            .boxlist1-bot1 {
              padding: 8px;
              display: flex;
              align-items: center;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }

              span {
                margin-left: 8px;
                color: #c3c3e2;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  .recently-box {
    width: 320px;
    height: 455px;
    background: url(../assets/img/recently.png) no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 30px;
    top: 18%;
    padding: 15px;
    filter: opacity(0.8);

    // opacity: 0.7;
    // overflow: hidden;
    .title {
      width: 100%;
      margin: 5px auto;
      text-align: center;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }

    ul {
      width: 78%;
      height: 85%;
      margin: 0 auto;
      margin-top: 20px;
      overflow: hidden;
      overflow-y: scroll;
      /*滚动条样式*/

      li {
        width: 100%;
        height: 16%;
        margin-bottom: 10px;
        border-bottom: 2px solid #0485a9;
        overflow: hidden;

        .boxlist-warp {
          width: 100%;
          height: 100%;
          display: flex;
          // justify-content: space-between;
          align-items: center;

          .pic2 {
            width: 27px;
            height: 27px;
            // margin-top: 5px;
            border-radius: 50%;
            background-color: #ccc;
            flex-shrink: 0;
            margin-right: 10px;
            position: relative;

            .anchor {
              width: 29px;
              height: 29px;
              position: absolute;
              top: -1px;
              left: -1px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            img {
              // display: block;
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .boxlist1-top {
            display: flex;
            height: 100%;
            // padding: 15px 20px 0;
            background-size: 100% 100%;
            text-align: center;
            display: flex;
            align-items: center;

            // margin-right: 10px;
            p {
              margin-top: 20px;
            }
          }

          .boxlist1-price {
            // width: 30%;
            height: 100%;
            display: flex;
            margin-right: 10px;

            img {
              // display: block;
              width: 14px;
              height: 14px;
              margin-top: 20px;
              margin-right: 5px;
            }

            span {
              display: block;
              margin-top: 18px;
              color: #ffffff;
            }
          }

          .boxlist1-bot {
            width: 52%;
            // padding: 0 8px;
            color: #fff;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: left;
            // margin-top: 16px;
          }
        }
      }
    }

    ul::-webkit-scrollbar {
      width: 4px;
      /*height: 4px;*/
    }

    ul::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    ul::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  /* .share-hide {
    //height: 300px;
  }*/
  .share-btn {
    display: flex;

    .btn {
      margin-left: 10px;
    }
  }

  /deep/ .el-dialog__footer {
    display: none;
  }

  /deep/ .el-dialog__title {
    color: #848492;
  }

  /deep/ .el-dialog__body {
    padding: 5px 20px;
  }

  /deep/ .el-dialog {
    min-height: none;
  }
}

@media screen and (max-width: 767px) {

  .kai-box-open2 {
    height: 600% !important;
  }

  .recently-box {}

  .openbox .box-list {
    margin-top: 30px !important;
  }

  .openbox .kai-box-open4 .kai-warp1 .kaibox-warp .kaibox-ul .kaibox-li ul li {
    background: url(../assets/newimg/kailistbg.png) no-repeat;
    background-size: 100% 100%;
  }

  .openbox .kai-con .con-main {
    background: none !important;
  }

  .openbox .kai_bg {
    height: 600px !important;
    background-size: 110% 90% !important;
    background-position: center 70px !important;
		background-repeat:no-repeat;
    padding-bottom: 0;
    position: relative;
    // z-index: 90;
    // background: rgba(0,0,0,0.6) !important;
  }

  .openbox .kai-box-open4 .kai-warp1 {
    height: 120px;
    transform: translateY(0%);
  }

  .con-name {
    transform: translate(0, 60%);
    width: 50% !important;
    height: 60px !important;

    span {
      font-size: 20px !important;
      position: absolute !important;
    }
  }

  .kaibox-line {
    // width:  100% !important;
    // left: 0 !important;
  }

  .con-main {
    position: absolute !important;
    top: 120%;
    // margin-top: 0 !important;
    width: 100% !important;
    height: 200px !important;

    .con-list {
      ul {
        li {
          width: 100% !important;

          // max-width: 350px !important;
          .conlist-box {
            // width: 80% !important;
						animation: move-up-and-down 2s linear infinite;
            margin-top: 50px;
            img {
              width: 70% !important;
            }
          }
					
					@keyframes move-up-and-down {
					  0%, 100% {
					    transform: translateY(0);
					  }
					  50% {
					    transform: translateY(100px);
					  }
					}

          .conlist-box1 {
            top: 50% !important;
            left: 30% !important;
            width: 40% !important;

            img {
              width: 100% !important;
            }
          }

          .conlist-box2 {
            width: 110px !important;
            left: 5px !important;

            img {
              width: 100% !important;
            }
          }
        }
      }
    }

    .con-bottom {
      width: 100% !important;
      bottom: -90px !important;
      transform: translate(-50%, 80%) !important;
      // margin-top: 20px;
      .con-num {
        width: 80% !important;
        margin: 0 auto;
        margin-top: 50px !important;

        ul {
          li {
            width: 40px !important;
            height: 50px !important;

            span {
              display: block !important;
              line-height: 50px !important;
              color: #000;
            }

            .img1 {
              flex-shrink: 0 !important;
            }

            // .img2{
            //   width: 30px !important;
            //   height: 32px !important;
            //   right: 5px !important;
            // }
          }

          li:first-child {
            .img2 {
              width: 30px !important;
              height: 32px !important;
              right: 5px !important;
            }
          }

          li:nth-child(5n) {
            .img2 {
              width: 50px !important;
              height: 65px !important;
              right: -6px !important;
            }
          }

          li:nth-child(3n) {
            .img2 {
              width: 50px !important;
              height: 70px !important;
              right: -10px !important;
            }
          }

          li:nth-child(2n) {
            .img2 {
              width: 42px !important;
              height: 50px !important;
              right: -5px !important;
            }
          }

          li:nth-child(4n) {
            .img2 {
              width: 46px !important;
              height: 65px !important;
              right: -5px !important;
            }
          }
        }
      }
    }
  }

  .con-btn2 {
    width: 40% !important;
    height: 40px !important;
    margin-top: 150px !important;
    position: absolute !important;
    top: 340px !important;
  }

  .recently-box {
    // width: 100% !important;
    position: static !important;
  }

  .openbox .win-box {
    top: 14% !important;
  }
}
@keyframes move-up-and-down {
	0%, 100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(100px);
	}
}

</style>
