<template>
  <div class="areroom">
    <div class="room-warp">
      <!-- <div class="room-title">
        <div class="title-left">
          免费皮肤> <span>{{ roomData.room_name }}</span>
        </div>
        <div class="title-right">
          <i class="el-icon-s-home"></i>
          <span>房间编号: {{ roomData.room_num }} </span>
          <span class="break">
            <i class="el-icon-timer"></i>
            <span>截止时间: {{ roomData.run_lottery_time }}</span>
          </span>
        </div>
      </div> -->
      <div class="room-top">
        <div class="roomtop-left">
          <div class="room-img">
            <div class="zhubo">
              <span>{{ roomData.type == 1 ? "官方" : "主播" }}</span>
            </div>
            <!-- <span class="span1" v-if="roomData.type == 2">主播</span>
            <span class="span1" v-if="roomData.type == 1">官方</span> -->
            <span class="span2">{{ roomData.room_name }}</span>
          </div>
          <p class="time">
            开奖时间：<span style="color: #de00ff">{{
              roomData.run_lottery_time
            }}</span>
          </p>
        </div>
        <div class="roomtop-right">
          <div class="room-int">
            <div class="roomint-top">
              <span>玩法规则：</span>
                <span style="color: #ffc400">{{ roomData.desc }}</span>
              <!-- <span style="color: #ffc400" v-if="roomData.type == 1">参与用户需在{{ roomData.condition_time }}至{{
                roomData.run_lottery_time
              }}期间消费满{{ roomData.condition_charge }}即可参与</span> -->
              <!-- <span style="color: #ffc400" v-if="roomData.type == 2">参与用户需绑定主播邀请码{{ roomData.player.invite_code }}；在{{
                roomData.condition_time
              }}至{{ roomData.run_lottery_time }}期间消费满{{
  roomData.condition_charge
}}即可参与</span> -->
            </div>
          </div>
          <!-- v-if="roomData.status == 1" -->
          <div class="btn-warp" @click="addRoom" v-if="roomData.status == 1">
            <img v-if="roomData.condition_type == 1 || roomData.condition_type == 3
              " src="../assets/img/suo1.png" />
            <span>立即参与</span>
          </div>
          <!-- <div class="btn-warp" v-if="roomData.status == 2">已参与</div>
          <div class="btn-warp" v-if="roomData.status == 2">已结束</div> -->
        </div>
      </div>
      <div class="pay-nav">
        <div class="nav_item" :class="nav == 0 ? 'active' : ''" @click="changeNav(0)">
          奖池
        </div>
        <div class="nav_item" :class="nav == 1 ? 'active' : ''" @click="changeNav(1)">
          参与人员
        </div>
        <div class="nav_item" :class="nav == 2 ? 'active' : ''" @click="changeNav(2)">
          中奖纪录
        </div>
      </div>
      <img class="duizhangang" src="../assets/newimg/duizhangang.png" alt="" />

      <div class="room-win" v-if="roomData.status == 2 && nav == 2">
        <!-- <div class="win-top">
       
          <h6 style="color: #fff">
            已送出 <span>{{ player_skin.length }}</span> 件 价值<span>{{
              player_skin_price
            }}</span>
            <img
              style="width: 20rpx; height: 20px"
              src="../assets/img/money.png"
            />
          </h6>
        </div> -->
        <div class="win-con1">
          <ul>
            <li v-for="(item, index) in player_skin" :key="index">
              <div class="win-warp">
                <div class="win-warp1">
                  <img :src="item.skin_img" />
                </div>
                <div class="win-name">
                  <img :src="item.player_img" />
                  <span>{{ item.player_name }}</span>
                </div>
                <div style="display: flex; align-items: center; width: fit-content;
            margin: 0 auto;">
                  <img style="width: 20px; height: 20px" src="../assets/img/money.png" />
                  <span style="color: #fff" class="span1">{{
                    item.price
                  }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="room-win" v-if="nav == 0">
        <div class="win-top">
          <span>饰品：{{ list.length }}件</span>
          <span>总价值：{{ price }}</span>
        </div>
        <div class="win-con roll">
          <ul>
            <li v-for="(item, index) in list" :key="index">
              <div class="win-warp">
                <div class="win-img">
                  <img :src="item.img" />
                </div>
                <div class="win-price">
                  <img src="../assets/img/money.png" />
                  <span>{{ item.price }}</span>
                </div>
                <div class="win-name">
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="room-person" v-if="nav == 1">
        <div class="win-top">
          <span>参与人数：{{ player_list.length }}人</span>
        </div>
        <div class="num-list">
          <ul>
            <li v-for="(item, index) in player_list" :key="index">
              <img :src="item.img" />
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="pass-box" v-if="passState">
      <span class="pass-hint" @click="hidePass"><i class="el-icon-close"></i></span>
      <div class="pass-title">输入房间口令</div>
      <div class="input">
        <input type="text" v-model="password" />
      </div>
      <div class="pass-btn">
        <el-button type="warning" @click="passAddRoom">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      passState: false,
      id: "",
      roomData: {},
      list: [],
      player_list: [
        {
          img: "",
          phone: "12345678945",
        },
        {
          img: "",
          phone: "12345678945",
        },
        {
          img: "",
          phone: "12345678945",
        },
      ],
      price: 0,
      player_skin: [],
      player_skin_price: 0,
      nav: 0,
    };
  },
  mounted() {
    //console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    this.getRoomList();
  },
  methods: {
    changeNav(index) {
      this.nav = index;
      // this.roomData.status = 2;
    },
    //加入房间
    addRoom() {
      if (
        this.roomData.condition_type == 1 ||
        this.roomData.condition_type == 3
      ) {
        this.passState = true;
        return;
      } else {
        var param = {
          free_room_id: this.id,
          player_id: this.$store.state.id,
        };
      }

      this.$axios
        .post("index/Free/addFreeRoom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //隐藏密码框
    hidePass() {
      this.passState = false;
    },
    //密码加入房间
    passAddRoom() {
      let param = {
        free_room_id: this.id,
        player_id: this.$store.state.id,
        password: this.password,
      };
      this.$axios
        .post("index/Free/addFreeRoom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
            this.passState = false;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //房间数据
    getRoomList() {
      let param = {
        free_room_id: this.id,
      };
      this.$post("/api/free/freeRoomDetail", param)
        .then((res) => {
          var data = res;
          if (data.status == 1) {
            this.player_list = data.data.player_list;
            this.roomData = data.data;
            this.list = data.data.skin_list;
            if (data.data.status == 2) {
              this.player_skin = data.data.player_skin;
              //中奖记录总价
              for (let i = 0; i < this.player_skin.length; i++) {
                this.player_skin_price += Number(this.player_skin[i].price);
              }
              this.player_skin_price = this.player_skin_price.toFixed(2);
            }

            //奖池奖品总价
            /* for (let i = 0; i < this.list.length; i++) {
            this.price += Number(this.list[i].price);
          }*/
            this.price = data.data.pool;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.areroom {
  width: 100;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  .room-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    min-height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }

  .room-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left {
      font-size: 16px;
      color: #848492;

      span {
        color: #c3c3e2;
      }
    }

    .title-right {
      font-size: 16px;
      color: #848492;

      i {
        margin-left: 10px;
      }

      span {
        margin-left: 5px;
      }
    }
  }

  .room-top {
    padding: 55px 58px;
    box-sizing: border-box;
    width: 100%;
    background: url(../assets/newimg/img_83.png) no-repeat;
    background-size: 100% 100%;
    height: 270px;
    position: relative;

    .roomtop-left {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        font-size: 18px;
        color: #ffffff;
      }

      .room-img {
        padding-right: 20px;
        display: flex;
        align-items: center;

        .zhubo {
          width: 112px;
          height: 61px;
          background: url(../assets/newimg/img_79.png) no-repeat;
          background-size: 100% 100%;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;

          span {
            font-style: italic;
            color: #ff5cfd;
            line-height: 61px;
            text-align: right;
            width: 100%;
            padding-left: 63px;
          }
        }

        .span1 {
          font-size: 14px;
          background-color: #e9b10e;
          padding: 0 6px;
          border-radius: 10px;
        }

        .span2 {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          font-style: italic;
          color: #ffffff;
          margin-left: 20px;
        }
      }
    }

    .roomtop-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 93%;
      bottom: 73px;
      left: 62px;
      padding: 0 30px;
      box-sizing: border-box;

      .room-int {
        span {
          font-size: 18px;
          color: #fff;
        }
      }

      .btn-warp {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 138px;
        height: 44px;
        background: url(../assets/newimg/chuangjianbtn.png) no-repeat;
        background-size: 100% 100%;

        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }

        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .btn-warp:hover {
        cursor: pointer;
      }
    }
  }

  .pay-nav {
    height: 51px;
    // margin-top: 30px;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    // border-bottom: 3px solid #ffc400;
  }

  .duizhangang {
    width: 100%;
    margin-top: 10px;
  }

  .nav_item {
    width: 154px;
    height: 45px;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    background-size: contain;
    font-weight: 700;
    cursor: pointer;
  }

  .nav_item:first-child {
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    background-size: 100% 100%;
  }

  .nav_item:first-child.active {
    height: 62px;
    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    background-size: 100% 100%;
    line-height: 62px;
  }

  .nav_item:nth-child(2).active {
    height: 62px;
    line-height: 62px;
    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    background-size: 100% 100%;
  }

  .nav_item:nth-child(2) {
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    margin-left: -20px;
    background-size: 100% 100%;
  }

  .nav_item:nth-child(3).active {
    height: 62px;
    line-height: 62px;
    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    background-size: 100% 100%;
  }

  .nav_item:nth-child(3) {
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    margin-left: -20px;
    background-size: 100% 100%;
  }

  .win-top {
    // display: flex;
    // margin-top: 15px;
    margin-bottom: 22px;
    align-items: center;

    span {
      font-size: 18px;
      color: #ffffff;
      margin-right: 20px;
    }
  }

  .room-win {
    margin-top: 26px;

    .win-con {
      margin-top: 20px;

      ul {
        display: flex;
        // justify-content: space-around;
        flex-wrap: wrap;

        li:nth-child(5n) {
          margin-right: 0;
        }

        li {
          width: 18.4%;
          margin-right: 2%;
          margin-bottom: 2%;

          .win-warp {
            padding: 20px 10px;
            position: relative;
            border-radius: 5px;
            //background: linear-gradient(180deg, #2C2316 0%, #010101 100%);
            background: linear-gradient(180deg,
                #183230 0%,
                rgba(1, 1, 1, 0.5) 100%);
            background: url(../assets/newimg/roombox.png) no-repeat;
            background-size: 100% 100%;

            box-sizing: border-box;

            .win-img {
              text-align: center;

              img {
                width: auto;
                height: 120px;
              }
            }

            .win-name {
              font-size: 13px;
              color: #ffffff;
              text-align: center;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .win-price {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 15px 0;

              img {
                width: 16px;
                height: 16px;
              }

              span {
                font-size: 13px;
                color: #ffc400;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .win-con1 {
    margin-top: 20px;
    // background-color: #2b2c37;
    padding: 16px;
    border-radius: 5px;

    ul {
      display: flex;
      flex-wrap: wrap;

      li:nth-child(5n) {
        margin-right: 0;
      }

      li {
        width: 18.4%;
        margin-right: 2%;
        margin-bottom: 2%;
        background: url(../assets/newimg/roombox.png) no-repeat;
        background-size: 100% 100%;

        // width: 7.69%;
        .win-warp {
          margin: 2px;
          padding: 20px 10px;
          // background-color: #24252f;
          position: relative;
          border-radius: 5px;

          .win-warp1 {
            // padding: 0 10px;

            img {
              width: 100%;
              max-height: 120px;
            }
          }

          .win-name {
            // margin-top: -25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
            }

            span {
              font-size: 14px;
              color: #c3c3e2;
              // margin-top: 5px;
              padding-bottom: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .span1 {
            // position: absolute;
            // top: 30px;
            // right: 4px;
            // background-color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            padding: 0 5px;
            border-radius: 10px;
            color: #fff;


          }

          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  .room-person {
    margin-top: 30px;

    .num-list {
      margin-top: 20px;

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 12.5%;
          text-align: center;
          margin-bottom: 3%;

          img {
            width: 82px;
            height: 82px;
            border-radius: 50%;
          }

          p {
            font-size: 14px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            margin-top: 8px;
          }
        }
      }
    }
  }

  .pass-box {
    position: fixed;
    top: 30%;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    padding: 16px;
    border-radius: 5px;
    background-color: #333542;

    .pass-hint {
      position: absolute;
      right: 0;
      top: 0;

      i {
        font-size: 20px;
        color: #c3c3e2;
      }

      i:hover {
        cursor: pointer;
      }
    }

    .pass-title {
      display: flex;
      justify-content: center;
      color: #c3c3e2;
      font-size: 20px;
    }

    .input {
      margin-top: 15px;
      width: 100%;

      input {
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        color: #848492;
      }

      input:focus {
        border: none;
      }
    }

    .pass-btn {
      margin-top: 15px;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
</style>

<style lang="less">
@media screen and (max-width: 768px) {
  .room-person {
    ul {
      width: 100% !important;

      li {
        width: 25% !important;

        img {
          width: 60% !important;
          height: auto !important;
          aspect-ratio: 1;
        }

        .list-warp {
          .num {
            display: block !important;
          }
        }
      }
    }
  }

}</style>
