<template>
  <div class="rule">
    <div class="rule-warp">
      <div class="roomleft-num">
        <div class="nav_item" style="cursor: pointer;"  @click="goLucky">
          <i class="back el-icon-arrow-left"></i>
          返回
        </div>
      </div>
      <p style="color:#fff;margin-top: 30px;">玩法介绍</p>
      <div class="rule-text">
        <div class="border"></div>
        <div class="text-title">玩法介绍</div>
        <div class="desc">
          {{ data }}
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      data:''
    }
  },
  mounted(){
    this.getData()
  },
  methods: {
    goLucky() {
      this.$router.push({
        path: `/Arena`,
      });
    },
    getData() {
      this.$post('/api/free/freeInt').then(res => {
        if (res.status == 1) {
          this.data=res.data.free_int;
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rule {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;
  .rule-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.4);
  }
  .rule-text {
    min-height:360px;
    margin-top: 20px;
    // border: 3px solid #FFC400;
    background: url(../assets/newimg/rulebg.png) no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding:30px 60px;
    .border{
      width:100%;
      height: 16px;
      // background: #FFC400;
      margin-bottom:20px;
    }
    .text-title {
      padding: 10px 0;
      color: #fff;
      font-size: 16px;
    }
    .desc{
      font-size: 14px;
      color: #fff;
      padding: 8px 0;
    }
  }
  .rule-input {
    display: table;
    margin-top: 30px;
    padding: 16px;
    background-color: #2b2c37;
    border-radius: 5px;
    .input-title {
      font-size: 16px;
      color: #c3c3e2;
    }
    .input {
      padding: 16px 0;
      input {
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        width: 400px;
        color: #c3c3e2;
        font-size: 12px;
        background-color: #24252f;
        outline: none;
        box-sizing: border-box;
        border: none;
      }
      span {
        display: inline-table;
        height: 40px;
        margin-left: 20px;
        line-height: 40px;
        padding: 0 20px;
        // background-color: #e9b10e;
        border-radius: 5px;
        font-weight: 600;
        color: #1a1c24;
        font-size: 15px;
      }
      span:hover {
        cursor: pointer;
        background-color: #f5c432;
      }
    }
  }

  .roomleft-num {
    display: flex;
    align-items: center;
    color: #848492;
    font-size: 16px;
    // border-bottom: 3px solid #FFC400;
    .nav_item {
      width:156px;
      height:61px;
      text-align: center;
      line-height:61px;
      font-size:16px;
      background-size: contain;
      font-weight: 700;
      background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
      color: #fff;
      background-size: 100% 100%;
      cursor: pointer;
      position: relative;
      .back{
        position: absolute;
        left:15px;
        font-size: 24px;
        top: 50%;
        transform: translate(0%,-50%);
      }
    }
  }
}
</style>