<template>
  <div class="card-img">
    <el-carousel height="370px" arrow="never" indicator-position="none">
      <el-carousel-item v-for="item in bannerList" :key="item.index">
        <a :href="'https://' + item.url" target="_blank">
          <img :src="item.banner_img" alt="" />
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [],
    };
  },
  watch: {},
  methods: {
    getCard() {
      this.$post("/api//index/banner", {
        // token: localStorage.getItem("token"),
      }).then((res) => {
        // console.log("banner11111", res);
        this.bannerList = res.data;
      });
    },
  },
  beforeDestroy() {},
  mounted() {},
  created() {
    this.getCard();
  },
};
</script>

<style lang="less" scoped>
.card-img {
  max-width: 1200px;
  // width: 97%;
  margin: 0 auto;
  height: 370px;
  text-align: center;
  // margin-top: -130px;
  z-index: 0;
  // background: url(../assets/newimg/homebg.png) no-repeat;
  background-size: 100% 100%;
  padding: 28px 25px;
  .el-carousel {
    height: 100%;
    .el-carousel__container {
      width: 100%;
      height: 100%;
      .el-carousel__item {
        // height: 100%;
        img {
          // width: 1400px;
          width: 100%;
          height: 100%;
          // max-height: 218px;
          margin: 0 auto;
          // padding: 30px 0;
        }
      }
    }
  }
}
.border {
  width: 100%;
  height: 5px;
  background: #ffc400;
}
@media screen and (max-width: 767px) {
  .card-img {
    padding: 15px 0px !important;
    height: 120px !important;
    // margin-top: 15px !important;
    .el-carousel{
      height: 120px !important;
      /deep/.el-carousel__container{
        height: 120px !important;
      }
    }
  }
}
</style>
