<template>
  <div class="room">
    <!-- <div class="room-right">
      <div class="room-right-name">
        <span>{{ fightboxList[boxListIndex] ? fightboxList[boxListIndex].name : '' }} </span>包含以下奖励
      </div>
      <div class="room-right-list">
        <div class="roomlist-title">详情</div>
        <div class="roomlist">
          <ul>
            <li v-for="(item, index) in boxList[boxListIndex]" :key="index">
              <div class="roomlist-warp">
                <div class="roomlist-img">
                  <img :src="item.imageUrl" />
                </div>
                <div class="roomlist-name" :title="item.itemName">{{ item.itemName }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
    <div class="room-left">
      <div class="room-left-hint">
        <!-- <div class="roomleft-num">
          <h5 @click="goLucky">盲盒对战</h5>
          >
          <h6>参加活动编号 {{ id }}</h6>
        </div>
        <div class="roomleft-btn">
          <span @click="goLuckyRule">活动规则</span>
          <span @click="goLuckyHistory">活动历史</span>
          <span>邀请好友</span>
        </div> -->
        <div class="roomleft-num">
          <div class="nav_item" style="cursor: pointer" @click="goLucky">
            <i class="back el-icon-arrow-left"></i>
            返回
          </div>
          <div class="right-one">
            <div class="span1" @click="roomActive(0)" :class="nav == 0 ? 'active' : ''">
              创建相同房间 
          <div class="allJage">
            <div class="all">{{ boxInfoCont.skins_price }}</div>
          </div>
            </div>
            <div class="span1" @click="roomActive(1)" :class="nav == 1 ? 'active' : ''">
              解散房间
            </div>
          </div>
        </div>
      </div>

      <div class="room-left-box">
        <!-- <div class="allJage">
          <img src="../assets/img/money.png" alt="" />
          <div class="all">{{ boxInfoCont.skins_price }}</div>
        </div> -->
        <div class="roombox-warp" :class="['num' + fightboxList.length]">
          <ul>
            <li v-for="(item, index) in fightboxList" :key="index" :id="item.state ? 'room-li' : ''">
              <div class="roombox-num">
                <div class="roombox-img" @click="selImg(index)">
                  <img :src="item.img_main" />
                </div>
                <div class="roombox-img2" @click="selImg(index)">
                  <img :src="item.img_active" />
                </div>
                <!-- <div class="roombox-price">
                  <img src="../assets/img/money.png" alt="" />
                  <span>{{ item.price }}</span>
                </div> -->
                <!-- <div class="roombox-num1">{{ index + 1 }}</div>
                <div class="roombox-line"></div> -->
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="room-left-people">
        <div class="roompe-warp">
          <!-- <div class="roompe-line" v-if="roomLineState"></div> -->
          <ul class="ul1">
            <li class="li1" v-for="(item, index) in peopleList" :key="index" style="width: 295px">
              <!-- :style="{ width: item.width }" -->
              <div class="room-warp">
                <!-- :class="item.winState ? 'room1 room1-win' : 'room1'" -->
                <div class="room2">
                  <div class="room2-tou" v-if="item.state">
                    <img :src="item.img" />
                  </div>
                  <div class="room2-name" v-if="item.state">
                    {{ item.name }}
                  </div>
                  <div class="room2-tou" v-if="!item.state" @click="goParticipate(index)">
                    <img src="../assets/newimg/img_74.png" />
                  </div>
                  <div class="room2-name" v-if="!item.state">等待玩家</div>
                  <div style="padding-left: 16%; display: flex; margin: 0 auto">
                    <div class="win-title" style="margin-right: 15px">
                      <img v-if="item.totalPrice" src="../assets/img/money.png" />
                      <span>{{ item.totalPrice }}</span>
                    </div>
                    <div class="win-title2">
                      <img v-if="item.Allscore" src="../assets/newimg/img_105.png" />
                      <span>{{ item.Allscore }}</span>
                    </div>
                  </div>
                </div>
                <div class="room1">
                  <div class="room1-img" v-if="item.end == 2">
                    <div :class="item.winState
                      ? 'room1-text1 room1-win'
                      : 'room1-text2 room-back'
                      ">
                      <img v-if="item.winState" src="../assets/newimg/img_70.png" alt="" />
                      <img v-else src="../assets/newimg/img_71.png" alt="" />
                    </div>
                  </div>
                  <div class="room1-img" v-if="item.state && item.end == 1">
                    <img src="../assets/newimg/img_72.png" class="yzb_img" />
                  </div>
                  <div class="room1-img" v-if="!item.state">
                    <img v-if="!item.state" @click="goParticipate(index)" :disabled="disabled"
                      src="../assets/newimg/dengdaizhong.png" class="yzb_img" />
                    <!-- <el-button
                      v-if="!item.state"
                      @click="goParticipate(index)"
                      :disabled="disabled"
                      ><i v-if="loading" class="el-icon-loading"></i
                      >立即参与</el-button
                    > -->
                  </div>
                  <div class="pool" v-if="openWin">
                    <ul :class="{
                      'pool-ul2': mode == 2,
                      'pool-ul3': mode == 3,
                      'pool-ul4': mode == 4,
                      'pool-ul5': mode == 5,
                      'pool-ul6': mode == 6,
                      'pool-ul7': mode == 7,
                      'pool-ul8': mode == 8,
                      'pool-ul9': mode == 9,
                      'pool-ul10': mode == 10,
                      'pool-ul11': mode == 11,
                      'pool-ul12': mode == 12,
                    }">
                      <li v-for="(itemBox, indexBox) in item.fightBox" :key="indexBox"
                       :style="'background-image: url(' + itemBox.background + ')'">
                        <div class="pool-img" >
                          <img :src="itemBox.imageUrl" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="win-list">
                  <ul class="win-ul" >
                    <li class="win-li" v-for="(item1, index1) in item.box" :key="index1" :class="peopleList.length==3? 'threeimgs' :''"
                      :style="'background-image: url(' + item1.background + ')'">
                      <div class="win-warp">
                        <!-- {{ item1.bg_color }} -->
                        <div class="img">
                          <img :src="item1.img" />
                        </div>
                        <h6>
                          <img src="../assets/img/money.png" /><span>{{
                            item1.price
                          }}</span>
                        </h6>
                        <h5 :title="item1.name">{{ item1.name }}</h5>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <div class="room-line" v-if="lineState"></div>
          </ul>
        </div>
      </div>
      <!-- <div class="room-left-bot">
        公平性验证 参加活动编号 - 813227561387798528 随机数 -
        2968b3a4324279ba9f4f4e6d19ba2a92 <span>[关于公平性验证]</span>
      </div> -->
    </div>

    <div class="dh_main" v-if="dialogBox">
      <div class="price">
        <span>消耗 </span>
        <img src="../assets/img/money.png" />
        <span> {{ boxInfoCont.skins_price }}加入房间</span>
      </div>
      <p class="title">确定加入房间吗？</p>
      <div class="goods_btn">
        <div class="btn" @click="dhCancel">取消</div>
        <div class="btn" @click="dhSubmit">确定</div>
      </div>
    </div>

    <audio controls ref="notify1" class="audio" loop="loop">
      <source src="../assets/audio/IMG_2477.mp3" />
    </audio>
  </div>
</template>

<script>
import Utils from "./../assets/js/util.js";
import { battle } from "@/api/socket.js";
import { log } from "util";
import { setInterval } from 'timers';
export default {
  inject: ['reload'],
  data() {
    return {
      dialogBox: false,
      disabled: false,
      loading: false,
      roomLineState: false,
      id: "",
      winList: [{ name: 1 }, { name: 2 }, { name: 3 }],
      loser: [{ name: "", img: "", price: "0.01" }],
      boxList: [],
      boxListIndex: 0,
      fightboxList: [],
      fightImg: [],
      fightImgObj: [],
      peopleList: [],
      peopleObj: {
        name: "凉凉奖励",
        width: "",
        state: true,
        price: "0.01",
        img: require("../assets/img/moneyback.png"),
        loading: false,
      },
      lineState: false,
      openWin: false,
      mode: "",
      openBox: [{ state: false }],
      totalPrice: 0,
      nav: undefined,
      boxInfoCont: {},
      hasOver: true,
    };
  },
  mounted() {
    this.initWebSocket();
    this.id = this.$route.query.id;
    this.getRoomList();
  },
  created() {
    console.log(this.peopleList);
  },
  destroyed() {
    
    //关闭
    //clearInterval(this.timer);
    this.websock.close();
  },
  methods: {
    dhSubmit() {
      this.loading = true;
      this.disabled = true;
      let _this = this;
      let param = {
        battle_id: this.id,
        player_id: this.$store.state.id,
      };
      _this
        .$post("/api/battle/addBattle", param)
        .then((res) => {
          var data = res;
          // console.log("参与6666666", data);
          if (data.status == 1) {
            this.loading = false;
            //扣除余额
            _this.$store.commit("getMoney", data.data.total_amount);
            _this.$store.commit("getScroAll", data.data.total_score);
            this.dialogBox = false;
            Utils.$emit("money", data.data.total_amount);
            //人数已满 开始对战
            if (data.data.battle_status == "start") {
              let box = data.data.result;
              this.mode = box.length;
              
              Utils.$emit("integral_money", data.data.total_score); //动画结束
              // this.reload()
              // this.$router.go(0);
            }
            //setTimeout(() => {
              //this.getRoomList();
            //}, 1000)
          } else {
            this.disabled = false;
            this.loading = false;
            _this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          //console.log("reason" + reason);
          //this.goParticipate(index);
          // console.log(error);
        });
    },
    dhCancel() {
      // this.dialogVisible = false;
      this.dialogBox = false;
    },
    roomActive(e) {
      let _this = this;
      if (e == 0) {
        let param = {
          mode: _this.boxInfoCont.mode,
          player_id: this.$store.state.id,
          boxInfo: _this.fightboxList,
        };
        // console.log("创建相同", param);
        _this
          .$post("/api/battle/createRoom", param)
          .then((res) => {
            let data = res;
            if (data.status == 1) {
              // console.log("777777777777", data);
              _this.$store.commit("getMoney", data.data.total_amount);
              Utils.$emit("money", data.data.total_amount);
              // _this.websocketsend(JSON.stringify(sendData));
              _this.$message({
                message: "创建成功",
                type: "success",
              });
              _this.$router.push({
                path: `/LuckyRoom`,
                query: {
                  id: data.data.battle_id,
                },
              });
              // _this.$emit("money", data.data.total_amount);
              // _this.id = data.data.battle_id;
              // console.log('_this.id',_this.id);
              // this.getRoomList();
            } else {
              //创建失败
              _this.$message({
                message: data.msg,
                type: "warning",
              });
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      } else {
        // console.log("退出房间", _this.id);
        _this
          .$post("/api/battle/logout_room", {
            battle_id: _this.id,
          })
          .then((res) => {
            // console.log("退出房间", res);
            _this.$message({
              message: res.msg,
              type: "success",
            });
            this.$store.commit("getMoney", res.data.total_amount);
            Utils.$emit("money", res.data.total_amount);
            _this.$router.push({
              path: `/Lucky`,
            });
            return;
          });
      }
      // _this.nav = e;
    },
    //音乐 播放
    playAlarm1() {
      // console.log("playAlarm1");
      this.$refs.notify1.currentTime = 0;
      this.$refs.notify1.loop = false;
      this.$refs.notify1.play();
    },
    //音乐 结束
    playAlarm2() {
      this.$refs.notify1.pause();
    },
    //推送
    initWebSocket() {
      const wsuri = battle();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    websocketonopen() { },

    websocketonerror() {
      this.initWebSocket();
    },

    websocketonmessage(d) {
      let da = JSON.parse(d.data);
      if (da.info) {
        this.sockBattleList(da);
      }
    },

    websocketsend(Data) {
      if (this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(Data);
      }
    },

    websocketclose(e) {
      //console.log("close:", e);
    },

    sockBattleList(data) {
      // console.log("推送数据->:", data.info);
      if (data.info) {
        if (data.info.id == this.id) {
          console.log("sockBattleList:111111111");
          if (data.info.status != 3) {
            this.getRoomList();
            
          }
        }
      }
    },

    //跳转
    // goLucky() {
    //   this.$router.push({
    //     path: `/Lucky`,
    //   });
    // },
    goLucky() {
      // this.$router.go(-1);
      this.$router.push({
        path: `/Lucky`,
      });
    },
    //获取房间信息
    getRoomList(over) {
      // if (this.add) {
      //   return;
      // }
      let param = {
        battle_id: this.id,
      };
      this.$post("/api/battle/battleDetail", param)
        .then((res) => {
          if (over) {
            var re = {
              state: "update",
              info: res.data,
            };
            Utils.$emit("update", JSON.stringify(re));
          }
          console.log("getRoomList1111111111", res);
          var data = res;
          //console.log("房间信息",data);
          if (data.status == "1") {
            this.fightboxList = data.data.boxInfo;
            this.boxInfoCont = data.data;
            for (let i = 0; i < this.fightboxList.length; i++) {
              this.boxList.push(this.fightboxList[i].skin_list);
            }
            this.peopleList = data.data.player_info;
            // console.log("结束对战555555", this.peopleList);
            var mode = data.data.mode;
            var peopleListCopy = JSON.parse(JSON.stringify(this.peopleList)); //深拷贝
            if (mode != peopleListCopy.length) {
              for (let i = 0; i < mode - peopleListCopy.length; i++) {
                this.peopleList.push(this.peopleObj);
              }
            }
            for (let i = 0; i < this.peopleList.length; i++) {
              if (this.peopleList[i].id) {
                this.peopleList[i].state = true;
              } else {
                this.peopleList[i].state = false;
              }
              if (mode == 2) {
                this.peopleList[i].width = "49.5%";
              } else if (mode == 3) {
                this.peopleList[i].width = "33%";
              } else {
                this.peopleList[i].width = "24.62%";
              }
            }

            //对战的滚动图片数据
            for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }

            //生成滚动的图片数组 Math.floor(30 / this.fightImg[0].length) + 1(循环次数，保证数据至少30个)
            if (this.fightImg.length == 1) {
              for (
                let i = 0;
                i < Math.floor(30 / this.fightImg[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.fightImg[0].length; j++) {
                  this.fightImgObj.push(this.fightImg[0][j]);
                  this.fightImgObj = this.fightImgObj.slice(0, 30);
                  this.fightImgObj = this.getRandomArr(this.fightImgObj, 30);
                }
              }
            } else if (this.fightImg.length != 1) {
              for (let i = 0; i < this.fightImg.length; i++) {
                for (
                  let j = 0;
                  j < Math.floor(30 / this.fightImg[i].length) + 1;
                  j++
                ) {
                  for (let x = 0; x < this.fightImg[i].length; x++) {
                    this.fightImgObj.push(this.fightImg[i][x]);
                    this.fightImgObj = this.fightImgObj.slice(0, 30 * (i + 1));
                    //没有打乱顺序
                    // this.fightImgObj = this.getRandomArr(this.fightImgObj,20 * (i+1))
                  }
                }
              }
            }

            //把滚动数据放在各自下
            for (let i = 0; i < this.peopleList.length; i++) {
              this.peopleList[i].fightBox = this.fightImgObj;
            }

            //对战进行中2  对战未开始1  3结束
            if (data.data.status == "3") {
              let box = data.data.winner_owner;

              // console.log("0000000033", data.data.winner_owner);
              let numPrice = 0;
              //是否为平局

              if (data.data.winner_owner.length == 0) {
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = this.peopleList[i].skin_list;

                  this.peopleList[i].end = 2;
                  this.peopleList[i].winState = true;
                  this.peopleList[i].totalPrice = 0;
                  if (this.peopleList[i].skin_list == undefined) {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                  } else {
                    for (let j = 0; j < this.peopleList[i].box.length; j++) {
                      this.peopleList[i].totalPrice += Number(
                        this.peopleList[i].box[j].price
                      );
                      //1111111
                      this.peopleList[i].Allscore += Number(
                        this.peopleList[i].score
                      );
                    }
                    this.peopleList[i].totalPrice = Number(
                      this.peopleList[i].totalPrice
                    ).toFixed(2);
                    this.peopleList[i].Allscore = Number(
                      this.peopleList[i].score
                    ).toFixed(2);
                  }
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              } else {
                for (let i = 0; i < this.peopleList.length; i++) {
                  if (this.peopleList[i].id == data.data.winner) {
                    this.peopleList[i].box = box;
                    this.peopleList[i].totalPrice = 0;
                    this.peopleList[i].winState = true;
                    // console.log("000000011", this.peopleList[i]);
                    this.peopleList[i].Allscore = Number(
                      this.peopleList[i].score
                    );
                    for (let j = 0; j < box.length; j++) {
                      this.peopleList[i].totalPrice += Number(box[j].price);
                    }
                  } else {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                    this.peopleList[i].Allscore = Number(
                      this.peopleList[i].score
                    );
                  }
                  this.peopleList[i].totalPrice = Number(
                    this.peopleList[i].totalPrice
                  ).toFixed(2);

                  this.peopleList[i].end = 2;
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              }
            } else if (data.data.status == "2") {
              // console.log("2222222222", this.peopleList);
              this.peopleList.forEach((ele) => {
                ele.score = 0;
              });
              //对战进行中
              this.fightResult1();
            } else {
              // console.log("11111111111", this.peopleList);
              //对战未开始
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].end = 1;
              }
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.totalPrice += Number(this.fightboxList[i].price);
                this.fightboxList[i].state = false;
                this.fightboxList[0].state = true;
              }
            }
          } else if (data.status == 0) {
            this.$message({
              message: data.msg,
              type: "error",
            });
            this.$router.go(-1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    getRoomList1() {
      let param = {
        battle_id: this.id,
      };
      this.$post("/api/battle/battleDetail", param)
        .then((res) => {
          var data = res;
          console.log("getRoomList22222222222222", data);
          if (data.status == "1") {
            this.fightboxList = data.data.boxInfo;
            for (let i = 0; i < this.fightboxList.length; i++) {
              this.boxList.push(this.fightboxList[i].skin_list);
            }
            this.peopleList = data.data.player_info;
            var mode = data.data.mode;
            var peopleListCopy = JSON.parse(JSON.stringify(this.peopleList)); //深拷贝
            if (mode != peopleListCopy.length) {
              for (let i = 0; i < mode - peopleListCopy.length; i++) {
                this.peopleList.push(this.peopleObj);
              }
            }
            for (let i = 0; i < this.peopleList.length; i++) {
              if (this.peopleList[i].id) {
                this.peopleList[i].state = true;
              } else {
                this.peopleList[i].state = false;
              }
              if (mode == 2) {
                this.peopleList[i].width = "49.5%";
              } else if (mode == 3) {
                this.peopleList[i].width = "33%";
              } else {
                this.peopleList[i].width = "24.62%";
              }
            }

            //对战的滚动图片数据
            for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }

            //生成滚动的图片数组 Math.floor(30 / this.fightImg[0].length) + 1(循环次数，保证数据至少30个)
            if (this.fightImg.length == 1) {
              for (
                let i = 0;
                i < Math.floor(30 / this.fightImg[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.fightImg[0].length; j++) {
                  this.fightImgObj.push(this.fightImg[0][j]);
                  this.fightImgObj = this.fightImgObj.slice(0, 30);
                  this.fightImgObj = this.getRandomArr(this.fightImgObj, 30);
                }
              }
            } else if (this.fightImg.length != 1) {
              for (let i = 0; i < this.fightImg.length; i++) {
                for (
                  let j = 0;
                  j < Math.floor(30 / this.fightImg[i].length) + 1;
                  j++
                ) {
                  for (let x = 0; x < this.fightImg[i].length; x++) {
                    this.fightImgObj.push(this.fightImg[i][x]);
                    this.fightImgObj = this.fightImgObj.slice(0, 30 * (i + 1));
                    //没有打乱顺序
                    // this.fightImgObj = this.getRandomArr(this.fightImgObj,20 * (i+1))
                  }
                }
              }
            }

            //把滚动数据放在各自下
            for (let i = 0; i < this.peopleList.length; i++) {
              this.peopleList[i].fightBox = this.fightImgObj;
            }

            //对战进行中2  对战未开始1  3结束
            if (data.data.status == "3") {
              let box = data.data.winner_owner;
              // let box = data.data.result;
              // let newBox = [];
              // box.forEach((ele) => {
              //   ele.forEach((ele1) => {
              //     newBox.push(ele1);
              //   });
              // });

              let numPrice = 0;
              //是否为平局
              if (data.data.winner_owner.length == 0) {
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = this.peopleList[i].skin_list;
                  this.peopleList[i].end = 2;
                  this.peopleList[i].winState = true;
                  this.peopleList[i].totalPrice = 0;
                  if (this.peopleList[i].skin_list == undefined) {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                  } else {
                    // console.log("00000000", this.peopleList);
                    for (let j = 0; j < this.peopleList[i].box.length; j++) {
                      this.peopleList[i].totalPrice += Number(
                        this.peopleList[i].box[j].price
                      );
                    }
                    this.peopleList[i].totalPrice = Number(
                      this.peopleList[i].totalPrice
                    ).toFixed(2);
                  }
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              } else {
                for (let i = 0; i < this.peopleList.length; i++) {
                  if (this.peopleList[i].id == data.data.winner) {
                    this.peopleList[i].box = box;
                    this.peopleList[i].totalPrice = 0;
                    this.peopleList[i].winState = true;
                    for (let j = 0; j < box.length; j++) {
                      this.peopleList[i].totalPrice += Number(box[j].price);
                    }
                  } else {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                  }
                  this.peopleList[i].totalPrice = Number(
                    this.peopleList[i].totalPrice
                  ).toFixed(2);
                  this.peopleList[i].end = 2;
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              }
            } else if (data.data.status == "2") {
              //对战进行中
              this.fightResult1();
            } else {
              //对战未开始
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].end = 1;
              }
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.totalPrice += Number(this.fightboxList[i].price);
                this.fightboxList[i].state = false;
                this.fightboxList[0].state = true;
              }
            }
          } else if (data.status == 0) {
            this.$message({
              message: data.msg,
              type: "error",
            });
            this.$route.go(-1);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //跳转至活动规则
    goLuckyRule() {
      this.$router.push({
        path: `/LuckyRule`,
      });
    },
    //跳转至活动历史
    goLuckyHistory() {
      this.$router.push({
        path: `/LuckyHistory`,
      });
    },
    //房间观看对战 请求对战结果
    fightResult1() {
      this.hasOver=false;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/resultInfo", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            // console.log(111,data)
            let box = data.data.result;
            this.mode = box.length;
            this.openWin = true;
            this.roomLineState = true;

            //把结果放在对应的玩家下
            for (let i = 0; i < this.peopleList.length; i++) {
              this.peopleList[i].boxImg = [];
              for (let j = 0; j < box.length; j++) {
                this.peopleList[i].boxImg.push(box[j][i]);
              }
            }

            //在滚动的图片放入相应的结果
            let obj = JSON.parse(JSON.stringify(this.peopleList));
            for (let i = 0; i < obj.length; i++) {
              for (let j = 0; j < obj[i].boxImg.length; j++) {
                obj[i].fightBox[25 * (j + 1)] = obj[i].boxImg[j];
              }
            }
            this.peopleList = obj;

            // console.log(this.peopleList)
            //调整显示图片的地址
            for (let i = 0; i < this.peopleList.length; i++) {
              for (let j = 0; j < this.peopleList[i].fightBox.length; j++) {
                if (!this.peopleList[i].fightBox[j].imageUrl) {
                  this.peopleList[i].fightBox[j].imageUrl =
                    this.peopleList[i].fightBox[j].img;
                  // this.peopleList[i].fightBox[j].bg_color =
                  //   this.peopleList[i].fightBox[j].bg_color;
                }
              }
            }

            //动画
            if (this.mode == 1) {
              this.playAlarm1(); //音乐
              setTimeout(() => {
                if (this.peopleList.filter(item => (item.box && item.box[0].name == '凉凉奖励')).length > 0) {
                  this.over();
                  return
                }
                // console.log('settimeout');
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = [];
                  this.peopleList[i].box.push(box[0][i]);
                  this.peopleList[i].totalPrice =
                    this.peopleList[i].box[0].price;
                  //111111
                  // this.peopleList[i].Allscore = this.peopleList[i].box[0].score;
                }
                this.$forceUpdate();
                this.playAlarm2();
              }, 4000);
              setTimeout(() => {
                this.over();
                // console.log("参与7777777", this.$store.state.integral_money);
                // Utils.$emit("integral_money", this.$store.state.integral_money); //动画结束
              }, 6000);
            } else {
              this.playAlarm1(); //音乐
              for (let i = 0; i < this.mode; i++) {
                this.fightboxList[0].state = true;
                setTimeout(() => {
                  // console.log(this.peopleList)
                  if (this.peopleList.filter(item => (item.box && item.box[0].name == '凉凉奖励')).length > 0) {
                    this.over();
                    return
                  }
                  this.playAlarm2(); //音乐
                  // console.log("playAlarm第几次", i)
                  if (i != this.mode - 1) {
                    this.playAlarm1(); //音乐
                  }
                  for (let j = 0; j < this.peopleList.length; j++) {
                    if (!this.peopleList[j].box) {
                      this.peopleList[j].box = [];
                    }
                    this.peopleList[j].box.push(box[i][j]);
                    this.peopleList[j].totalPrice = 0;
                    //11111
                    this.peopleList[j].Allscore = 0;
                    // console.log("9999990000", this.peopleList);
                    for (let x = 0; x < this.peopleList[j].box.length; x++) {
                      this.peopleList[j].totalPrice += Number(
                        this.peopleList[j].box[x].price
                      );
                      //00000000
                      // this.peopleList[j].bg_color += Number(
                      //   this.peopleList[j].box[x].bg_color
                      // );
                      this.peopleList[j].Allscore += Number(
                        this.peopleList[j].box[x].score
                      );
                    }
                    this.peopleList[j].totalPrice =
                      this.peopleList[j].totalPrice.toFixed(2);
                    this.peopleList[j].Allscore =
                      this.peopleList[j].Allscore.toFixed(2);
                  }
                  for (let y = 0; y < this.fightboxList.length; y++) {
                    this.fightboxList[y].state = false;
                  }
                  if (i + 1 < this.mode) {
                    // console.log("i + 1 < this.mode", i);
                    this.fightboxList[i + 1].state = true;
                  } else if (i + 1 == this.mode) {
                    // console.log("i + 1 == this.mode", i);
                    this.fightboxList[i].state = true;
                  }
                  this.$forceUpdate();
                }, (i + 1) * 4000 + 1000 * i);
              }
              //音乐
              /*  for (let i = 0; i < this.mode; i++) {
                setTimeout(() => {
                  this.playAlarm1();
                }, i * 5000 );
              }
              for (let i = 0; i < this.mode; i++) {
                setTimeout(() => {
                  this.playAlarm2();
                }, (i + 1 ) * 5000 - 1000);
              } */
              setTimeout(() => {
                this.over();
              }, this.mode * 4000 + 1000 * this.mode);
            }
          }
        });
    },

    //对局结束
    over() {
      this.openWin = false;
      this.roomLineState = false;
      this.playAlarm2();
      var _this = this;
      if (this.hasOver) {
        return;
      }
      this.hasOver = true;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/setBattleStatus", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          if (res.data.status == "1") {
            // console.log("对战结束")

            //发送
            /* let sendData = {
              state: "update",
              battle_id: _this.id,
            };
            _this.websocketsend(JSON.stringify(sendData));*/
            console.log('overoveroveroveroverover');
            _this.getRoomList("over");
          }
        });
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    //点击参与活动
    goParticipate(index) {
      this.dialogBox = true;

      // this.dhSubmit(index);
      // this.loading = false;
    },
    //点击盒子查看右侧图片
    selImg(index) {
      this.boxListIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.room {
  width: 100%;
  height: 100%;
  overflow: hidden;
  // overflow-y: scroll;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  .audio {
    display: none;
  }

  .dh_main {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 570px;
    height: 250px;
    background: url(../assets/newimg/shuangren.png) no-repeat;
    background-size: 100% 100%;
    padding: 50px;
    box-sizing: border-box;

    .title {
      color: #ffc400;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    .price {
      width: 100%;
      // display: flex;
      // align-items: center;
      text-align: center;
      margin-bottom: 20px;

      img {
        height: 16px;
        width: auto;
        display: inline-block;
        margin-top: 5px;
      }

      span {
        font-size: 16px;
        color: #ffc400;
        font-weight: 400;
      }
    }

    .goods_btn {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;

      .btn {
        width: 157px;
        height: 44px;
        color: #fff;
        // background-color: #ccc;
        background: url(../assets/newimg/img_15.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .room-right {
    width: 300px;
    height: 100%;
    position: fixed;
    top: 60px;
    right: 17px;
    background-color: #24252f;

    .room-right-name {
      text-align: center;
      padding: 10px;
      color: #848492;
      font-size: 16px;

      span {
        color: #e9b10e;
      }
    }

    .room-right-list {
      padding: 0 10px;
      height: 100%;
      background-color: #30313f;
      overflow: hidden;
      overflow-y: scroll;

      .roomlist-title {
        font-size: 16px;
        color: #c3c3e2;
        padding: 10px 0;
      }

      ul {
        margin: 0 -4px;
        display: flex;
        flex-flow: row wrap;

        li {
          width: 50%;

          .roomlist-warp {
            margin: 4px;
            background-color: #24252f;
            border-radius: 5px;
            overflow: hidden;

            .roomlist-img {
              width: 100%;
              background-image: url("../assets/img/box-skins-blue.jpg");
              background-size: 100% 100%;

              img {
                width: 100%;
                height: auto;
              }
            }

            .roomlist-name {
              font-size: 14px;
              color: #c3c3e2;
              padding: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      img {
        width: 100px;
        height: 100px;
      }
    }

    /*滚动条样式*/
    .room-right-list::-webkit-scrollbar {
      width: 4px;
      /*height: 4px;*/
    }

    .room-right-list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .room-right-list::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .room-left {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    //height: 100%;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;

    // background: rgba(0, 0, 0, 0.4);
    .room-left-hint {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .roomleft-num {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;

        .right-one {
          display: flex;
          align-items: center;
          margin-top: -17px;

          .span1 {
            min-width: 120px;
            margin-left: 18px;
            height: 34px;
            line-height: 34px;
            // background: url(../assets/newimg/zise.png) no-repeat;
            background-size: 100% 100%;
            // padding: 10px;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-style: italic;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid #e9b10e;
          }

          .span1.active {
            background: #ffc400;
            color: #000;
            padding: 0 5px;
          }
        }

        .nav_item {
          width: 50px;
          height: 25px;
          text-align: center;
          line-height: 25px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-style: italic;
          color: #ffffff;
          border: 1px solid #ccc;
          // background: url(../assets/newimg/manghe.png) no-repeat 50%;

          background-size: 100% 100%;
          cursor: pointer;
          position: relative;

          .back {
            position: absolute;
            left: 15px;
            font-size: 24px;
            top: 50%;
            transform: translate(0%, -50%);
          }
        }
      }

      .roomleft-btn {
        margin-right: -5px;

        span {
          padding: 12px 22px;
          color: #848492;
          font-weight: 600;
          font-size: 15px;
          background-color: #333542;
          border-radius: 5px;
          margin-right: 5px;
        }

        :hover {
          cursor: pointer;
          background-color: #3a3f50;
        }
      }
    }

    .room-left-box {
      width: 100%;
      // margin-top: 10px;
      display: flex;
      justify-content: center;
      background: url(../assets/newimg/duizhanroom.png) no-repeat;
      background-size: 100% 100%;
      // box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
      position: relative;

      .allJage {
        position: absolute;
        top: 48px;
        left: 44%;
        display: flex;
        align-items: center;

        img {
          margin-right: 11px;
        }

        .all {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .roombox-warp {
        width: 100%;
        height: 284px;
        display: flex;
        align-items: center;
        justify-content: center;
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 70px;

          li {
            width: 177px;
            height: 166px;
            display: flex;
            align-items: center;
            justify-content: center;
            // margin-right: 12px;

            .roombox-num {
              position: relative;

              .roombox-img {
                img {
                  width: auto;
                  height: 113px;
                }
              }

              .roombox-img2 {
                position: absolute;
                left: 30px;
                top: 10px;

                img {
                  width: auto;
                  height: 90px;
                }
              }

              .roombox-price {
                display: flex;
                align-items: center;
                font-size: 20px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffc400;
                justify-content: center;

                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 12px;
                }
              }

              .roombox-num1 {
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 20px;
                margin-right: 30px;
                color: #e9b10e;
                position: relative;
                font-size: 14px;
              }

              .roombox-num1::before {
                top: 0;
                left: 0;
                width: 100%;
                // border: 1px solid #e9b10e;
                height: 100%;
                content: "";
                position: absolute;
                transform: rotate(45deg);
                box-sizing: border-box;
              }

              .roombox-line {
                width: 60px;
                height: 1px;
                background-color: #e9b10e;
              }
            }
          }

          li:last-child .roombox-line {
            display: none;
          }

          li:last-child {
            margin-right: 0;
          }

          #room-li {
            background: url(../assets/newimg/border.png) no-repeat;
            // border: 1px solid #ccc;
            background-size: 100% 100%;
            background-position: center 0px;

            .roombox-num1 {
              width: 30px;
              height: 30px;
              line-height: 30px;
            }

            .roombox-img {
              img {
                width: auto;
                height: 113px;
              }
            }

            .roombox-num1::before {
              box-shadow: 0px 0px 7px #e9b10e;
            }
          }
        }
      }
    }

    .room-left-people {
      width: auto;
      min-width: 50%;
      margin-top: 30px;

      .roompe-warp {
        width: auto;
        position: relative;

        .ul1 {
          width: fit-content;
          margin: 0 auto;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          .li1 {
            height: 604px;
            border-radius: 25px;
            background: url(../assets/newimg/duizhankuang2.png) no-repeat;
            background-size: 100% 100%;
            overflow: hidden;
            padding: 25px 0;

            .room-warp {
              height: 100%;
              overflow: hidden;

              .room1 {
                width: 80%;
                margin: 0 auto;
                height: 129px;
                line-height: 200px;
                // background: url(../assets/newimg/winbg.png) no-repeat;
                background-size: 100% 100%;
                // border-top: 1px solid #0a9cd3;
                // border-bottom: 1px solid #0a9cd3;
                text-align: center;
                position: relative;
                overflow: hidden;
                border-bottom: 1px solid rgb(52, 53, 80);

                // margin-top: 10px;
                .pool {
                  overflow: hidden;
                  position: absolute;
                  top: 10px;
                  left: 8px;
                  width: 94%;
                  height: 85%;
                  border-radius: 10px;
                  background-image: linear-gradient(#2d2d36, #483856);
                  background-size: 100% 100%;
                  z-index: 2;

                  ul {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    animation: run 4s;
                    animation-timing-function: ease; //动画 速度一样
                    animation-iteration-count: 1; //播放几次动画
                    animation-delay: 0s; //动画运行前等待时间
                    animation-fill-mode: forwards; //动画结束 是否保持

                    li {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      background: url(../assets/newimg/kailistbg.png) no-repeat;
                      background-size: 100% 100%;

                      .pool-img {
                        height: 200px;

                        img {
                          height: 100px;
                          width: auto;
                          margin: 0 auto;
                          margin-top: -10px;
                        }
                      }
                    }
                  }

                  @keyframes run {
                    0% {
                      top: 0;
                    }

                    100% {
                      top: -5000px;
                    }
                  }

                  .pool-ul2 {
                    animation: run2 9s;
                    animation-timing-function: ease-out !important; //动画慢 快 慢
                  }

                  @keyframes run2 {
                    0% {
                      top: 0;
                    }

                    40%,
                    50% {
                      top: -5000px;
                    }

                    90%,
                    100% {
                      top: -10000px;
                    }
                  }

                  .pool-ul3 {
                    animation: run3 15s;
                  }

                  @keyframes run3 {
                    0% {
                      top: 0;
                    }

                    26.66%,
                    33.33% {
                      top: -5000px;
                    }

                    60%,
                    66.66% {
                      top: -10000px;
                    }

                    93.33%,
                    100% {
                      top: -15000px;
                    }
                  }

                  .pool-ul4 {
                    animation: run4 20s;
                  }

                  @keyframes run4 {
                    0% {
                      top: 0;
                    }

                    20%,
                    25% {
                      top: -5000px;
                    }

                    45%,
                    50% {
                      top: -10000px;
                    }

                    70%,
                    75% {
                      top: -15000px;
                    }

                    95%,
                    100% {
                      top: -20000px;
                    }
                  }

                  .pool-ul5 {
                    animation: run5 25s;
                  }

                  @keyframes run5 {
                    0% {
                      top: 0;
                    }

                    16%,
                    20% {
                      top: -5000px;
                    }

                    36%,
                    40% {
                      top: -10000px;
                    }

                    56%,
                    60% {
                      top: -15000px;
                    }

                    76%,
                    80% {
                      top: -20000px;
                    }

                    96%,
                    100% {
                      top: -25000px;
                    }
                  }

                  .pool-ul6 {
                    animation: run6 30s;
                  }
                  
                  @keyframes run6 {
                    0% {
                      top: 0;
                    }

                    13.33%,
                    16.66% {
                      top: -5000px;
                    }

                    30%,
                    33.33% {
                      top: -10000px;
                    }

                    46.66%,
                    50% {
                      top: -15000px;
                    }

                    63.33%,
                    66.66% {
                      top: -20000px;
                    }

                    80%,
                    83.33% {
                      top: -25000px;
                    }

                    96.66%,
                    100% {
                      top: -30000px;
                    }
                  }
                  .pool-ul7 {
                    animation: run7 35s;
                  }
                  
                  @keyframes run7 {
                    0% {
                      top: 0;
                    }

                    11.29%,
                    14.29% {
                      top: -5000px;
                    }

                    25.58%,
                    28.58% {
                      top: -10000px;
                    }

                    39.87%,
                    42.87% {
                      top: -15000px;
                    }

                    53.16%,
                    57.16% {
                      top: -20000px;
                    }

                    67.45%,
                    71.45% {
                      top: -25000px;
                    }

                    81.74%,
                    85.74% {
                      top: -30000px;
                    }

                    96.66%,
                    100% {
                      top: -35000px;
                    }
                  }
                  .pool-ul8 {
                    animation: run8 40s;
                  }
                  
                  @keyframes run8 {
                    0% {
                      top: 0;
                    }

                    9.5%,
                    12.5% {
                      top: -5000px;
                    }

                    22.5%,
                    25% {
                      top: -10000px;
                    }

                    34.5%,
                    37.5% {
                      top: -15000px;
                    }

                    47%,
                    50% {
                      top: -20000px;
                    }

                    59.5%,
                    62.5% {
                      top: -25000px;
                    }

                    72%,
                    75% {
                      top: -30000px;
                    }

                    84.5%,
                    87.5% {
                      top: -35000px;
                    }

                    96.66%,
                    100% {
                      top: -40000px;
                    }
                  }

                  .pool-ul9 {
                    animation: run9 45s;
                  }
                  
                  @keyframes run9 {
                    0% {
                      top: 0;
                    }

                    8.11%,
                    11.11% {
                      top: -5000px;
                    }

                    19.22%,
                    22.22% {
                      top: -10000px;
                    }

                    30.33%,
                    33.33% {
                      top: -15000px;
                    }

                    41.44%,
                    44.44% {
                      top: -20000px;
                    }

                    52.55%,
                    55.55% {
                      top: -25000px;
                    }

                    63.66%,
                    66.66% {
                      top: -30000px;
                    }

                    74.77%,
                    77.77% {
                      top: -35000px;
                    }
                    
                    85.88%,
                    88.88% {
                      top: -40000px;
                    }

                    96.66%,
                    100% {
                      top: -45000px;
                    }
                  }

                  .pool-ul10 {
                    animation: run10 50s;
                  }
                  
                  @keyframes run10 {
                    0% {
                      top: 0;
                    }

                    7%,
                    10% {
                      top: -5000px;
                    }

                    17%,
                    20% {
                      top: -10000px;
                    }

                    27%,
                    30% {
                      top: -15000px;
                    }

                    37%,
                    40% {
                      top: -20000px;
                    }

                    47%,
                    50% {
                      top: -25000px;
                    }

                    57%,
                    60% {
                      top: -30000px;
                    }

                    67%,
                    70% {
                      top: -35000px;
                    }
                    
                    77%,
                    80% {
                      top: -40000px;
                    }

                    87%,
                    90% {
                      top: -45000px;
                    }

                    96.66%,
                    100% {
                      top: -50000px;
                    }
                  }

                  .pool-ul11 {
                    animation: run11 55s;
                  }
                  
                  @keyframes run11 {
                    0% {
                      top: 0;
                    }

                    6.1%,
                    9.1% {
                      top: -5000px;
                    }

                    15.2%,
                    18.2% {
                      top: -10000px;
                    }

                    24.3%,
                    27.3% {
                      top: -15000px;
                    }

                    33.3%,
                    36.3% {
                      top: -20000px;
                    }

                    42.4%,
                    45.4% {
                      top: -25000px;
                    }

                    51.5%,
                    54.5% {
                      top: -30000px;
                    }

                    60.6%,
                    63.6% {
                      top: -35000px;
                    }

                    69.7%,
                    72.7% {
                      top: -40000px;
                    }

                    78.8%,
                    81.8% {
                      top: -45000px;
                    }

                    97%,
                    90% {
                      top: -50000px;
                    }

                    96.66%,
                    100% {
                      top: -550000px;
                    }
                  }

                  .pool-ul12 {
                    animation: run12 60s;
                  }
                  
                  @keyframes run12 {
                    0% {
                      top: 0;
                    }

                    5.33%,
                    8.33% {
                      top: -5000px;
                    }

                    13.66%,
                    16.66% {
                      top: -10000px;
                    }

                    22%,
                    25% {
                      top: -15000px;
                    }

                    30.33%,
                    33.33% {
                      top: -20000px;
                    }

                    38.66%,
                    41.66% {
                      top: -25000px;
                    }

                    47%,
                    50% {
                      top: -30000px;
                    }

                    55.33%,
                    58.33% {
                      top: -35000px;
                    }

                    63.66%,
                    66.66% {
                      top: -40000px;
                    }
                    
                    72%,
                    75% {
                      top: -45000px;
                    }
                    
                    80.33%,
                    83.33% {
                      top: -50000px;
                    }

                    88.66%,
                    91.66% {
                      top: -55000px;
                    }

                    96.66%,
                    100% {
                      top: -60000px;
                    }
                  }
                }
                .room1-img {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  // img {
                  //   width: 50px;
                  //   height: 50px;
                  // }
                  .yzb_img {
                    width: 149px;
                    height: 83px;
                  }

                  // .room1-text2 {
                  //   margin-top: -20px;
                  //   font-size: 16px;
                  //   color: #848492;
                  // }
                  // .room1-text1 {
                  //   margin-top: -20px;
                  //   font-size: 30px;
                  //   color: #e9b10e;
                  // }
                  .room1-win {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: auto;
                      height: 150px;
                    }
                  }

                  .room-back {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 85px;
                      height: 46px;
                    }
                  }
                }

                button {
                  padding: 12px 40px;
                  background-color: #e9b10e;
                  border-radius: 20px;
                  color: #1a1c24;
                  font-size: 14px;
                  font-weight: 600;
                  border: none;
                }

                button:hover {
                  cursor: pointer;
                }
              }

              .room2 {
                // margin-top: 20px;
                text-align: center;
                height: 129px;
                
                .room2-tou {
                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                  }
                }

                .room2-name {
                  font-size: 14px;
                  color: #fff;
                  margin-top: 8px;
                }

                span {
                  font-size: 14px;
                  color: #fff;
                  display: inline-block;
                  padding-top: 40px;
                }
              }
            }
          }
          .li1:nth-child(2){
            height: 604px;
            border-radius: 25px;
            background: url(../assets/newimg/duizhankuang2.png) no-repeat;
            background-size: 100% 100%;
            overflow: hidden;
            padding: 25px 0;

            .room-warp {
              height: 100%;
              overflow: hidden;

              .room1 {
                width: 80%;
                margin: 0 auto;
                height: 129px;
                line-height: 200px;
                // background: url(../assets/newimg/winbg.png) no-repeat;
                background-size: 100% 100%;
                // border-top: 1px solid #0a9cd3;
                // border-bottom: 1px solid #0a9cd3;
                text-align: center;
                position: relative;
                overflow: hidden;
                border-bottom: 1px solid rgb(52, 53, 80);

                // margin-top: 10px;
                .pool {
                  overflow: hidden;
                  position: absolute;
                  top: 10px;
                  left: 8px;
                  width: 94%;
                  height: 85%;
                  border-radius: 10px;
                  background-image: linear-gradient(#2d2d36, #483856);
                  background-size: 100% 100%;
                  z-index: 2;

                  ul {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    animation: run 3s;
                    animation-timing-function: ease; //动画 速度一样
                    animation-iteration-count: 1; //播放几次动画
                    animation-fill-mode: forwards; //动画结束 是否保持

                    li {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      background: url(../assets/newimg/kailistbg.png) no-repeat;
                      background-size: 100% 100%;

                      .pool-img {
                        height: 200px;

                        img {
                          height: 100px;
                          width: auto;
                          margin: 0 auto;
                          margin-top: -10px;
                        }
                      }
                    }
                  }
                  @keyframes run {
                    0% {
                      top: 0;
                    }

                    100% {
                      top: -5000px;
                    }
                  }

                  .pool-ul2 {
                    animation: run2 9s;
                    animation-timing-function: ease-out !important; //动画慢 快 慢
                  }

                  @keyframes run2 {
                    0% {
                      top: 0;
                    }

                    40%,
                    50% {
                      top: -5000px;
                    }

                    90%,
                    100% {
                      top: -10000px;
                    }
                  }

                  .pool-ul3 {
                    animation: run3 14s;
                  }

                  @keyframes run3 {
                    0% {
                      top: 0;
                    }

                    26.66%,
                    33.33% {
                      top: -5000px;
                    }

                    60%,
                    66.66% {
                      top: -10000px;
                    }

                    93.33%,
                    100% {
                      top: -15000px;
                    }
                  }

                  .pool-ul4 {
                    animation: run4 19s;
                  }

                  @keyframes run4 {
                    0% {
                      top: 0;
                    }

                    20%,
                    25% {
                      top: -5000px;
                    }

                    45%,
                    50% {
                      top: -10000px;
                    }

                    70%,
                    75% {
                      top: -15000px;
                    }

                    95%,
                    100% {
                      top: -20000px;
                    }
                  }

                  .pool-ul5 {
                    animation: run5 24s;
                  }

                  @keyframes run5 {
                    0% {
                      top: 0;
                    }

                    16%,
                    20% {
                      top: -5000px;
                    }

                    36%,
                    40% {
                      top: -10000px;
                    }

                    56%,
                    60% {
                      top: -15000px;
                    }

                    76%,
                    80% {
                      top: -20000px;
                    }

                    96%,
                    100% {
                      top: -25000px;
                    }
                  }

                  .pool-ul6 {
                    animation: run6 29s;
                    // animation-delay: 1s; //动画运行前等待时间
                  }

                  @keyframes run6 {
                    0% {
                      top: 0;
                    }

                    13.33%,
                    16.66% {
                      // transform: scale(1);
                      top: -5000px;
                    }
                    
                    30%,
                    33.33% {
                      // transform: scale(1);
                      top: -10000px;
                    }

                    46.66%,
                    50% {
                      // transform: scale(1);
                      top: -15000px;
                    }

                    63.33%,
                    66.66% {
                      // transform: scale(1);
                      top: -20000px;
                    }

                    80%,
                    83.33% {
                      // transform: scale(1);
                      top: -25000px;
                    }

                    96.66%,
                    100% {
                      top: -30000px;
                    }
                  }
                  .pool-ul7 {
                    animation: run7 34s;
                  }
                  
                  @keyframes run7 {
                    0% {
                      top: 0;
                    }

                    11.29%,
                    14.29% {
                      top: -5000px;
                    }

                    25.58%,
                    28.58% {
                      top: -10000px;
                    }

                    39.87%,
                    42.87% {
                      top: -15000px;
                    }

                    53.16%,
                    57.16% {
                      top: -20000px;
                    }

                    67.45%,
                    71.45% {
                      top: -25000px;
                    }

                    81.74%,
                    85.74% {
                      top: -30000px;
                    }

                    96.66%,
                    100% {
                      top: -35000px;
                    }
                  }
                  .pool-ul8 {
                    animation: run8 39s;
                  }
                  
                  @keyframes run8 {
                    0% {
                      top: 0;
                    }

                    9.5%,
                    12.5% {
                      top: -5000px;
                    }

                    22.5%,
                    25% {
                      top: -10000px;
                    }

                    34.5%,
                    37.5% {
                      top: -15000px;
                    }

                    47%,
                    50% {
                      top: -20000px;
                    }

                    59.5%,
                    62.5% {
                      top: -25000px;
                    }

                    72%,
                    75% {
                      top: -30000px;
                    }

                    84.5%,
                    87.5% {
                      top: -35000px;
                    }

                    96.66%,
                    100% {
                      top: -40000px;
                    }
                  }

                  .pool-ul9 {
                    animation: run9 44s;
                  }
                  
                  @keyframes run9 {
                    0% {
                      top: 0;
                    }

                    8.11%,
                    11.11% {
                      top: -5000px;
                    }

                    19.22%,
                    22.22% {
                      top: -10000px;
                    }

                    30.33%,
                    33.33% {
                      top: -15000px;
                    }

                    41.44%,
                    44.44% {
                      top: -20000px;
                    }

                    52.55%,
                    55.55% {
                      top: -25000px;
                    }

                    63.66%,
                    66.66% {
                      top: -30000px;
                    }

                    74.77%,
                    77.77% {
                      top: -35000px;
                    }
                    
                    85.88%,
                    88.88% {
                      top: -40000px;
                    }

                    96.66%,
                    100% {
                      top: -45000px;
                    }
                  }

                  .pool-ul10 {
                    animation: run10 49s;
                  }
                  
                  @keyframes run10 {
                    0% {
                      top: 0;
                    }

                    7%,
                    10% {
                      top: -5000px;
                    }

                    17%,
                    20% {
                      top: -10000px;
                    }

                    27%,
                    30% {
                      top: -15000px;
                    }

                    37%,
                    40% {
                      top: -20000px;
                    }

                    47%,
                    50% {
                      top: -25000px;
                    }

                    57%,
                    60% {
                      top: -30000px;
                    }

                    67%,
                    70% {
                      top: -35000px;
                    }
                    
                    77%,
                    80% {
                      top: -40000px;
                    }

                    87%,
                    90% {
                      top: -45000px;
                    }

                    96.66%,
                    100% {
                      top: -50000px;
                    }
                  }

                  .pool-ul11 {
                    animation: run11 54s;
                  }
                  
                  @keyframes run11 {
                    0% {
                      top: 0;
                    }

                    6.1%,
                    9.1% {
                      top: -5000px;
                    }

                    15.2%,
                    18.2% {
                      top: -10000px;
                    }

                    24.3%,
                    27.3% {
                      top: -15000px;
                    }

                    33.3%,
                    36.3% {
                      top: -20000px;
                    }

                    42.4%,
                    45.4% {
                      top: -25000px;
                    }

                    51.5%,
                    54.5% {
                      top: -30000px;
                    }

                    60.6%,
                    63.6% {
                      top: -35000px;
                    }

                    69.7%,
                    72.7% {
                      top: -40000px;
                    }

                    78.8%,
                    81.8% {
                      top: -45000px;
                    }

                    97%,
                    90% {
                      top: -50000px;
                    }

                    96.66%,
                    100% {
                      top: -550000px;
                    }
                  }

                  .pool-ul12 {
                    animation: run12 59s;
                  }
                  
                  @keyframes run12 {
                    0% {
                      top: 0;
                    }

                    5.33%,
                    8.33% {
                      transform: scale(1);
                      top: -5000px;
                    }

                    13.66%,
                    16.66% {
                      transform: scale(1);
                      top: -10000px;
                    }

                    22%,
                    25% {
                      transform: scale(1);
                      top: -15000px;
                    }

                    30.33%,
                    33.33% {
                      transform: scale(1);
                      top: -20000px;
                    }

                    38.66%,
                    41.66% {
                      transform: scale(1);
                      top: -25000px;
                    }

                    47%,
                    50% {
                      transform: scale(1);
                      top: -30000px;
                    }

                    55.33%,
                    58.33% {
                      transform: scale(1);
                      top: -35000px;
                    }

                    63.66%,
                    66.66% {
                      transform: scale(1);
                      top: -40000px;
                    }
                    
                    72%,
                    75% {
                      transform: scale(1);
                      top: -45000px;
                    }
                    
                    80.33%,
                    83.33% {
                      transform: scale(1);
                      top: -50000px;
                    }

                    88.66%,
                    91.66% {
                      transform: scale(1);
                      top: -55000px;
                    }

                    96.66%,
                    100% {
                      top: -60000px;
                    }
                  }
                }

                .room1-img {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  // img {
                  //   width: 50px;
                  //   height: 50px;
                  // }
                  .yzb_img {
                    width: 149px;
                    height: 83px;
                  }

                  // .room1-text2 {
                  //   margin-top: -20px;
                  //   font-size: 16px;
                  //   color: #848492;
                  // }
                  // .room1-text1 {
                  //   margin-top: -20px;
                  //   font-size: 30px;
                  //   color: #e9b10e;
                  // }
                  .room1-win {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: auto;
                      height: 150px;
                    }
                  }

                  .room-back {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 85px;
                      height: 46px;
                    }
                  }
                }

                button {
                  padding: 12px 40px;
                  background-color: #e9b10e;
                  border-radius: 20px;
                  color: #1a1c24;
                  font-size: 14px;
                  font-weight: 600;
                  border: none;
                }

                button:hover {
                  cursor: pointer;
                }
              }

              .room2 {
                // margin-top: 20px;
                text-align: center;
                height: 129px;
                
                .room2-tou {
                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                  }
                }

                .room2-name {
                  font-size: 14px;
                  color: #fff;
                  margin-top: 8px;
                }

                span {
                  font-size: 14px;
                  color: #fff;
                  display: inline-block;
                  padding-top: 40px;
                }
              }
            }
          }
          // .room-line:before {
          //   content: "";
          //   display: block;
          //   width: 100%;
          //   height: 2px;
          //   position: absolute;
          //   z-index: 8;
          //   top: 104px;
          //   left: 0;
          //   background-color: #e9b10e;
          //   box-shadow: 0px 0px 7px #e9b10e;
          // }
        }
      }

      .roompe-line {
        position: absolute;
        left: 0;
        top: 104px;
        width: 100%;
        height: 3px;
        z-index: 9;
        background-color: #e9b10e;
      }

      // .roompe-warp::before {
      //   content: "";
      //   display: block;
      //   width: 0;
      //   height: 0;
      //   border-top: 10px solid transparent;
      //   border-left: 20px solid #e9b10e;
      //   border-bottom: 10px solid transparent;
      //   position: absolute;
      //   left: 0;
      //   top: 95px;
      //   z-index: 10;
      // }
      // .roompe-warp::after {
      //   content: "";
      //   display: block;
      //   width: 0;
      //   height: 0;
      //   border-top: 10px solid transparent;
      //   border-right: 20px solid #e9b10e;
      //   border-bottom: 10px solid transparent;
      //   position: absolute;
      //   right: 0;
      //   top: 95px;
      //   z-index: 10;
      // }
    }

    .room-left-bot {
      margin-top: 100px;
      color: #848492;
      font-size: 14px;

      span {
        color: #e9b10e;
      }

      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .win-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    img {
      height: 22px;
      width: auto;
      margin-right: 5px;
    }

    span {
      // margin-left: 5px !important;
      font-size: 18px !important;
      color: #e9b10e !important;
      padding-top: 0 !important;
    }
  }

  .win-title2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;

    img {
      height: 22px;
      width: auto;
      margin-right: 5px;
    }

    span {
      // margin-left: 5px !important;
      font-size: 18px !important;
      color: #e9b10e !important;
      padding-top: 0 !important;
    }
  }

  //开奖列表
  .win-list {
    width: 80%;
    margin: 0 auto;
    padding: 10px 20px 30px 20px;
    height: 300px;
    overflow: scroll;
    .win-ul {
      height: 320px;
      // height: 10%;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      margin: 0 auto;
      overflow-y: scroll;
      overflow-x: hidden;
      display: -webkit-box;

      // .win-li:nth-child(2n) {
      //   background: url(../assets/newimg/room5.png) no-repeat;
      //   background-size: 100% 100%;
      // }
      .threeimgs{
        width: 100% !important;
      }
      .win-li {
        width: 47%;
        height: 100px;
        // background: linear-gradient(
        //   180deg,
        //   #183230 0%,
        //   rgba(1, 1, 1, 0.5) 100%
        // );
        // background: url(../assets/newimg/room5.png) no-repeat;
        background-size: 100% 100%;
        border-radius: 10px;
        border: 1px solid;
        box-sizing: border-box;
        margin-left: 2%;
        margin-bottom: 2%;

        .win-warp {
          .img {
            max-height: 55px;
            padding: 0 10px;

            img {
              margin-left: 10%;
              width: 80%;
              height: auto;
            }
          }

          h5 {
            margin: 0px 4px 15px 4px;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: center;
          }

          h6 {
            padding: 0 4px 0px 4px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              height: 10px;
              width: auto;
            }

            span {
              margin-left: 5px;
              font-size: 16px;
              color: #ffc400;
              font-weight: 400;
            }
          }
        }
      }
    }

    .win-ul::-webkit-scrollbar {
      display: none;
      width: 8px;
      height: 8px;
    }

    .win-ul::-webkit-scrollbar-thumb {
      // height: 2px;
      border-radius: 10px;
      background: rgba(210, 206, 206, 0.2);
    }

    .win-ul::-webkit-scrollbar-track {
      border-radius: 0;
      background: rgba(58, 59, 61, 0.2);
    }
  }
}

@media screen and (max-width: 767px) {
  .room .room-left .room-left-box .roombox-warp {
    height: auto !important;
    // margin-top: 30px !important;
  }
}
</style>
