import {request} from "../index";

// 武器饰品导航
function getluckyType(){
    return request({
        url: `/api/luckyopenbox/type`,
        method:'get'
    })
}
// 武器饰品列表
function getluckyList(page = 1,type_id = 0,name = '',start = 0,end = 0,sort = 1){
    if(name != ''){
        return request({
            url: `/api/luckyopenbox/list?page=${page}&type_id=${type_id}&name=${name}&sort=${sort}`,
            method:'get'
        })
    }
    if(end == 0){
        return request({
            url: `/api/luckyopenbox/list?page=${page}&type_id=${type_id}&sort=${sort}`,
            method:'get'
        })
    }
    if(end != 0){
        return request({
            url: `/api/luckyopenbox/list?page=${page}&type_id=${type_id}&start=${start}&end=${end}&sort=${sort}`,
            method:'get'
        })
    }
}
// 武器饰品搜索
function getluckySearch(page,name){
    return request({
        url: `/api/luckyopenbox/search?name=${name}&page=${page}`,
        method:'get'
    })
}
// 武器详情
function getluckydetail(id){
    return request({
        url: `/api/skins/detail?id=${id}`,
        method:'get'
    })
}
// 武器开启
function getluckyOpen(data){
    return request({
        url: `/api/luckyopenbox/open`,
        method:'post',
        data
    })
}
//赠送
function usergive(data){
    return request({
        url: `/api/users/give`,
        method:'post',
        data
    })
}
function usersearch(data){
    return request({
        url: `/api/users/search`,
        method:'post',
        data
    })
}
// 幸运开箱历史掉落
function getluckyhistory(id,sign,time){
    return request({
        url: `/api/luckyopenbox/history?id=${id}&sign=${sign}&time=${time}`,
        method:'get',
    })
}

// 我的最近掉落
function meHistory(page){
    return request({
        url: `/api/luckyopenbox/me-history?page=${page}`,
        method:'get'
    })
}
// 我的最近掉落
function getHistory(id,sign,time){
    return request({
        url: `/api/luckyopenbox/history?id=${id}&sign=${sign}&time=${time}`,
        method:'get'
    })
}

export default {
    getluckyType,
    getluckyList,
    getluckySearch,
    getluckydetail,
    getluckyOpen,
    getHistory,
    meHistory,
    getluckyhistory,
    usergive,
    usersearch
}
