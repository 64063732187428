<template>
  <div class="pay">
    <div class="pay-warp">
      <!-- <div class="pay-title">充值Z币</div>
      <div class="pay-hint">
        <div class="pay-hint-right">Z币明细></div>
      </div> -->
      <div class="pay-nav">
        <!-- <div
          class="nav_item"
          :class="nav == 0 ? 'active' : ''"
          @click="changeNav(0)"
        >
          微信充值
        </div> -->
        <div
          class="nav_item"
          :class="nav == 1 ? 'active' : ''"
          @click="changeNav(1)"
        >
          支付宝支付
        </div>
      </div>

      <div class="pay-ment">
        <!-- <div class="ment-three" v-if="firstGiveValidate">
          <span class="ment-three">限时活动：<span class="time">{{firstGive.start_time ? firstGive.start_time :"即日起"}}</span> <span class="time">至{{firstGive.end_time}}截止</span>  {{firstGive.desc}}</span>
          充值将在10分钟内到账
        </div>
        <div class="ment-three" v-else-if="ordinaryValidate">
          <span v-if="ordinaryActivity.start_time && ordinaryActivity.end_time">
            活动时间 {{ordinaryActivity.start_time}} 至 {{ordinaryActivity.end_time}}
          </span>
          <span v-if="ordinaryActivity.start_time && !ordinaryActivity.end_time">
            活动时间 {{ordinaryActivity.start_time}} 起
          </span>
          <span v-if="!ordinaryActivity.start_time && ordinaryActivity.end_time">
            截至 {{ordinaryActivity.end_time}}
          </span>
          <span v-if="!ordinaryActivity.start_time && !ordinaryActivity.end_time">
            即日起
          </span>
          <span v-if="ordinaryActivity.money>0 && ordinaryActivity.limit>0">
            单笔充值{{ordinaryActivity.money}}钻(含)以上,{{ordinaryActivity.limit}}(含)以下+送{{ordinaryActivity.billie}}%,
          </span>
          <span v-if="ordinaryActivity.money==0 && ordinaryActivity.limit>0">
            单笔充值{{ordinaryActivity.limit}}钻(含)以下+送{{ordinaryActivity.billie}}%,
          </span>
          <span v-if="ordinaryActivity.money>0 && ordinaryActivity.limit==0">
            单笔充值{{ordinaryActivity.money}}钻(含)以上+送{{ordinaryActivity.billie}}%,
          </span>
          <span v-if="ordinaryActivity.money==0 && ordinaryActivity.limit==0">
            单笔充值+送{{ordinaryActivity.billie}}%,
          </span>
          充值将在10分钟内到账
        </div>
        <div class="ment-three" v-else>充值将在10分钟内到账</div> -->
        <div class="ment-list">
          <ul>
            <li
              v-for="(item, index) in list"
              :key="index"
              :class="tab == index ? 'ment-state' : ''"
              @click="weixinMoney(index, item)"
            >
              <div>
                <div class="list-top">
                  <img src="../assets/img/money.png" />
                  <div class="list-con">{{ item.money }}</div>
                </div>
                <div class="list-warp">
                  <!-- <div class="list-bot" v-if="item.presenter != 0">
                    赠送{{ item.presenter }}%
                  </div> -->
                </div>
              </div>
            </li>
          </ul>
          <!-- <div class="list-warp1">
            <div class="list-top1">自定义</div>
            <div style="display: flex; align-items: center">
              <div class="list-input">
                <span class="list-span1" @click="remInput">-</span>
                <el-input
                  class="input"
                  @focus="focusInput"
                  @input="getInput"
                  type="number"
                  v-model="zdyMoney"
                />
                <span class="list-span2" @click="addInput">+</span>
              </div>
              <div class="list-bot">≈ ￥ {{ allMoney }}</div>
            </div>
          </div> -->
          <div class="ment-btn">
            <div class="el-btn" :disabled="loading" @click="payAction()">
              <i v-if="loading" class="el-icon-loading"></i>立即充值
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="payBox"
      width="320px"
      center
      top="5%"
      :before-close="handleClose"
    >
      <div slot="title" class="dialog-title">
        <div>
          <img :src="require('../assets/img/' + payTitleIcon)" />
          <!-- <span class="title-text">{{ payTitle }}</span> -->
        </div>
      </div>
      <div style="min-height: 160px">
        <div id="qrcode" ref="qrcode" style="margin-left: 60px" />
      </div>
      <div class="save-qrcode">
        <el-button type="warning" @click="saveImg">保存二维码</el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <img src="../assets/img/scan.svg" />
        <div>
          <p>{{ payFooter }}</p>
          <p>{{ payFooterDesc }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      nav: 1,
      loading: false,
      payTitle: "",
      payTitleIcon: "alipay.svg",
      payFooter: "",
      payFooterDesc: "",
      payBox: false,
      payType: "wechat",
      list: [],
      rmb: 0,
      ratioList: [],
      firstGiveValidate: false,
      ordinaryValidate: false,
      firstGive: {}, //新用户活动
      ordinaryActivity: {}, //非新用户充值活动
      is_new: false, //是否是新用户
      userInfo: {},
      zdyMoney: "",
      tab: 0,
      moneyValue: "",
      billie: "",
      allMoney: 0,
      idss:2
    };
  },
  mounted() {
    this.payBox = false;
    this.amountList();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.userInfo = userInfo;
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      return flag;
    },
    changeNav(index) {
      this.nav = index;
      if (this.nav == 0) {
        this.payTitleIcon = "wechat.svg";
      } else {
        this.payTitleIcon = "alipay.svg";
      }
    },
    //充值送多少
    // getRatio() {
    //   this.$axios.get("index/User/giveAboutRecharge").then((res) => {
    //     let data = res.data;
    //     console.log(data);
    //     if (data.status == 1) {
    //       this.ratioList = data.data;
    //     }
    //   });
    // },
    //input框
    getInput() {
      this.allMoney = (Number(this.zdyMoney) * 1).toFixed(2);
    },
    focusInput() {
      this.tab = -1;
    },
    //加
    addInput() {
      this.tab = -1;
      this.zdyMoney++;
      this.allMoney = (Number(this.zdyMoney) * 1).toFixed(2);
    },
    //减
    remInput() {
      this.tab = -1;
      if (this.zdyMoney <= 1) return;
      this.zdyMoney -= 1;
      this.allMoney = (Number(this.zdyMoney) * 1).toFixed(2);
    },
    amountList() {
      let _this = this;
      _this
        .$post("/api/pay/chargeInfoList")
        .then((res) => {
          if (res.status == 1) {
            _this.list = res.data.list;
            _this.moneyValue = res.data.list[0].money;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    payAction(t) {
      if (this.tab == -1) {
        if (this.zdyMoney < 1) {
          this.$message({
            message: "金额有误",
            type: "warning",
          });
          return;
        }
      }

      if (this.nav == 0) {
        this.pay("1");
      } else {
        this.pay("2");
      }
    },
    pay(type) {
      let _this = this;
      console.log(_this);
      
      // /api/pay/recharge
      let url = type == "weixin" ? "/api/wx_pay/pay" : "/api/third_pay/pay";
      let money = _this.tab == -1 ? _this.zdyMoney : _this.moneyValue;
      
      _this
        .$post(url, { id: _this.idss , pay_way: type})
        .then((res) => {
          _this.loading = false;
          if (res.code == 1) {
            const isPhone = this._isMobile();
            console.log("isPhone", isPhone);
            if (isPhone) {
              window.open(res.data.qr_url,'_self')
              // location.href = res.data.url;
              console.log(res.data,'123123123');
              
              // window.open(res.data.url);
            } else {
              console.log("zhifuhou", res);
              _this.payBox = true;
              _this.loading = false;
              _this.$nextTick(() => {
                const qrCode = new QRCode("qrcode", {
                  width: 160,
                  height: 160,
                  text: res.data.url,
                });
              });
            }

            // if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
            //   console.log("当前设备是移动设备");
            //   window.open(res.data.url);
            // } else {
            //   console.log("当前设备是pc设备");
            // }
          } else {
            _this.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    handleClose() {
      this.payBox = false;
      this.$refs.qrcode.innerHTML = "";
      this.$router.go(0);
    },
    saveImg() {
      var canvasData = this.$refs.qrcode.getElementsByTagName("canvas");
      var a = document.createElement("a");
      var event = new MouseEvent("click");
      a.href = canvasData[0].toDataURL("image/png");
      a.download = "支付二维码";
      a.dispatchEvent(event);
    },
    weixinMoney(index, item) {
      this.tab = index;
      this.moneyValue = item.money;
      this.idss=item.id

      console.log(this.idss);
      
    },
    zhifuMoney(index) {
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.list[index].state = true;
    },
  },
};
</script>

<style lang="less" scoped>
.pay {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 100%;
  background: url(../assets/newimg/img_45.png) no-repeat;
  background-size: 100% 100%;

  ::v-deep .el-dialog {
    background-color: #333542 !important;
    .dialog-title {
      // background-color: rgba(0, 0, 0, 0.8);
    }
  }
  .pay-warp {
    // width: 1400px;
    // width: 90%;
    max-width: 1200px;
    padding: 30px 0px;
    box-sizing: border-box;
    margin: 0 auto;
    // background: rgba(0, 0, 0, 0.3);
  }
  .pay-title {
    font-size: 20px;
    color: #c3c3e2;
  }
  .pay-nav {
    width: 100%;
    height: 51px;
    //margin-top:20px;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    // border-bottom: 3px solid #ffc400;
  }
  .nav_item {
    width: 164px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    background-size: contain;
    font-weight: 700;
    cursor: pointer;
  }
  .nav_item:first-child {
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    background-size: 100% 100%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ffffff;
  }
  .nav_item:first-child.active {
    height: 68px;
    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    color: #000;
    background-size: 100% 100%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    line-height: 68px;
  }
  .nav_item:nth-child(2).active {
    height: 68px;
    background: url(../assets/newimg/manghe-active.png) no-repeat 50%;
    color: #000;
    background-size: 100% 100%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ff5cfd;
    line-height: 68px;
  }
  .nav_item:nth-child(2) {
    background: url(../assets/newimg/manghe.png) no-repeat 50%;
    margin-left: -20px;
    background-size: 100% 100%;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-style: italic;
    color: #ffffff;
  }
  .pay-hint {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pay-hint-left {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #848492;
      }
      img {
        width: auto;
        height: 16px;
      }
    }
    .pay-hint-right {
      font-size: 16px;
      color: #848492;
    }
    .pay-hint-right:hover {
      cursor: pointer;
      color: #e9b10e;
    }
  }
  .pay-ment {
    margin-top: 30px;

    .ment-one {
      display: flex;
      justify-content: space-between;
      color: #848492;
      font-size: 16px;
      span {
        color: #17b4ed;
      }
      span:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .ment-two {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 32px;
        color: #c3c3e2;
        margin-left: 15px;
      }
      img {
        // width: 50px;
        height: auto;
      }
    }
    .ment-three {
      margin-top: 20px;
      text-align: center;
      font-size: 20px;
      color: #e9b10e;
    }
    .ment-list {
      margin-top: 30px;
      // display: flex;
      // justify-content: center;
      width: 713px;
      min-height: 650px;
      background: url(../assets/newimg/img_01.png) no-repeat;
      background-size: 100% 100%;
      margin: 0 auto;
      padding: 90px 68px;
      box-sizing: border-box;
      .list-warp1 {
        .list-top1 {
          font-size: 16px;
          color: #fff;
          margin-bottom: 20px;
        }
        .list-bot {
          font-size: 20px;
          color: #fff;
          margin-left: 34px;
        }
        .list-input {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          z-index: 66;
          width: 228px;
          height: 54px;
          background: url(../assets/newimg/img_64.png) no-repeat;
          background-size: 100% 100%;
          z-index: 0;
          /deep/ .el-input {
            width: auto;
          }
          .input /deep/ .el-input__inner {
            color: #fff;
            background: none;
            border: none;
          }

          span {
            font-size: 30px;
            color: #fff;
            padding: 0 16px;
          }
          .list-span1 {
            cursor: pointer;
            margin-right: 20px;
            border-right: 2px solid;
            border-image: linear-gradient(
                180deg,
                rgba(221, 255, 0, 0) 0%,
                #ddff00 51%,
                rgba(221, 255, 0, 0) 100%
              )
              2 2 2 2;
          }
          .list-span2 {
            cursor: pointer;
            margin-left: 20px;
            border-left: 2px solid;
            border-image: linear-gradient(
                180deg,
                rgba(221, 255, 0, 0) 0%,
                #ddff00 51%,
                rgba(221, 255, 0, 0) 100%
              )
              2 2 2 2;
          }
        }
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        li:nth-child(3n) {
          margin-right: 0;
        }
        li.ment-state {
          background: url(../assets/newimg/img_66.png) no-repeat;
          background-size: 100% 100%;
        }
        li {
          width: 179px;
          background: url(../assets/newimg/img_65.png) no-repeat;
          background-size: 100% 100%;
          height: 120px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          margin-bottom: 25px;
          .list-top {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 28px;
              height: 28px;
            }
            .list-con {
              color: #fff;
              font-size: 27px;
              margin-left: 9px;
            }
          }
          .list-warp {
            margin-top: 10px;
            .list-bot {
              text-align: center;
              font-size: 16px;
              color: #fff;
              white-space: nowrap;
            }
          }
          .ment-state {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            border: 1px solid #e9b10e;
            background-color: rgba(233, 177, 14, 0.1);
          }
          .give {
            position: absolute;
            top: 1px;
            left: 0;
            width: 100%;
            height: 30px;
            line-height: 30px;
            margin-left: 1px;
            background-color: #e9b10e;
            color: #1a1c24;
            font-size: 12px;
            text-align: center;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
          }
        }

        li:hover {
          cursor: pointer;
        }
      }
    }
    .ment-btn {
      margin-top: 50px;
      display: flex;
      justify-content: center;

      .el-btn {
        width: 164px;
        height: 45px;
        line-height: 45px;
        text-align: center;

        background: url(../assets/newimg/img_15.png) no-repeat;
        background-size: 100% 100%;
        height: 44px;
        padding: 0;
        font-size: 16px;
        color: #fff;
      }
      /*span:hover {
        cursor: pointer;
      }*/
    }
  }

  .pay-ment /deep/ .el-tabs__item.is-active {
    color: #c3c3e2;
  }
  .pay-ment /deep/ .el-tabs__item {
    color: #848492;
    font-size: 20px;
  }
  .el-button--warning {
    background-color: #e9b10e;
    color: #1a1c24;
    font-weight: 600;
  }
}
</style>

<style lang="less">
// .el-dialog {
//   background-color: rgba(0, 0, 0, 0.8);
// }
// .el-dialog {
//   display: flex;
//   flex-direction: column;
//   margin: 0 !important;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   min-height: 415px;
//   min-width: 300px;
//   background-color: #333542;
//   color: #c3c3e2;
//   font-size: 18px;
//   line-height: 44px;
// }
// .el-dialog__header {
//   background-color: #333542;
// }

// .dialog-title {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #333542;
//   div {
//     display: flex;
//     align-items: center;
//     img {
//       width: 25px;
//       margin: 0 16px 0 0;
//     }
//   }
// }

// .el-dialog__body {
//   flex: 1;
//   overflow: auto;
//   background-color: #333542;
// }
// .dialog-footer {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #2b2c37;
//   img {
//     max-width: 29px;
//     max-height: 29px;
//     margin-right: 16px;
//   }
//   div > p {
//     font-size: 12px;
//     line-height: 16px;
//     display: block;
//   }
// }
// .el-dialog__footer {
//   background-color: #2b2c37;
// }
.save-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.img-r0 {
  width: 40% !important;
}
.img-r1 {
  width: 50% !important;
}
.img-r2 {
  width: 60% !important;
}
.img-r3 {
  width: 70% !important;
}
.img-r4 {
  width: 80% !important;
}
.time {
  font-size: 14px;
}
.no-p {
  height: 23px !important;
}
.no-p-1 {
  height: 0 !important;
}
</style>
