<template>
  <div class="bot-right-inform" v-if="$store.state.informState">
    <div ref="informInput" class="inform-left">
      <div class="cont">
        <div ref="informcon" class="span" :style="{ left: listTop + 'px' }">
          <span id="span">{{ noticeData }} </span>
        </div>
      </div>
    </div>
   <div class="right-sildes">
      <div class="right-btn" ref="informRights">
        <img src="../assets/image/index/rightNav.png" alt="" @click="sidleLeft" />
      </div>
      <div class="inform-right" ref="informRight">
        <div class="li" @click="openKf">
          <img src="../assets/newimg/img_32.png" alt="" />
          <span>客服</span>
        </div>
        <div class="li" @click="openQQ">
          <img src="../assets/newimg/img_33.png" alt="" />
          <span>官方群</span>
        </div>
        <div class="li" @click.stop="isFuliShow">
          <img   src="../assets/img/fuli.png" /> 
          <span>福利中心</span>
          <!-- <img style="
            cursor: pointer;
          " class="img3"  src="../assets/img/fuli.png" /> -->
        </div>
      </div>
   </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      informState: true,
      screenWidth: document.body.clientWidth,
      noticeData: "",
      listTop: 0,
      informInputWidth: 0,
    };
  },
  watch: {
    screenWidth(val) {
      console.log(val);
    },
  },
  methods: {
    sidleLeft(){
      console.log(this.$refs.informRights);
      if( this.$refs.informRight.style.right=='-50px'){
        // transform: rotate(360deg);
        this.$refs.informRights.style.transform='rotate(0)';
        this.$refs.informRights.style.right='50px'
        this.$refs.informRight.style.right='0px'
      }else{
        this.$refs.informRights.style.transform='rotate(180deg)';
        this.$refs.informRights.style.right='0px'
        this.$refs.informRight.style.right='-50px'
      }

    },
    isFuliShow() {
      this.$emit('isFuliShow')
    },
    openKf() {
      const url =
        "https://tb.53kf.com/code/client/24771999ce4e057eb15ab4be9786ccf45/1";
      window.open(url, "_blank");
    },
    openQQ() {
      console.log("7777777");
      this.$bus.$emit("openQQnum", true);
    },
    getNotice() {
      let _this = this;
      _this
        .$post("/api/index/notice")
        .then((res) => {
          if (res.code == 1) {
            _this.noticeData = res.data.content;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //取消顶部通知
    getInformState() {
      this.$store.commit("getInform", false);
    },
    getSpanWidth() {
      var span = document.getElementById("span");
      var spanWidth = span.offsetWidth;

      // let style = document.createElement('style');
      // style.setAttribute('type', 'text/css');
      // document.head.appendChild(style);
      // let sheet = style.sheet;
      // let random = Math.floor(Math.random()*190)+11905;
      // sheet.insertRule(
      //   `@keyframes run``{
      //     0% {
      //       left: 0;
      //     }
      //     100% {
      //       left: -`+random+`px
      //     }
      // }`,0);
    },
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {
      const scrollTop = () => {
        _this.listTop -= 1;
        _this.informInputWidth = _this.$refs.informInput.clientWidth;
        if (_this.listTop == -_this.$refs.informcon.clientWidth) {
          _this.listTop = _this.informInputWidth; //重置top
        }
        const request = requestAnimationFrame(scrollTop);
      };
      scrollTop();
    });

    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        _this.screenWidth = window.screenWidth;
      })();
    };
    _this.getSpanWidth();
    this.getNotice();
  },
};
</script>

<style lang="less" scoped>
.right-sildes{
  position: fixed;
  right: 0;
  top: 50%;
  
}
.right-btn{
  position: relative;
  z-index: 9999999;
  top: 100px;
  right: 50px;
  transition: all 1s;
  
  img{
    z-index: 9999999;
    width: 50px;
  }
}
.span{
  position: relative;
}
.bot-right-inform {
  max-width: 1200px;
  // width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 11;
  .inform-left {
    width: 100%;
    height: 32px;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    // background: rgba(255, 255, 255, 0.2);
    // border-radius: 51px;
    position: relative;
    overflow: hidden;
    background: url(../assets/img/topnav.png) no-repeat center;
    background-size: 100% 100%;
    .cont {
      width: 100%;
      height: 92px;
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 20px;
      overflow: hidden;
      
      span {
        margin-left: 20px;
        font-size: 14px;
        color: #fffae8;
        white-space: nowrap;
        //	text-overflow: ellipsis;
        // overflow: hidden;
      }
      span {
        position: relative;
        right: 0;
        //animation: marquee 30s linear infinite;
        // @keyframes marquee {
        //   0% {
        //     right: 0;
        //   }
        //   100% {
        //     right: 1400px;
        //   }
        // }
      }
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  .inform-right {
    position: fixed;
    right: 0;
    top: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    // background: #000;
    transition: all 1s;
    .li {
      width: 50px !important;
      padding: 10px !important;
      background: url(../assets/newimg/silde1.png) no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      font-size: 12px;
      flex-direction: column;
      @media screen and (max-width: 1000px) {
        width: 30px;
        display: flex;
        // align-items: center;
        color: #ffffff;
        // margin-left: 46px;
        background: url(../assets/newimg/silde1.png) no-repeat;
        background-size: 100% 100%;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
      }
    }
    img {
      width: 50%;
      height: 50%;
      left: 0;
      top: 0;
      @media screen and (max-width: 1000px) {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
    span {
      font-size: 12px;
      color: #fff;
      @media screen and (max-width: 1000px) {
        width: 100%;
        display: block;
        margin-left: 0px !important;
        position: relative;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #fff;
        margin-top: 8%;
        text-align: center;
      }
    }
  }
  .inform-right:hover {
    cursor: pointer;
  }
  .tz {
    width: 30px;
    height: 30px;
    background: #ff9b0b;
    border-radius: 50%;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -9px;
      margin-top: -9px;
    }
  }
}
@media screen and (max-width: 767px) {
  // .inform-left{
  //   width: 100%;
  //   height: 10px;
  // }
}
</style>
