<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">关于我们</div>
      <div class="con" v-html="content">
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: "",
      content: "",
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.getData();
  },
  methods: {
    // goLucky() {
    //   this.$router.push({ path: "/" });
    // },
    getData() {
      let _this = this;
      _this
        .$post("/api/common/get_config")
        .then((res) => {
          if (res.code == 1) {
            _this.content=res.data.about_us
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  // overflow-y: scroll;
  width: 100%;
  height: 60vh;
  background-color: #1a1c24;

  .doubt-warp {
    width: 90%;
    margin: 0 auto;
  }
  .title {
    padding: 20px 0;
    font-size: 24px;
    color: #848492;
  }
  .con {
    font-size: 14px;
    color: #848492;
    span {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
</style>
