<template>
    <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class="bg-size-100 bg-repeat"
         :style="`width:100%; background-image:url(${require('@/assets/image/index/rollactivebg4.png')})`">

        <div class="d-flex justify-content-between p-2">
            <div class="dura_alias d-md-block " style="font-size: 12px;">
                {{resData.dura_alias}}
            </div>
            <div class="d-flex align-items-center">
                <img style="width:15px" src="../../../assets/img/money.png" />{{resData.price}}
            </div>
        </div>

        <div class="bg-size bg-repeat position-relative d-flex
        justify-content-center align-items-center border-bottom-0">
            <img style="height: 100px" class="position-relative z-index-1 "
                 :src="resData.imageUrl" alt="">
        </div>
        <div style="line-height:2" class="px-2 pb-2 text-center text-overflow-1" >
            <span class="rounded px-1 d-inline-flex text-overflow-1  panda-bg" style="height: 30px ">
                {{resData.itemName}}
            </span>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "weapon",
        props:{
            // 传输武器整体宽度
            width:{
                type:String,
                default:"180px"
            },
            // 武器显示的高度，宽度和整体宽度是一样的
            // heightWeapon:{
            //     type:String,
            //     default:"150px"
            // },
            // 传输背景 style 对象形式传输
            bgSize:{
                type:Object,
                default(){
                    return {
                        height: '102px'
                    }
                }
            },
            // 武器对应的宽度 高度 带单位 style 对象形式传输
            // weaponSize:{
            //     type:Object,
            //     default(){
            //         return {
            //             width: '115px',
            //             marginTop:'25px'
            //         }
            //     }
            // },
            //    传输的item
            resData:{
                type:Object,
                default(){return {}}
            },
            index:{
                type:Number,
                default:0
            }
        },
        computed:{
            mathM(){
                return Math.floor(Math.random()*4) + 1
            }
        }
    }
</script>

<style scoped lang="less">
    .weapon{
        &-name{
            padding: 10px 0;
        }
    }
    .dura_alias{
        position: relative;
        padding-left: 10px;
        line-height: 1.2;
        &::before{
            content: "";
            width: 5px;
            height: 100%;
            background-color: var(--pan-bottom);
            position: absolute;
            left: 0;
        }
    }
</style>
